<template>
    <div>
        <!-- <el-affix :offset="52" > -->
            <div class="colorwhite stickyheader">
                <el-row>
                    <el-col :span="12">
                        <div class="headerstyle">Role's</div>
                    </el-col>
                    <el-col :span="12">
                        <div class="headerstyle">
                            <el-button 
                                class="floatright minebtn"
                                type="primary"
                                @click="addrole"
                                icon="circle-plus-filled"
                                :disabled="!editpermission">
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
                <hr class="spaceBetween" />
            </div>
        <!-- </el-affix> -->

        <el-table :data="tableData.roles" class="tableBox" border style="width: 100%">
            <el-table-column label="Role" width="160">
                <template #default="scope">    
                    <el-input v-model="scope.row.name"></el-input>
                </template>
            </el-table-column>

            <el-table-column label="Users" width="180">
                <template #default="scope">
                    <el-select
                        v-model="scope.row.usersid"
                        multiple
                        collapse-tags
                        placeholder="Select"
                        @change="userchanged(scope.row, $event)"
                        class="fullwidth">
                            <el-option
                            v-for="item in getuserlist(scope.row.usersid)"
                            :key="item.id"
                            :label="item.firstname + ' ' + item.lastname"
                            :value="item.id">
                            </el-option>
                    </el-select>
                </template>
            </el-table-column>

            <el-table-column v-for="(item) in tableData.pages" :key="item" align="center">
                <template #header>
                    <div> {{ item.displayname.split('>')[0] }} </div>
                </template>

                <el-table-column align="center">
                    <template #header>
                        <div class="breakword"> {{ item.name }} </div>
                    </template>

                    <el-table-column align="center" width="110">
                        <template #header>
                            <div>
                                <span class="paddingleft8px">
                                    <el-tooltip effect="dark" content="None" placement="top">
                                        <b>N</b>
                                    </el-tooltip>
                                </span>
                                <span class="paddingTB0pxRL10px"></span>
                                <span>
                                    <el-tooltip effect="dark" content="Read" placement="top">
                                        <b>R</b>
                                    </el-tooltip>
                                </span>
                                <span class="paddingTB0pxRL10px"></span>
                                <span>
                                    <el-tooltip effect="dark" content="Read + Edit" placement="top">
                                        <b>R+E</b>
                                    </el-tooltip>
                                </span>
                            </div>
                        </template>

                        <template #default="scope">
                            <div> 
                                <span> <el-checkbox v-model="scope.row['none'+item.id]" size="large" @change="permissionchanged(scope.row, item, 'none')" /> </span>
                                <span class="paddingTB0pxRL10px"></span>
                                <span> <el-checkbox v-model="scope.row['read'+item.id]" size="large" @change="permissionchanged(scope.row, item, 'read')" /> </span>
                                <span class="paddingTB0pxRL10px"></span>
                                <span> <el-checkbox v-model="scope.row['readedit'+item.id]" size="large" @change="permissionchanged(scope.row, item, 'readedit')" /> </span>
                            </div>
                        </template>        
                    </el-table-column>
                </el-table-column>



                <!-- <el-table-column>
                    <el-table-column label="None" align="center">
                        <template #default="scope">
                            <span> <el-checkbox v-model="scope.row['none'+item.id]" @change="permissionchanged(scope.row, item, 'none')" /></span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Read" align="center">
                        <template #default="scope">
                            <span> <el-checkbox v-model="scope.row['read'+item.id]" @change="permissionchanged(scope.row, item, 'read')" /></span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Read + Edit" align="center">
                        <template #default="scope">
                            <span> <el-checkbox v-model="scope.row['readedit'+item.id]" @change="permissionchanged(scope.row, item, 'readedit')" /></span>
                        </template>
                    </el-table-column>
                </el-table-column> -->
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            issave: false,
            selectoption: []
        }
    },

    computed: {
        getuserlist(){
            return function (data) {
                if(!this.selectoption){
                    return [];
                }

                var ttss = [];
                ttss = this.selectoption.filter(x => {
                    if(data.length > 0){
                        var isfin = data.find(t => t == x.id);
                        if(isfin){
                            return x;
                        }
                        
                        if(!x.isuseruse){
                            return x;
                        }
                    } else {
                        if(!x.isuseruse){
                            return x;
                        }
                    }
                    
                });
                return ttss;
            }
        },
    },

    methods: {
        getRolePermissionData: function() {
            this.showloading();
            this.$http.post("account/getRolePermissionData")
                .then((response) => {
                    this.tableData = response.data;
                    this.selectoption = response.data.users;
                    this.tableData.roles.forEach(rl => {
                        if(rl.usersid.length > 0){
                            rl.usersid.forEach(el => {
                                this.selectoption.forEach(tts => {
                                    if(tts.isuseruse == undefined){
                                        tts.isuseruse = false;
                                    }
                                    if(tts.id  == el){
                                        tts.isuseruse = true;
                                    }
                                });
                            });
                        }
                        this.tableData.pages.forEach(p => {
                            let founditem = rl.rpr.find(rpi => rpi.pageid == p.id);
                            if(founditem){
                                let permissionname = founditem.permission == 2 ? 'readedit' :  founditem.permission == 1 ? 'read' : 'none';
                                rl[permissionname + p.id] = true;
                            } else {
                                rl['none' + p.id] = true;
                            }
                        });
                    });
                    this.hideloading();
                })
                .catch((err) => {
                    this.hideloading();
                    this.apierror(err);
                });
        },

        permissionchanged: function(row, pageitem, permissionname) {
            let permission = 0;
            if (!row.name.trim() || row.usersid.length == 0) {
                row['read' + pageitem.id] = false;
                row['readedit' + pageitem.id] = false;
                row['none' + pageitem.id] = false;

                let style = '<style> .el-message-box {width:35%;} </style>';
                this.$alert("Role name and Users should not be empty." + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                return;
            } else {
                if (permissionname == "none") {
                    row['read' + pageitem.id] = false;
                    row['readedit' + pageitem.id] = false;
                }

                if (permissionname == "read") {
                    row['none' + pageitem.id] = false;
                    row['readedit' + pageitem.id] = false;
                    permission = 1;
                }

                if (permissionname == "readedit") {
                    row['none' + pageitem.id] = false;
                    row['read' + pageitem.id] = false;
                    permission = 2;
                }
            }

            var newobj = { roles: [], pages: [], users: []};
            if (row.id == 0) {
                newobj.role = { id: row.id, name: row.name, usersid: [...row.usersid] }
                newobj.rp = { id: 0, roleid: row.id, permission: permission, pageid: pageitem.id }
            } else {
                let foundrolepermission = { id: 0 };
                if (row.rpr && row.rpr.length > 0) {
                    foundrolepermission = row.rpr.find(rpi => rpi.pageid == pageitem.id);
                    if (!foundrolepermission) {
                        foundrolepermission = { id: 0 };
                    }
                }
                newobj.role = { id: row.id, name: row.name, usersid: [...row.usersid] }
                newobj.rp = { id: foundrolepermission.id, roleid: row.id, permission: permission, pageid: pageitem.id }
            }
            this.saveroledata(newobj);
        },

        saveroledata: function(rv) {
            this.loading = true;
            if (!this.issave) {
                this.issave = true;
                this.$http.post("account/saveRolePermissionData", rv)
                    .then((response) => {
                        this.$message({
                            showClose: true,
                            message: response.data,
                            type: "success"
                        });
                        this.loading = false;
                        this.issave = false;
                        this.getRolePermissionData();
                })
                .catch((err) => {
                    this.loading = false;
                    this.issave = false;
                    this.apierror(err);
                });
            }
        },

        addrole: function() {
            var foundunsavedrow =  this.tableData.roles.find(r => r.id == 0);
            if (!foundunsavedrow) {
                var roleitem = { id: 0, name: '', usersid: [] };
                this.tableData.roles.push(roleitem);
            }
        },

        userchanged: function(row, value) {
            if (value.length == 0) {
                this.selectoption.filter(s => {
                    if (s.isuseruse == true && s.roleid == row.id) {
                        s.isuseruse = false;
                    }
                });
            } else {
                value.forEach(va => {
                    this.selectoption.forEach(x => {
                        if (x.id == va) {
                            x.isuseruse = true;
                        }
                    });
                });
            }
            this.selectoption = JSON.parse(JSON.stringify(this.selectoption));
        },

        getUsersList: function () {
            this.loading = true;
            this.$http.post("account/GetUsers")
                .then((response) => {
                    var json = JSON.parse(response.data);
                    this.userslist = json;
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    this.apierror(err);
                });
        }
    },

    created() {
        this.getRolePermissionData();
    },
}
</script>