<template>
    <div class="fullwidth">
        <div id="dialoguecontainer">
            <div class="stickymanu">
            <!-- <el-affix :offset="0"> -->
                <nav-menu ></nav-menu>
            <!-- </el-affix> -->
            </div>
        <div class="padleftmain">
        <router-view :key="$route.index" />
        </div>
        <directionsdialogue v-if="$store.state.currentdirection" :myoption="$store.state.currentdirection">
      </directionsdialogue>
      <el-dialog
    v-model="installDialogVisible"
    title="System Notification"
    width="500"
    class="dialog__body_padding"
    align-center
  >
  <div style="height: 108px;">
    <div style="line-height:2;">
        <span>Would you like to install TSA Time Tracking on your device?</span>
        <br>
        <span>Get the Windows app for a better experience:</span>
    </div>
    <br>
    <div style="padding-left: 29px;">
        <div class="floatleft">
            <ui class="spaced-list">
                <li><span style="position: relative;left: -6px;"> </span></li>
                <li><span style="position: relative;left: -6px;">  </span></li>
                <li><span style="position: relative;left: -6px;">  </span></li>
            </ui>
        </div>
        <div class="floatleft">
            <ui style="list-style-type: none;  " class="spaced-list-text"> 
                <li><span > Faster performance </span></li>
                <li><span > Instant notifications and updates </span></li>
                <li><span > Easier access with one-click desktop shortcuts </span></li>
            </ui>
        </div>
    </div>
</div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="installDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="install">
            Download
        </el-button>
      </div>
    </template>
  </el-dialog>
    </div>
    </div>
</template>
<script>
    import NavMenu from './components/NavMenu.vue';
    import directionsdialogue from "./components/directionsdialogue.vue";
    export default {
        name: 'App',
        data(){
            return {
                installDialogVisible:false,
                deferredPrompt:null,
            }
        },
        components: {
            "NavMenu":NavMenu,
            "directionsdialogue":directionsdialogue
        },
        methods: {
            install(){
                this.installDialogVisible = false;
                if(this.$store.state.deferredPrompt != null){
                    this.$store.state.deferredPrompt.prompt();
                }
            },
            getSubscription(reg) {
                var self = this;
                reg.pushManager.getSubscription().then(function (sub) {
                    if (sub === null) {
                        reg.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: "BAEjM-n2xXy6extzCs9I3xYbAe2HoUXyi43Kd96STCUeaarBBFZCipC3n7mSaM5OO975cQ4N09VMcHu2TU78_mE"
                        }).then((sub) => {
                            self.fillSubscribeFields(sub);
                        }).catch(function (e) {
                            console.error("Unable to subscribe to push", e);
                        });
                    } else {
                        self.fillSubscribeFields(sub);
                    }
                });
            },

            fillSubscribeFields(sub) {
                this.$store.state.subscription.endpoint = sub.endpoint;
                this.$store.state.subscription.p256dh = this.arrayBufferToBase64(sub.getKey("p256dh"));
                this.$store.state.subscription.auth = this.arrayBufferToBase64(sub.getKey("auth"));
            },

            arrayBufferToBase64(buffer) {
                var binary = '';
                var bytes = new Uint8Array(buffer);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                return window.btoa(binary);
            },
            requestNotificationAccess(reg) {
                var self = this;
                Notification.requestPermission(function (status) {
                    // $("#GiveAccess").hide();
                    if (status == "granted") {
                        // $("#form").show();
                        self.getSubscription(reg);
                    } else {
                        // $("#NoSupport").show();
                    }
                });
            },
        clearBadge() {
            if (navigator.clearAppBadge) {
            navigator.clearAppBadge();
            } else if (navigator.clearExperimentalAppBadge) {
            navigator.clearExperimentalAppBadge();
            } else if (window.ExperimentalBadge) {
            window.ExperimentalBadge.clear();
            }
        },
        setdialogwidth(){
            this.$nextTick(() => {
                const dialogBody = document.querySelector('.dialog__body_padding .el-dialog__body');
                if (dialogBody) {
                    dialogBody.style.paddingBottom = "24px";
                    dialogBody.style.paddingTop = "0px";
                }
            });
        },
        },
        mounted() {
            this.clearBadge();
            this.$direction.setcomponent(this);
            
            window.addEventListener('beforeinstallprompt', (e) => {
                // Prevent the mini-info bar from appearing.
                e.preventDefault();
                this.installDialogVisible = true;
                this.setdialogwidth();
                // Stash the event so it can be triggered later.
                this.$store.state.deferredPrompt = e;
                // this.deferredPrompt = e;
            });
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register("/sw.js")
                    .then((reg) => {
                        if (Notification.permission === "granted") {
                            // $("#form").show();
                            this.getSubscription(reg);
                            //this.pushManagerpushnotification(reg)
                        } else if (Notification.permission === "blocked") {
                            // $("#NoSupport").show();
                        } else {
                            // $("#GiveAccess").show();
                            this.requestNotificationAccess(reg);
                        }
                    });
            } 
        },
    }
</script>
<style scoped>
.spaced-list li{
  padding-bottom: 5px;
  padding-top: 1px;
}
.spaced-list-text li{
  padding-bottom: 4px;
  padding-top: 1px;
}
</style>