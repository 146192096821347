<template>
    <div>
        <div v-if="!roleaddedit" class="userrole">
             <!-- <el-affix :offset="52" > -->
             <div class="colorwhite stickyheader">
                 <el-row>
                     <el-col :span="12">
                        <div class="headerstyle">Role's</div>
                     </el-col>
                     <el-col :span="12">
                         <div class="headerstyle">
                             <el-button 
                           class="floatright minebtn"
                           icon="circle-plus-filled"
                           type="primary"
                           @click="addrole"
                           :disabled="!editpermission">
                           </el-button>
                         </div>
                     </el-col>
                 </el-row>
                 <hr class="spaceBetween" />
             </div>
             <!-- </el-affix> -->
            <el-table :border="true" :data="roles" class="fullwidth">

                <el-table-column prop="name" label="Roles" width="270"></el-table-column>
                  
                <el-table-column align="center" label="Actions" width="90">
                    <template #default="scope">
                        <el-row :gutter="5">
                            <el-col :span="10">
                                <el-button class="minebtn"
                                           type="primary"
                                           :icon="editpermission == true ? 'edit-pen' : 'view'"
                                           @click="handleEdit(scope.row)">
                                </el-button>
                            </el-col>

                            <el-col :span="14">
                                <el-button type="danger"
                                           class="minebtn"
                                           :disabled="!editpermission"
                                           icon="delete"
                                           @click="deleterole(scope.row)">
                                    
                                </el-button>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="roleaddedit" class="userrole">
             <!-- <el-affix :offset="50" > -->
                 <div class="colorwhite stickyheader">
                     <el-row>
                         <el-col :span="12">
                                <div class="headerstyle">Role Add/Edit</div>
                         </el-col>
                         <el-col :span="12">
                             <div class="floatright margintb2mm">
                                 <span>
                                     <el-button :disabled="!editpermission"
                                        @click="save"
                                        type="success"
                                        icon="circle-check">Save</el-button>
                                 </span>
                                 <span>
                                     <el-button icon="back" class="marginleft5" @click="cancel">Back</el-button>
                                 </span>
                             </div>
                         </el-col>                         
                     </el-row>
                     <el-row>
                        <el-col :span="24">
                            <el-form >
                                <div class="colorwhite ">
                                    <el-form-item label="Role Name">
                                            <el-input placeholder="Role Name" v-model="form.role.name"></el-input>                        
                                    </el-form-item>
                                    <hr class="spaceBetween"/>
                                </div>
                            </el-form>
                        </el-col>
                     </el-row>
                 </div>
             <!-- </el-affix> -->
            <el-form ref="form">
                 <!-- <el-affix :offset="88">
                    <div class="colorwhite ">
                    <el-form-item label="Role Name">
                            <el-input placeholder="Role Name" v-model="form.role.name"></el-input>                        
                    </el-form-item>
                    <hr class="spaceBetween"/>
                 </div>
                 </el-affix> -->
                
                <el-table :border="true" :cell-class-name="cellClassName" :data="form.rolepermissionpages" class="fullwidth">
                    <el-table-column prop="p.displayname" label="Pages" width="250">
                        <template #default="scope">
                            <div class="cursorpointer"
                                @click="openpermissiondialog(scope.row)">
                                {{ scope.row.p.displayname }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="permission" label="Permission" width="110">                   
                        <template #header>
                            <el-select v-model="manepermission"
                                       @change="selectallpr"
                                       placeholder="Permissions">
                                <el-option :disabled="!editpermission"
                                           v-for="item in [ { value: 0, name: 'None' }, { value: 1, name: 'Read' }, { value: 2, name: 'Read + Edit' }]"
                                           :key="item.value"
                                           :label="item.name"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </template>

                        <template #default="scope">
                            <el-select v-model="scope.row.rp.permission"
                                       placeholder="Please Select Permission">
                                <el-option :disabled="!editpermission"
                                           v-for="item in [{ value: 0, name: 'None' }, { value: 1, name: 'Read' },{ value: 2, name: 'Read + Edit' }]"
                                           :key="item.value"
                                           :label="item.name"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>
        </div>

        <div>
            <el-dialog :title="checkpage" 
                    class="centerdialog" width="400px" 
                    :close-on-click-modal="false" :draggable="true"
                    v-model="showdialog" :before-close="beforedialogclose">
                <div v-for="item in projects" :key="item.id">
                    <span>
                        <el-checkbox size="default" 
                                :class="item.typeid == 4 ? 'bold' : ''" 
                                :label="item.name" v-model="item.accessible" />
                    </span>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="showdialog = false">Cancel</el-button>
                        <el-button type="primary" @click="confirmprojectpermission">
                            Confirm
                        </el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                manepermission: "",
                roles: [],
                roleaddedit: false,
                form: {
                    role: null,
                    rolepermissionpages: []
                },
                showdialog: false,
                projects: [],
                checkpage: 'Reports > Multiple Searches',
                changed: false
            };
        },
        methods: {
            selectallpr(event) {
                this.form.rolepermissionpages.forEach((p) => {
                    p.rp.permission = event;
                });
            },

            cancel() {
                this.form = { role: {}, rolepermissionpages: [] };
                this.roleaddedit = false;
            },

            deleterole: function (row) {
                this.$confirm("Are you sure you want to delete this role. Continue?", "Warning",
                    {
                        confirmButtonText: "OK",
                        cancelButtonText: "Cancel",
                        type: "warning"
                    }).then(() => {
                        this.$http.post("account/deleteRoleById", row)
                            .then((response) => {
                                this.$message({
                                    type: "success",
                                    message: response.data,
                                });
                                this.getroles();
                            })
                            .catch((err) => {
                               this.apierror(err);
                            });
                    }).catch(() => {
                        this.$message({
                            showClose: true,
                            type: 'info',
                            message: 'Delete canceled'
                        });
                    });
            },

            comparer(otherArray) {
                return function (current) {
                    return (
                        otherArray.filter(function (other) {
                            return other.page == current.page;
                        }).length == 0
                    );
                };
            },

            handleEdit(row) {
                this.form = { role: {}, rolepermissionpages: [] };
                this.showloading();
                this.$http.post("account/getRoleById", row)
                    .then((response) => {
                        this.form = response.data;
                        this.setdefaultpermission();
                        this.roleaddedit = true;
                        this.hideloading();
                    })
                    .catch((err) => {
                        this.apierror(err);
                    });
            },

            cellClassName({ row, column }) {
                let cls = [];
                if (column.property == "p.displayname" && row.p.displayname == this.checkpage) {
                    cls.push("tablelinkcolor", "bold");
                }
                return cls.join(" ");
            },

            validatepermission: function (row, callback) {
                var error = [];
                if (row.rp.permission === 0) {
                    error.push("Please grant Read or Read + Edit permission to this page.");
                }
                if (error.length > 0) {
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(error.join("<br />") + style, "Invalid Data", {
                        dangerouslyUseHTMLString: true,
                    });
                }
                else {
                    callback();
                }
            },

            openpermissiondialog(row) {
                this.validatepermission(row, () => {
                    if (row.p.displayname == this.checkpage) {
                        if (row.rp.accessproject != null && row.rp.accessproject.trim() != '') {
                            let oldselected = row.rp.accessproject.split(',').map(Number);
                            for (let i = 0; i < this.projects.length; i++) {
                                let element = this.projects[i];
                                if (!oldselected.includes(element.id)) {
                                    element.accessible = false;
                                }
                            }
                        }
                        this.showdialog = true;
                    }
                });
            },

            confirmprojectpermission() {
                this.changed = true;
                this.setprojectidforpermission();
                this.showdialog = false;
            },

            setdefaultpermission() {
                this.projects.forEach(x => {
                    x.accessible = true;
                });
            },

            setprojectidforpermission() {
                let currentpage = this.form.rolepermissionpages.find(x => x.p.displayname == this.checkpage);
                if (currentpage != undefined) {
                    let selectedproject = this.projects.filter(item => item.accessible === true).map(x => x.id).join(",");
                    currentpage.rp.accessproject = selectedproject;
                }
            },

            beforedialogclose(done) {
                this.showdialog = false;
                done();
            },

            getroles: function () {
                this.$http.get("account/getrole")
                    .then((response) => {
                        this.roles = response.data;
                        this.changed = false;
                    })
                    .catch((err) => {
                       this.apierror(err);
                    });
            },

            myvalidate: function (callback) {
                var msg = [];
                if (!this.form.role.name) {
                    msg.push("Role Name is required");
                }
                if (msg.length > 0) {
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(msg.join("<br />") + style, "Invalid Data", {
                        dangerouslyUseHTMLString: true,
                    });
                }
                else {
                    callback();
                }
            },

            save: function () {
                this.myvalidate(() => {
                    /*
                   var newrole = this.form.rpr.filter((x)=>{
                        var t = this.pages.find(y => x.page == y.page);
                        if(t != undefined){
                            // if (t.page == "TimeEntry > TimeEntry" || t.page == "UserLastEntry > UserLastEntry") {
                            //     this.form.istimetrackeradmin == true && x.permission == 0 ? x.permission = 1: x.permission;
                            // }
                            return x;
                        }
                    })*/
                    // this.form.rpr = newrole; 
                    var arr = this.form.rolepermissionpages.filter(x => x.rp.permission != 0);
                    var data = { role: this.form.role, rolepermissionpages: arr };
                    this.showloading();
                    this.$http.post("account/saverole", data)
                        .then((response) => {
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.form = {};
                            this.roleaddedit = false;
                            this.changed = false;
                            this.getroles();
                            this.hideloading();
                        })
                        .catch((err) => {
                            this.apierror(err);
                        });
                });
            },

            addrole: function () {
                this.form = { role: {}, rolepermissionpages: [] };
                this.showloading();
                this.$http.get("account/getpages")
                    .then(response => {
                        this.form.rolepermissionpages = response.data;                        
                        this.setdefaultpermission();
                        this.setprojectidforpermission();
                        this.roleaddedit = true;
                        this.hideloading();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.apierror(err);
                        this.roleaddedit = false;
                    });
            },
        },

        beforeRouteLeave(to, from, next) {
            let answer = true;
            if (this.changed) {
                answer = window.confirm('Do you really want to leave? you have unsaved changes!');
            }
            if (answer == true) {
                next()
                window.onbeforeunload = null;
            } else {
                next(false)
            }
        },

        created() {
            this.getroles();            
            this.getprojectbytypeid().then((data) => {
                this.projects = data;                
            });
        }
    };
</script>
<style>
   .el-select-dropdown__item {
        padding: 0 0 0 6px;
    }
</style>
