<template>
  <div>
    <div>
    <!-- <el-affix :offset="52" > -->
       <div class="colorwhite OverheadTSAwidth stickyheader">
         <div class="headerstyle">{{pagename}}</div>
         <el-row :gutter="5">
            <el-col :span="12">
                 <el-date-picker
                       v-model="view.filter[0].value"
                       type="date"
                       class="datewidth135 marginr4"
                        format="ddd DD-MMM-YYYY"
                       :editable="false"
                       :clearable="false"
                       @change="datechange($event, 0)"
                        placeholder="To Date"
                        :disabled-date="$commonutils.disableFutureDates">
                     </el-date-picker>
                 <el-date-picker
                        v-model="view.filter[1].value"
                        type="date"
                        class="datewidth135 marginr4 left"
                        format="ddd DD-MMM-YYYY"
                        :editable="false"
                        :clearable="false"
                        @change="datechange($event,1)"
                        placeholder="From Date"
                        :disabled-date="$commonutils.disableFutureDates">
                      </el-date-picker>
            </el-col>
            <el-col :span="12" >
              <div class="right">
               <span >
                <el-select 
                   v-model="view.filter[2].value"  
                   class="m-2 fixedwidth120" @change="refresh">
                    <el-option
                      v-for="item in userselectlist"
                      :key="item.name"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
               </span>
                 <span>
                <el-select v-model="hour"  @change="refresh" class="m-2 fixedwidth120 padding5px">
                    <el-option
                      v-for="item in hourselectlist"
                      :key="item.name"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                 </span>
                 <span>
                      <el-button  class="fixedwidth120" type="primary" 
                      :disabled="!editpermission" 
                      @click="getreportFun" 
                      icon="document">
                      View Report
                      </el-button>
                 </span>
                 <span style="padding-left: 5px;">
                      <el-button  class="m-2 fixedwidth120 padding5px" type="success" 
                      :disabled="!editpermission" 
                      @click="Exportreport" 
                      icon="document">
                      Export
                      </el-button>
                 </span>
              </div>
             </el-col>
          </el-row>
           <hr class="spaceBetween" />
       </div>
    <!-- </el-affix> -->
  
    <el-table
          v-if="show"
         :show-header="true"
         :border="true"
         :row-class-name="tableRowClassName"
         :span-method="arraySpanMethod"
         :data="tabledata"
         :cell-style="tablecellStyles"
         @header-click="handleWidthChange"
         class="overheadsummarytable">
         <el-table-column 
            fixed="left" 
            prop="pname" 
            resizable
            label="Overhead" 
            max-width="300"
            width="300">
            <template #default="scope">
                 <div  v-if="scope.row.rowspan == true"  style="text-align: left; font-weight: 300;background-color: #C8C8C8; color:black">
                   {{scope.row.pname}}
                 </div>
                 <div v-else>
                   {{scope.row.aname}}
                 </div>
               </template>
         </el-table-column>
         <el-table-column 
             align="right" 
             fixed="left" 
             resizable
             label="Total" 
             prop="total"
              max-width="80"
            width="80">
             <template #header>
                 {{'Total'}}
                  <div class="spaceBetween"/>
                  <div class="paddingbottomtotal">
                {{getalltotal}}
                  </div>
             </template>
              <template #default="scope">
                  {{gatotalrow(scope.row)}} 
              </template>
           </el-table-column>
         <el-table-column
            max-width="80"
            width="80"
            resizable
             v-for="column in users"
             :key="column.id"
             prop="action" >
              <template #header >
                <div>
                  {{column.firstname}}
                </div>
                <div class="spaceBetween"/>
                <div>
                   {{column.lastname}}
                </div>
             </template >
              <el-table-column 
                 align="right" 
                 resizable
                 label="Time"
                  max-width="80"
                 width="80">
                  <template #header>
                    {{gettotalcell(column)}}
                 </template>
                 <template #default="scope">
                   {{ isuser(scope.row, column) }}
                 </template>
              </el-table-column>
         </el-table-column>
    </el-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      pagename:'',
      hour:"Hours",
      userselectlist:[
        {value:"",name:'All Users'},
        {value:'1',name:'Active Users'},
        {value:null,name:'Hours Booked'},
      ],
      hourselectlist:[
           {value:'Hours',name:'Hours'},
           {value:'net',name:'Net Cost'},
           {value:'gross',name:'Gross Cost'},
      ],
       allhours:0,
       allnet:0,
       allgross:0,
      view: {
        filter: [
          {
            table: "t",
            column: "date",
            value: "",
            operation: ">=",
            paraname: "sdate",
          },
          {
            table: "t",
            column: "date",
            value: "",
            operation: "<=",
            paraname: "edate",
          },
           {
            table: "u",
            column: "active",
            value: null,
            operation: "<=",
            paraname: "edate",
          },
           {
            table: "u",
            column: "user",
            value: "",
            operation: "",
            paraname: "user",
          },
           {
            table: "a",
            column: "typeid",
            value: "",
            operation: "",
            paraname: "atypeid",
          },
        ],
      },
      tabledata: [],
      users: [],
      show:false
    };
  },
  computed:{
    getalltotal(){
       if(this.allhours != undefined && this.hour == 'Hours'){
           return  this.allhours;
        }
         if(this.allnet != undefined && this.hour == 'net'){
            if(this.allnet == 0){
              return "";
            }
           return  this.allnet.toFixed(6);
          }
          if(this.allgross != undefined &&  this.hour == 'gross'){
            if(this.allgross == 0){
              return "";
            }
           return  this.allgross.toFixed(6);
          }
          return "";
    },
  },
  methods: {
    Exportreport(){
      this.$http
        .post("reports/ExportRoverheadView", this.view,{ responseType: "blob" })
        .then((response) => {
          this.hideloading();
          var blob = response.data;
          var fileName ="Overhead.xlsx";
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          // var data = response.data.data;
          //  this.users = response.data.u;
          // var pname = "";
          //  this.getcollandrowtime(data);
          // for (var i = 0; i < data.length; i++) {
          //   if (pname != data[i].pname) {
          //     pname = data[i].pname;
          //     data.splice(i, 0, { pname: pname });
          //     data[i].rowspan = true;
          //   }
          // }
          // this.tabledata = data;
          // this.show = true;
        })
        .catch((error) => {
          this.hideloading();
          this.apierror(error);
        });
    },
      handleWidthChange(col, event) {
        const colindicies = this.$store.state.tablecellindicies;
        const index = event.currentTarget.cellIndex;
        // const oldWidth = col.realWidth;

        if (this.$route.path === "/overheadsummary" && col.level === 1) {
          this.toggleIndexInArray(colindicies.overhead1, col, index);
          /*
          if (!colindicies.overhead1.includes(index)) {
            col.width = oldWidth;
            if (col.children) {
              col.children.forEach(childCol => {
                childCol.width = oldWidth;
              });
            }
          } else {
            col.width = 30;
            if (col.children) {
              col.children.forEach(childCol => {
                childCol.width = 30;
              });
            }
          } */
        } 
        else if (this.$route.path === "/personalsummary" && col.level === 1) {
          this.toggleIndexInArray(colindicies.overhead2, col, index);
          /*
          if (!colindicies.overhead1.includes(index)) {
            col.width = oldWidth;
            if (col.children) {
              col.children.forEach(childCol => {
                childCol.width = oldWidth;
              });
            }
          } else {
            col.width = 30;
            if (col.children) {
              col.children.forEach(childCol => {
                childCol.width = 30;
              });
            }
          }*/
        }

        this.tabledata.forEach(ti => {
          if (ti.iswidthchange) {
            ti.iswidthchange = false;
          } else {
            ti.iswidthchange = true;
          }
        });
      },

    toggleIndexInArray(columninfoarr, column, colindex) {
      const colInfoIndex  = columninfoarr.findIndex(item => item.columnindex === colindex);
      const cololdwidth = column.width;

      if (colInfoIndex === -1) {
        column.width = 30;
        if (column.children) {
          column.children.forEach(childCol => {
            childCol.width = 30;
          });
        }

        columninfoarr.push({ columnindex: colindex, oldwidth: cololdwidth});
      } else {

        column.width = columninfoarr[colInfoIndex].oldwidth;
        if (column.children) {
          column.children.forEach(childCol => {
            childCol.width = columninfoarr[colInfoIndex].oldwidth;
          });
        }
        columninfoarr.splice(colInfoIndex, 1);
      }
    },

    tablecellStyles({  column,  columnIndex }) {
      const widths = this.$store.state.tablecellindicies;
      const index = columnIndex;

      if (this.$route.path === "/overheadsummary") {
        if (widths.overhead1.some(item => item.columnindex === index)) {
          column.width = 30;
        }
        // else {
        //   column.width = column.realWidth;
        // }
      } 
      else if (this.$route.path === "/personalsummary") {
        if (widths.overhead1.some(item => item.columnindex === index)) {
          column.width = 30;                                                                                                          
        }
        // else {
        //   column.width = column.realWidth;
        // }
      }
    },
     tableRowClassName({row}) {
        if (row.rowspan == true) {
          return 'overhead-row';
        } 
        return '';
      },
    getreportFun(){
      if(this.show == false){
          this.show = true;
          this.refresh();
        }
    },
    datechange(event, index) {
       if (event == null) {
        this.view.filter[index].value = "";
      }else{
           this.view.filter[index].value = moment(event)
          .format("YYYY-MM-DD").toString()
      }
      this.show = false;
    },
    arraySpanMethod({ row, columnIndex }) {
      if (columnIndex == 0) {
        if (row.rowspan == true) {
          return [1, this.users.length+3];
        }
      }
    },
    gettotalcell(data){
       if(data.total != undefined && this.hour == 'Hours'){
           return  data.total;
        }
         if(data.net != undefined && this.hour == 'net'){
            if(data.net == 0){
              return "";
            }
           return  data.net.toFixed(6);
          }
          if(data.gross != undefined &&  this.hour == 'gross'){
            if(data.gross == 0){
              return "";
            }
           return  data.gross.toFixed(6);
          }
    },
    gatotalrow(row){
          if(row.total != undefined && this.hour == 'Hours'){
           return  this.gettduration(row.total);
          }
          if(row.net != undefined && this.hour == 'net'){
            if(row.net == 0){
              return "";
            }
           return  row.net.toFixed(6);
          }
          if(row.gross != undefined &&  this.hour == 'gross'){
            if(row.gross == 0){
              return "";
            }
           return  row.gross.toFixed(6);
          }
    },
      isuser(row, col) {
       
      var colname = "col" + col.id;
      if (row[colname] != undefined && row[colname] != 0) {
             if(this.hour == 'Hours'){
                 return this.gettduration(row[colname]);
             }
             if(col.op != undefined){
                 var totalnetgross = 0
                 if(this.hour == 'net'){
                      totalnetgross =  this.getnetcost(row[colname],col);
                 }
                 if(this.hour == 'gross'){
                       totalnetgross =  this.getgrosscost(row[colname],col);          
                 }
                if( totalnetgross != 0){
                  return totalnetgross.toFixed(6);
                }else{
                  return "";
                }
             }
      }
      return "";
    },
    getmonth(date) {
      if (date != null) {
        var newdate = new Date();
        var y = date.getFullYear(),
          m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay =
          m == newdate.getMonth() ? new Date() : new Date(y, m + 1, 0);
        this.view.filter[0].value = moment(firstDay)
          .format("YYYY-MM-DD")
          .toString();
        this.view.filter[1].value = moment(lastDay)
          .format("YYYY-MM-DD")
          .toString();
        this.refresh();
      }
    },
    getgrosscost(duration,user){
      
        var h = duration / 60;
        if(user.op != undefined && h != 0){
             if(user.op.ratec != undefined && user.op.rateo != undefined){
                   var net2 =  user.op.rate / user.op.ratec.exchangerate;
                   return (net2 * h) + user.op.overhead;
               }
        }
         return 0;
    },
    getnetcost(duration,user){
   
      var h = duration / 60;
        if(user.op != undefined && h != 0){
          if(user.op.ratec != undefined){
            var net1 =  user.op.rate / user.op.ratec.exchangerate;
               return net1 * h;
          }
        }
        return 0;
    },
    gettduration(duration) {
      var h = Math.floor(duration / 60),
        m = duration % 60;
      return (h<10?"0":"") + h.toString() + ":" + (m<10?"0":"") + m.toString();
    },
    getcollandrowtime:function(data){
      var coltotalarr = {};
      var allhours = 0;
      var allnet = 0;
      var allgross = 0;
      this.allhours = 0;
      this.allnet = 0;
      this.allgross = 0;
      var newuser = [];
      data.forEach(d => {
        d.total = 0;
        d.net = 0;
        d.gross = 0
        for (const [key, value] of Object.entries(d)) {
          if(key.includes('col')){
            d.total += value;
            var id =  key.match(/\d+/g);
            var t = this.users.find(x => x.id == id);
            if(t){
              if(t.net == undefined){
                t.net = 0
              }
              if(t.gross == undefined){
                t.gross = 0
              }
              d.net += this.getnetcost(value,t);
              d.gross += this.getgrosscost(value,t);
              t.net += this.getnetcost(value,t);
              t.gross += this.getgrosscost(value,t);
               if(coltotalarr[id[0]] == undefined){
                   coltotalarr[id[0]] = 0;
                 }
                 coltotalarr[id[0]] += value;
            }
            
          }
        }
        allhours +=  d.total;    
        allnet +=  d.net;    
        allgross +=  d.gross;    
      });
        for (const [key, value] of Object.entries(coltotalarr)) {
          if(value > 0){
            var t = this.users.find(x => x.id == key);
            if(t){
              if(t.total == undefined){
                t.total = "";
              }
              t.total = this.gettduration(value);
              if(this.view.filter[2].value === null){
                newuser.push(t);

              }
            }
          }
        }
      this.allhours = this.gettduration(allhours);
      this.allnet = allnet;
      this.allgross = allgross;
      if(this.view.filter[2].value === null){
          this.users = newuser;
      }
    },
    refresh: function () {
      this.showloading();
      this.$http
        .post("reports/GetRoverheadView", this.view)
        .then((response) => {
          this.hideloading();
          var data = response.data.data;
           this.users = response.data.u;
          var pname = "";
           this.getcollandrowtime(data);
          for (var i = 0; i < data.length; i++) {
            if (pname != data[i].pname) {
              pname = data[i].pname;
              data.splice(i, 0, { pname: pname });
              data[i].rowspan = true;
            }
          }
          this.tabledata = data;
          this.show = true;
        })
        .catch((error) => {
          this.hideloading();
          this.apierror(error);
        });
    },
  },
   watch: {
        '$route.path':function(){
             this.pagename = this.$route.path == "/personalsummary" ? "Personal Summary" : "Overheads - TSA";
             if(this.pagename == "Personal Summary" && this.$store.state.user){
               this.view.filter[3].value = this.$store.state.user.id.toString();
               this.view.filter[4].value = "3";
                this.refresh();
             }else{
               this.view.filter[3].value = "";
               this.view.filter[4].value = "2";
                this.refresh();
             }
            
       }
  },
  created() {
      this.pagename = this.$route.path == "/personalsummary"? "Personal Summary" : "Overheads - TSA";
      if(this.pagename == "Personal Summary" && this.$store.state.user){
          this.view.filter[3].value = this.$store.state.user.id.toString();
          this.view.filter[4].value = "3";
       }else{
               this.view.filter[3].value = "";
               this.view.filter[4].value = "2";
             }
    if (this.tabledata.length <= 0) {
      var d = new Date();
      this.getmonth(d);
    }
  },
};
</script>
<style>
    .el-table .overhead-row {
    background: #C8C8C8;
  }

  .overheadsummarytable .cell {
    white-space: pre;
  }
</style>
