<template>
      <div>
        <div v-if="!paymentedit">
       <!-- <el-affix :offset="52" > -->
             <div class="colorwhite ipwidth-header stickyheader">
               <el-row class="">
                  <el-col :span="12">
                   <div class="headerstyle">International Payments (PL)</div>
                  </el-col>
                  <el-col :span="12">
                  <el-row class="right">                       
                       <div class="padding2px" />
                       <span>
                         <el-button class="right" type="success" icon="circle-check" @click="saveall">Save</el-button>
                       </span>
                     </el-row>
                  </el-col>
               </el-row>
               <el-row class="ipwidth-header" >
                 <span class="IPmonth-width">
                     <el-date-picker
                             v-model="newmonth"
                             type="month"
                             class="width90px"
                             :clearable="false"
                             @change="changrmonth"
                             format="MMM-YYYY"
                             value-format="MMM-YYYY"
                             placeholder="Month"
                             :disabled-date="$commonutils.disableFutureDates"
                           />
                 </span>
                 <span class="IPhours-width">
                       <el-select style="margin-left: 39px;width: 63px;" :clearable="true"   v-model="hourscheck" placeholder="Hours">
                             <el-option
                                  v-for="item in [{name:'Hours',value:'HoursBooked'},{name:'All',value:'All'}]"
                                  :key="item.value"
                                  :label="item.name"
                                  :value="item.value"
                                >
                              </el-option>
                             </el-select>
                 </span>
                  <span class="IPprovider-width">
                       <el-select style="width: 94px;" :clearable="true"   v-model="providersearchid" placeholder="Provider">
                             <el-option
                                  v-for="item in providers"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                                >
                              </el-option>
                             </el-select>
                 </span>
                 <span class="IPGBPrate-width">
                   <div>
                      <div style="font-size: 14px; margin-top: 4px; font-weight: bold; color:#606266;">
                            <div class="right"> {{ new Intl.NumberFormat('en-GB',{ style: 'currency', currency: 'GBP' }).format(GBPcosttotal)}}</div>
                      </div>
                    </div>
                 </span>
                 <span class="IPpaid-width">
                      <el-select class="right" style="width: 65px;" :clearable="false" v-model="paidsearchid" placeholder="Paid">
                              <el-option
                                   v-for="item in [{name:'Unpaid',value:false},{name:'paid',value:true},{name:'All',value:null},]"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value"
                                 >
                              </el-option>
                       </el-select>
                 </span>
               </el-row>
                <!-- <el-row class="ipwidth-header">
                      <el-col :span="6">
                         <el-date-picker
                             v-model="newmonth"
                             type="month"
                             class="daytime1"
                             :clearable="false"
                             @change="changrmonth"
                             format="MMM-YYYY"
                             value-format="MMM-YYYY"
                             placeholder="Month"
                           />
                        </el-col>
                        <el-col :span="5">
                           <el-select style="width: 94px;" :clearable="true"   v-model="hourscheck" placeholder="Hours">
                             <el-option
                                  v-for="item in [{name:'Hours Booked',value:'HoursBooked'},{name:'All',value:'All'}]"
                                  :key="item.value"
                                  :label="item.name"
                                  :value="item.value"
                                >
                              </el-option>
                             </el-select>
                        </el-col>
                        <el-col :span="9">
                            <el-select style="margin-left: 39px;width: 94px;" :clearable="true"   v-model="providersearchid" placeholder="Provider">
                             <el-option
                                  v-for="item in providers"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                                >
                              </el-option>
                             </el-select>
                        </el-col>
                        <el-col :span="3">
                        <div class="right" >
                          <div class="el-input el-input--mini" style="width: 54px;font-size: 14px;font-weight: bold; color:#606266;">
                            <div   > {{ new Intl.NumberFormat('en-GB',{ style: 'currency', currency: 'GBP' }).format(GBPcosttotal)}}</div>
                          </div>
                          </div>
                        </el-col>
                        <el-col :span="1">
                       <span>
                          <div  > {{ new Intl.NumberFormat('en-GB',{ style: 'currency', currency: 'GBP' }).format(GBPcosttotal)}}</div>
                        </span>
                        <span>
                          <el-select class="right" style="width: 72px;" :clearable="false" v-model="paidsearchid" placeholder="Paid">
                              <el-option
                                   v-for="item in [{name:'Unpaid',value:false},{name:'paid',value:true},{name:'All',value:null},]"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value"
                                 >
                              </el-option>
                       </el-select>
                        </span> -->
                        <!-- <el-row :gutter="5">
                        <el-col :span="14">
                          <div class="el-input el-input--mini" style="font-size: 14px;font-weight: bold; color:#606266;" >
                           <div  class="right"> {{ new Intl.NumberFormat('en-GB',{ style: 'currency', currency: 'GBP' }).format(GBPcosttotal)}}</div>
                          </div>
                        </el-col>
                        <el-col :span="9">
                            <el-select class="right" :clearable="false" v-model="paidsearchid" placeholder="Paid">
                              <el-option
                                   v-for="item in [{name:'Unpaid',value:false},{name:'paid',value:true},{name:'All',value:null},]"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value"
                                 >
                              </el-option>
                       </el-select>
                       </el-col>
                       </el-row> -->
                        <!-- </el-col>
                </el-row> -->
                <hr class="spaceBetween " />
             </div>
       <!-- </el-affix> -->
        <el-table 
        :height="height"
        :data="gettabledata" 
        border 
        :cell-class-name="cellClassName"
        style="width: 100%"
        class="tablemine ipwidth"
        :header-cell-style="headercellstyle"
        :default-sort="{ prop: 'u.firstname', order: 'ascending' }"
        @sort-change="sortchange">
          <el-table-column prop="u.firstname"  sortable="custom" label="Name" width="180"> 
             <!-- <template #header>
              <div > {{'Name'}}</div>
             </template> -->
              <template #default="scope">
                  {{scope.row.u.firstname}}{{"  "}}{{scope.row.u.lastname}}
              </template>
          </el-table-column>
           <el-table-column prop="pd"  width="20"> 
              <template #default="scope">
                <b>  {{getcalculation(scope.row.pd.calculationon,'code')}} </b>
              </template>
          </el-table-column>
          <el-table-column prop="u" align="center"  label="Hours Logged" width="65"> 
             <template #header>
                 <div class="center" >
                    {{"Hours"}}
                 </div>
                 <div class="spaceBetween2"/>
                 <div class="spaceBetween2"/>
                 <div class="center">
                    {{"Logged"}}
                 </div>
             </template>
              <template #default="scope">
                  {{gettduration(scope.row.t.duration)}}
              </template>
          </el-table-column>
          <el-table-column prop="u" label="Signed Off By" width="70"> 
             <template #header>
                 <div class="center">
                    {{"Signed"}}
                 </div>
                 <div class="spaceBetween2"/>
                 <div class="center">
                    {{"Off By"}}
                 </div>
             </template>
               <template #default="{}">
                  {{"-"}}
              </template>
          </el-table-column>
           <el-table-column  width="10">
          </el-table-column>
           <el-table-column label="TSA Cost" width="300"> 
                 <el-table-column align="center" label="Cur" width="40"> 
                     <template #default="scope">
                        {{scope.row.crate.code}}
                    </template>
                </el-table-column>
                 <el-table-column align="right" label="Rate" width="60"> 
                       <template #default="scope">
                          {{scope.row.o.rate == 0 ? '' : scope.row.o.rate.toFixed(2)}}
                      </template>
                  </el-table-column>
                   <el-table-column  align="right" label="Total" width="90"> 
                     <template #default="scope">
                        {{gettotalcost(scope.row)}}
                    </template>
                </el-table-column>
                 <el-table-column  align="center" label="Inv Rec" width="55"> 
                       <template #default="scope">
                          <el-checkbox @change="canedit(scope.row)" class="mycheck" v-model="scope.row.pd.isinvoicereceived"></el-checkbox>
                      </template>
                  </el-table-column>
           </el-table-column>
            <el-table-column  width="10">
             </el-table-column>
            <el-table-column label="Payment" width="300"> 
                <el-table-column align="left" sortable="custom" prop="pr.name" label="Provider" width="100"> 
                       <template #default="scope">
                          {{scope.row.provider.name}}
                      </template>
                  </el-table-column>
                 <el-table-column  align="center" label="Cur" width="40"> 
                     <template #default="scope">
                        {{scope.row.cpayment.code}}
                    </template>
                </el-table-column>
                 <el-table-column align="right"  label="Rate" width="70"> 
                       <template #default="scope">
                         <div v-if="scope.row.cpayment.code && scope.row.crate.code && scope.row.cpayment.code === scope.row.crate.code">
                           {{getpaymentrate(scope.row) }}
                         </div>
                         <div v-else>
                         <el-input-number :precision="2" placeholder="Rate" @change="canedit(scope.row)" class="fullwidthinput inputtypenumlr6px" :controls="false" v-model="scope.row.pd.paymentcurrencyrate" />
                         </div>
                          <!-- {{scope.row.o.rate == 0 ? '' : scope.row.o.rate}} -->
                      </template>
                  </el-table-column>
                   <el-table-column align="right" label="Total" width="70"> 
                     <template #default="scope">
                        {{totalpaymentrate(scope.row)}}
                    </template>
                </el-table-column>
                 <el-table-column  label="Details" align="center" width="50"> 
                       <template #default="scope">
                          <el-button class="addddelet" @click="getPaymentDetailEdit(scope.row)" icon="edit-pen" type="primary"></el-button>
                      </template>
                  </el-table-column>
                    <el-table-column align="left" label="Transaction Reference" width="150"> 
                       <template #default="scope">
                          <!-- {{"-"}} -->
                          <el-input :controls="false"  class="fullwidthinput" @change="canedit(scope.row)" v-model="scope.row.pd.transactionreference" />
                      </template>
                  </el-table-column>
           </el-table-column>
            <el-table-column align="right" label="GBP Cost (Inc Fees)" width="130"> 
               <template #header>
                 <div class="center">
                    {{"GBP Cost"}}
                 </div>
                 <div class="spaceBetween2"/>
                 <div class="center">
                    {{"(Inc Fees)"}}
                 </div>
             </template>
                  <template #default="scope">
                      <!-- <el-input-number class="fullwidthinput" :value="getggbpcost(scope.row.pd)" @blur="bpblur" @focus="bpfocus" :controls="false"  /> -->
                      <el-input  class="fullwidthinput GBPcosttext"  @change="canedit(scope.row)" v-model="scope.row.pd.strGDP" @blur="bpblur(scope.row.pd)" @focus="bpfocus(scope.row.pd)"  :controls="false"  />
                 </template>
             </el-table-column>
             <el-table-column  label="Paid" align="center" width="70"> 
                <template #header>
                 <div style="vertical-align: top;">
                    {{"Paid"}}
                 </div>
             </template>
                  <template #default="scope">
                     <el-checkbox  @change="canedit(scope.row)"  v-model="scope.row.pd.ispaid"></el-checkbox>
                 </template>
             </el-table-column>
        </el-table>
        <!-- <div class="spaceBetween" />
        <el-row class="ipwidth" style="font-size: 14px;font-weight: bold;
    color:#606266;">
          <el-col :span="2" :offset="21">
            
                <div style="padding-right:20px" class="right"> {{ new Intl.NumberFormat('en-GB',{ style: 'currency', currency: 'GBP' }).format(GBPcosttotal)}}</div>
             
          </el-col>
          </el-row> -->
      </div>
      <div v-if="paymentedit">
           <!-- <el-affix :offset="52" > -->
             <div class="colorwhite stickyheader">
               <div class="headerstyle">Payment Details</div>
             </div>
             <!-- </el-affix> -->
             <div  class="md-width">
               <hr class="spaceBetween" />
                  <el-form :model="editdetail" label-position="left" label-width="150px">
                     <el-form-item label="Name">                       
                             <div >
                             {{editdetail.u.firstname}} {{editdetail.u.lastname}}
                             </div>
                     </el-form-item>
                     <div class="spaceBetween" />
                     <el-form-item  label="Hours/ Total/ Nil">
                     
                          <div >
                       <el-select class="fullwidthinput" v-model="editdetail.pd.calculationon" placeholder="Please select Hours/ Total/ Nil">
                          <el-option
                              v-for="item in [{name:'Hours (H)',value:1},{name:'Total (T)',value:2},{name:'N/A (N)',value:3},]"
                              :key="item.value"
                              :label="item.name"
                              :value="item.value"
                            />
                       </el-select>
                       </div>
                     </el-form-item>
                      <div class="spaceBetween" />
                     <el-form-item  label="Other Payment Notes">
                       <el-input :autosize="{ minRows: 2, maxRows: 4 }"
                               type="textarea" v-model="editdetail.pd.paymentnote" placeholder="Other Payment Notes" />
                     </el-form-item>
                      <div class="spaceBetween" />
                     <el-form-item  label="Bank Account">
                       <el-input class="fullwidthinput" placeholder="Bank Account No" onkeypress='return event.charCode >= 48 && event.charCode <= 57' :controls="false" v-model="editdetail.pd.bankaccount" />
                     </el-form-item>
                      <div class="spaceBetween" />
                     <el-form-item  label="Sort Code">
                       <el-input :onkeypress='onkeyprace' class="fullwidthinput" placeholder="Sort Code" :controls="false" v-model="editdetail.pd.sortcode" />
                     </el-form-item>
                      <div class="spaceBetween" />
                     <el-form-item  label="IBAN">
                       <el-input v-model="editdetail.pd.iban" placeholder="IBAN" />
                     </el-form-item>
                      <div class="spaceBetween" />
                     <el-form-item  label="Cost Currency">
                       <div v-if="editdetail.crate.code">
                       {{editdetail.crate.code}}
                       </div>
                     </el-form-item>
                      <div class="spaceBetween" />
                     <el-form-item   label="Cost Rate">
                       <div v-if="editdetail.o.rate">
                       {{editdetail.o.rate}}
                       </div>
                     </el-form-item>
                      <div class="spaceBetween" />
                     <el-form-item  label="Last Updated">
                       {{  getdateformat(editdetail.o.rateon) }}
                     </el-form-item>
                      <div class="spaceBetween" />
                     <el-form-item  label="Payment Currency">
                        <el-select class="fullwidthinput" v-model="editdetail.pd.paymentcurrencyid" placeholder="Payment Currency">
                         <el-option
                              v-for="item in currencies"
                              :key="item.id"
                              :label="item.code"
                              :value="item.id"
                            />
                       </el-select>
                     </el-form-item>
                      <div class="spaceBetween" />
                     <el-form-item  label="Payment Provider">
                       <!-- <el-input v-model="editdetail.pd.providerid" /> -->
                        <el-select class="fullwidthinput" v-model="editdetail.pd.providerid" placeholder="Payment Provider">
                         <el-option
                              v-for="item in providers"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            >
                             <div v-if="item.name != null">
                              <span style="float: left">{{ item.name }}</span>
                              <el-button style="float: right"
                                         v-if="providers.length != 0"
                                         icon="edit-pen"
                                         type="text"
                                         @click="editprovider(item)"></el-button>
                        </div>
                         </el-option>
                         <el-option value="">
                        <div>
                            <el-button icon="circle-plus"
                                       type="text"
                                       class="fullwidth"
                                       @click="addprovider"></el-button>
                        </div>
                         </el-option>
                       </el-select>
                     </el-form-item>
                      <div class="spaceBetween" />
                     <el-form-item>
                       <el-button type="success" icon="circle-check" @click="savepaymentdetail">Save</el-button>
                       <el-button @click="paymentedit = false">Cancel</el-button>
                     </el-form-item>
                   </el-form>
                  <!-- <el-button class="addddelet" @click="paymentedit = false"  >Cancel</el-button> -->
             </div>
       
      </div>
       <el-dialog title="Add/Edit Provider "
                   width="400px"
                   v-model="providerdilg"
                   :before-close="closedig">
            <el-form>
                <el-form-item label="Provider Name" label-width="120px">
                    <el-input placeholder="Provider Name" v-model="provider.name"></el-input>
                </el-form-item>
                <div class="spaceBetween"/>
            </el-form>
            <template #footer>
                <span>
                    <el-button plain
                               @click="saveprovider"
                               type="success"
                               style="margin-right:150px;"
                               icon="circle-check"
                               >Save</el-button>
                </span>
            </template>
        </el-dialog>
         <!-- <el-dialog width="850px" v-model="dialogInvoiceVisible" title="Monthly Invoice">
               
                <el-row>
               <el-col :span="12" >
                <strong>User : </strong>
                 <el-select @change="ChangeInvoiceUserId" v-model="InvoiceUserId" class="m-2" placeholder="Select User">
                    <el-option
                      v-for="item in tabledata"
                      :key="item.u.id"
                      :label="item.u.firstname"
                      :value="item.u.id"
                    />
                  </el-select>
                  </el-col>
                  <el-col :span="12" >
                       <el-button plain
                             class="right"
                               @click="DownloadPDF"
                               type="primary"
                               >Invoice PDF</el-button>
                  </el-col>
                  </el-row>
                  <div class="spaceBetween" />
                  <div class="spaceBetween" />
                  <div id="InvoicePDF" ref="InvoicePDFref" class="setfontcololor">
                    <div style="background-color:#606060 !important;color:white;width: 100%;height:50%">
                      <div class="center" style="font-weight:bold;font-size:22px;">Monthly Invoice</div>
                      </div>
                    <div class="spaceBetween" />
                    <el-row>
                      <el-col :span="12" >
                      <h3 class="left"><b>Trevor Sadd Associates Ltd</b></h3>
                      </el-col>
                      <el-col :span="12" >
                      <h5 class="right" style="color:#606266">{{new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) }}</h5>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="10">
                         <el-row>
                            <el-col :span="24">
                            <el-descriptions title="" :column="1"  >
                                      <el-descriptions-item    label-align="right" align="left" >
                                      2 Hardingham Street,
                                      </el-descriptions-item>
                                      <el-descriptions-item  label=""  label-align="right" align="left" >
                                        Hingham
                                      </el-descriptions-item>
                                      <el-descriptions-item  label=""  label-align="right" align="left" >
                                       Norwich, Norfolk NR9 4JB, GB
                                      </el-descriptions-item>
                                       <el-descriptions-item  label=""  label-align="right" align="left" >
                                        United Kingdom
                                      </el-descriptions-item>
                                      </el-descriptions>
                            </el-col>
                          </el-row>
                      </el-col>
                      <el-col :offset="6" :span="8">
                           <el-row>
                             
                              <el-col :span="24">
                              <el-descriptions title="" :column="1" border >
                                        <el-descriptions-item label="Phone"  label-align="right" align="center" >
                                        +44 1953 851520
                                        </el-descriptions-item>
                                         <el-descriptions-item label="Email"  label-align="right" align="center" >
                                        enquiries@trevorsadd.co.uk
                                        </el-descriptions-item>
                                         <el-descriptions-item  label="Website"  label-align="right" align="center" >
                                         www.trevorsadd.co.uk
                                        </el-descriptions-item>
                                      </el-descriptions>
                              </el-col>
                            </el-row>
                      </el-col>
                    </el-row>
                    <div class="spaceBetween" />
                  <el-row  >
                    <el-col>
                           <el-descriptions title="" :column="2" border>
                                <el-descriptions-item
                                  label="Name"
                                  label-align="right"
                                  align="center"
                                  :column="4"
                                  v-if="InvoiceUserData.u"
                                  >{{InvoiceUserData.u.firstname}} {{" "}}{{InvoiceUserData.u.lastname}}</el-descriptions-item
                                >
                               
                                <el-descriptions-item v-if="InvoiceUserData.pd" :span="1" label="Invoice Month" label-align="right" align="center"
                                  >{{getPLMonth(InvoiceUserData.pd.createdon)}}</el-descriptions-item
                                >
                                 <el-descriptions-item  label-align="right" align="center" label="Hours/ Total/ Nil" v-if="InvoiceUserData.pd">{{getcalculation(InvoiceUserData.pd.calculationon,'name')}}</el-descriptions-item>
                                <el-descriptions-item  label="Payment Status" v-if="InvoiceUserData.pd" label-align="right" align="center"
                                  >{{InvoiceUserData.pd.ispaid == true ? "paid" : "Unpaid"}}</el-descriptions-item
                                >
                                <el-descriptions-item  label="Payment Provider" v-if="InvoiceUserData.provider" label-align="right" align="center"
                                  >{{InvoiceUserData.provider.name}}</el-descriptions-item
                                >
                                 <el-descriptions-item  label="Transaction Reference" v-if="InvoiceUserData.pd" label-align="right" align="center"
                                  >{{InvoiceUserData.pd.transactionreference}}</el-descriptions-item
                                >
                              </el-descriptions>
                               <div class="spaceBetween" />
                              <el-row>
                                <el-col  :span="8">
                                <el-descriptions title="" direction="vertical" :column="1" border>
                                   <el-descriptions-item  label="Payment Notes" v-if="InvoiceUserData.pd" label-align="left" align="left"
                                  >{{InvoiceUserData.pd.paymentnote}}</el-descriptions-item
                                >
                                </el-descriptions>
                                </el-col>
                                <el-col :offset="10"  :span="6" >
                                <el-descriptions title="" :column="1" border>
                                 <el-descriptions-item  label="Currency" v-if="InvoiceUserData.crate" label-align="right" align="center" >
                                  {{InvoiceUserData.crate.code}}
                                </el-descriptions-item>
                                <el-descriptions-item  label="Hours" v-if="InvoiceUserData.t" label-align="right" align="center" >
                                  {{gettduration(InvoiceUserData.t.duration)}}
                                </el-descriptions-item>
                                <el-descriptions-item  label="Rate" v-if="InvoiceUserData.o" label-align="right" align="center" >
                                  {{InvoiceUserData.o.rate == 0 ? '' : InvoiceUserData.o.rate}}
                                </el-descriptions-item>
                                 <el-descriptions-item  label="Total" v-if="InvoiceUserData.t && InvoiceUserData.o" label-align="right" align="center" >
                                  {{gettotalcost(InvoiceUserData)}}
                                </el-descriptions-item>
                                
                                </el-descriptions>
                                </el-col>
                              </el-row>
                        </el-col>
                  </el-row>
                  </div>
                
          </el-dialog> -->
      </div>
</template>
<script>
import moment from "moment";
import { ElMessage } from 'element-plus'
// import { jsPDF } from "jspdf";
// import html2canvas from 'html2canvas';
 export default {
        data() {
            return {
              hourscheck:"HoursBooked",
              InvoiceUserId:null,
              InvoiceUserData:{},
              dialogInvoiceVisible:false,
              height:(document.documentElement.clientHeight -170),
              GBPcosttotal:0,
              providersearchid:null,
              paidsearchid:null,
              newmonth: new Date(),
              providers:[],
              provider:{name:'',id:0},
              providerdilg:false,
              currencies:[],
              editdetail:{pd:{},u:{},o:{},crate:{}},
              paymentedit:false,
               view: {
                  pagesize:20,
                  Dfilter: {month:"",year:""},
                  sortcol:"u.firstname",
                  sortorder:"ascending"
              },
              tabledata:[],
            }
        },
        computed:{
          getPLMonth(){
            return function(data){
              var t =  new Date(data).toLocaleDateString('en-us', {  year:"numeric", month:"short"})
              if(t) {return t}
              else{return ""}
            }
          },
          gettabledata(){
            if (this.tabledata.length == 0)
            {
              return [];
            } 
           this.setgbpcosttotal()
            var fdata = this.tabledata.filter((x)=>{
              var ismatch = true;
               if(this.hourscheck == "HoursBooked")
              {
                 ismatch = x.t.duration != 0;
              }
              if(this.providersearchid &&  ismatch == true){
                 ismatch = x.pd.providerid == this.providersearchid;
              }
              if (this.paidsearchid != null &&  ismatch == true) {
                 ismatch = x.pd.ispaid == this.paidsearchid;
              }
              if (this.paidsearchid === false &&  ismatch == true) {
                ismatch = x.pd.ispaid == this.paidsearchid;
              }
              if(ismatch == true && x.pd.GBPcost){
                this.GBPcosttotal += x.pd.GBPcost;
              }
                return ismatch; 
            })
            return fdata;
          },
          totalpaymentrate(){
            return function(data){
              if (data.t) {
                    var tt =  this.gettotalcost(data);
                     if(tt) 
                      var total = Number(tt.replace(/[^0-9.-]+/g,""));
                      if(total){
                          var toral1 = data.pd.paymentcurrencyrate * total;
                          if (toral1 != typeof(NaN)) {
                            return new Intl.NumberFormat('en-GB',{minimumFractionDigits: 2}).format(toral1);
                          }
                          return "";
                      }
               }
            }
          },
          getpaymentrate(){
            return function(data){
                data.pd.paymentcurrencyrate = 1.00;
                var tt = data.pd.paymentcurrencyrate;
                return tt.toFixed(2);
            }
          },
          gettotalcost(){
            return function(data){  
                  // var d1 = parseFloat(Math.floor(data.t.duration / 60) +"."+data.t.duration % 60);
                  var d1 = data.t.duration / 60;
                if(d1){
                 var total =  d1 * data.o.rate;
                 if(total){ 
                  return total.toFixed(2);
                  // return new Intl.NumberFormat('en-GB',{minimumFractionDigits:2}).format(total)
                 }else{
                   return "";
                 }
                }
            }
          },
            getdateformat(){
              return function (data) {
                if(data){
              return  moment(data).format("YYYY-MM-DD")
                }else{
                  return "";
                }
              }
            }
        },
        methods:{
          // ChangeInvoiceUserId(id){
          
          //   // this.post("InternationalPayment/GetUserInvoice",id).then((data)=>{
          //   //    this.$nextTick(() => this.InvoiceUserData = data);
          //   // })
          //   var usedata = this.tabledata.find(x=> x.u.id == id);
          //   if(usedata){
          //     this.$nextTick(() => this.InvoiceUserData = usedata);
          //   }
          // },
          // DownloadPDF(){
           
          //     var dd = document.querySelector('#InvoicePDF');
          //     if (dd) {
          //         if (!this.InvoiceUserData.u) {
          //             ElMessage({
          //                 showClose: true,
          //                 message: 'Please Select User First!',
          //                 type: 'error',
          //             });
          //             return;
          //         }
          //       const filename = ""+this.InvoiceUserData.u.firstname+"-"+ this.InvoiceUserData.u.lastname +"-TSA-Invoice"+".pdf" 
          //        var doc = new jsPDF('l', 'mm', [dd.offsetWidth + 50, dd.offsetHeight + 50]);
          //        doc.html(dd, {
          //          callback: function (doc) {
          //            doc.save(filename);
          //          },
          //          x: 30,
          //          y: 30,
          //        });
          //     }

              //    const quality = 20 // Higher Quality results in better output
              // html2canvas(document.querySelector('#InvoicePDF'),
              //     { 
              //       scale: quality
                    
              //     }
              // ).then(canvas => {
              //       var img = canvas.toDataURL("image/png");
              //        var doc = new jsPDF('p', 'mm', 'a4');
              //         // var imgWidth = 208;
              //         var imgHeight = canvas.height * 208 / canvas.width;
              //         var position = 1;
              //         doc.addImage(img, 'PNG', 1, position, 208, imgHeight)
              //         doc.save('newPDF.pdf');
              // });
          // },
          headercellstyle(data){
            if(data.column.label == "TSA Cost" || data.column.label == "Payment" )
            {
              return { "background-color": "#c3c3c3",
                         "color": "#ffffff"}
            }else{
              return { "background-color": "#e5e0e04a",
                         "color": "#606266"}
            }
          },
          setgbpcosttotal(){
            this.GBPcosttotal= 0;
          },
           cellClassName({columnIndex }) {
                var cls = [];
                if (columnIndex == 4) {
                    cls.push("bordertbnone");
                }
                 if (columnIndex == 9) {
                    cls.push("bordertbnone");
                }
                return cls.join(" ");
            },
          onkeyprace(event){
              return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 45;
          },
          saveall(){
            var savearr = [];
             this.tabledata.filter((x)=>{
              if(x.pd.isedit == true){
                x.pd.createdon = moment(this.newmonth).format("YYYY-MM-DDTHH:mm:ss");
                x.pd.userid = x.u.id; 
                savearr.push(x.pd);
              }
            });
            if(savearr.length > 0){
               this.$http.post("internationalpayment/saveipayments", savearr)
                        .then(response => {
                            this.$message({
                           showClose: true,
                           message: response.data,
                           type: "success"
                       });
                        this.refresh();
                        })
                        .catch(error => {
                            this.apierror(error);
                        });
            }else{
              ElMessage('Nothing to Save!')
            }
          },
          canedit(data){
            data.pd.isedit = true;
          },
          bpblur(data){
           
                   var strcheck = parseFloat(data.strGDP);
                  if(isFinite(strcheck) && !isNaN(strcheck) && data.strGDP != ""){
                  data.GBPcost = strcheck;
                  data.strGDP = new Intl.NumberFormat('en-GB',{ style: 'currency', currency: 'GBP' }).format(data.GBPcost);
                }else{
                  data.GBPcost = 0;
                  data.strGDP = ""; //new Intl.NumberFormat('en-GB',{ style: 'currency', currency: 'GBP' }).format(0);
                }
           
          },
          bpfocus(data){
            data.strGDP = data.GBPcost;
          },
          changrmonth(event){
            if(event){
               this.view.Dfilter.month = moment(event).format("MM");
               this.view.Dfilter.year = moment(event).format("YYYY");
               this.refresh();
            }
          },
           gettduration(duration) {
             if(!duration){
               return "";
             }
             var h = Math.floor(duration / 60),
               m = duration % 60;
             return (h<10?"0":"") + h.toString() + ":" + (m<10?"0":"") + m.toString();
           },
          getcalculation(data,type){
          
            if(data){
              var h,t,n;
              if(type == 'code')
              {
                h = "H";
                t = "T";
                n = "N";
              }else if(type == 'name'){
                  h = "Hours (H)";
                  t = "Total (T)";
                  n = "N/A (N)";
              }
                  if (data == 1) {
                    return h;
                  }
                  if (data == 2) {
                    return t;
                  }
                  if (data == 3) {
                    return n;
                  }
            }
            return "";
          },
          savepaymentdetail(){
               this.validatepaymentdetail(() => {
                 let newdata = moment(this.newmonth).format("YYYY-MM-DDTHH:mm:ss");
               this.editdetail.pd.createdon = newdata;//this.newmonth.getFullYear().toString()+ "-"+ (this.newmonth.getMonth() + 1).toString(); // new Date(this.newmonth.getFullYear(),this.newmonth.getMonth() + 1).toString();  
               const postdata = this.editdetail.pd;
               this.post("InternationalPayment/savepaymentdetail",postdata).then((data)=>{
                     this.$message({
                           showClose: true,
                           message: data.data,
                           type: "success"
                       });
                    this.paymentedit = false;
                    this.refresh();
                 }) 
               });
          },
           validatepaymentdetail: function (callback) {
              
                var msg = [];
                if (!this.editdetail.pd.operativecostid  || this.editdetail.pd.operativecostid == 0) {
                    msg.push("Please create a new Operative Cost for this User!   Admin > Operative Cost");
                }
                if (msg.length > 0) {
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(msg.join('<br/>') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }
                else {
                    callback();
                }
            },
          addprovider(){
             this.provider={name:'',id:0};
                  this.providerdilg = true;  
          },
          editprovider(data){
            this.provider={name:'',id:0};
                this.provider = data;
                this.providerdilg = true; 
          },
          saveprovider(){
            if(!this.provider.name && !this.provider.name.trim())
            {
              this.$message({
                           showClose: true,
                           message: 'Provider Name is required',
                           type: "warning"
                       });
              return;
            }
           
             var isduplicat = this.providers.find(x=> x.name == this.provider.name)
             if(isduplicat)
             { 
               this.$message({
                        showClose: true,
                           message: 'Provider Name is already exists, Please select another Provider Name!',
                           type: "warning"
                       });
              return;
             }
               this.post("InternationalPayment/saveprovider",this.provider).then((data)=>{
                     this.$message({
                           showClose: true,
                           message: data.data,
                           type: "success"
                       });
                    this.providerdilg = false;
                       this.getproviderandcurrency().then(() =>{
                          this.provider={name:'',id:0};
                       this.providers =  this.$store.state.providers;
                      });
                 }) 
          },
          getPaymentDetailEdit(data){
               this.paymentedit = true;
              //  data.pd.userid = data.u.id;
              let newdata = moment(this.newmonth).format("YYYY-MM-DDTHH:mm:ss");
               var rdata = {userid:data.u.id,createdon:newdata,id:data.pd.id};
                this.post("InternationalPayment/getpaymentdetailedit",rdata).then((res)=>{
                  this.editdetail = res.data;
                 })
          },
            sortchange: function (sort) {
                if(sort.prop != null){
                   this.view.sortcol = sort.prop 
                   this.view.sortorder = sort.order;
                   this.refresh();
                }
            },
            refresh:function(){
                 this.post("InternationalPayment/GetInternationalPayment",this.view).then((data)=>{
                 this.tabledata = data.data.data;
                 this.tabledata.map((x)=>{
                   
                   if(x.pd.GBPcost != 0){
                     this.bpfocus(x.pd);
                     this.bpblur(x.pd);
                   }
                 })
                  this.view = data.data.view;  
                 })
            },
            getheight() {
             this.height = (document.documentElement.clientHeight - 170);
           }
        },
        unmounted() {
             window.removeEventListener('resize', this.getheight);
         },
          mounted() {
            window.addEventListener('resize', this.getheight);
          },
        created(){
           if(this.$store.state.currencies.length ==  0){
              this.getproviderandcurrency().then(() =>{
               this.currencies =  this.$store.state.currencies;
               this.providers =  this.$store.state.providers;
              });
            }else{
               this.currencies =  this.$store.state.currencies;
                 this.providers =  this.$store.state.providers;
            }
            
             var d = new Date();
             this.newmonth = moment(d).format("MMM-YYYY"); 
             this.changrmonth(d);
        },
 }
</script>
<style scoped>
.md-width{
    width:400px;
}
.setfontcololor{
  color: black;
}
.fullwidthinput{
  width: 100%;
}
.IPmonth-width{
    /* max-width: 168px; */
    width:168px;
}
.IPhours-width{
    /* max-width: 201px; */
    width:437px;
}
.IPprovider-width{
    width:473px;
}
.IPGBPrate-width{
    width:121px;
}
.IPpaid-width{
    width:79px;
}
 
 
@media only screen and (max-width: 600px) {
 .md-width{
    width:100%;
}


}
@media only screen and (max-width: 1316px) {
   .IPmonth-width{
     width:70px;
    } 
    .IPhours-width{
       width:120px;
    }
    .IPprovider-width{
       width:70px;
     }
}
</style>