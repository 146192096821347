<template>
    <div class="projectsummarywidth">
        <!-- <el-affix :offset="52"> -->
            <div class="colorwhite stickyheader">
                <div>
                    <span class="headerstyle">Project Summary</span>
                </div>
                <div class="spaceBetween" />
                <div>
                    <span class="floatleft">
                        <el-date-picker v-model="view.filter[0].value"
                                        type="date"
                                        format="ddd DD-MMM-YYYY"
                                        :editable="false"
                                        :clearable="false"
                                        class="datewidth135"
                                        @change="datechange($event, 0)"
                                        placeholder="From Date"
                                        :disabled-date="$commonutils.disableFutureDates">
                        </el-date-picker>
                    </span>
                    <span class="paddingl5 floatleft">
                        <el-date-picker v-model="view.filter[1].value"
                                        type="date"
                                        format="ddd DD-MMM-YYYY"
                                        :editable="false"
                                        :clearable="false"
                                        class="datewidth135"
                                        @change="datechange($event, 1)"
                                        placeholder="To Date"
                                        :disabled-date="$commonutils.disableFutureDates">
                        </el-date-picker>
                    </span>
                    <span>
                        <el-row class="paddingleft100">
                            <el-pagination layout="prev, pager, next"
                                           :total="view.count"
                                           :page-size="view.pagesize"
                                           @current-change="refresh"
                                           v-model:currentPage="view.pageno">
                            </el-pagination>
                        </el-row>
                    </span>
                </div>
            </div>
        <!-- </el-affix> -->
        <div class="bordertopnone beforehw afterhw">
            <el-table :data="detailform"
                      :default-sort="{prop: 'pname', order: 'descending'}"
                      @sort-change="sortchange"
                      :cell-class-name="cellClassName"
                      header-align="center"
                      :header-cell-class-name="tableHeaderColor"
                      :border="true">
                <el-table-column label="Name"
                                 prop="pname"
                                 sortable="custom"
                                 fixed="left"
                                 width="300">
                                 <template #default="scope">
                                    <div class="cursorpointer"
                                        @click="gotoprojecthourspage(scope.row)">
                                        {{ scope.row.pname }}
                                    </div>
                                </template>
                </el-table-column>
                <el-table-column label="Invoiced"
                                 align="center"
                                 class-name="boldtablecol"
                                 width="70">
                </el-table-column>
                <el-table-column label="Estimate"
                                 align="right"
                                 class-name="boldtablecol"
                                 width="70">
                </el-table-column>
                <el-table-column label="Actual"
                                 :formatter="findactual"
                                 prop="totaldurationint"
                                 align="right"
                                 width="70">
                </el-table-column>
                <el-table-column label="Base"
                                 prop="base"
                                 :formatter="findBase"
                                 align="right"
                                 width="70">
                </el-table-column>
                <el-table-column label="B/E"
                                 width="70">
                </el-table-column>
                <el-table-column width="12">
                </el-table-column>
                <el-table-column v-for="(column, index) in months"
                                 :key="index" :label="column"
                                 width="70"
                                 align="right">
                    <template #default="scope">
                        {{ getdata(scope.row, column) }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    export default {
        data() {
            return {
                detailform: [],
                view: {
                    filter: [
                        { table: "ts", column: "date", value: "", operation: ">=", paraname: "startdate" },
                        { table: "ts", column: "date", value: "", operation: "<=", paraname: "enddate" }
                    ]
                },
                basepersent: 0,
                months: []
            }
        },
        methods: {
            myvalidate: function (callback) {
                var msg = [];
                if (this.view.filter[1].value < this.view.filter[0].value) {
                    msg.push("End Date should be bigger than the Start Date.");
                }
                if (msg.length > 0) {
                    var style = '<style> .el-message-box {width:50%;} </style>'
                    this.$alert(msg.join('<br />') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }
                else {
                    callback();
                }
            },
            getdata(row, column) {
                for (const [key, value] of Object.entries(row)) {
                    if (key == column) {
                        if (value > 0) {
                            return this.convertminutetohour(value);
                        }
                        return null;
                    }
                }
            },
            getmonth(date) {
                if (date != null) {
                    var today = new Date();
                    var y = date.getFullYear(), m = date.getMonth();
                    var firstDay = new Date(y, m, 1);
                    var lastDay = m == today.getMonth() ? new Date() : new Date(y, m + 1, 0);
                    this.view.filter[0].value = moment(firstDay).format("YYYY-MM-DD").toString();
                    this.view.filter[1].value = moment(lastDay).format("YYYY-MM-DD").toString();
                    this.sortchange({ prop: 'pname', order: 'descending' });
                }
            },
            datechange(event, index) {
                if (event == null) {
                    this.view.filter[index].value = "";
                }
                else {
                    this.view.filter[index].value = moment(event).format("YYYY-MM-DD").toString();
                }
                this.refresh();
            },

            // eslint-disable-next-line no-unused-vars
            cellClassName({ row, column, rowIndex, columnIndex }) {
                var cls = [];
                if (column.property == "pname") {
                    cls.push("tablelinkcolor");
                }
                if (columnIndex == 6) {
                    cls.push("bordertbnone");
                }
                return cls.join(" ");
            },
            gotoprojecthourspage: function (row) {
                this.$confirm("Would you like to view / edit this project hours?", "Move to project hours?", {
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                    type: "" //success
                }).then(() => {
                    window.setTimeout(() => {
                        let daterange = {
                            startdate: this.view.filter[0].value,
                            enddate: this.view.filter[1].value
                        }
                        var project = { id: row.pid, name: row.pname, active: true, typeid: 1, drv: daterange, projectpart: { projectid: 0, actionsview: [], actions: [] }, projectparts: [] };
                        this.$store.state.p = project;
                        this.$router.push("/projecthours");
                    }, 100);
                });
            },
            tableHeaderColor({ rowIndex }) {
                var cls = [];
                if (rowIndex === 0) {
                    cls.push("bordertb");
                }
                return cls.join(" ");
            },
            sortchange: function (sort) {
                this.view.sortcol = sort.prop;
                this.view.sortorder = sort.order;
                this.refresh();
            },

            convertminutetohour: function (value) {
                if (value) {
                    const [h, m] = [Math.floor(value / 60), value % 60];
                    const hours = (h < 10) ? "0" + h : h;
                    const minutes = (m < 10) ? "0" + m : m;
                    return `${hours}:${minutes}`;
                }
                return value;
            },
            findactual: function (row, column, cellValue) {
                if (cellValue) { //Actual
                    return this.convertminutetohour(cellValue);
                }
                return cellValue;
            },
            findBase: function (row) {
                if (row.totaldurationint != null && row.totalcleaningminutes != null) {
                    var cm = row.totalcleaningminutes;
                    let baseminute = parseInt(row.totaldurationint) + cm;
                    var [hh, min] = [Math.trunc(baseminute / 60), Math.abs(baseminute % 60)];
                    const hours = (hh > 0 && hh < 10) ? "0" + hh : hh;
                    const minutes = (min > 0 && min < 10) ? "0" + min : min;
                    return `${hours}:${minutes}`;
                }
                else if (row.totaldurationint != null) {
                    return this.convertminutetohour(row.totaldurationint);
                }
                else {
                    return null;
                }
            },
            basedividebyestimate: function (row) {
                var estimate = 0; // this varible created temparary
                if (row.totaldurationint != null && row.totalcleaningminutes != null) {
                    let td = Math.floor(row.totaldurationint / 60);
                    var basehours = td + row.totalcleaningminutes;
                    let basemin = basehours * 60;
                    if (estimate != 0) {
                        let pr = Math.floor((basemin / estimate) * 100);
                        if (pr >= 10 && pr < 100) {
                            return this.basepersent = "0" + pr;
                        }
                        else if (pr < 10) {
                            return this.basepersent = "00" + pr;
                        }
                        else {
                            return this.basepersent = pr;
                        }
                    }
                }
                else if (row.totaldurationint != null) {
                    if (estimate != 0) {
                        let td = Math.floor(row.totaldurationint / 60);
                        let basemin = td * 60;
                        let pr = Math.floor((basemin / estimate) * 100);
                        if (pr >= 10 && pr < 100) {
                            return this.basepersent = "0" + pr;
                        }
                        else if (pr < 10) {
                            return this.basepersent = "00" + pr;
                        }
                        else {
                            return this.basepersent = pr;
                        }
                    }
                }
                else {
                    return null;
                }
            },
            findmonths: function () {
                this.months = [];
                const start = new Date(this.view.filter[0].value);
                const end = new Date(this.view.filter[1].value);
                let loop = new Date(start);
                while (loop <= end) {
                    let m = moment(loop).format("MMM-YY");
                    loop = new Date(loop.setMonth(loop.getMonth() + 1)); // 1 month++
                    this.months.push(m);
                }
            },
            refresh: function () {
                this.myvalidate(() => {
                    this.showloading();
                    this.axios.post("reports/getprojectsummary", this.view)
                        .then((response) => {
                            this.view = response.data.view;
                            this.detailform = response.data.data;
                            this.findmonths();
                            this.hideloading();
                        })
                        .catch((error) => {
                            this.hideloading();
                            this.apierror(error);
                        });
                });
            }
        },
        created() {
            if (this.detailform.length <= 0) {
                this.getmonth(new Date());
            }
        }
    }
</script>