<template>
    <div>
        <div v-if="!canprint">
             <!-- <el-affix :offset="52" > -->
             <div class="colorwhite stickyheader">
             <div class="headerstyle">Multiple Searches</div> 
            <hr class="spaceBetween"/>
            <el-table :data="searchdata" style="width: 100%" ref="table1">

                <!-- <el-table-column label="Management Search"> -->
                    <el-table-column prop="name" label="Search Name" align="center" width="70">
                        <template #header>
                           <div>
                               Search
                           <!-- {{column.firstname}} -->
                           </div>
                           <div class="spaceBetween"/>
                           <div>
                               Name
                              <!-- {{column.lastname}} -->
                           </div>
                         <!-- {{column.firstname + '    ' + column.lastname}} -->
                        </template>
                        <template #default="scope">
                            <el-input v-model="scope.row.name"
                                    class="padinput"
                                    type="text"
                                    placeholder="Search Name">
                            </el-input>
                        </template>
                    </el-table-column>

                    <el-table-column label="Date" align="center">
                        <el-table-column prop="start" label="Start" align="center">
                            <template #default="scope">
                                <el-date-picker v-model="scope.row.start"
                                    style="width:100%;"
                                   format="ddd DD-MMM-YYYY"
                                    :clearable="false"
                                    :editable="false"
                                    type="date"
                                    placeholder="Start"
                                    :disabled-date="$commonutils.disableFutureDates">
                                </el-date-picker>
                            </template>
                        </el-table-column>

                        <el-table-column prop="finish" label="Finish" align="center">
                            <template #default="scope">
                                <el-date-picker v-model="scope.row.finish"
                                    style="width:100%;"
                                    format="ddd DD-MMM-YYYY"
                                    :clearable="false"
                                    :editable="false"
                                    type="date"
                                    placeholder="Finish"
                                    :disabled-date="$commonutils.disableFutureDates">
                                </el-date-picker>
                            </template>
                        </el-table-column>
                    </el-table-column>

                    <el-table-column prop="fullname" label="Name" align="center">
                        <template #default="scope">
                            <el-autocomplete v-model="scope.row.user.fullname"
                                :fetch-suggestions="searchuser"
                                style="width:100%;"
                                placeholder="Name"
                                @select="selectuser($event, scope.row)"
                                @change="valuechanged(scope.row)">

                            <template #default="{ item }">
                                <div class="value">{{ item.firstname }} {{item.lastname}}</div>
                            </template>
                            </el-autocomplete>
                        </template>
                    </el-table-column>

                    <el-table-column prop="projectoverhead" label="Project / Overheads" align="center">
                        <template #default="scope">
                          
                             <el-select  class="fullwidth"
                                    v-model="scope.row.projectid"
                                    @change="selectproject($event, scope.row)"
                                    clearable
                                    placeholder="Project/Overhead"
                                        filterable
                                        remote
                                        popper-class="customselect"
                                        reserve-keyword
                                        :remote-method="remoteMethodproject"
                                        >
                                    <el-option
                                    v-for="item in projectoptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>  
                            <!-- <el-autocomplete v-model="scope.row.project.name"
                                    :fetch-suggestions="searchproject"
                                    style="width:100%;"
                                    placeholder="Project / Overheads"
                                    @select="selectproject($event, scope.row)"
                                    @change="valuechanged(scope.row)">
                                <template slot-scope="{ item }">
                                    <div class="value" style="white-space:normal">{{item.name}}</div>
                                </template>
                            </el-autocomplete> -->
                        </template>
                    </el-table-column>

                    <el-table-column prop="action" label="Activity Area" align="center">
                        <template #default="scope">
                             <el-select v-if="ispro(scope.row)"
                                   v-model="scope.row.projectparid"
                                   clearable 
                                    popper-class="customselect"
                                   placeholder="Project Part"
                                   class="fullwidth"
                                   @change="projectpartchange">
                            <el-option v-for="item in scope.row.parts"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                            <el-select v-if="isove(scope.row)" v-model="scope.row.actionid" clearable
                                        placeholder="Actions"
                                        popper-class="customselect"
                                        @change="getsubactions($event,scope.row)"
                                        class="fullwidth">
                                <el-option v-for="item in scope.row.actions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>

                    <el-table-column prop="subaction" label="Actions" align="center">
                        <template #default="scope">
                             <el-select v-if="ispro(scope.row)" v-model="scope.row.actionid" clearable
                                        placeholder="Actions"
                                         popper-class="customselect"
                                        @change="getsubactions($event,scope.row)"
                                        class="fullwidth">
                                <el-option v-for="item in getactionsbypartid(scope.row)"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                            <el-select v-if="isove(scope.row)" v-model="scope.row.subactionid" clearable
                                        placeholder="Sub Actions"
                                         popper-class="customselect"
                                        class="fullwidth">
                                <el-option v-for="item in scope.row.subactions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" fixed="right" label="Operations" width="100">
                        <template #default="scope">
                            <el-button type="danger"
                                    :disabled="!editpermission"
                                    @click="deleteRow(scope.$index)">Delete</el-button>
                        </template>
                    </el-table-column>

                <!-- </el-table-column> -->
            </el-table>
            <el-button type="primary" :disabled="!editpermission" @click="addRow(null, null)" class="spaceBetween" icon="circle-plus-filled">Add Row</el-button>
            <el-button type="primary" :disabled="!editpermission" @click="searchview" icon="document" class="spaceBetween floatright">View Report</el-button>
        </div>
     <!-- </el-affix> -->
        </div>
        <div v-if="viewreport == true">
            <span v-if="canprint" class="section-to-print-head"> 
            <div>
                Multiple Searches
          </div>
          <div class="spaceBetween" />
          </span>
            <el-table class="section-to-print-body" :data="visibletabledata" 
                v-el-table-infinite-scroll="handleInfiniteScroll" 
                :infinite-scroll-disabled="disableinfinitescroll" 
                :height="height" ref="table2" border>
                <el-table-column  prop="date" label="Date"   :width="tablewidths.date" :formatter="dateformatter">
                        <template #default="scope">
                                <div class="wordbreak">
                                    {{ dateformatter("","",scope.row.date) }}
                                </div>
                        </template>
                </el-table-column>

                <el-table-column label="Start" align="center">
                    <el-table-column label="Time" prop="startminute" class="wordbreak" align="center" :width="tablewidths.startminute" :formatter="timeformatter"></el-table-column>
                </el-table-column>

                <el-table-column label="Stop" align="center">
                    <el-table-column label="Time" prop="t.duration" align="center" class="wordbreak" :width="tablewidths.endminute" :formatter="getfinishtime"></el-table-column>
                </el-table-column>

                <el-table-column label="Name" prop="fullname" :width="tablewidths.fullname">
                    <template #default="scope">
                                <div class="wordbreak">
                                    {{ scope.row.fullname }}
                                </div>
                        </template>
                </el-table-column>
                <el-table-column label="Project / Overhead" prop="pname" 
                        :width="tablewidths.project">
                             <template #default="scope">
                                    <div class="wordbreak">
                                        {{ scope.row.pname }}
                                    </div>
                            </template>
                            </el-table-column>
                <el-table-column label="Activity Area" prop="aname" 
                            class="wordbreak"
                            :width="tablewidths.activity">
                     <template #default="scope">
                            <div class="wordbreak">
                               <div v-if="scope.row.ptypeid == 1">
                                {{ scope.row.prname == null ? 'Single Bill': scope.row.prname }}
                               </div>
                               <div v-if="scope.row.ptypeid == 2">
                                {{ scope.row.aname }}
                               </div>
                            </div>
                        </template>
                </el-table-column>
                <el-table-column label="Actions"    
                        :width="tablewidths.action" prop="sname">
                     <template #default="scope">
                            <div class="wordbreak" >
                              <div v-if="scope.row.ptypeid == 1">
                                {{ scope.row.aname }}
                               </div>
                               <div v-if="scope.row.ptypeid == 2">
                                {{ scope.row.sname }}
                               </div>                               
                            </div>
                        </template>
                </el-table-column>
                <el-table-column label="Work Description" :width="tablewidths.description" prop="comments">
                    <template #default="scope">
                        <div class="wordbreak" >
                            <div v-if="scope.row.ptypeid == 2">
                                {{ scope.row.comments }}
                              </div>
                              <div v-if="scope.row.ptypeid == 1">
                                <div v-if="scope.row.sname == undefined && scope.row.comments != undefined">
                                {{  scope.row.comments  }}
                                </div>
                                <div v-if="scope.row.sname != undefined && scope.row.comments == undefined">
                                  {{  scope.row.sname  }}
                                </div>
                                <div v-if="scope.row.sname != undefined && scope.row.comments != undefined">
                                  {{  scope.row.sname + " - " + scope.row.comments }}
                                </div>
                              </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column  v-for="column in searchdata"
                                 :key="column.id"
                                 :label="column.name"
                                 align="center">
                    <el-table-column :label="column.sum" prop="duration" align="center" :width="tablewidths.searchdata">
                        <!-- <template slot="header">
                            {{column.sum}}
                        </template> -->
                        <template #default="scope">
                            <div class="wordbreak">
                                {{gettduration(scope.row,column)}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>

                <template #append v-if="infiniteloading">
                  <p class="center bold">Loading...</p>
                </template>
            </el-table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import ElTableInfiniteScroll  from "el-table-infinite-scroll";

    export default {
        data() {
            return {
                canprint:false,
                tablewidths:{
                    date:140,
                    startminute:60,
                    endminute:60,
                    fullname:120,
                    project:200,
                    activity:200,
                    action:200,
                    description:300,
                    searchdata:80
                },
                projectoptions:[],
                projects:[],
                searchdata: [],
                action: [],
                subactions: [],
                project: {},
                user: this.$store.state.user,
                tabledata: [],
                viewarr: [],
                viewreport: false,
                view: {},
                height: 600,
                visibletabledata: [],
                disableinfinitescroll: true,
                infiniteloading: false,
                startlength: 0,
                endlength: 0,
                batchSize: 200,
            }
        },
        computed:{
            getactionsbypartid(){
                return function(data){
                    // if(data.project.typeid == 1 && data.projectparid != undefined && data.projectparid != 0 && data.projectparid != ""){
                    //     return data.actions.filter(x => x.projectpartid == data.projectparid);
                    // }
                     return data.actions;
                }
            },
             ispro(){
                 return function(data){
                    if (data.project.typeid == 1 || data.project.typeid == 4) {
                        return true;
                    }
                    return false;
                }
            },
              isove(){
                return function(data){
                    if (data.project.typeid == 2) {
                        return true;
                    }
                    return false;
                } 
            },
        },
        
        directives: {
            ElTableInfiniteScroll
        },

        methods: {
             remoteMethodproject(query){
                if(query == ""){
                    this.projectoptions = this.projects
                }else{
                this.projectoptions = this.projects.filter(item => {
                    return item.name.toLowerCase()
                        .indexOf(query.toLowerCase()) > -1;
                    });
                }
            },
            addRow() {
                this.viewreport = false;
                var searchtext = this.searchdata.length + 1;
                var cd = new Date();
                cd.setDate(1);
                var firstdate = new Date(cd.setMonth(cd.getMonth() - 1));
                var itm = { name: "S - " + searchtext,
                 start: firstdate,
                  finish: new Date(),
                    user: {},
                    project: {},
                    actions:[],
                    subactions:[],
                    parts:[],
                    colname: "col"+searchtext,
                    sum:"",
                    projectid:null,
                    subactionid:null,
                    actionid:null,
                    projectparid:null, };
                this.searchdata.push(itm);
            },

            deleteRow(index) {
                this.viewreport = false;
                this.tabledata = [];
                this.searchdata.splice(index, 1);
            },

            selectuser(item, row) {
                row.user = item;
            },

            selectproject: function (item, row) {
                row.actionid = null;
                row.subactionid = null;
                row.projectparid = null;
                if(item != ""){
                    var project = {};
                     project = this.projects.find(x => x.id == item);
                    row.project = project;
                    row.projectid = project.id;
                    this.getactions(row).then((x)=>{
                      row.actions = x.acs;
                     
                        row.parts = new Array; 
                        if(x.pps.length == 0){
                            row.parts.push({ name: "Single Bill", id: "" }) ;   
                        } else{
                            row.parts = x.pps;
                        }
                     
                    
                    });
                }else{
                    row.actions = [];
                    row.subactions = [];
                }
            },

            getactions: function (row) {
                this.subactions= [];
               return this.$http.post("action/getactions", row.project)
                    .then((response) => {
                        return  response.data;
                    })
                    .catch((err) => {
                       this.apierror(err);
                    });
            },

            getsubactions(event,row) {
                if(event != ""){
                this.$http.post("action/getsubactions", { actionid: event })
                    .then((response) => {
                        row.subactions = response.data;
                    })
                    .catch((err) => {
                      this.apierror(err);
                    });
                }
            },

            dateformatter: function (row, column, value) {
                if (value) {
                    var m = moment(value).format("ddd DD-MMMM-YYYY");
                    return m;
                }
                return value;
            },
            valuechanged(row){
                if (row.user.firstname == "") {
                    row.user = {};
                }
                // if (row.project.name == "") {
                //     row.project = {};
                // }
            },
            tableheaderstyle({rowIndex}){
                if (rowIndex === 0) {
                    return {'background':'rgb(153, 51, 255)','font-weight':'bold','color':'white','font-size': '14pt',
                    'font-family': 'Calibri'};
                }
                else if (rowIndex === 1 || rowIndex === 2) {
                    return {'background':'rgb(251, 210, 223)','font-weight':'bold','color':'black','font-size': '16px',
                    'font-family': 'Calibri'};
                }
                return {};
            },
            getallduration(duration){
                var h1 = Math.floor(duration / 60),m1 = duration % 60;
                return h1+":"+m1;
            },
            gettduration(row, column){
                if (row != undefined && Object.keys(row).length != 0) {
                    var dt = row[column.colname];
                    if (dt) {
                        var h1 = Math.floor(dt / 60),m1 = dt % 60;
                        var t =  moment(h1+":"+m1, "HH:mm").format('HH:mm');
                        return t;
                    }else{
                        return null;
                    }
                }
            },

            getfinishtime(row){
                var durations = []
                durations.push(this.timeformatter(null, null, row.startminute));
                var h1 = Math.floor(row.duration / 60),m1 = row.duration % 60;
                durations.push(h1+":"+m1);
                const totalDurations = durations.slice(1)
                    .reduce((prev, cur) => moment.duration(cur).add(prev),
                moment.duration(durations[0]))
                return moment.utc(totalDurations.asMilliseconds()).format("HH:mm")
            },

            timeformatter: function (row, column, value) {
                if (value) {
                    // var h = "";
                    // var m = "";
                    // value = value.toString();
                    // if (value.length == 3) {
                    //     h = value.slice(0, 1);
                    //     m = value.slice(1, 3);
                    //     return "0" + h + ":" + m;
                    // }
                    // if (value.length == 4) {
                    //     h = value.slice(0, 2);
                    //     m = value.slice(2, 4);
                    //     return h + ":" + m;
                    // }
                    // if (value.length == 2) {
                    //     return value + ":" + "00";
                    // }
                    // if (value.length == 1) {
                    //     return "00" + ":0" + value;
                    // }
                    var m = value % 60;
                    var h = (value-m)/60;
                    return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
                }
                return "";
            },

            searchview: function () {
                this.viewreport == false ? this.viewreport = true : this.viewreport = false;
                this.viewarr = [];
                this.searchdata.forEach(x => {
                    if (x.sum) {
                        x.sum = "";
                    }
                    this.view = {
                        filter: [
                            { table: 't', column: 'date', value: '', operation: '>=', paraname: 'sdate' },
                            { table: 't', column: 'date', value: '', operation: '<=', paraname: 'edate' },
                            { table: 'u', column: 'id', value: '' },
                            { table: 'p', column: 'id', value: '', paraname: 'projectname' },
                            { table: 'a', column: 'id', value: '', paraname: 'actionname' },
                            { table: 's', column: 'id', value: '', paraname: 'subactionname' },
                            { table: 'pr', column: 'id', value: '', paraname: 'projectpartname' }
                        ],
                    };
                    this.view.filter[0].value = moment(x.start).format("YYYY-MM-DD").toString();
                    this.view.filter[1].value = moment(x.finish).format("YYYY-MM-DD").toString();
                    this.view.filter[2].value = x.user.id != undefined ? x.user.id.toString() : "";
                    this.view.filter[3].value = x.projectid != undefined ? x.projectid.toString() : "";
                    this.view.filter[4].value = x.actionid != undefined ? x.actionid.toString() : "";
                    this.view.filter[5].value = x.subactionid != undefined ? x.subactionid.toString() : "";
                    this.view.filter[6].value = x.projectparid != undefined && x.projectparid != 0 ? x.projectparid.toString() : "";

                    if (x.projectid) {
                        let foundproject = this.projectoptions.find(p => p.id == x.projectid && p.typeid == 4);
                        if (foundproject) {
                            this.view.filter[3].column = "typeid";
                            this.view.filter[3].value = "1";

                            if (x.projectparid) {
                                let foundpart = x.parts.find(xpp => xpp.id == x.projectparid);
                                if (foundpart) {
                                    this.view.filter[6].column = "name";
                                    this.view.filter[6].value = foundpart.name.toString();
                                }
                            }
                        }
                    }
                    this.viewarr.push(this.view);
                });
                if (this.viewreport) {
                    this.viewmanagereport();
                }

                this.tableHeight();
            },

            viewmanagereport: function () {
                this.tabledata = [];
                var vu = { v: this.viewarr };
                this.showloading();
                this.$http.post("rmanage/GetViewRmanage", vu)
                    .then(response => {
                        this.tabledata = response.data;
                        if (this.tabledata.length == undefined || this.tabledata.length == 0) {
                            this.tabledata = [];
                        }
                        var data = response.data;
                        for (var i = 0; i < this.searchdata.length; i++) {
                            var colname = "col"+(i+1);
                            var total = 0
                            data.filter(x => {
                                if(x[colname]){
                                    total += x[colname];
                                }
                                // if(Object.prototype.hasOwnProperty.call(x,colname)){

                                //     total += x[colname];
                                // }
                            });
                            var h1 = Math.floor(total / 60),m1 = total % 60;
                            total = (h1<10?"0":"") + h1.toString() + ":" + (m1<10?"0":"") + m1.toString();
                            // total =  moment(h1+":"+m1, "HH:mm").format('HH:mm');
                            this.searchdata[i].sum = total;

                        }

                        if (data.length > 1000) {
                            this.visibletabledata = data.slice(0, 1000);
                            this.disableinfinitescroll = false;
                            this.infiniteloading = true;
                        } else {
                            this.visibletabledata = data;
                        }

                        // this.view = response.data.view;
                        this.hideloading();
                    })
                    .catch(error => {
                        this.hideloading();
                        this.apierror(error);
                    });
           },
          afterprint() {
              this.canprint = false;
              this.tablewidths = {
                    date:140,
                    startminute:60,
                    endminute:60,
                    fullname:120,
                    project:200,
                    activity:200,
                    action:200,
                    description:300,
                    searchdata:80
                  };
           },
           realPrint() {
               this.canprint = true;
               this.tablewidths = {
                    date:70,
                    startminute:40,
                    endminute:40,
                    fullname:60,
                    project:100,
                    activity:100,
                    action:100,
                    description:100,
                    searchdata:60
               };
            },
           setprint(){
              window.addEventListener("beforeprint", () => {
              this.realPrint();
              });
              window.addEventListener("afterprint", () => {
              this.afterprint();
              });
           },

            getallprojectsandoverheads() {
                var data = "";
                const existpage = this.user.userrole.rolepermissionpages.find(x => x.p.name === this.$route.name);
                if (existpage && existpage.rp && existpage.rp.accessproject && existpage.rp.accessproject.trim() !== '') {
                    data = existpage.rp.accessproject;
                }
                this.$http.get("project/GetAllProjectsAndOverheads?data=" + data, {})
                    .then((response) => {
                        this.projectoptions = response.data;
                        this.projects = response.data;
                    })
                    .catch((err) => {
                        this.apierror(err);
                    });
            },

            tableHeight() {
                const table1height = this.$refs.table1.$el.clientHeight;
                this.height = (window.innerHeight - table1height) - 150;
            },

            handleInfiniteScroll() {
                if(this.endlength < this.tabledata.length){
                    this.infiniteloading = true;

                    this.startlength = this.visibletabledata.length;
                    this.endlength = this.startlength + this.batchSize;
                    // this.startlength = this.visibletabledata.length;
                    // this.endlength += 200;

                    if(this.endlength > this.tabledata.length)
                    {
                        this.endlength = this.tabledata.length;
                    }
                    var tdataitems = this.tabledata.slice(this.startlength, this.endlength);
                    this.visibletabledata.push(...tdataitems);

                    const table = this.$refs.table2.$el.querySelector('.el-scrollbar__wrap');
                    table.scrollTop = table.scrollTop - 1;
                } else {
                    this.infiniteloading = false;
                    this.disableinfinitescroll = true;
                }
            },

            clearInfiniteScrollData() {
                this.startlength = 0;
                this.endlength = 0;
                this.visibletabledata = [];
                this.disableinfinitescroll = true;
                this.infiniteloading = false;
            },
        },

        unmounted() {
            window.removeEventListener('resize', this.tableHeight);
        },

        mounted() {
            window.addEventListener('resize', this.tableHeight);
        },

        created: function () {
           this.setprint();           
           this.getallprojectsandoverheads();
           /*
            if(this.$store.state.projects.length ==  0){
                this.searchproject().then(() =>{
                this.projectoptions = this.$store.state.projects;
                this.projects =  this.$store.state.projects;
            });
            }else{
                this.projectoptions = this.$store.state.projects;
                this.projects =  this.$store.state.projects;
            }
            */
            this.addRow(null, null);
        }
    }
</script>
