<template>
    <div>
        <el-tiptap v-model:content="metadata.content" :extensions="extensions" placeholder="Write something ..." />
    </div>
</template>

<script>
import {
    // necessary extensions
    Doc,
    Text,
    Paragraph,
    //_______________________
    Heading,
    Bold,
    Underline,
    Italic,
    TextAlign,
    FontSize,
    codeView,
    Strike,
    BulletList,
    OrderedList,
    Link,
    HorizontalRule,
    HardBreak,
    History,
    FormatClear,
    Color,
    Highlight,
    SelectAll,
    Gapcursor,
    Commands,
    getSuggestionItems,
    renderItems,
} from "element-tiptap-vue3-fixed";

export default {
    props: {
        metadata: { type: Object, default: () => ({ content: "" }) }
    },

    data() {
        return {
            extensions: [
                Doc,
                Text,
                Paragraph,
                Bold,
                Underline,
                Italic,
                TextAlign,
                FontSize,
                Heading,
                codeView,
                Strike,
                BulletList,
                OrderedList,
                Link,
                HorizontalRule,
                HardBreak,
                History,
                FormatClear,
                Color,
                Highlight,
                SelectAll,
                Gapcursor,
                Commands.configure({
                    suggestion: {
                        items: getSuggestionItems,
                        render: renderItems,
                    },
                }),
            ]
        };
    },
}
</script>

<style>
.el-tiptap-editor__characters {
    color: #939599;
    display: none;
}

.el-tiptap-editor>.el-tiptap-editor__content {
    background-color: #fff !important;
    border: 1px solid #ebeef5 !important;
    border-bottom: 0 !important;
    border-top: 0 !important;
    color: #000 !important;
    flex-grow: 1 !important;
    padding: 10px 5px !important;
}

.el-tiptap-editor__command-button {
    border: 1px solid white;
    box-sizing: border-box;
    align-items: center;
    border-radius: 50%;
    color: #303133;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 30px !important;
    margin: 0px !important;
    outline: 0;
    transition: all .2s ease-in-out;
    width: 30px !important;
}

.el-tiptap-editor__menu-bar {
    background-color: #fff;
    border: 0px solid #efefef !important;
    border-bottom: 0;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    padding: 0px !important;
    position: relative;
}

.el-tiptap-editor__footer {
    border: 0px solid #ebeef5 !important;
    padding: 0px !important;
}
</style>