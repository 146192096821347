<template>
    <div >
        <transition name="fade" mode="in-out">
            <div :ref="'DirectionsBackground'+myoption.uniqueid"  :id="'DirectionsBackgrounddirection'+myoption.uniqueid" :draggable="isdraggble" class="draggable Directionsbody direction_box_shadow" v-show="myoption.visible">
                <div class="row cursorstylemove" @mouseover="isdraggble = true">
                        
                    <span class="fontsize24px" >{{myoption.heading}} </span>
                    <!-- <el-button class="glyphicon glyphicon-circle-arrow-up arrowiconstyle" :style="'transform: rotate('+ myoption.directiondegree +'deg); transition: 0.5s linear;'" circle></el-button> -->
                </div>
                    <div class="row flex aligncewnter" >
                        <div class="Directionscoll1" @mouseover="isdraggble = false">
                            <p class="textbox" v-html="myoption.content"></p>
                        </div>
                        <div class="Directionscoll2"  v-if="myoption.mediacontent"  @mouseover="isdraggble = false" >
                            <div style="display: flex; align-items: center; justify-content: center; height: 100%;">
                                <span  v-if="myoption.mediacontent.name && myoption.mediacontent.name.substring(myoption.mediacontent.name.lastIndexOf('.') + 1).toLowerCase() == 'jpg' || myoption.mediacontent.name.substring(myoption.mediacontent.name.lastIndexOf('.') + 1).toLowerCase() == 'jpeg' || myoption.mediacontent.name.substring(myoption.mediacontent.name.lastIndexOf('.') + 1).toLowerCase() == 'gif' || myoption.mediacontent.name.substring(myoption.mediacontent.name.lastIndexOf('.') + 1).toLowerCase() == 'png'">
                                    <el-image :preview-src-list="[myoption.mediacontent.link]" id="infoimg" :src="myoption.mediacontent.link" style="width: 244px !important; height: auto !important;" />
                                </span>

                                <span v-if="myoption.mediacontent.name && myoption.mediacontent.name.substring(myoption.mediacontent.name.lastIndexOf('.') + 1).toLowerCase() == 'mp4'">
                                    <video controls class="previewbox" style="margin-bottom: 8px;">
                                        <source :src="myoption.mediacontent.link" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row margintop6px" >
                        <!-- <el-button  type="info" @click="changepage('left')" 
                                    :disabled="!previouspage"
                                    icon="el-icon-arrow-left" 
                                    class="left" circle>
                        </el-button> -->
                        <div class="floatleft" style="width: 85%;padding-right: 10px;padding-left: 10px;">
                            <span class="floatleft" >
                                <div class="policylink" v-if="myoption.link && myoption.link != ''">
                                    <a :href="myoption.link" target="_blank" class="policylink" >  {{ myoption.link }}</a>
                                </div>
                            </span>
                            <span class="floatright">
                                <el-button v-if="myoption.offercloseoption == 'error'" type="success" @click="Correctclick" >Correct</el-button>
                                <!-- <el-button v-if="myoption.offercloseoption == 'warning'" type="success" @click="Ignoreclick">Ignore</el-button> -->
                            </span>
                        </div>
                         <!-- <el-button  type="info" :disabled="!nextpage"
                                     @click="changepage('right')" 
                                     icon="el-icon-arrow-right" 
                                     class="right" circle>
                        </el-button> -->
                    </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'directionsdialogue',
    props: { 
        myoption: {
            sectionname:String, 
            visible: Boolean,
            content:String,
            mediacontent:Object,
            boxtype:String,
            offercloseoption:String,
            heading:String,
            directiondegree:Number,
            isedit:Boolean,
            position:Object
        }
         },
    data() {
        return {
            dragstart:false,
            // newcallback:null,
            // directiondegree: 0,
            isdraggble: true,
            // previouspage:null,
            // nextpage:null, 
        };
    },

    watch: {
        'myoption'() {
            this.setpostion();
        }
    },
    computed:{
        // hasPreviousContent() {
        //     return this.$store.state.currentdirectionindex > 0;
        // },

        // hasNextContent() {
        //     return this.$store.state.currentdirectionindex < this.$store.state.directionsdialogue.length  - 1;
        // }
    },
    methods: {
        // chheckvaluenext(canadd){
        //     if(canadd == 1){
        //         this.$store.state.currentdirectionindex ++;
        //     }else{
        //         this.$store.state.currentdirectionindex --;
        //     }
        //     var dd =  this.$store.state.directionsdialogue[this.$store.state.currentdirectionindex];
        //     if(dd){
        //         if(dd.usedata != true){
        //         var node = document.getElementById(dd.elementid);
        //          var show = this.$training.RG_Direction_Match(node.value,dd.inc_pattern,dd.exc_pattern,dd.format_pattern);
        //             if(show == false){
        //                 return true;
        //             }else{
        //                 return this.chheckvaluenext(canadd);
        //             }
        //         }else{
        //            dd.validatedirectionsingle(dd);
        //            if(dd.value != undefined){
        //                 var canshow = this.$training.RG_Direction_Match(dd.value,dd.inc_pattern,dd.exc_pattern,dd.format_pattern);
        //             }
        //            if(canshow == false){
        //                 return true;
        //             }else{
        //                 return this.chheckvaluenext(canadd);
        //             }
        //         }
                 
        //     }
        // }, 
        // changepage(pagename){
        //     let isid = document.getElementById(this.myoption.elementid);  
        //     isid.style.backgroundColor = '';
        //     isid.style.color = '';

        //     if(pagename == 'right'){
        //          var isshow = this.chheckvaluenext(1);
        //          if(isshow){
        //             this.$nextTick(()=>{
        //             this.$store.state.currentdirection = this.$store.state.directionsdialogue[this.$store.state.currentdirectionindex];
        //             });
        //          }
        //         this.previouspage =  this.hasPreviousContent;
        //         this.nextpage =  this.hasNextContent;
        //     }else{
        //          isshow = this.chheckvaluenext(-1);
        //         if(isshow){
        //             this.$nextTick(()=>{
        //                 this.$store.state.currentdirection = this.$store.state.directionsdialogue[this.$store.state.currentdirectionindex];    
        //             });
        //         }
        //         this.previouspage =  this.hasPreviousContent;
        //         this.nextpage =  this.hasNextContent;
        //     }
        //     if(this.previouspage == false && this.nextpage == false){
        //         this.myoption = {};
        //         // this.$store.state.currentdirectionindex = 0;
        //     }
             
        // },
        Ignoreclick(){
            if(this.myoption.isedit == false){
                //  this.ignoredirection(this.myoption.uniqueid,this.myoption.sectionname)
            }
            // this.callcallback();
            this.Correctclick();
        },
        drag_start(event) {
            this.dragstart = true;
            var style = window.getComputedStyle(event.target, null);
            event.dataTransfer.setData(
                "text/plain",
                parseInt(style.getPropertyValue("left"), 10) -
                event.clientX +
                "," +
                (parseInt(style.getPropertyValue("top"), 10) - event.clientY)
            );
        },
        drag_over(event) {
            event.preventDefault();
            return false;
        },
        drop(event) {
            if(this.dragstart == true){
                this.dragstart = false;
                var offset = event.dataTransfer.getData("text/plain").split(",");
                this.$refs['DirectionsBackground'+this.myoption.uniqueid].style.left =
                    event.clientX + parseInt(offset[0], 10) + "px";
                this.$refs['DirectionsBackground'+this.myoption.uniqueid].style.top =
                    event.clientY + parseInt(offset[1], 10) + "px";
                event.preventDefault();

                return false;
            }
        },        
        makeDraggable() {
            var dm = this.$refs['DirectionsBackground'+this.myoption.uniqueid];
            var dropid = document.querySelector("#dialoguecontainer")
            if (dm) {
                dm.addEventListener("dragstart", this.drag_start, false);
                dropid.addEventListener("dragover", this.drag_over, false);
                dropid.addEventListener("drop", this.drop, false);
            }
        },
        // callcallback(){
        //     if(this.newcallback != null){
        //       this.newcallback();
        //     }
        // },
        Correctclick(){
            this.myoption.visible = false;
            // this.newcallback = null;

            // let isid = document.getElementById(this.myoption.elementid);  
            // isid.style.backgroundColor = '';
            // isid.style.color = '';
        },
        setpostion(){
            // this.previouspage =  this.hasPreviousContent;
            // this.nextpage =  this.hasNextContent;
            // if(this.previouspage == null){
            //     this.previouspage =  this.hasPreviousContent;
            // }
            // if(this.nextpage == null){
            //     this.nextpage =  this.hasNextContent;
            // }
            // var isid = document.getElementById(this.myoption.elementid);  
            // isid.style.backgroundColor = 'yellow';
            // isid.style.color = 'black';
            // if(isid != null){
                
                // isid.scrollIntoView();
            // const self = this;
            // this.$nextTick(()=>{
            //     const position =  isid.getBoundingClientRect(); 
            //     const pos =  this.$training.getboxposition(position);
            //     self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.left = pos.left;
            //     self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.right = pos.right;
            //     self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.top = pos.top;
            //     self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.bottom = pos.bottom;
            // })
            // }
        },
        // handleScroll (event) {
        //     console.log(event);
        //     var isid = document.getElementById(this.myoption.elementid);  
        //     const self = this;
        //     this.$nextTick(()=>{
        //         if(isid != undefined){
        //             const position =  isid.getBoundingClientRect(); 
        //             const pos =  this.$training.getboxposition(position);
        //             self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.left = pos.left;
        //             self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.right = pos.right;
        //             self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.top = pos.top;
        //             self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.bottom = pos.bottom;
        //         }
        //     })
        // // Any code to be executed when the window is scrolled
        // },
        // findscroltag(){
        //     var ElementsWithScrolls = (function() {
        //         var getComputedStyle = document.body && document.body.currentStyle ? function(elem) {
        //             return elem.currentStyle;
        //         } : function(elem) {
        //             return document.defaultView.getComputedStyle(elem, null);
        //         };

        //         function getActualCss(elem, style) {
        //             return getComputedStyle(elem)[style];
        //         }

        //         function isXScrollable(elem) {
        //             return elem.offsetWidth < elem.scrollWidth &&
        //                 autoOrScroll(getActualCss(elem, 'overflow-x'));
        //         }

        //         function isYScrollable(elem) {
        //             return elem.offsetHeight < elem.scrollHeight &&
        //                 autoOrScroll(getActualCss(elem, 'overflow-y'));
        //         }

        //         function autoOrScroll(text) {
        //             return text == 'scroll' || text == 'auto';
        //         }

        //         function hasScroller(elem) {
        //             return isYScrollable(elem) || isXScrollable(elem);
        //         }
        //         return function ElemenetsWithScrolls() {
        //             return [].filter.call(document.querySelectorAll('*'), hasScroller);
        //         };
        //     })();
        // return ElementsWithScrolls();
        // },
    },
    mounted(){
        this.$nextTick(()=>{
            // this.setpostion();
        })
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll);
        // var dd = this.findscroltag();
        // dd.forEach(element => {
        //     element.removeEventListener('scroll', this.handleScroll);    
        // });
    },
    created() { 
        // var dd = this.findscroltag();
        // dd.forEach(element => {
        //     element.addEventListener('scroll', this.handleScroll);    
        // });
        // window.addEventListener('scroll', this.handleScroll);
        this.$nextTick(this.makeDraggable);
    },
};
</script>

<style scoped>
.policylink{
    width: 250px;
    text-overflow: ellipsis !important;
    overflow: hidden;
    height: 23px;
    color: #18bfff !important;
    background-color: white !important;
    font-weight: bold;
    padding: 2px;
}
.policylink:hover{
    width: 250px;
    text-overflow: ellipsis !important;
    overflow: hidden;
    height: 23px;
    color: #18bfff !important;
    background-color: white !important;
    font-weight: bold;
    padding: 2px;
}
.Directionsbody {
    padding: 4px 10px 4px 10px;
    background-color: #d5e2e8;
    /* border: 1px solid; */
    width: 528px;
    /* height: 360px; */
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 1200;
    align-items: center;
    /* resize: none; */
}.row:after {
  content: "";
  display: table;
  clear: both;
}

/* #dlgbox {
    position: fixed;
    width: 90%;
    height: 50%;
    margin: auto;
    background-color: lightgray;
    z-index: 1;
    border-radius: 25px;
} */

/* .dlg-body {
    background-color: lightgray;
    color: black;
    font-size: 14px;
    padding-left: 58px;
    border: 4px solid black;
    min-height: 90%;
    border-radius: 25px;
} */







.textbox {
    margin: 0 0 0px;
}
.fade-enter-active {
    animation: fadein 0.5s;
    animation-iteration-count: 1;
}
.fade-leave-active {
    animation: fadeout 0.7s;
    animation-iteration-count: 1;
}
.margintop6px {
    margin-top: 6px;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
.direction_box_shadow{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 5px;
}
</style>
  
