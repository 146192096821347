import { createStore } from "vuex"
const store = createStore({
   state:{
        actionview: {},
        counter: 0,
        descriptiondata: {},
        project: { projectname: '' },
        partname: '',
        projectpart: {},
        user: {},
        role: {},
        projects: [],
        currencies: [],
        isnotificationtime: false,
        providers:[],
        p: {},
        subscription: {},
        pages: [],
        newfeaturedialgoue: { editedialgoue: false },
        currentdirection:null,
        tablecellindicies: {
         overhead1: [],
         overhead2: [],
         overhead3: []
        },
        pagesizes: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
        messagecount:0,
        deferredPrompt:null,
   },
   methods: {
      adduser () {
      return this.state.user;
     }
   }
})

export default store