<template>
   <div class="widthprojectandoverhead">
         <!-- <el-affix :offset="52" > -->
             <div class="colorwhite">
        <div class="headerstyle stickyheader">{{modedisplay}}</div>
        <hr class="spaceBetween" />
             </div>
         <!-- </el-affix> -->
        <el-form label-position="left" label-width="125px">
             <el-form-item v-if="ProOrOve == 'Overhead '"  label="Personal/Overhead" >
                  <el-select v-model="personal" class="width50" placeholder="Personal / Overhead">
                      <el-option
                         v-for="item in PorOarray"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value" >
                       </el-option>
                    </el-select>
             </el-form-item>
             <div class="spaceBetween" />
            <el-form-item v-if="ProOrOve == 'Overhead '"  :label="ProOrOve +'Name '" >
                 <div class="inpuwidth"> 
                <el-autocomplete class="width50"
                                 popper-class="my-autocomplete"
                                 v-model="form.a.overheadname"
                                 :fetch-suggestions="searchoverhead"
                                 placeholder="Project/Overhead"
                                 @select="selectoverhead">
                    <template #default="{ item }">
                        <div class="value">{{ item.name }}</div>
                    </template>
                </el-autocomplete>
                 </div>
            </el-form-item>
            <div class="spaceBetween" />
            <el-form-item :label="isactivity">
               <div class="inpuwidth"> 
                   <el-input class="width50" v-model="form.a.name" :placeholder="isactivity"></el-input>
               </div>
            </el-form-item>         
            <el-form-item label="Active">
               <div class="inpuwidth"> 
                <el-checkbox  v-model="form.a.active"></el-checkbox>
               </div>
            </el-form-item>                       
             <!-- <div class="spaceBetween" /> -->
            <!-- <el-form-item v-if="ProOrOve == 'Project '"  label="Project Part Name *">
                 <div class="inpuwidth"> 
                <el-select class="fullwidth" v-model="form.a.projectpartid" placeholder="Select">
                  <el-option
                    v-for="item in project.projectparts"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                 </div>
            </el-form-item> -->

            <div class="spaceBetween" />
            <el-form-item>
                <el-button :disabled="!editpermission"
                           type="success"
                           icon="circle-check"
                           @click="save">Save</el-button>

                <el-button icon="back" @click="$router.go(-1)">Back</el-button>
            </el-form-item>
            <br>
            <div class="spaceBetween" />
            <div v-if="form.sav && this.ProOrOve != 'Project '">
                <div>
                    <span class="headerstyle">{{ProOrOve + 'Sub-actions'}}</span>
                    <span class="floatright">
                        <el-button :disabled="!editpermission"
                           type="primary"
                           class="minebtn"
                           icon="circle-plus-filled"
                           @click="addsarow"></el-button>
                    </span>
                </div>
                 <hr class="spaceBetween" />
                <el-table :border="true" :data="form.sav" class="fullwidth">
                    <el-table-column prop="sa.name" width="300" label="Sub Action Name"></el-table-column>
                    <el-table-column align="center" prop="sa.active" width="70" label="Active">
                        <template #default="scope">
                            <el-checkbox disabled v-model="scope.row.sa.active"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="Actions" align="center" width="60">
                        <template #default="scope">
                            <el-button class="minebtn"
                                       type="primary"
                                       :icon="editpermission == true ? 'edit-pen' : 'view'"
                                       @click="editsarow(scope.row)">
                                       </el-button>
                        </template>
                    </el-table-column>
                </el-table>               
            </div>
        </el-form>
   </div>
</template>

<script>
    export default {
        data() {
            return {
                personal:2,
                PorOarray:[
                    {label:'Overheads - Personal',value:3},
                    {label:'Overheads - TSA',value:2},
                ],
                project:{},
                overheads: [],
                id: 0,
                form: {
                    a: {}
                },
            }
        },

        computed: {
            modedisplay: function () {
                if (this.ProOrOve == "Project ") {
                    return this.id == 0 ? "Project Action Add" : "Project Action Edit";
                }
                else {
                    return this.id == 0 ? "Overhead Action Add" : "Overhead Action Edit";
                }
            },

            ProOrOve: function () {
                if (this.$route.path.includes("/projectactionedit")) {
                    return "Project ";
                }
                else {
                    return "Overhead ";
                }
            },
            isactivity: function () {
                if (this.$route.path.includes("/projectactionedit")) {
                    return "Action Name";
                }
                else {
                    return "Activity Area";
                }
            }
        },

        methods: {
            selectoverhead(item) {
                this.form.a.projectid = item.id
                this.form.a.overheadname = item.name;

                this.post("project/getproject",item).then((data)=>{
                    this.project = data.data; 
                })
            },
            
            myvalidate: function (callback) {
                var msg = [];
                if (!this.form.a.name) {
                    msg.push("Action Name is required");
                }
                 if (this.form.a.name.length > 50) {
                    msg.push("Action should not exceed 50 characters.");
                }
                if (!this.form.a.overheadname && this.$route.path.includes("/overheadactionedit")) {
                    msg.push("Overhead Name is required");
                }
                if (msg.length > 0) {
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(msg.join('<br/>') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }
                else {
                    callback();
                }
            },

            refresh: function () {
                this.showloading();
                this.axios.get('action/get', { params: { id: this.id } })
                    .then(response => {
                        this.hideloading();
                        this.form = response.data;
                        if (this.form.p != null) {
                            this.form.a.overheadname = this.form.p.name;
                            this.project = this.form.p;
                        }
                        if( this.form.a.typeid == 3){
                            this.personal = 3;
                        }
                    })
                    .catch(error => {
                        this.hideloading();
                        this.apierror(error);
                    })
            },

            addsarow() {
                if (this.ProOrOve == "Project ") {
                    this.$router.push({ path: '/projectsubactionedit', query: { actionid: this.id, id: 0 } });
                }
                else {
                    this.$router.push({ path: '/overheadsubactionedit', query: { actionid: this.id, id: 0 } });
                }
            },

            editsarow(row) {
                if (this.ProOrOve == "Project ") {
                    this.$router.push({ path: '/projectsubactionedit', query: { actionid: this.id, id: row.sa.id } });
                }
                else {
                    this.$router.push({ path: '/overheadsubactionedit', query: { actionid: this.id, id: row.sa.id } });
                }
            },

            save: function () {
                if (this.ProOrOve == "Project ") {
                    this.form.a.typeid = 1;
                }
                else if(this.personal == 3) {
                    this.form.a.typeid = 3;
                }
                else{
                    this.form.a.typeid = 2;
                }
                this.myvalidate(() => {
                    this.showloading();
                    this.axios.post("action/actionsave", this.form)
                        .then(response => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: 'success'
                            });

                            this.$router.go(-1);
                        })
                        .catch(error => {
                            this.hideloading();
                            this.apierror(error);
                        });
                });
            },

            searchoverhead: function (query, done) {
                if (query == undefined) { query = '' }
                var name = ""
                if(this.ProOrOve == "Project "){
                  name = "searchproject"
                }else{
                  name = "searchoverhead"
                }
                // name = "searchproject";
                this.axios.post("project/"+name+"?q=" + query, {})
                    .then((response) => {
                        // var overhead = JSON.parse(response.data);
                        done(response.data);
                    })
                    .catch((error) => {
                        this.apierror(error);
                    });
            }
        },

        created: function () {
            if (this.$route.query.id > 0) {
                this.id = this.$route.query.id;
                this.refresh();
            }
        }
    }
</script>
<style scoped>
.inpuwidth{
  width: 330px;
}
</style>