<template>
    <div>
        <el-card>
            <div class="header">
                <div class="headerstyle">Update Database Structure</div>
            </div>

            <div>
                <el-button type="primary" @click="update">Update</el-button>
            </div>
            <br />

            <div>
                <el-table :data="form" :border="true" stripe>

                    <el-table-column prop="id" label="ID" width="40">
                    </el-table-column>

                    <el-table-column prop="querynumber" label="Query Number" width="110">
                    </el-table-column>

                    <el-table-column prop="checkquery" label="Check Query">
                    </el-table-column>

                    <el-table-column prop="updatequery" label="Update Query">
                        <template #default="scope">
                            <div class="whitespacepre"> {{ scope.row.updatequery }} </div>
                        </template>
                    </el-table-column>

                    <el-table-column :formatter="cellValueRenderer" prop="issuccess" label="Status" width="60">
                    </el-table-column>

                     <el-table-column align="left" prop="checkmethod" width="100" label="Check Method" >
                          <template #default="scope">
                              <div>
                                  <div class="left">
                                   {{getCheckMethod(scope.row)}}
                                 </div>
                              </div>
                          </template>
                     </el-table-column>

                    <el-table-column prop="updatedon" label="Updated On" :formatter="dateformatter" width="155">
                    </el-table-column>

                    <el-table-column prop="errordetail" label="Error Message">
                    </el-table-column>

                    <el-table-column label="Delete" fixed="right" width="60">
                        <template #default="scope">
                            <el-button-group>
                                <el-button type="danger" icon="delete" v-if="scope.row.id>0"
                                           @click="deleteRow(scope.row)"></el-button>
                            </el-button-group>
                        </template>
                    </el-table-column>

                    <el-table-column label="Run" fixed="right" width="60">
                        <template #default="scope">
                            <el-button-group>
                                <el-button type="primary" icon="refresh"
                                           @click="runRow(scope.row)"></el-button>
                            </el-button-group>
                        </template>
                    </el-table-column>

                </el-table>
                <div>
                    <el-button type="primary" @click="recycle">Recycle</el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: []
            }
        },
         computed:{
        getCheckMethod(){
                return function(data){
                      let name = '';
                        if(data.checkmethod == 1){name = 'Data Not Exist'}
                        if(data.checkmethod == 2){name = 'Data Exist'}
                        return name;
                    }
            },
        },
        methods: {
            apierror: function (error) {
                if (error.response != null) {
                    if (typeof (error.response.data) == "object") {
                        var json = error.response.data;
                        this.$message({
                            showClose: true,
                            message: json.message,
                            type: 'error'
                        });
                    }
                    else {
                        var style = '<style> .el-message-box {width:60vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>'
                        this.$alert(error.response.data + style, error.message, {
                            dangerouslyUseHTMLString: true
                        });
                    }
                } else {
                    this.$alert(error.message, "Error");
                }
            },
            deleteRow: function (row) {
                this.showloading();
                this.$http.post("/account/deletequery", { id: row.id })
                    .then(response => {
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: "success"
                        });
                        this.hideloading();
                        this.refresh();
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.apierror(err);
                    });
            },
            runRow: function (row) {
                this.showloading();
                this.$http.post("/account/runquery", { querynumber: row.querynumber, checkquery: row.checkquery, updatequery: row.updatequery,checkmethod:row.checkmethod })
                    .then(() => {
                        this.hideloading();
                        this.refresh();
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.apierror(err);
                    });
            },
            update: function () {
                this.showloading();
                this.$http.post("account/getupdatehistory")
                    .then(() => {
                        this.hideloading();
                        this.refresh();
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.apierror(err);
                    });
            },
            recycle: function () {
                this.showloading();
                this.$http.post("account/exit")
                    .then(response => {
                        this.hideloading();
                        this.$alert(response.data);
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.apierror(err);
                    });
            },
            refresh: function () {
                this.showloading();
                this.$http.post("account/viewupdatestructure")
                    .then(response => {
                        this.form = response.data;
                        this.hideloading();
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.apierror(err);
                    });
            },
            cellValueRenderer: function (row, column, cellValue) {
                let value = cellValue;
                if (typeof row[column.property] === 'boolean') {
                    value = String(cellValue);
                }
                return value;
            },

            dateformatter: function (value) {
                if (value.updatedon != "0001-01-01T00:00:00") {
                    var d = new Date(value.updatedon)
                    return d.toLocaleString();
                }
                else {
                    return "";
                }
            },
        },
        created: function () {
            if (this.$store.state.user.superadmin == true) {
                this.refresh();
            }
            else {
                this.$router.push('/')
            }
        }
    }
</script>