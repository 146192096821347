<template>
    <div>
        <div v-if="!details" class="operativecost">
            <!-- <el-affix :offset="50"> -->
                <div class="colorwhite stickyheader">
                    <el-row>
                        <el-col :span="10">
                            <div class="headerstyle">Operative Cost</div>
                        </el-col>
                        <el-col :span="9" class="margint8">
                            <el-pagination layout="prev, pager, next"
                                           :total="view.count"
                                           :page-size="view.pagesize"
                                           @current-change="refresh"
                                           v-model:currentPage="view.pageno">
                            </el-pagination>
                        </el-col>
                        <el-col :span="5">
                            <div class="floatright margint8">
                                <span>
                                    <el-button type="primary"
                                               @click="addrow(null)"
                                               icon="circle-plus-filled"
                                               :disabled="!editpermission"></el-button>
                                </span>
                            </div>
                        </el-col>
                    </el-row>
                    <hr class="spaceBetween" />
                </div>
            <!-- </el-affix> -->
            <div>
                <el-table :border="true"
                          :data="tabledata"
                          :default-sort="{prop: 'fullname', order: 'ascending'}"
                          @sort-change="sortchange">
                    <el-table-column prop="u.fullname"
                                     sortable="custom"
                                     label="Name"
                                     width="120">
                    </el-table-column>

                    <el-table-column prop="o.rate"
                                     label="Rate"
                                     align="right"
                                     width="65">
                        <template #default="scope">
                            <div v-if="scope.row.o.rate > 0">
                                {{ scope.row.o.rate.toFixed(2) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="ratecurrency.code"
                                     label="Currency"
                                     width="80">
                    </el-table-column>

                    <el-table-column prop="o.rateon"
                                     label="Rate From"                                     
                                     width="93" :formatter="dateformatter">
                    </el-table-column>

                    <el-table-column width="20"
                                     class-name="bordertbnone">
                    </el-table-column>

                    <el-table-column prop="o.overhead"
                                     label="Overhead"
                                     align="right"
                                     width="65">
                        <template #default="scope">
                            <div v-if="scope.row.o.overhead > 0">
                                {{ scope.row.o.overhead.toFixed(2) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="overheadcurrency.code"
                                     label="Currency"
                                     width="80">
                    </el-table-column>

                    <el-table-column prop="o.overheadon"
                                     label="Increased"
                                     width="90" :formatter="dateformatter">
                    </el-table-column>

                    <el-table-column align="center" label="Update" width="55">
                        <template #default="scope">
                            <el-button type="primary"
                                       class="padding7"
                                       icon="edit-pen"
                                       @click="editrow(scope.row)"
                                       :disabled="!editpermission"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div v-if="details" class="operativecostform">
            <!-- <el-affix :offset="50"> -->
                <div class="colorwhite stickyheader">
                    <el-row>
                        <el-col :span="15">
                            <div class="headerstyle">Update Operative Costs</div>
                        </el-col>
                        <el-col :span="9">
                            <div class="floatright margint8">
                                <span>
                                    <el-button type="success"
                                               @click="save"
                                               v-loading.fullscreen.lock="savingcost"
                                               :disabled="!editpermission">Save</el-button>
                                </span>
                                <span>
                                    <el-button type="info"
                                               class="marginleft5"
                                               @click="resetform"
                                               icon="back">Back</el-button>
                                </span>
                            </div>
                        </el-col>
                    </el-row>
                    <hr class="spaceBetween" />
                </div>
            <!-- </el-affix> -->

            <div class="paddingr0left5">
                <el-table :data="detailform">
                    <el-table-column prop="u.fullname" label="Name" width="120">
                        <template #default="scope">
                            <el-autocomplete class="inline-input fullwidth readonlyinput"
                                             v-model="scope.row.u.fullname"
                                             popper-class="customselect"
                                             :fetch-suggestions="searchuser"
                                             placeholder="All Users"
                                             @select="selectuser($event, scope.row)"
                                             :clearable="true"
                                             :disabled="isdisable"
                                             @change="usernamechange($event, scope.row)">
                                <template #default="{ item }">
                                    <div class="value">{{ item.fullname }}</div>
                                </template>
                            </el-autocomplete>
                        </template>
                    </el-table-column>

                    <el-table-column prop="o.rate"
                                     label="Rate"
                                     align="right"
                                     header-align="left"
                                     width="65">
                        <template #default="scope">
                            <el-input-number :precision="2"
                                             :controls="false"
                                             placeholder="Rate"
                                             v-model="scope.row.o.rate"
                                             @change="checkcurrency(scope.row.o, 'rate', $event)"
                                             class="widthinput60 inputtypenumlr6px" />
                        </template>
                    </el-table-column>

                    <el-table-column label="Currency" width="90">
                        <template #default="scope">
                            <el-select class="left"
                                       v-model="scope.row.o.ratecurrencyid"
                                       @change="currencychange(scope.row.o, 'ratecurrencyid')"
                                       :clearable="true"
                                       popper-class="customselect-currency"
                                       placeholder="Currency"
                                       :filterable="true"
                                       remote
                                       reserve-keyword
                                       :remote-method="remoteMethodcurrencies">
                                <el-option v-for="item in currencyoptions"
                                           :key="item.id"
                                           :label="item.code"
                                           :value="item.id">
                                    <div v-if="item.code != null">
                                        <span style="float: left">{{ item.code }}</span>
                                        <el-button style="float: right"
                                                   v-if="currencyoptions.length != 0"
                                                   icon="edit-pen"
                                                   type="text"
                                                   @click="editcurrency(item)"></el-button>
                                    </div>
                                </el-option>
                                <el-option value="">
                                    <div>
                                        <el-button type="text"
                                                   class="fullwidth"
                                                   @click="addcurrency"
                                                   icon="circle-plus"></el-button>
                                    </div>
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>

                    <el-table-column prop="o.rateon"
                                     label="Rate From"
                                     width="70">
                        <template #default="scope">
                            <el-date-picker v-model="scope.row.o.rateon"
                                            type="month"
                                            :editable="false"
                                            placeholder="Month"
                                            :clearable="false"
                                            format="MMM-YYYY"
                                            value-format="YYYY-MM-DD"
                                            @change="editedchanges(scope.row.o, true)"
                                            class="customdate noinputpadding datemonthwise"
                                            :disabled-date="$commonutils.disableFutureDates">
                            </el-date-picker>
                        </template>
                    </el-table-column>

                    <el-table-column width="14">
                    </el-table-column>

                    <el-table-column prop="o.overhead"
                                     label="Overhead"
                                     header-align="left"
                                     width="65">
                        <template #default="scope">
                            <el-input-number :precision="2"
                                             :controls="false"
                                             placeholder="Overhead"
                                             v-model="scope.row.o.overhead"
                                             @change="checkcurrency(scope.row.o, 'overhead', $event)"
                                             class="widthinput60 inputtypenumlr6px" />
                        </template>
                    </el-table-column>

                    <el-table-column label="Currency" width="90">
                        <template #default="scope">
                            <el-select class="left"
                                       v-model="scope.row.o.overheadcurrencyid"
                                       @change="currencychange(scope.row.o, 'overheadcurrencyid')"
                                       :clearable="true"
                                       popper-class="customselect-currency"
                                       placeholder="Currency"
                                       :filterable="true"
                                       remote
                                       reserve-keyword
                                       :remote-method="remoteMethodcurrencies">
                                <el-option v-for="item in currencyoptions"
                                           :key="item.id"
                                           :label="item.code"
                                           :value="item.id">
                                    <div v-if="item.code != null">
                                        <span style="float: left">{{ item.code }}</span>
                                        <el-button style="float: right"
                                                   v-if="currencyoptions.length != 0"
                                                   icon="edit-pen"
                                                   type="text"
                                                   @click="editcurrency(item)"></el-button>
                                    </div>
                                </el-option>
                                <el-option value="">
                                    <div>
                                        <el-button type="text"
                                                   class="fullwidth"
                                                   icon="circle-plus"
                                                   @click="addcurrency"></el-button>
                                    </div>
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>

                    <el-table-column prop="o.overheadon"
                                     label="Increased"
                                     width="70">
                        <template #default="scope">
                            <el-date-picker v-model="scope.row.o.overheadon"
                                            type="month"
                                            :editable="false"
                                            placeholder="Month"
                                            :clearable="false"
                                            format="MMM-YYYY"
                                            value-format="YYYY-MM-DD"
                                            @change="editedchanges(scope.row.o, true)"
                                            class="customdate noinputpadding datemonthwise"
                                            :disabled-date="$commonutils.disableFutureDates"
                                            >
                            </el-date-picker>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div>
                <el-dialog title="Add/Edit Currency" v-model="currencydialog" custom-class="centerdialog" width="400px" center>
                    <el-form>
                        <el-form-item label="Currency Name" label-width="115px">
                            <el-input placeholder="Currency Name"
                                      v-model="currency.name"
                                      @change="currencyaddedchange(currency, true)"></el-input>
                        </el-form-item>
                        <div class="spaceBetween" />

                        <el-form-item label="Currency Code" label-width="115px">
                            <el-input placeholder="Currency Code"
                                      v-model="currency.code"
                                      @change="currencyaddedchange(currency, true)"></el-input>
                        </el-form-item>
                        <div class="spaceBetween" />

                        <el-form-item label="Country Name" label-width="115px">
                            <el-input placeholder="Country Name"
                                      v-model="currency.country"
                                      @change="currencyaddedchange(currency, true)"></el-input>
                        </el-form-item>
                        <div class="spaceBetween" />

                        <el-form-item label="Symbol" label-width="115px">
                            <el-input placeholder="Symbol"
                                      v-model="currency.symbol"
                                      @change="currencyaddedchange(currency, true)"></el-input>
                        </el-form-item>
                    </el-form>
                    <template #footer>
                        <span>
                            <el-button type="success"
                                       @click="savecurrency"
                                       icon="circle-check">Save</el-button>
                        </span>
                    </template>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    export default {
        data() {
            return {
                details: false,
                view: {
                    filter: [{ table: "o", column: "userid", value: "" }]
                },
                tabledata: [],
                detailform: [],
                isdisable: false,
                savingcost: false,
                currencies: [],
                currencyoptions: [],
                currencydialog: false,
                currency: {
                    name: "",
                    code: "",
                    country: "",
                    symbol: ""
                },
            }
        },
        methods: {
            refresh: function () {
                this.showloading();
                this.$http.post("operativecost/GetView", this.view)
                    .then((response) => {
                        this.hideloading();
                        this.view = response.data.view;
                        this.tabledata = response.data.data;
                        this.detailform = [];
                        this.details = false;
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },
            sortchange: function (sort) {
                this.view.sortcol = sort.prop;
                this.view.sortorder = sort.order;
                this.refresh();
            },
            dateformatter: function (_row, _column, cellValue) {
                if (cellValue) {
                    return moment(cellValue).format("MMM-YYYY");
                }
                return cellValue;
            },
            addrow: function (row) {
                this.details = true;
                this.isdisable = false;
                let operativecost = {
                    id: 0,
                    userid: null,
                    rate: null,
                    ratecurrencyid: null,
                    overhead: null,
                    overheadcurrencyid: null,
                    edited: false
                };
                let user = { fullname: "" };
                operativecost.rateon = moment(new Date()).format("YYYY-MM-DD");
                operativecost.overheadon = moment(new Date()).format("YYYY-MM-DD");
                if (row != null) {
                    let dt = new Date(row.o.rateon);
                    dt.setMonth(dt.getMonth() + 1);
                    let newmonth = dt.toLocaleDateString();
                    operativecost.rateon = moment(newmonth).format("YYYY-MM-DD");
                    operativecost.overheadon = moment(newmonth).format("YYYY-MM-DD");

                    operativecost.userid = row.o.userid;
                    user.fullname = row.u.fullname;
                    this.isdisable = true;
                }
                this.detailform.unshift({ o: operativecost, u: user });
            },
            resetform: function () {
                this.details = false;
                this.isdisable = false;
                this.detailform = [];
                this.refresh();
            },
            editrow: function (row) {
                this.showloading();
                let data = row.o;
                this.$http.post("operativecost/Getuseroperativecosts", data)
                    .then((response) => {
                        this.hideloading();
                        this.details = true;
                        this.detailform = response.data.data;
                        this.addrow(this.detailform[0]);
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.details = false;
                        this.apierror(error);
                    });
            },
            selectuser(event, row) {
                if (event.loginname != null) {
                    row.u.fullname = event.fullname;
                    row.o.userid = event.id;
                } else {
                    row.userid = null;
                }
                this.editedchanges(row.o, true);
            },
            usernamechange(item, row) {
                if (item == undefined || item == "") {
                    row.o.userid = null;
                    row.u.fullname = item;
                }
                this.editedchanges(row.o, true);
            },
            remoteMethodcurrencies(query) {
                if (query == "") {
                    this.currencyoptions = this.currencies;
                }
                else {
                    this.currencyoptions = this.currencies.filter(item => {
                        return item.code.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }
            },
            editedchanges(row, event) {
                row.edited = event;
            },
            checkcurrency: function (row, name, event) {
                let val = parseFloat(event);
                if (!isNaN(val) && isFinite(val)) {
                    row[name] = parseFloat(val);
                } else {
                    row[name] = null;
                }
                this.editedchanges(row, true);
            },
            currencychange(row, name) {
                if (row[name] == "") {
                    row[name] = null;
                }
                this.editedchanges(row, true);
            },
            addcurrency() {
                this.currency = { name: "", code: "", country: "", symbol: "" };
                this.currencydialog = true;
            },
            editcurrency(row) {
                this.currency = { name: "", code: "", country: "", symbol: "" };
                this.currency = row;
                this.currencydialog = true;
            },
            currencyaddedchange: function (row, event) {
                row.currencyedited = event;
            },
            myvalidatecurrency: function (callback) {
                var msg = [];
                if (!this.currency.country) {
                    msg.push("Country Name is required");
                }
                if (this.currency.country.length > 50) {
                    msg.push("Country Name should not exceed 50 characters.");
                }
                if (!this.currency.code) {
                    msg.push("Code Name is required");
                }
                if (!this.currency.code.length > 50) {
                    msg.push("Code Name should not exceed 50 characters.");
                }
                if (msg.length > 0) {
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(msg.join('<br/>') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }
                else {
                    callback();
                }
            },
            savecurrency: function () {
                if (this.currency.currencyedited) {
                    this.myvalidatecurrency(() => {
                        this.showloading();
                        this.post("operativecost/savecurrency", this.currency)
                            .then((response) => {
                                this.hideloading();
                                this.$message({
                                    showClose: true,
                                    message: response.data,
                                    type: "success"
                                });
                                this.currencydialog = false;
                                this.getcurrency().then(() => {
                                    this.currency = { name: "", code: "", country: "", symbol: "" };
                                    this.currencyoptions = this.$store.state.currencies;
                                    this.currencies = this.$store.state.currencies;
                                });
                            })
                            .catch((err) => {
                                this.hideloading();
                                this.alerterror(err);
                            });
                    });
                }
                else {
                    this.$message({
                        type: "info",
                        showClose: true,
                        message: "Nothing to save"
                    });
                }
            },
            validateoperativecost: function (callback) {
                let msg = [];
                //  const uniqueValues = new Set(this.detailform.map(v => moment(v.o.rateon).format("YYYY-MM")));
                // if (uniqueValues.size < this.detailform.length) {
                //   msg.push("Increased Months and Years are duplicate");
                //  }

                let changedcost = [];
                changedcost = this.detailform.filter((x) => x.o.edited == true);
                for (const item of changedcost) {
                    if (item.o.userid == null) {
                        msg.push("User Name is required");
                    }
                    if (item.o.rate == undefined || item.o.rate == null || item.o.rate == 0) {
                        msg.push("Rate is required");
                    }
                    if (item.o.overhead == undefined || item.o.overhead == null || item.o.overhead == 0) {
                        msg.push("Overhead is required");
                    }
                }
                if (msg.length > 0) {
                    this.savingcost = false;
                    let style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(msg.join('<br/>') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }
                else {
                    callback();
                }
            },
            save: function () {
                this.savingcost = true;
                let archanged = this.detailform.filter((x) => x.o.edited == true);
                if (archanged.length > 0 && this.savingcost) {
                    this.validateoperativecost(() => {
                        this.showloading();
                        this.$http.post("operativecost/saveoperativecost", archanged)
                            .then((response) => {
                                this.hideloading();
                                this.savingcost = false;
                                this.$message({
                                    type: "success",
                                    showClose: true,
                                    message: response.data.message
                                });
                                this.details = false;
                                this.refresh();
                            })
                            .catch(error => {
                                this.hideloading();
                                this.savingcost = false;
                                if (error.response != null && typeof (error.response.data) != "object") {
                                    let style = '<style> .el-message-box {width:60vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>'
                                    this.$alert(error.response.data + style, "Invalid request", { dangerouslyUseHTMLString: true });
                                } else {
                                    this.apierror(error);
                                }
                            });
                    });
                }
                else {
                    this.savingcost = false;
                    this.$message({
                        type: "info",
                        showClose: true,
                        message: "Nothing to save"
                    });
                }
            }
        },
        created() {
            if (this.$store.state.currencies.length == 0) {
                this.getcurrency().then(() => {
                    this.currencyoptions = this.$store.state.currencies;
                    this.currencies = this.$store.state.currencies;
                });
            } else {
                this.currencyoptions = this.$store.state.currencies;
                this.currencies = this.$store.state.currencies;
            }
            let sort = { prop: 'fullname', order: 'ascending' };
            this.sortchange(sort);
        }
    }
</script>
