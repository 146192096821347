<template>
    <div v-show="showdata" v-loading="loading" class="accesspermissions">
        <!-- <el-affix :offset="50"> -->
                 <div class="colorwhite stickyheader">
                     <el-row>
                         <el-col :span="12">
                                <div class="headerstyle">Access Permissions</div>
                         </el-col>
                         <el-col :span="12">
                             <div class="floatright margint8 paddingright5">
                                 <span>
                                    <el-button :disabled="!editpermission"
                                        icon="circle-check"
                                        @click="save"
                                        type="success">Save</el-button>
                                 </span>
                                 <span>
                                     <el-button :disabled="!editpermission"
                                        class="marginleft5"
                                        title="Add New"
                                        icon="circle-plus-filled"
                                        type="primary" @click="addRow"></el-button>
                                 </span>
                                 <span>
                                     <el-button icon="back" class="marginleft5" @click="$router.go(-1)">Back</el-button>
                                 </span>
                             </div>
                         </el-col>                         
                     </el-row>
                    <hr class="spaceBetween" />
                 </div>
        <!-- </el-affix> -->
        <el-table :default-sort="{  order: 'ascending' }"  @sort-change="sortchange"
                  :data="detailform" class="fullwidth">

            <el-table-column prop="firstname" label="First name"  sortable="custom"   :width="widthsobj.firstname" >
                <template #default="scope"  >
                    <el-input :disabled="!editpermission"
                    placeholder="First name"
                              v-model="scope.row.firstname"
                             
                              @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>

            <el-table-column prop="lastname" label="Last name"  sortable="custom" :width="widthsobj.lastname">
                <template #default="scope">
                    <el-input :disabled="!editpermission"
                    placeholder="Last name"
                              v-model="scope.row.lastname"
                              @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>

            <el-table-column prop="loginname" label="Login name" sortable="custom" :width="widthsobj.loginname">
                <template #default="scope">
                    <el-input :disabled="!editpermission"
                              v-model="scope.row.loginname"
                               placeholder="Login name"
                              @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>

            <el-table-column prop="password" label="Password" sortable="custom" :width="widthsobj.password">
                <template #default="scope">
                    <el-input :disabled="!editpermission"
                     placeholder="Password"
                              v-model="scope.row.password"
                              @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>

            <!-- <el-table-column prop="accesslevel" label="Type" sortable="custom" width="60">
                <template #default="scope">
                    <el-select v-model="scope.row.accesslevel"
                               @change="changed(scope.row)"
                               placeholder="Type">
                        <el-option label="A"
                                   value="A"
                                   :disabled="!editpermission"></el-option>
                        <el-option label="B"
                                   value="B"
                                   :disabled="!editpermission"></el-option>
                        <el-option label="C"
                                   value="C"
                                   :disabled="!editpermission"></el-option>
                    </el-select>
                </template>
            </el-table-column> -->

            <el-table-column prop="active" label="Active" sortable="custom" align="center" width="70">
                <template #default="scope">
                    <el-checkbox :disabled="!editpermission"
                                 v-model="scope.row.active"
                                 @change="changed(scope.row)"></el-checkbox>
                </template>
            </el-table-column>

            <el-table-column prop="istimetrackeradmin" label="TTA" align="center" width="80">
                <template #header>
                    <el-tooltip
                        class="box-item"
                        content="Time Tracker Admin"
                        placement="top"
                    >
                    <el-icon class="verticalmiddle"><InfoFilled /></el-icon>
                    </el-tooltip>
                    <span class="verticalmiddle">TTA</span>
                </template>
                <template #default="scope">
                    <el-checkbox :disabled="!editpermission"
                                 v-model="scope.row.istimetrackeradmin"
                                 @change="changed(scope.row)"></el-checkbox>
                </template>
            </el-table-column>

            <el-table-column prop="reportstoid"
                             label="Report's To"
                             align="center"
                             :width="widthsobj.reportsto">
                <template #default="scope">
                     <el-select v-model="scope.row.reportstoid"
                          clearable
                          placeholder="Report's To"
                           @change="reporttochange(scope.row, $event)">
                           <el-option v-for="item in getuserlist"
                                   :disabled="!editpermission"
                                   :key="item.id"
                                   :label="item.loginname"
                                   :value="item.id">
                        </el-option>
                        </el-select>
                    <!-- <el-select clearable
                               :v-model="scope.row.reportstoid"
                               placeholder="Report's To"
                               @change="reporttochange(scope.row, $event)">
                        <el-option v-for="item in getuserlist"
                                   :disabled="!editpermission"
                                   :key="item.id"
                                   :label="item.loginname"
                                   :value="item.id">
                        </el-option>
                    </el-select> -->
                </template>
            </el-table-column>

            <el-table-column prop="roleid" label="Role" align="center" :width="widthsobj.role">
                <template #default="scope">
                    <el-select v-model="scope.row.roleid"
                     placeholder="Role"
                     clearable
                     @change="rolechange(scope.row, $event)">
                        <el-option
                           v-for="item in roles"
                           :disabled="!editpermission"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <!-- <el-select clearable
                               :v-model="scope.row.roleid"
                               @change="rolechange(scope.row, $event)"
                               placeholder="Role">
                        <el-option v-for="item in roles"
                                   :disabled="!editpermission"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select> -->
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import { ElMessageBox } from 'element-plus'
    export default {
        data() {
            return {
                widthsobj:{
                    firstname:'100px',
                    lastname:'100px',
                    loginname:'100px',
                    password:'100px',
                    reportsto:'120px',
                    role:'150px',
                },
                roles: [],
                detailform: [],
                loading: true,
                showsection: true,
                showrole: true,
                showdata: false
            };
        },

        computed: {
            getuserlist() {
                if(this.detailform.length <= 0){
                 return [];
                }
                return this.detailform;
                // return this.detailform.filter((x) => {
                //     if (x.accesslevel == "A" || x.accesslevel == "B") {
                //         return x;
                //     }
                // });
            },

            getreportname() {
                return function (data) {
                    if(data.reportstoid != null){
                       var user = this.getuserlist.find((x) => x.id == data.reportstoid);
                       if (user) {
                           return user.loginname;
                       } 
                    }
                    return "";
                };
            },

            getrolename() {
                return function (data) {
                    if(data.roleid != null){
                       var role = this.roles.find((x) => x.id == data.roleid);
                       if (role) {
                           return role.name;
                       }
                    }
                    return "";
                };
            }
        },

        methods: {
            sortchange: function (sort) {
                if(sort.order != null && sort.prop != null){
                this.detailform  = this.detailform.sort( function ( a, b ) {
                      if(sort.order == "descending"){
                      if (b[sort.prop] > a[sort.prop]) {
                            return 1;
                        }
                        if (a[sort.prop] > b[sort.prop]) {
                            return -1;
                        }
                        return 0;
                      }
                      if(sort.order == "ascending"){
                        if (b[sort.prop] > a[sort.prop]) {
                            return -1;
                        }
                        if (a[sort.prop] > b[sort.prop]) {
                            return 1;
                        }
                        return 0;
                      }
                     });
                    }
                    //  this.$nextTick(()=>{
                    //      this.detailform = tt;
                    //  })
                // this.view.sortcol = sort.prop;
                // this.view.sortorder = sort.order;
                // this.refresh();
            },
            WarningMSG(msg){
                ElMessageBox.confirm(
                    msg,
                    'Warning',
                    {
                        showcancelbutton: true,
                    type: 'warning',
                    })
            },
            reporttochange(row, event) {
                if (event == null || event == "") {
                    row.reportstoid = null;
                    this.changed(row);
                }else{
                    var fullname = row.firstname +" "+ row.lastname; 
                    if(row.id == event){
                        this.WarningMSG(fullname +  " can't Report to themself !");
                        row.reportstoid = null;
                        this.changed(row);
                        return
                    }
                    var isfind = this.detailform.find(x => x.reportstoid == row.id);
                    if(isfind && isfind.id == row.reportstoid){
                        this.WarningMSG(isfind.firstname + " " + isfind.lastname + " already report to "+ fullname +" !");
                        row.reportstoid = null;
                        this.changed(row);
                        return
                    }
                    row.reportstoid = event;
                    this.changed(row);
                }
            },

            rolechange(row, event) {
               
                if (event == null || event == "") {
                    row.roleid = null;
                }
                else {
                    row.roleid = event;
                }
                this.changed(row);
            },

            addRow: function () {
                var itm = {
                    active: true,
                        superadmin: false,
                             firstname:'',
                             lastname:'',
                             loginname:'',
                             password:'',
                             accesslevel:'',   
                             reportstoid:null,
                             roleid:null };
                this.detailform.unshift(itm);
                // this.changed(itm);
                // this.detailform.push(itm);
            },

            changed: function (row) {
                row.edited = true;
            },

            save: function () {
                var archanged = this.detailform.filter((x) => x.edited == true);
                if (archanged.length > 0) {
                    this.$http.post("account/saveusers", archanged)
                        .then((response) => {
                            this.$message({
                                showClose: true,
                                message: response.data,
                                type: "success"
                            });
                            this.refresh();
                        })
                        .catch((err) => {
                           this.apierror(err);
                        });
                }
                else {
                    this.$message({
                        showClose: true,
                        message: "Nothing to save",
                        type: "info"
                    });
                }
            },

            getroles: function () {
                this.$http.get("account/getrole")
                    .then((response) => {
                        this.roles = response.data;
                    })
                    .catch((err) => {
                        this.apierror(err);
                    });
            },
        columnwidth: function () {
          var fwidth = 0,fname = "",
              lwidth = 0,lname = "", 
              lowidth = 0,loname = "",
              pswidth = 0,psname = "", 
              rtwidth = 0,rtname = "",
              rwidth = 0,rname = "";       
          for (var i = 0; i < this.detailform.length; i++) {
              var row = this.detailform[i];
               var reportto = this.getreportname(row);
               var role = this.getrolename(row);
              if (row.firstname && row.firstname.length != 0) {
                  if (row.firstname.length > fwidth) {
                      fwidth = row.firstname.length;
                       fname = row.firstname;
                  }
              }
               if (row.lastname && row.lastname.length != 0) {
                  if (row.lastname.length > lwidth) {
                      lwidth = row.lastname.length;
                       lname = row.lastname;
                  }
              }
              if (row.loginname && row.loginname.length != 0) {
                  if (row.loginname.length > lowidth) {
                      lowidth = row.loginname.length;
                       loname = row.loginname;
                  }
              }
               if (row.password && row.password.length != 0) {
                  if (row.password.length > pswidth) {
                      pswidth = row.password.length;
                       psname = row.password;
                  }
              }
              if ( reportto && reportto.length != 0) {
                  if (reportto.length > rtwidth) {
                      rtwidth = reportto.length;
                       rtname = reportto;
                  }
              }
               if ( role && role.length != 0) {
                  if (role.length > rwidth) {
                      rwidth = role.length;
                       rname = role;
                  }
              }
          }
          if (fwidth != 0) {
              var fnamesize = this.measureTextWidth(fname) + 40;
              if(fnamesize > 100){
              this.widthsobj.firstname = fnamesize.toString() + "px";
              }
          }
           if (lwidth != 0) {
              var lnamesize = this.measureTextWidth(lname) + 40;
              if(lnamesize > 100){
              this.widthsobj.lastname = lnamesize.toString() + "px";
              }
          }
           if (lowidth != 0) {
              var lonamesize = this.measureTextWidth(loname) + 40;
              if(lonamesize > 100){
              this.widthsobj.loginname = lonamesize.toString() + "px";
              }
          }
           if (pswidth != 0) {
              var psnamesize = this.measureTextWidth(psname) + 40;
              if(psnamesize > 100){
              this.widthsobj.password = psnamesize.toString() + "px";
              }
          }
           if (rtwidth != 0) {
              var rtnamesize = this.measureTextWidth(rtname) + 40;
              if(rtnamesize > 120){
              this.widthsobj.reportsto = rtnamesize.toString() + "px";
              }
          }
          if (rwidth != 0) {
              var rnamesize = this.measureTextWidth(rname) + 40;
              if(rnamesize > 100){
                  this.widthsobj.role = rnamesize.toString()  + "px";
              }
          }
        },   
            refresh: function () {
                this.getroles();
                this.loading = true;
                this.$http.post("account/GetUsers")
                    .then((response) => {
                        var json = JSON.parse(response.data);
                        this.detailform = json;
                        this.loading = false;
                        this.columnwidth();
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.apierror(err);
                    });
            }
        },

        created: function () {
            this.refresh();
            this.showdata = true;
        }
    };
</script>
<style>
.el-table .cell {
    padding-right: 4px !important;
    padding-left: 4px !important;
}
</style>
