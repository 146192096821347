<template>
    <div class="pro_over_style">
        <div class="stickyheader colorwhite">
        <el-row>
            <el-col :span="12">
                <div class="headerstyle">{{routerdata}} Add/Edit</div>
            </el-col>
            <el-col :span="12">
                    <div class="floatright topbottomspace">
                        <div class="floatleft padright5px">
                            <span class="spaceBetweenLR">Active &nbsp;</span>
                            <el-checkbox :disabled="isarchive" v-model="form.active"></el-checkbox>
                            &nbsp;
                        </div>
                        <div class="floatleft">
                            <el-button @click="saveproject"
                               plain
                               type="success"
                               :disabled="!editpermission || isarchive"
                               icon="circle-check">Save</el-button>
                            <el-button icon="back" @click="cancel" plain>Back</el-button>
                        </div>
                </div>
                </el-col>
        </el-row>
        <hr class="spaceBetween" />
        <el-row justify="space-between">
            <el-col :span="12">
                <el-input class="width50" v-model="form.name"></el-input>
            </el-col>
            <el-col :span="12">
                <el-button class="floatright marginleft4px"  v-if="!isarchive" type="primary" icon="Document"  >Eh Import</el-button>
                <el-button class="floatright"  type="danger" @click="deleteproject" v-if="totaltime <= 0 && form.id > 0 && !isarchive" icon="delete" >Delete</el-button>
                <el-button class="floatright"  type="primary" @click="deleteproject" v-if="totaltime > 0 && form.id > 0 && !isarchive">Archive</el-button>
                <el-button class="floatright"  type="primary" @click="archivetoproject" v-if="isarchive">Archive To Project</el-button>
            </el-col>
        </el-row>
        <hr class="spaceBetween" />
    </div>
       
       

            <!-- <div class="spaceBetween" /> -->
            <!-- <el-form-item label="Project Parts" v-if="routerdata == 'Project'">
                <el-select v-model="selectpartid"
                           placeholder="Project Parts"
                          class="width50"
                          >

                    <el-option v-for="(item, index) in form.projectparts"
                               :key="item.name"
                               :label="item.name"
                               :value="item.name">

                        <div v-if="item.name != null">
                            <span style="float: left">{{ item.name }}</span>
                            <el-button style="float: right"
                                       v-if="form.projectparts.length != 0"
                                       icon="edit-pen"
                                       type="text"
                                       @click="editprojectpart(index)"></el-button>
                        </div>
                    </el-option>

                    <el-option value="">
                        <div>
                            <el-button icon="circle-plus"
                                       type="text"
                                       class="fullwidth"
                                       @click="addprojectpart"></el-button>
                        </div>
                    </el-option>
                </el-select>

              
            </el-form-item> -->
            
            <div v-if="routerdata == 'Project' && form && form.projectparts ">
            <el-table  :data="form.projectparts" style="width: 100%">
                    <el-table-column prop="name" label="Project Parts"  >
                        <template #default="scope">
                            <el-input
                            v-model="scope.row.name"
                            class="w-50 m-2"
                            size="small"
                            placeholder="Please Input Project Part Name"
                            :prefix-icon="Search"
                            :disabled="isarchive"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="Action" width="200" >
                        <template #default="scope">
                            <!-- #default="scope" -->
                            <div class="floatright">
                            <el-button icon="CirclePlus" :disabled="isarchive" @click="addnewpart(scope.row,scope.$index)" type="primary" ></el-button>
                            <el-button
                                    icon="delete" :disabled="isarchive"
                                    v-if="!scope.row.timesheetcount || scope.row.timesheetcount <= 0"
                                    @click="deletenewpart(scope.row,scope.$index)"
                                    type="danger"></el-button>
                                    <span v-else style="margin-left: 50px;">
                                        &nbsp;
                                    </span>
                                    <el-button  type="primary" @click="importgooglesheet(scope.row)" icon="Document" :disabled="isarchive" >Import</el-button>
                                </div>
                </template>
                    </el-table-column>
                </el-table>
            </div>  
            <!-- <div class="spaceBetween" />
            <el-form-item label="Active">
                <el-checkbox v-model="form.active"></el-checkbox>
            </el-form-item>

            <div class="spaceBetween" />
            <el-form-item>
                <el-button @click="saveproject"
                           plain
                           type="success"
                           :disabled="!editpermission"
                           icon="circle-check">Save</el-button>
                <el-button icon="back" @click="cancel" plain>Back</el-button>
            </el-form-item> -->
        

        <!-- <el-dialog title="Add/Edit Project Part"
                   width="400px"
                   v-model="partdilg"
                   :before-close="closedig">
            <el-form>
                <el-form-item label="Part name" label-width="100px">
                    <el-input v-model="form.projectpart.name"></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span>
                    <el-button plain
                               @click="savepart"
                               type="success"
                               style="margin-right:150px;"
                               icon="circle-check">Save</el-button>
                </span>
            </template>
       
        </el-dialog> -->
        <el-dialog v-model="dialogImportrowsVisible" width="50%" center @before-close="importrows = [];"  title="Imported Rows">
            <div>
                <el-table :data="importrows" :show-header="false" default-expand-all="true"  style="width: 100%">
                    <el-table-column type="expand">
                        <template #default="props">
                            <el-table :data="props.row.eh" :show-header="false" border style="width: 100%">
                                <el-table-column prop="sectionname" label="Section Name"  />
                                <el-table-column prop="action.name" label="Project Action Name"  />
                                <!-- <el-table-column prop="managementhour" label="Management Hours" width="130" >
                                    <template #default="props">
                                        {{ timeget(props.row.managementhour) }}
                                    </template>            
                                </el-table-column>
                                <el-table-column prop="billinghour" label="Billing Hours" width="100"  >
                                    <template #default="props">
                                        {{ timeget(props.row.billinghour ) }}
                                    </template>            
                                </el-table-column> -->
                                <el-table-column prop="billinghour" label="Total" width="100"  >
                                    <template #default="props">
                                        {{timeget(props.row.billinghour + props.row.managementhour ) }}
                                    </template>            
                                </el-table-column>
                            </el-table> 
                            </template>
                            </el-table-column>
                    <el-table-column prop="headname" label="Header"  >
                        <template #default="props">
                            <b>{{props.row.headname}}</b>
                        </template>
                        </el-table-column>
                </el-table>
            </div>
        </el-dialog>
        <el-dialog v-model="dialogImportVisible" width="30%" center draggable :show-close="false" title="Import Spreadsheet">
            <div>
                <el-row >
                    <el-col :span="8">
                        Spreadsheet Link
                    </el-col>
                    <el-col :span="16">
                        <el-input
                        v-model="gapi.spreadsheetid"
                        class="w-50 m-2"
                        size="small"
                        placeholder="Please Input spreadsheet Link"
                        
                        />  
                      
                    </el-col>
                </el-row>
                <div class="spaceBetween" />
                <el-row>
                    <el-col :span="8">Sheet Name</el-col>
                    <el-col :span="16">
                        <el-input
                        v-model="gapi.googlesheetname"
                        class="w-50 m-2"
                        size="small"
                        placeholder="Please Input Sheet Name"
                        />  
                        
                    </el-col>
                </el-row>
                <div class="spaceBetween" />
                <el-row  justify="center">
                    <el-col :span="3">
                        <el-button type="primary" @click="getimportsheet" >Ok</el-button>
                    </el-col>
                    <el-col :span="3">
                        <el-button @click="closeimportdialog" plain>cancel</el-button>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                isarchive:false,
                totaltime:0,
                importrows:[],
                dialogImportrowsVisible:false,
                importrow:{},
                dialogImportVisible:false,
                gapi:{
                    googlesheetname:"",
                    spreadsheetid:""
                },
                tableData:[],
                routerdata: "",
                routername: "",
                selectpartid: "",
                // partdilg:false,
                form: {
                    projectparts: [],
                    projectpart: {name: "Single Bill", id: 0},
                }
            }
        },
        computed: {
            timeget() {
                return function (date) {
                    if (date != null) {
                        var m = date % 60;
                        var h = (date - m) / 60;
                        return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
                    }
                    return "";
                };
            }
        },
        methods:{
            archivetoproject(){
                this.showloading();
                this.axios.post("project/archivetoproject", this.form)
                    .then((response) => {
                        if(response.data == true){
                            this.$message({
                                showClose: true,
                                message: "The project's archive conversion was successful!",
                                type: "success"
                                });
                            this.form = {
                                projectparts: [],
                                projectpart: {}
                            };
                            this.$router.push({ path: this.routername });
                        }
                        this.hideloading();    
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },
            deleteproject(){
                this.showloading();
                this.axios.post("project/deleteproject", this.form)
                    .then((response) => {
                        this.hideloading();
                        if(response.data == true){
                            this.$message({
                                showClose: true,
                                message: "The project deleted successfully!",
                                type: "success"
                            });
                            this.form = {
                                projectparts: [],
                                projectpart: {}
                            };
                            this.$router.push({ path: this.routername });
                        }
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },
            getimportsheet(){
                var style = "<style> .el-message-box {width:50%;} </style>";
                var error = [];
                var tt =  this.extractSpreadsheetIdFromUrl(this.gapi.spreadsheetid);
                if(tt == null){
                    error.push('Please enter the valid Spreadsheet Link');
                    // this.$alert('Please enter the valid Spreadsheet Link' + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                    
                }
                if(this.gapi.googlesheetname == "" || this.gapi.googlesheetname == undefined){
                    error.push('Sheet Name must not be empty');
                    // this.$alert('Project Part Name must not be empty' + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                    
                }
                if(error.length > 0){
                    this.$alert(error.join('<br/>') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                    return;
                }else{
                if(tt != null && this.importrow != null){
                    this.gapi.spreadsheetid = tt;
                    var obj = {
                        pr:this.importrow,
                        gapi:this.gapi
                    }
                    this.form.prview = obj; 
                    this.importgooglesheetapi();
                  this.closeimportdialog();
                }
            }
            },
            closeimportdialog(){
                this.importrow = null;
                this.dialogImportVisible = false;
                this.gapi = {
                    googlesheetname:"",
                    spreadsheetid:""
                }
            },
            extractSpreadsheetIdFromUrl(url) {
                // Regular expression pattern to extract the spreadsheet ID
                const spreadsheetIdPattern = /\/spreadsheets\/d\/([a-zA-Z0-9-_]+)\//;

                // Extract the spreadsheet ID from the URL using the pattern
                const matches = url.match(spreadsheetIdPattern);

                if (matches && matches.length >= 2) {
                    return matches[1]; // Return the extracted spreadsheet ID
                } else {
                    return null; // Unable to extract the spreadsheet ID
                }
            },
            importgooglesheet(row){
                var canimpoert = ()=>{
                    if(this.form.id == 0 || this.form.id == undefined){
                        this.form.typeid = this.routerdata == "Project" ? 1 : 2;
                    }
                    if(row.id == 0){
                        row.projectid = this.form.id;
                    }
                    this.importrow = row; 
                    this.dialogImportVisible = true;
                }
                this.validate(canimpoert);
                
                
                // ElMessageBox.prompt("Please input your Spreadsheet's Link", "Import", {
                //     confirmButtonText: 'OK',
                //     cancelButtonText: 'Cancel',
                //     inputPattern:
                //     /\/spreadsheets\/d\/([a-zA-Z0-9-_]+)\//,
                //     inputErrorMessage: "Invalid Spreadsheet Link",
                // })
                //     .then(({ value }) => {
                //         var dd = this.extractSpreadsheetIdFromUrl(value);
                //         var obj = {
                //             pr:row,
                //             gapi:{spreadsheetid:dd}
                //         }
                //         // row.Spreadsheetid = dd;
                //         this.importgooglesheetapi(obj);
                //         // ElMessage({
                //         //     type: 'success',
                //         //     message: `Imported successfully.`,
                //         // })
                //     })
                //     .catch(() => {
                //         this.$message({
                //         type: 'info',
                //         message: 'Import canceled',
                //     })
                //     })
            },
            importgooglesheetapi(){
                this.showloading();
                    this.axios.post("project/importspreadsheet", this.form)
                        .then((response) => {
                            this.hideloading();
                            this.form = response.data;
                            this.getimportedspreadsheet(this.form.prview.pr)
                            this.getprojectdata();
                            this.$message({
                                showClose: true,
                                message: "Spreadsheet Imported successfully!",
                                type: "success"
                            });
                        })
                        .catch((error) => {
                            this.hideloading();
                            this.apierror(error);
                        });
            },
            deletenewpart(row,index){
                if(this.form.projectparts.length > 1){
                    this.form.projectparts.splice(index, 1);
                }else{
                    this.form.projectparts = [{name: "", id: 0}];
                }
            },
            addnewpart(row,index){
                var obj = {name: "", id: 0};
                this.form.projectparts.splice(index, 0,obj);
            },
            // closedig() {
            //     this.form.projectpart = {name: "", id: 0};
            //     this.partdilg = false;
            // },

            cancel() {
                // this.partdilg = false,
                this.form = {
                    projectparts: [],
                    projectpart: {name: "", id: 0}
                };
                this.$router.push({ path: this.routername });
            },

            // savepart: function () {
            //     if (this.form.projectparts[0].name == 'Single Bill') {
            //         this.form.projectparts[0] = this.form.projectpart
            //     }
            //     if (this.form.projectpart.id == -1) {
            //         this.form.projectparts.push({
            //             name: this.form.projectpart.name,
            //             id: 0
            //         });
            //     }
            //     this.selectpartid = this.form.projectpart.name;
            //     this.form.projectpart = {name: "", id: 0};
            //     this.partdilg = false;
            // },

            // editprojectpart: function (index) {
            //     this.form.projectpart = this.form.projectparts[index];
            //     this.partdilg = true;
            // },

            // addprojectpart: function () {
            //     if (this.form.projectparts[0].name == 'Single Bill') {
            //          this.form.projectpart = this.form.projectparts[0];
            //     }else{
            //        this.form.projectpart = {name: "", id: 0};
            //        this.form.projectpart.id = -1;
            //     } 
            //     this.partdilg = true;
            // },

            getprojectdata: function () {
                this.showloading();
                this.axios.post("project/getproject", this.form)
                    .then((response) => {
                        this.hideloading();
                        this.form = response.data;
                        this.form.projectparts = this.form.projectpartselect;
                        
                        // if (this.form.projectpart == null) {
                        //     this.form.projectpart = { name: "Single Bill", id: 0 };
                        // }
                        if (this.form.projectparts.length != 0){
                            this.selectpartid = this.form.projectparts[0].name;
                            this.form.projectparts.forEach((x)=>{
                                this.totaltime += x.timesheetcount;
                            })
                        }else{
                            this.form.projectparts.push({ name: "Single Bill", id: 0 });
                             this.selectpartid = this.form.projectparts[0].name;
                        }
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },

            validate: function (callback) {
                var msg = [];
                if (!this.form.name) {
                    msg.push(this.routerdata + " Name is required");
                }
                  if (this.form.name && this.form.name.length >= 50) {
                        msg.push(this.routerdata +" Name should not exceed 50 characters.");
                    }

                for (var i = 0; i < this.form.projectparts.length; i++) {
                    var a = this.form.projectparts[i];
                    if (a.name == "") {
                        msg.push("Project Part Name must not be empty");
                    }
                     if (a.name.length >= 50) {
                        msg.push("Project Part Name should not exceed 50 characters.");
                    }

                    for (var j = i + 1; j < this.form.projectparts.length; j++) {
                        var b = this.form.projectparts[j];
                        if (a.name == b.name) {
                            var msgname = "Part: " + a.name + " is duplicate";
                            var check = msg.find(x => x == msgname);
                            if (!check) {
                                msg.push(msgname);
                            }
                        }
                    }
                }
                if (msg.length > 0) {
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(msg.join('<br/>') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }
                else {
                    callback();
                }
            },
            getimportedspreadsheet(pr){
                this.showloading();
                this.axios.post("project/getimportedspreadsheet", pr)
                        .then((response) => {
                            if(response.data.length > 0){
                                this.importrows = response.data;
                                this.dialogImportrowsVisible = true;
                            } 
                            this.hideloading();
                            this.getprojectdata();
                        })
                        .catch((error) => {
                            this.hideloading();
                            this.apierror(error);
                        });
            },
            saveproject: function () {
                this.validate(() => {
                    this.showloading();
                    this.form.typeid = this.routerdata == "Project" ? 1 : 2;
                    this.axios.post("project/saveproject", this.form)
                        .then(() => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: "Save Successful!",
                                type: "success"
                            });

                            this.form = {
                                projectparts: [],
                                projectpart: {}
                            };
                            this.searchproject();
                            this.$router.push({ path: this.routername });
                        })
                        .catch((error) => {
                            this.hideloading();
                            this.apierror(error);
                        });
                });
            }
        },

        created: function () {
            this.routerdata = this.$route.path == "/projectedit" ? "Project" : "Overhead";
            this.routername = this.$route.path == "/projectedit" ? "/project" : "/overhead";
            if (this.$route.query.id > 0) {
                this.isarchive =  this.$route.query.isarchive == "true" ? true : false;
                this.form.isarchive = this.isarchive;
                this.form.id = parseInt(this.$route.query.id);
                this.getprojectdata();
            }else{
                 this.form.projectparts.push({ name: "Single Bill", id: 0 });
                 this.selectpartid = this.form.projectparts[0].name;
            }
        }
    }
</script>
