<template>
    <div v-loading="loading" >
       <!-- <el-affix  :offset="52" > -->
             <div v-if="!canprint" class="colorwhite stickyheader">
               <!-- Reports -->
        <div class="headerstyle">Individual Search</div>
        <el-row>
        <el-col :span="24">
                <el-date-picker
                    v-model="month1"
                    type="month"
                    :clearable="false"
                    :editable="false"
                    class="width90px marginr4 left"
                    @change="getmonth"
                    :disabled-date="$commonutils.disableFutureDates"
                    format="MMM-YYYY"
                    placeholder="Pick a month">
                </el-date-picker>
          
                <el-date-picker
                    v-model="view.filter[1].value"
                    type="date"
                    class="datewidth135 marginr4 left"
                   format="ddd DD-MMM-YYYY"
                    :editable="false"
                    :clearable="false"
                    @change="startdatachange"
                    :disabled-date="$commonutils.disableFutureDates"
                    placeholder="From Date">
                </el-date-picker>
               
                <el-date-picker
                    v-model="view.filter[4].value"
                    type="date"
                    class="datewidth135 marginr4 left"
                   format="ddd DD-MMM-YYYY"
                    :editable="false"
                    :clearable="false"
                    @change="enddatachange"
                    :disabled-date="$commonutils.disableFutureDates"
                    placeholder="To Date">
                </el-date-picker>
        
              <el-select class="width220 left"
                    v-model="form.projectid"
                     @change="selectproject"
                      clearable
                       popper-class="customselect"
                       placeholder="All Project/Overhead"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="remoteMethodproject"
                        >
                    <el-option
                      v-for="item in projectoptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>   

                  <!-- <el-select class="width150px marginleft4px left"
                    v-model="form.projectpartid"
                      clearable
                       popper-class="customselect"
                       placeholder="Activity Area"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="remoteMethodactivity"
                        >
                    <el-option
                      v-for="item in partoptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>   -->
                  <span class="width150px marginleft4px left">
                    <el-select v-if="this.isproject == 1"
                                   v-model="form.projectparid"
                                   clearable 
                                   placeholder="Project Part"
                                   @change="projectpartchange">
                            <el-option v-for="item in partoptions"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                            <el-select v-if="this.isproject == 2" v-model="form.actionid" clearable
                                        placeholder="Actions"
                                        @change="getsubactions($event)">
                                <el-option v-for="item in actionptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                  </span>

                  <!-- <el-select class="width150px marginleft4px left"
                    v-model="form.projectactionid"
                      clearable
                       popper-class="customselect"
                       placeholder="Actions"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="remoteMethodaction"
                        >
                    <el-option
                      v-for="item in actionptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>   -->
                  <span class="width150px marginleft4px left">
                    <el-select v-if="this.isproject == 1" 
                                        v-model="form.actionid" 
                                        clearable
                                        placeholder="Actions"
                                        @change="getsubactions($event)">
                                <el-option v-for="item in actionptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                            <el-select v-if="this.isproject == 2" 
                                        v-model="form.subactionid" 
                                        clearable
                                        placeholder="Sub Actions"
                                        @change="changesubactions">
                                <el-option v-for="item in subactionptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                  </span>
               
                <el-autocomplete
                    class="inline-input width150px marginleft4px left"
                    v-model="selecteduser"
                     popper-class="customselect"
                    :fetch-suggestions="searchuserforreport"
                    placeholder="All Users"
                    @select="selectuser"
                    :clearable="true"
                    @change="usernamech">
                    <template #default="{ item }">
                        <div class="value">{{ item.fullname }}</div>
                    </template>
                </el-autocomplete>
                 <span class="right">
                     <el-button    
                         class="marginleft4px"
                         type="primary"
                         icon="document"
                         @click="getreports">View
                     </el-button>
                     <el-button
                         class="marginleft4px"
                         type="primary"
                         icon="document"
                         @click="getsummary">Summary
                     </el-button>
                   </span>
        </el-col>
      </el-row>
        <hr class="spaceBetween" />
             </div>
       <!-- </el-affix> -->
        <el-row v-if="summary == false && !showhtmltable">
          <!-- <span v-if="canprint" class="section-to-print-head"> 
            <div>
            Individual Search
          </div>
          <div class="spaceBetween" />
          </span> -->
            <el-table 
              :data="visibletabledata" :span-method="arraySpanMethod"
              ref="individualsearchtable" :show-header="true" :border="true" 
              v-el-table-infinite-scroll="handleInfiniteScroll" :infinite-scroll-disabled="Disableinfinitescroll" :height="height">
                <el-table-column
                    v-if="form.loginname == ''"
                    label="Employee"
                    prop="u.loginname"
                    :width="tablewidths.employee"
                    min-width="70"
                   >
                    <template #default="scope">
                        <div  class="wordbreak" v-if="scope.row.rowspan != true">
                            {{ scope.row.u.firstname + "    " + scope.row.u.lastname }}
                        </div>
                        <div v-else class="wordbreak"
                            style="font-weight: 300; background-color: #c8c8c8; color: black;">

                            <el-row justify="space-between">
                                  <!-- <el-col :span="6"><div class="grid-content ep-bg-purple" /></el-col> -->
                                  <el-col :offset="8" :span="6">
                                    <div style="text-align: center;">
                                      {{ dateget(scope.row.date) }}
                                    </div>
                                  </el-col>
                                  <el-col :span="6">
                                    <div style="float: right;margin-right: 20px;">
                                      {{ getdayduration(scope.row.dayhours) }}
                                    </div>
                                  </el-col>
                                </el-row>
                            <!-- {{ dateget(scope.row.date) }} -->
                        </div>
                    </template>
                </el-table-column>

                <el-table-column :label="getusername">
                    <el-table-column label="Project/Overhead" prop="p.name" 
                            :width="tablewidths.project"
                             min-width="120"
                            >
                        <template #default="scope">
                            <div class="wordbreak" v-if="scope.row.rowspan != true">
                                {{ scope.row.p.name }}
                            </div>
                            <div class="wordbreak" v-if="form.loginname != '' && scope.row.rowspan == true"
                                style="font-weight: 300; background-color: #c8c8c8; color: black;">
                                <el-row justify="space-between">
                                  <!-- <el-col :span="6"><div class="grid-content ep-bg-purple" /></el-col> -->
                                  <el-col :offset="8" :span="6">
                                    <div style="text-align: center;">
                                      {{ dateget(scope.row.date) }}
                                    </div>
                                  </el-col>
                                  <el-col :span="6">
                                    <div style="float: right;margin-right: 20px;">
                                      {{ getdayduration(scope.row.dayhours) }}
                                    </div>
                                  </el-col>
                                </el-row>
                                
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Activity Area" prop=""  
                            :width="tablewidths.activity"
                            min-width="120"
                             >
                        <template #default="scope">
                            <div class="wordbreak" v-if="scope.row.rowspan != true">
                               <div v-if="scope.row.p.typeid == 1">
                                {{ scope.row.pp.name == null ? 'Single Bill': scope.row.pp.name }}
                               </div>
                               <div v-if="scope.row.p.typeid == 2">
                                {{ scope.row.a.name }}
                               </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>

                <el-table-column style="text-align: center"
                    :label=" dateget(view.filter[1].value) + ' - ' + dateget(view.filter[4].value)">
                    <el-table-column label="Actions" prop="s.name"  
                              :width="tablewidths.action"
                              min-width="120"
                             >
                        <template #default="scope">
                            <div class="wordbreak" v-if="scope.row.rowspan != true">
                              <div v-if="scope.row.p.typeid == 1">
                                {{ scope.row.a.name }}
                               </div>
                               <div v-if="scope.row.p.typeid == 2">
                                {{ scope.row.s.name }}
                               </div>                               
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" label="Start" prop="t.startminute" min-width="50" :width="tablewidths.start" >
                        <template #default="scope">
                            <div class="wordbreak" v-if="scope.row.rowspan != true">
                                {{ timeget(scope.row.t.startminute) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Work Description" prop="t.comments"   :width="tablewidths.description" >
                        <template #default="scope">
                            <div class="wordbreak" v-if="scope.row.rowspan != true">
                              <div v-if="scope.row.p.typeid == 2">
                                {{ scope.row.t.comments }}
                              </div>
                              <div v-if="scope.row.p.typeid == 1">
                                <div v-if="scope.row.s.name == undefined && scope.row.t.comments != undefined">
                                {{  scope.row.t.comments  }}
                                </div>
                                <div v-if="scope.row.s.name != undefined && scope.row.t.comments == undefined">
                                  {{  scope.row.s.name  }}
                                </div>
                                <div v-if="scope.row.s.name != undefined && scope.row.t.comments != undefined">
                                  {{  scope.row.s.name + " - " + scope.row.t.comments }}
                                </div>
                              </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" label="Finish" prop="t.duration" min-width="50" :width="tablewidths.finish" >
                        <template #default="scope">
                            <div class="wordbreak" v-if="scope.row.rowspan != true">
                                {{ getendtime(scope.row) }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>

                <el-table-column align="center" :label="getallduration(view.duration)">
                    <el-table-column align="center" label="Duration" prop="t.duration" min-width="60" :width="tablewidths.duration" >
                        <template #default="scope">
                            <div class="wordbreak" v-if="scope.row.rowspan != true">
                                {{ gettduration(scope.row.t.duration) }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>

                <template #append v-if="infiniteloading">
                  <p class="center bold">Loading...</p>
                </template>
            </el-table>
            <div class="spaceBetween" />
        </el-row>

        <div v-if="summary == true && !showhtmltable">
          <!-- <span v-if="canprint" class="section-to-print-head"> 
            <h2>
            Individual Search (Summary)
            </h2>
          <div class="spaceBetween" />
          </span> -->
            <el-table :data="tabledata" class="section-to-print-body" :border="true">
                <el-table-column v-if="form.loginname == ''" 
                prop="u.loginname" 
                label="User" 
                min-width="150"
                             width="150">
                    <template #default="scope">
                        {{ scope.row.u.firstname + " " + scope.row.u.lastname }}
                    </template>
                </el-table-column>

                <el-table-column prop="p.name" label="Project" 
                            min-width="290"
                             width="290">
                    <template #default="scope">
                        {{ scope.row.p.name }}
                    </template>
                </el-table-column>

                <el-table-column prop="t.date" align="center" label="Duration" 
                          min-width="70"
                             width="70">
                    <template #default="scope">
                        {{ getallduration(scope.row.t.duration) }}
                    </template>
                </el-table-column>
                <!-- <el-table-column min-width="0" ></el-table-column> -->
            </el-table>
            <!-- <el-table
                class="fullwidth"
                :data="tabledata"
            >
                <el-table-column
                v-if="user.accesslevel != 'C' && view.filter[2].value == ''"
                label="Users"
                prop="u.loginname"
                width="100"
                class="fullwidth"
                >
                <template slot-scope="scope">
                    {{ scope.row.u.loginname }}
                </template>
                </el-table-column>
                <el-table-column label="Project" prop="p.name" class="fullwidth">
                <template slot-scope="scope">
                    {{ scope.row.p.name }}
                </template>
                </el-table-column>
                <el-table-column label="Duration" prop="t.date" class="fullwidth">
                <template slot-scope="scope">
                    {{ gettduration(scope.row.t.duration) }}
                </template>
                </el-table-column>
            </el-table> -->
        </div>

        <CustomTable v-if="showhtmltable && summary == false" :tableHeaders="reportheaders" :tableData="printarray" :showTableTitle="'Individual Search'" />
        <CustomTable v-if="showhtmltable && summary == true" :tableHeaders="summaryheaders" :tableData="printarray" :showTableTitle="'Individual Search (Summary)'" style="width: 80% !important" />
    </div>
</template>

<script>
import { eventbus } from "../eventbus";
import moment from "moment";
import CustomTable from "./CustomTable.vue";
import ElTableInfiniteScroll  from "el-table-infinite-scroll";

export default {
  data() {
    return {
      selectprojectobj:null,
      canprint:false,
      tablewidths:{
        employee:100,
        project:200,
        activity:150,
        action:150,
        description:null,
        start:80,
        finish:80,
        duration:80
      },
      userslist:[],
      loading:false,
      projects:[],
      projectoptions:[],
      partoptions:[],
      actionptions:[],
      subactionptions:[],
      summary: false,
      duration: "",
      form: {},
      month1: "",
      tabledata: [],
      user: this.$store.state.user,
      view: {
        filter: [
          { table: "p", column: "id", value: "" , operation: "=", paraname: "pid", },
          {
            table: "t",
            column: "date",
            value: "",
            operation: ">=",
            paraname: "sdate",
          },
          { table: "u", column: "loginname", value: "" },
          { table: "u", column: "id", value: "", operation: "In" },
          {
            table: "t",
            column: "date",
            value: "",
            operation: "<=",
            paraname: "edate",
          },
          { table: "pr", column: "id", value: "" , operation: "=", paraname: "prid", },
          { table: "a", column: "id", value: "" , operation: "=", paraname: "aid", },
          { table: "s", column: "id", value: "" , operation: "=", paraname: "sid", },
        ],
        Dfilter: {summary:false},
        sortcol: "t.date",
        order: "ascending",
      },
      showhtmltable: false,
      reportheaders: [
        [
          { label: '', colspan: 1, rowspan: 1 },
          { label: '', isdate: true, colspan: 4, rowspan: 1 },
          { label: '00:00', isduration: true, colspan: 1, rowspan: 1 }
        ],
        [
          { prop: 'projectname', label: 'Project/Overhead', colspan: 1, rowspan: 1 },
          { prop: 'activity', label: 'Activity Area', colspan: 1, rowspan: 1 },
          { prop: 'actions', label: 'Actions', colspan: 0, rowspan: 1 },
          { prop: 'startminute', label: 'Start', colspan: 1, rowspan: 1 },
          { prop: 'comments', label: 'Work Description', colspan: 1, rowspan: 1 },
          { prop: 'finishtime', label: 'Finish', colspan: 1, rowspan: 1 },
          { prop: 'duration', label: 'Duration', colspan: 1, rowspan: 1 }
        ]
      ],

      summaryheaders: [
      [
        { prop: 'projectname', label: 'Project'},
        { prop: 'duration', label: 'Duration'}
      ]
      ],
      printarray: [],
      visibletabledata: [],
      offset: 20,
      Disableinfinitescroll: true,
      height: window.innerHeight - 145,
      startlength: 600,
      endlength: 800,
      infiniteloading: false,
      scrollDebounce: null,
      scrollThreshold: 200,
      selecteduser: ""
    };
  },

  components: {
    CustomTable
  },
  
  directives: {
    ElTableInfiniteScroll
  },

  methods: {
    changesubactions(data){
      if(data != ""){
        this.view.filter[7].value = data.toString(); 
      }else{
        this.view.filter[7].value = "";
      }
      this.refresh();
    },
    projectpartchange(data){
      this.view.filter[6].value = "";
      this.view.filter[7].value = "";
      if(data != ""){
        this.view.filter[5].value = data.toString(); 
      }else{
        this.view.filter[5].value = "";
      
      }
    },
     remoteMethodproject(query){
     
        if(query == ""){
            this.projectoptions = this.projects
        }else{
           this.projectoptions = this.projects.filter(item => {
              return item.name.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
        }
    },
    getendtime(row) {
      var durations = [];
      durations.push(this.timeget(row.t.startminute));
      var h1 = Math.floor(row.t.duration / 60),
        m1 = row.t.duration % 60;
      durations.push(h1 + ":" + m1);
      const totalDurations = durations
        .slice(1)
        .reduce(
          (prev, cur) => moment.duration(cur).add(prev),
          moment.duration(durations[0])
        );
      return moment.utc(totalDurations.asMilliseconds()).format("HH:mm");
    },
    getallduration(duration) {
      var h1 = Math.floor(duration / 60),
        m1 = duration % 60;
      return (
        (h1 < 10 ? "0" : "") +
        h1.toString() +
        ":" +
        (m1 < 10 ? "0" : "") +
        m1.toString()
      );
    },
    gettduration(duration) {
      var h1 = Math.floor(duration / 60),
        m1 = duration % 60;
      var t = moment(h1 + ":" + m1, "HH:mm").format("HH:mm");
      return t;
    },
    getsummary() {
      this.showloading();
      this.view.Dfilter.summary = true;
      this.summary = true;
      this.refresh();
    },
    getmonth(date) {
      this.showloading();
      if (date != null) {
        var newdate = new Date();
        var y = date.getFullYear(),
          m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay =
          m == newdate.getMonth() ? new Date() : new Date(y, m + 1, 0);
        this.view.filter[1].value = moment(firstDay)
          .format("YYYY-MM-DD")
          .toString();
        this.view.filter[4].value = moment(lastDay)
          .format("YYYY-MM-DD")
          .toString();
        this.refresh();
      }
    },
    arraySpanMethod({ row, columnIndex }) {
        if (row.rowspan == true) {
            if (columnIndex == 0) {
                return [1, 8];
            }
            else {
                return [0, 0];
            }
        }
    },
    usernamech(item) {
      // this.showloading();
      // if (item) {
      //   let getmatcheditem = this.userslist.find(u => u.fullname.toLowerCase() == item.toLowerCase());
      //   if (getmatcheditem) {
      //     this.selectuser(getmatcheditem);
      //   } else {
      //     // item = "";
      //   }
      // }

      if (item == undefined || item == "") {
         this.form.loginname = "";
         this.selecteduser = "";
          // var lst = [];
          // for (var u of this.userslist) {
          //     lst.push(u.id)
          // }
          if(this.userslist.length == 1){
            this.$nextTick(()=>{
              this.form.loginname = this.user.firstname + " " + this.user.lastname;
            })
          }
          this.view.filter[3].value = this.userslist.map(u => u.id).join(",")
        this.refresh();
      }
    },
    // projectnamech(item) {
    //   this.showloading();
    //   if (item == undefined || item == "") {
    //     this.view.filter[0].value = "";
    //     this.refresh();
    //   }
    // },
    startdatachange(event) {
      this.showloading();
      if (event == null) {
        this.view.filter[1].value = "";
      } else {
        this.view.filter[1].value = moment(event)
          .format("YYYY-MM-DD")
          .toString();
      }
      this.refresh();
    },
    enddatachange(event) {
      this.showloading();
      if (event == null) {
        this.view.filter[4].value = "";
      } else {
        this.view.filter[4].value = moment(event)
          .format("YYYY-MM-DD")
          .toString();
      }
      this.refresh();
    },

    disableFutureDates(time) {
      return time.getTime() > Date.now();
    },

    getreports() {
      this.showloading();
      this.view.Dfilter.summary = false;
      this.summary = false;
      this.refresh();
    },
      selectuser(item) {
      if (item.loginname != null) {
        this.form.loginname = item.fullname;
        this.selecteduser = item.fullname;
        this.view.filter[3].value = item.id.toString();
      } else {          
          // var lst = [];
          // for (var u of this.userslist) {
          //     lst.push(u.id)
          // }
          this.view.filter[3].value = this.userslist.map(u => u.id).join(",");
      }
      this.refresh();
    },
    getsubactions(event){
        this.subactionptions = [];
        this.form.subactionid  = null;
        this.view.filter[7].value = "";
      if(event != ""){
        this.view.filter[6].value = event.toString(); 
        this.$http.post("action/getsubactions", { actionid: event })
            .then((response) => {
                this.subactionptions = response.data;
            })
            .catch((err) => {
              this.apierror(err);
            });
        }else{
          this.view.filter[6].value = "";
          
        }
        this.refresh();
    },
    getactivitys(){
      this.loading = true;
      // var obj = {id:this.form.projectid,typeid:this.selectprojectobj.typeid}
      this.axios.post("action/getactions", this.selectprojectobj)
         .then((response) => {
          this.partoptions = [];
          if(this.isproject == 1){
            if(response.data.pps.length > 0){
              this.partoptions = response.data.pps; 
            }else{
              this.partoptions.push({ name: 'Single Bill', id: null }); 
            }
          }
          // if(this.isproject == 2){
            this.actionptions = response.data.acs;
          // }
            //  row.projectactions = response.data.acs;
            //  if (response.data.pps.length == 0) {
            //      row.t.projectpartid = null;
            //      row.projectparts.push({ name: 'Single Bill', id: null });
            //  } else {
            //      row.projectparts = response.data.pps;
            //  }
            //  if (row.t.id > 0 && row.t.actionid != "" && row.p.typeid == 2) {
            //     this.getsubaction(row);
            //  }
         })
         .catch((error) => {
             this.loading = false;
             this.apierror(error);
         });
    },
    selectproject(item) {
      this.selectprojectobj = null;
        this.form.projectparid = null;
        this.form.actionid = null;
        this.form.subactionid = null;
        this.partoptions = [];
        this.actionptions = [];
        this.subactionptions = [];
        this.view.filter[5].value = "";
        this.view.filter[6].value = "";
        this.view.filter[7].value = "";
      if (item != "") {
        this.form.projectid = item;
         
        this.view.filter[0].value = item.toString();
        var  isfindpro =  this.projectoptions.find(x => x.id == item);
        if(isfindpro){
          this.selectprojectobj = isfindpro; 
        }
        this.getactivitys();
      } else {
        this.view.filter[0].value = "";
      }
      this.refresh();
    },
    refresh: function () {
      this.loading = true;
      this.clearInfiniteScrollData();
      this.$http
        .post("reports/GetView", this.view)
        .then((response) => {
          var printarray = [];
          this.tablewidths = JSON.parse(JSON.stringify(this.tablewidths));
          var data = response.data.data;
          var date = "";
          var total = 0;
          var index = 0;
          if (this.summary == false) {
            for (var i = 0; i < data.length; i++) {
              if (date != data[i].t.date) {
                // data[index].dayhours = Number;
                data[index].dayhours = total;
                const printtotal = total;
                const printindex = index;
               
                date = data[i].t.date;
                data.splice(i, 0, {
                  date: date,
                  a:data[i].a,
                  p:data[i].p,
                  pp:data[i].pp,
                  s:data[i].s,
                  t:data[i].t,
                  u:data[i].u
                });
                data[i].rowspan = true;

                var newdata = {};
                newdata.date =  data[i].t.date;
                // newdata.dayhours = total;
                newdata.colspan = 8;
                // newdata.htmlContent = "<div><span>" + newdata.date + "</span><span>" + newdata.dayhours + "</span></div>";
                newdata.isHtmlRow = true;
                printarray.push(newdata);

                printarray[printindex].dayhours = printtotal;
                 total = 0;
                index = i;
              }else{
                total += data[i].t.duration;

                var dd = data[i];
                var obj = {
                  projectname: dd.p.name,
                  activity: null,
                  actions: null,
                  startminute: null,
                  comments: null,
                  finishtime: null,
                  duration: null
              }

                if (this.form.loginname == '') {
                  obj.username = dd.u.firstname + "  "  + dd.u.lastname;
                }

                if (dd.p.typeid == 1) {
                  obj.activity = dd.pp.name == null ? 'Single Bill': dd.pp.name;
                  obj.actions = dd.a.name;

                  if (dd.s.name == undefined && dd.t.comments != undefined) {
                    obj.comments = dd.t.comments;
                  }

                  if (dd.s.name != undefined && dd.t.comments == undefined) {
                    obj.comments = dd.s.name;
                  }

                  if (dd.s.name != undefined && dd.t.comments != undefined) {
                    obj.comments = dd.s.name + " - " + dd.t.comments;
                  }

                } 
                else if (dd.a.typeid == 2) {
                  obj.activity = dd.a.name;
                  obj.actions = dd.s.name;
                  obj.comments = dd.t.comments;
                }

                obj.startminute = this.timeget(dd.t.startminute);
                obj.finishtime = this.getendtime(dd);
                obj.duration = this.gettduration(dd.t.duration);
                printarray.push(obj);
              }
            }
          }

          if (this.summary == true) {
            data.forEach(d => {
              d.u.fullname = d.u.firstname + ' ' + d.u.lastname;
            });
            
            data = this.$commonutils.sortArray(data, 'u.fullname');
            
            printarray = [];
            for (let j = 0; j < data.length; j++) {
              let item = data[j];

              let obj = {
                projectname: item.p.name
              }
              obj.duration = this.getallduration(item.t.duration);

              if (this.form.loginname == '') {
                obj.username = item.u.firstname + "  "  + item.u.lastname;
              }

              printarray.push(obj);
            }

            if (this.form.loginname == '' && this.summaryheaders[0][0].label != "User") {
              this.summaryheaders[0].unshift({prop: 'username', label: 'User'});
            } else if (this.summaryheaders[0][0].label == "User" && !this.form.loginname.trim() == '') {
              this.summaryheaders[0].shift();
            }
          }

          if(data.length > 0){
            data[index].dayhours = total;
            printarray[index].dayhours = total;
            total = null;
          }

          this.printarray = printarray;
          this.tabledata = data;
          this.view = response.data.view;

          let tdata = data;

          if (tdata.length > 800) {
            // this.startlength = 0;
            // this.endlength = 500;
            this.visibletabledata = tdata.slice(0, 800);
            this.Disableinfinitescroll = false;
            this.infiniteloading = true;
            // this.startAutoScroll();
          } else {
            this.visibletabledata = tdata;
          }
          
          this.checkprintheaderanddata();

          this.hideloading();
           this.loading = false;
        })
        .catch((error) => {
          this.hideloading();
          this.apierror(error);
           this.loading = false;
        });
    },
    searchuserforreport: function(query, done) {
            var tt= this.userslist.filter(x => x.fullname.toLowerCase().includes(query));
            done(tt);
    },
    Getreportstousers(){
        let data = {
                id: this.user.id,
                firstname: this.user.firstname,
                lastname: this.user.lastname,
                loginname: this.user.loginname,
                accesslevel: this.user.accesslevel
            };
      this.$http
        .post("Account/Getreportstousers", data)
        .then((response) => {
          this.userslist = response.data;
        })
        .catch((error) => {
          this.hideloading();
          this.apierror(error);
           this.loading = false;
        });
    },
    afterprint() {
      this.canprint = false;
      this.showhtmltable = false;
      
      /*
      this.tablewidths = {
        employee:100,
        project:200,
        activity:150,
        action:150,
        description:300,
        start:80,
        finish:80,
        duration:80
          };
          this.tablewidths = JSON.parse(JSON.stringify(this.tablewidths));
      */
      this.hideloading();
    },
    realPrint() {
      // var cls = document.getElementsByClassName('el-scrollbar__view');
      // var dd = cls[0];
      // dd.removeAttribute('style');

      this.showloading();
      this.showhtmltable = true;
      this.canprint = true;

      /*
      this.tablewidths = {
        employee: 70,
        project: 120,
        activity: 120,
        action: 120,
        start: 50,
        description: 150,
        finish: 50,
        duration: 60
      };
      */
    },
    setprint(){
       window.addEventListener("beforeprint", () => {
       this.realPrint();
       });
       window.addEventListener("afterprint", () => {
       this.afterprint();
       });
    },

    checkprintheaderanddata() {
      // this.reportheaders[0][1].label = this.dateget(this.view.filter[1].value) + ' - ' + this.dateget(this.view.filter[4].value);
      // this.reportheaders[0][2].label = this.getallduration(this.view.duration);

      let level1header = this.reportheaders[0].filter(item => item.label.trim() == "" && !item.isdate);
      let level2header = this.reportheaders[1].filter(item => item.prop == "username");

      if (this.form.loginname == '') {
        if (level1header.length < 1) {
          this.reportheaders[0][0].label = "Employee"
          this.reportheaders[0][0].rowspan = 2;
          this.reportheaders[0][0].colspan = 1;
          this.reportheaders[0].splice(1, 0, {label: '', colspan: 2});
        }
        if (level2header.length < 1) {
          this.reportheaders[1].unshift({ prop: 'username', label: '', colspan: 1, rowspan: 1, isheaderignore: true });
        }
      } else {
        if (this.reportheaders[1][0].prop == 'username') {
          this.reportheaders[1].shift();
          this.reportheaders[0].splice(1, 1);
        }
        this.reportheaders[0][0].label = this.getusername;
        this.reportheaders[0][0].colspan = 2;
        this.reportheaders[0][0].rowspan = 1;
      }

      for (let i = 0; i < this.reportheaders[0].length; i++) {
        let element = this.reportheaders[0][i];
        if (element.isdate) {
          element.label = this.dateget(this.view.filter[1].value) + ' - ' + this.dateget(this.view.filter[4].value);
        } else if (element.isduration) {
          element.label = this.getallduration(this.view.duration);
        }
      }

      for (let i = 0; i < this.printarray.length; i++) {
        let element = this.printarray[i];

        if (this.form.loginname == '' && element.isHtmlRow) {
          element.colspan = 9;
        } else if (element.isHtmlRow) {
          element.colspan = 8;
        }

        if (element.date && element.dayhours) {
          element.date = this.dateget(element.date);
          element.dayhours = this.getdayduration(element.dayhours);
          element.htmlContent = "<div style='background-color: rgb(200, 200, 200);'><span style='margin-left: 40%;'>" + element.date + "</span><span style='float: right; margin-right: 4.2%;'>" + element.dayhours + "</span></div>";
        }
      }
    },

    handleInfiniteScroll() {
      if(this.endlength < this.tabledata.length){
        this.infiniteloading = true;
        this.startlength += 200;
        this.endlength += 200;

        if(this.endlength > this.tabledata.length)
        {
            this.endlength = this.tabledata.length;
        }
        var tdataitems = this.tabledata.slice(this.startlength, this.endlength);
        this.visibletabledata.push(...tdataitems);

        const table = this.$refs.individualsearchtable.$el.querySelector('.el-scrollbar__wrap');
        table.scrollTop = table.scrollTop - 1;
      } else {
        this.infiniteloading = false;
        this.Disableinfinitescroll = true;
        this.stopAutoScroll();
      }

      /* const tdata = this.tabledata;
      const remainingItems = tdata.length - this.visibletabledata.length;
      const numItemsToAdd = Math.min(200, remainingItems);

      if (numItemsToAdd > 0) {
        let rmdata = tdata.slice(this.visibletabledata.length, this.visibletabledata.length + numItemsToAdd)
        this.visibletabledata.push(...rmdata);
      }

      if (this.visibletabledata.length === tdata.length) {
        this.stopAutoScroll();
      }

      const tdata = this.tabledata;

      const nextOffset = this.visibletabledata.length + 100;

      if (nextOffset <= tdata.length) {
        this.visibletabledata = tdata.slice(0, nextOffset);
      } else {
        this.visibletabledata = this.tabledata;
        this.Disableinfinitescroll = true;
        this.stopAutoScroll();
      } */
    },

    clearInfiniteScrollData() {
      this.startlength = 600;
      this.endlength = 800;
      this.visibletabledata = [];
      this.Disableinfinitescroll = true;
      this.infiniteloading = false;
      this.stopAutoScroll();
    },

    debounce(func, delay) {
      let timeoutId;
      return function() {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(func, delay);
      };
    },

    startAutoScroll() {
      // const debouncedScroll = this.debounce(this.handleInfiniteScroll, 1000);
      this.intervalId = setInterval(this.handleInfiniteScroll,15000);
    },

    stopAutoScroll() {
      clearInterval(this.intervalId);
    },

    tableHeight() {
      this.height = window.innerHeight - 145;
    },

    handleTableScroll() {
      if (this.scrollDebounce) clearTimeout(this.scrollDebounce);
      this.scrollDebounce = setTimeout(() => {
        const table = this.$refs.individualsearchtable.$el.querySelector('.el-scrollbar__wrap');
        if (table.scrollTop + table.clientHeight >= table.scrollHeight - this.scrollThreshold) {
          this.handleInfiniteScroll();
        }
      }, 300);
    },

    sortArray(arr, prop, ascending = true) {
       const self = this;
        arr.sort(function(a, b) {
          const propValueA = prop ? self.getProperty(a, prop) : a;
          const propValueB = prop ? self.getProperty(b, prop) : b;

            let comparison = 0;

            if (propValueA > propValueB) {
                comparison = 1;
            } else if (propValueA < propValueB) {
                comparison = -1;
            }

            return ascending ? comparison : -comparison;
        });
        return arr;
    },

    getProperty(obj, prop) {
        const props = prop.split('.');
        let value = obj;

        for (const nestedProp of props) {
            value = value ? value[nestedProp] : undefined;

            if (value === undefined || value === null) {
                value = '';
                break;
            }
        }

        return value;
    }
  },
  computed: {
    isproject(){
       if(this.selectprojectobj == null){
         return false;
       }else{
         return this.selectprojectobj.typeid;
       } 
    },
    getdayduration(){
      return function(duration){
        if(duration){
            var h1 = Math.floor(duration / 60),
            m1 = duration % 60;
            var t = `${(h1 < 10 ? "0" : "")}${h1}:${(m1 < 10 ? "0" : "")}${m1}`; 
            return t;
        }else{
          return "";
        }
      }
    },
    dateget() {
      return function (date) {
        var d = moment(date).format("ddd DD-MMMM-YYYY");
        if (d == "Invalid date") {
          return "";
        }
        return d;
      };
    },
    getusername() {
      // if (this.user.accesslevel == "C") {
      //   return this.user.loginname;
      // }
      if (this.view.filter[3].value != "") {
        return this.form.loginname;
      }
      return "All Users";
    },
    timeget() {
      return function (date) {
        if (date != null) {
          // var h, m;
          // date = date.toString();
          // h = parseInt(date.slice(0, -2));
          // h = isNaN(h) ? 0 : h;
          // m = parseInt(date.slice(-2));
          var m = date % 60;
          var h = (date-m)/60;
          return (
            (h < 10 ? "0" : "") +
            h.toString() +
            ":" +
            (m < 10 ? "0" : "") +
            m.toString()
          );
        }
        return "";
      };
    },
  },

  
  unmounted() {
    window.removeEventListener('resize', this.tableHeight);
    this.stopAutoScroll();
  },

  mounted() {
    // const tableBody = this.$refs.individualsearchtable.$el.querySelector('.el-scrollbar__wrap');
    // tableBody.addEventListener('scroll', () => {
    //   debugger
    //   this.handleTableScroll();
    // });
    
    window.addEventListener('resize', this.tableHeight);
  },
  
  created() {
    this.setprint();
    eventbus.$on("setuser", (user2) => {
        this.user = user2;
        this.form.loginname = this.user.firstname + " " + this.user.lastname;
        this.selecteduser = this.user.firstname + " " + this.user.lastname;
    });

    // if(this.user.istimetrackeradmin != true){
      this.view.filter[3].value = this.user.id.toString();
      this.form.loginname = this.user.firstname + " " + this.user.lastname //this.$store.state.user.loginname;    
      this.selecteduser = this.user.firstname + " " + this.user.lastname;
    // }
    if(this.$store.state.projects.length ==  0){
      this.searchproject().then(() =>{
       this.projectoptions = this.$store.state.projects;
       this.projects =  this.$store.state.projects;
      });
    }else{
       this.projectoptions = this.$store.state.projects;
       this.projects =  this.$store.state.projects;
    }
    if (this.tabledata.length <= 0) {
      var d = new Date();
      this.month1 = d;
      this.getmonth(d);
      this.Getreportstousers();
    }
  },
};
</script>

<style scoped>
table {
        page-break-inside: auto;
        border: 1px solid black
    }

    thead {display: table-header-group;}

    tr {
        page-break-inside: avoid;
        page-break-after: auto;
        border: 1px solid black
    }

    td {
        border: 1px solid black
    }
</style>