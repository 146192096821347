<template>
    <div>
        <h1 v-if="user != null" class="font"> Hello <span style="color:#04D4F0"> {{ user.firstname }} </span>, Welcome to
            TSA
            Time Tracking</h1>
        <div class="spaceBetween" />

        <!-- <el-row>
            <div v-if="details.version" class="italic fontsize14px positionrelative">Version:
                <span class="bold">{{ details.version }}</span>({{ lastsaved }})
            </div>
        </el-row>
        <div class="spaceBetween" /> -->

        <el-row class="homepagebox">
            <!-- <div v-if="isupdatehidden" v-html="details.content"></div> -->
            <!-- <div v-if="isupdatehidden" class="fontsize14px positionrelative spancolor"> Tick
                <span>
                    <el-checkbox v-model="confirm" @change="changeversion"></el-checkbox>
                </span> {{ line1 }}
            </div> -->

            <div class="fontsize14px positionrelative spancolor">
                <p>{{ line2 }}</p>
                <ul>
                    <li>
                        <p>{{ line3 }}</p>
                    </li>
                    <li>
                        <p>{{ line4 }}</p>
                    </li>
                    <li>
                        <p>{{ line5 }}</p>
                    </li>
                </ul>
                <!-- color: black; -->
                
            </div>
            
        </el-row>
        <el-row class="homepagebox">
            <div class="fontsize14px positionrelative spancolor">
                <div
                style="font-size: large;
                       font-weight: bold;
                       "
                >Why install the TSA TimeSheet Windows App?</div>
                <div style="line-height:2;">
                <ul >
                    <li><b> Faster Performance:</b> Enjoy seamless time tracking with enhanced speed and reliability.</li>

                    <li><b> Instant Notifications:</b> Get real-time alerts and updates to stay on top of your tasks.</li>

                    <li><b>Easy Communication:</b> We can communicate with you directly through the app for quick assistance.</li>

                    <li><b>Quick Access:</b> Use desktop shortcuts for one-click access to your time tracking.</li>

                   <li> <b>Stay Organized:</b> Track your time efficiently with the app's smooth interface.</li>
                    
                   <el-button style="margin-top: 14px;" size="default"   v-if="this.$store.state.deferredPrompt != null" type="primary" @click="install">
                       Download Now
                   </el-button>
                </ul>
            </div>
            </div>
        </el-row>
    </div>
</template>
<script>
import { eventbus } from '../eventbus';
import moment from "moment";
export default {
    data() {
        return {
            user: this.user = this.$store.state.user,
            line1: "to confirm that you have completed above notes.",
            line2: "Please, when using the new version take the time to:",
            line3: "Check your work to ensure that everything is as it should be.",
            line4: "Report on anything that doesn't seem right.",
            line5: "Make suggestions as to how we could improve the Time Tracking.",
            confirm: false,
            details: {},
        };
    },

    computed: {
        isupdatehidden() {
            if (this.details.userreminder == null) {
                return true;
            } else {
                return false;
            }
        },

        lastsaved: function () {
            if (this.details.date != undefined) {
                var dt = new Date(this.details.date);
                return moment(dt).format("DD-MMM-YYYY");
            }
            return null;
        },
    },

    methods: {
        install(){
            if(this.$store.state.deferredPrompt != null){
                    this.$store.state.deferredPrompt.prompt();
                    this.$store.state.deferredPrompt = null;
                }
        },
        changeversion() {
            this.showloading();
            this.$http.post("account/hideupdateversion", this.details)
                .then(() => {
                    this.hideloading();
                    this.refresh();
                })
                .catch((error) => {
                    this.hideloading();
                    this.apierror(error);
                });
        },

        refresh() {
            this.showloading();
            this.$http.get("account/getnewversion")
                .then(response => {
                    if (response.data != null) {
                        this.details = response.data;
                    }
                    else {
                        this.details = {};
                    }
                    this.hideloading();
                })
                .catch((error) => {
                    this.hideloading();
                    this.apierror(error);
                });
        },
    },

    created: function () {
        this.$nextTick(() => {
            this.refresh();
        });
        eventbus.$on('setuser', (user2) => {
            this.user = user2;
            document.body.style.zoom = "100%";
        });
        document.body.style.zoom = "99%";
    },
}
</script>

<style scoped>
.font {
    font: italic 30px/30px Georgia, serif;
}
</style>