<template>
    <div class="userlastentry">
         <!-- <el-affix :offset="52" > -->
             <div class="colorwhite stickyheader">
               <el-row class="alignitemscenter">  
                   <el-col :span="12">
                  <div class="headerstyle">User Last Entry</div>
                   </el-col>
                   <el-col :span="12">
                       <div class="floatright">
                       <el-select v-model="view.filter[0].value" class="m-2 spaceBetweenLR" placeholder="Select" @change="refresh">
                            <el-option
                            v-for="item in uesrsselectlist"
                            :key="item.name"
                            :label="item.name"
                            :value="item.value">
                            </el-option>
                        </el-select>

                        <el-select v-model="view.filter[1].value" class="m-2 spaceBetweenLR" placeholder="Select" @change="refresh">
                            <el-option
                            v-for="item in hoursselectlist"
                            :key="item.name"
                            :label="item.name"
                            :value="item.value">
                            </el-option>
                        </el-select>
                        </div>
                   </el-col>
               </el-row>
                <hr class="spaceBetween" />
             </div>
         <!-- </el-affix> -->
        <el-table :data="tabledata"  border class="fullwidth">
                <el-table-column prop="u.fullname" label="Name" width="120">
                </el-table-column>

                <el-table-column prop="t.date"
                                :formatter="dateformatter"
                                label="Last Entry" width="110">
                </el-table-column>

                <el-table-column label="Time" align="center" width="40">
                    <template #default="scope">
                        {{timeformatter(scope.row)}}
                    </template>
                </el-table-column>

                <el-table-column prop="p.name" label="Project/Overhead" width="320"></el-table-column>

                <el-table-column label="Activity Area" width="320">
                    <template #default="scope">
                        <div v-if="scope.row.p.typeid == 1">
                            {{ scope.row.pp.name == null ? "Single Bill" : scope.row.pp.name}}
                        </div>
                        <div v-if="scope.row.p.typeid == 2">
                            {{ scope.row.a.name }}
                        </div>
                    </template>
                </el-table-column>
        </el-table>
    </div>
</template>
<script>
    import moment from 'moment';
    export default {
        data() {
            return {
                tabledata: [],
                uesrsselectlist: [
                    { value: "", name: "All" },
                    { value: "1", name: "Active Only" }
                ],
                hoursselectlist: [
                    { value: "", name: "All" },
                    { value: "8 HOUR", name: "8 Hours" },
                    { value: "24 HOUR", name: "24 Hours" },
                    { value: "48 HOUR", name: "48 Hours" }
                ],
                view: {
                    filter: [
                        { table: "u", column: "active", value: "1", operation: "", paraname: "" },
                        { table: "", column: "", value: "", operation: "", paraname: "" }
                    ]
                }
            }
        },
        methods: {
            refresh: function () {
                this.showloading();
                this.$http.post("timesheet/userlastentry", this.view)
                    .then((response) => {
                        this.hideloading();
                        this.tabledata = response.data;
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },
            dateformatter: function (row, column, cellValue) {
                if (cellValue == "0001-01-01T00:00:00") {
                    return "";
                }
                if (cellValue) {
                    var m = moment(cellValue).format("ddd DD-MMM-YYYY");
                    return m;
                }
                return cellValue;
            },
            timeformatter: function (row) {
                if (row.t.startminute) {
                    var finishtime = row.t.startminute + row.t.duration;
                    var m = finishtime % 60;
                    var h = (finishtime-m)/60;
                    return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
                }
            },
            columnwidth: function() {
                var maxwidth = 0;
                for (var i = 0; i < this.tabledata.length; i++) {
                    var value = this.tabledata[i].u.fullname;
                    if (value && value.length != 0) {
                        if (value.length > maxwidth) {
                            maxwidth = value.length;
                            var str = value;
                        }
                    }
                }
                if (maxwidth != 0) {
                    var size = this.measureTextWidth(str);
                    size = size + 30;
                    if (size < 80) {
                        size = 80;
                    }
                    return size;
                }
                return 80;
            }
        },
        created() {
            this.refresh();
        }
    }
</script>
