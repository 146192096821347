<template>
    <div>
        <div v-if="!canedit" class="directiontablewidth">
            <!-- <el-affix :offset="51"> -->
                <div class="colorwhite stickyheader">
                    <el-row>
                        <div class="headerstyle">Multiple Searches Library</div>
                    </el-row>

                    <el-row>
                        <div class="fullwidth flexjustifyspacebetween">
                            <el-input class="width50 floatleft" v-model="view.filter[0].value" placeholder="Multiple Search Name"
                                @change="refresh" prefix-icon="search">
                            </el-input>

                            <el-pagination 
                                class="marginright160px"
                                layout="sizes, prev, pager, next" 
                                :total="view.count" 
                                :page-sizes="pagesizes"
                                :page-size="view.pagesize"
                                @current-change="refresh" 
                                v-model:currentPage="view.pageno"
                                @size-change="rowperpages" small>
                            </el-pagination>

                            <el-button class="floatright" :disabled="!editpermission" type="primary" icon="CirclePlusFilled"
                                @click="addrow"></el-button>
                        </div>
                    </el-row>
                    <!--
                    <el-row class="justifycontentcenter">
                        <el-pagination layout="prev, pager, next" :total="view.count" :page-size="view.pagesize"
                            @current-change="refresh" v-model:currentPage="view.pageno">
                        </el-pagination>
                    </el-row>
                    -->
                    <hr class="spaceBetween" />
                </div>
            <!-- </el-affix> -->
            <el-table :data="tabledata" border :default-sort="{ order: 'ascending', sortable:'d.directionname' }"
                @sort-change="sortchange">

                <el-table-column label="Multiple Search Name" prop="d.directionname" sortable="d.directionname" width="200">
                </el-table-column>

                <el-table-column label="Description" prop="d.description" sortable="d.description" width="300">
                </el-table-column>

                <el-table-column label="Author" prop="u.firstname" sortable="u.firstname" width="110">
                </el-table-column>

                <el-table-column align="center" label="Actions" width="135">
                    <template #default="scope">
                        <el-button type="primary" class="marginr4 marginl2" @click="editrow(scope.row)"
                            :icon="editpermission == true ? 'editPen' : 'view'">
                        </el-button>

                        <el-button type="primary" class="glyphicon glyphicon-duplicate marginr4 marginl2 marginb2px" @click="copyrow(scope.row)"
                            :disabled="!editpermission">
                        </el-button>

                        <el-button icon="delete" class="marginl2" type="danger" @click="deleterow(scope.row)" :disabled="!editpermission">
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="canedit" class="directionwidth">
            <!-- <el-affix :offset="51"> -->
                <div class="colorwhite stickyheader">
                    <el-row>
                        <el-col :span="14">
                            <div class="headerstyle">Multiple Search Add/Edit</div>
                        </el-col>
                        <el-col :span="10">
                            <div class="floatright" style="padding-top:10px;">
                                <el-button type="success" :disabled="!editpermission" icon="circle-check" plain
                                    @click="save">Save</el-button>
                                <!-- <el-button type="success"  @click="showpopup">Show Preview</el-button> -->
                                <el-button @click="cancel">Cancel</el-button>
                            </div>
                        </el-col>
                    </el-row>
                    <hr class="spaceBetween" />
                </div>
            <!-- </el-affix> -->
            <div>
                <el-form :model="form" label-width="133px">
                    <el-form-item label="Multiple Search Name">
                        <el-input v-model="form.directionname" show-word-limit maxlength="50"
                            placeholder="Multiple Search Name" />
                    </el-form-item>
                    <div class="spaceBetween" />
                    <el-form-item label="Description">
                        <el-input v-model="form.description" show-word-limit maxlength="50" placeholder="Description"
                            type="textarea" />
                    </el-form-item>
                    <div class="spaceBetween" />
                    <el-form-item label="Page Location">
                        <!-- <el-select v-model="form.pageid" class="m-2 fullwidth" placeholder="Page Location" size="small">
                            <el-option v-for="item in pages" :key="item.id" :label="item.displayname" :value="item.id" />
                        </el-select> -->
                        <el-select class="fullwidth" v-model="form.pageid" placeholder="Page Location" clearable filterable
                            remote value-key="id" collapse-tags popper-class="customselect" reserve-keyword
                            :remote-method="remoteMethodpage">
                            <el-option v-for="item in pages" :key="item.id" :label="item.displayname" :value="item.id">
                                <span>{{ item.displayname }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="spaceBetween" />
                    <el-form-item label="Project / Overhead">
                        <el-select class="fullwidth" v-model="form.projectoverheadid" @change="selectproject" clearable
                            popper-class="customselect" placeholder="All Project/Overhead" filterable remote reserve-keyword
                            :remote-method="remoteMethodproject">
                            <el-option v-for="item in projectoptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="spaceBetween" />
                    <el-form-item v-if="canpartshow" label="Project part">
                        <el-select class="fullwidth" v-model="form.projectpartid" clearable popper-class="customselect"
                            placeholder="Project part" filterable remote reserve-keyword>
                            <el-option v-for="item in projectparts" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="spaceBetween" />

                    <el-form-item label="Actions">
                        <el-select class="fullwidth" v-model="form.directionactionstemp" placeholder="Actions" clearable
                            filterable remote value-key="actionid" multiple popper-class="customselect" reserve-keyword
                            :remote-method="remoteMethodaction">
                            <el-option v-for="item in actions" :key="item.id" :label="item.name" :value="item.id">
                                <span>{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="spaceBetween" />
                    <el-form-item label="Policy Link Cell">
                        <el-input v-model="form.link" show-word-limit maxlength="1000" placeholder="Policy Link Cell" />
                    </el-form-item>
                    <div class="spaceBetween" />
                    <el-form-item label="Users Role">
                        <el-select class="fullwidth" v-model="userrole" placeholder="Users Role" clearable
                            filterable remote @change="selectuserrole" popper-class="customselect" reserve-keyword
                            :remote-method="remoteMethodUsersRole">
                            <el-option v-for="item in userroles" :key="item.id" :label="item.name" :value="item.id">
                                <!-- <span>{{ item.firstname }} {{ item.lastname }}</span> -->
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="spaceBetween" />
                    <el-form-item label="Send to Users">
                        <el-select class="fullwidth" v-model="form.directionuserstemp" placeholder="Send to Users" clearable
                            filterable remote multiple popper-class="customselect" reserve-keyword
                            :remote-method="remoteMethodUsers">
                            <el-option v-for="item in users" :key="item.id" :label="item.firstname" :value="item.id">
                                <span>{{ item.firstname }} {{ item.lastname }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    
                    <hr class="spaceBetween" />
                    <el-form-item label="Algorithms">
                        <div>
                            <!-- <el-button type="primary" @click="gettotalhours"> Add Algorithm</el-button> -->
                            <div>
                                <el-row v-if="form.directionalgorithm">
                                    <el-col :span="12">
                                        <div class="spaceBetween" />
                                        <el-radio-group v-model="form.directionalgorithm.algorithmtype" class="ml-2">
                                            <el-radio label="percentage" :disabled="selectprojectobj.typeid != 1"
                                                size="small">Percentage</el-radio>
                                            <el-radio label="hours" size="small">Hours</el-radio>
                                        </el-radio-group>
                                        <div class="spaceBetween" />
                                        <el-row>
                                            <el-input v-if="form.directionalgorithm.algorithmtype != null"
                                                @change="checknumber" v-model="form.directionalgorithm.value"
                                                class="w-50 m-2 fullwidth" size="small"
                                                :placeholder="'Please Add ' + form.directionalgorithm.algorithmtype"
                                                :prefix-icon="Search" />
                                            <!-- <el-input-number v-model="form.directionalgorithm.value" 
                                                :controls="false"
                                                v-if="form.directionalgorithm.algorithmtype != null"
                                                :placeholder="'Please Add ' + form.directionalgorithm.algorithmtype"
                                                size="small fullwidth" /> -->
                                        </el-row>
                                        <div class="spaceBetween" />
                                        <el-row>
                                            <el-select v-if="form.directionalgorithm.algorithmtype != null"
                                                v-model="form.directionalgorithm.operation" class="m-2" placeholder="Select"
                                                size="small">
                                                <el-option v-for="item in operations" :key="item.value" :label="item.label"
                                                    :value="item.value" />
                                            </el-select>
                                            <div class="spaceBetween" />
                                        </el-row>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-row justify="center">
                                            <el-button type="primary" @click="gettotalhours"> Show Live Hours</el-button>
                                        </el-row>
                                        <div v-if="totalhours.actualhours != null">
                                            <div class="spaceBetween" />
                                            <el-row justify="center">
                                                <el-tag class="ml-2">Actual Hours : {{
                                                    totalhours.actualhours }}</el-tag>
                                            </el-row>
                                            <div class="spaceBetween" />
                                            <el-row v-if="selectprojectobj.typeid == 1" justify="center">
                                                <el-tag class="ml-2">Estimated Hours : {{
                                                    totalhours.estimatedhours }}</el-tag>
                                            </el-row>
                                            <div class="spaceBetween" />
                                            <el-row v-if="selectprojectobj.typeid == 1" justify="center">
                                                <el-tag class="ml-2">Percentage : {{ getPercentage }}</el-tag>
                                            </el-row>
                                        </div>
                                    </el-col>

                                </el-row>
                                <!-- actualhours: {{ timeget(totalhours.actualhours) }}
                                estimatedhours: {{ timeget(totalhours.estimatedhours)}}
                                actualhours: {{ totalhours.actualhours }}
                                estimatedhours: {{ totalhours.estimatedhours}} -->
                                <!-- Percentage:{{(totalhours.actualhours / totalhours.estimatedhours) * 100 }} -->
                            </div>
                            <!-- <el-row> -->
                            <!-- <el-dropdown size="small" @command="selectoperation">
                                <el-button type="primary">
                                    operations<el-icon class="el-icon--right"><arrow-down /></el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                    <el-dropdown-item command="=">Equals</el-dropdown-item>
                                    <el-dropdown-item command="!=">Not equal</el-dropdown-item>
                                    <el-dropdown-item command="<">Less than</el-dropdown-item>
                                    <el-dropdown-item command="<=">Less than or equals</el-dropdown-item>
                                    <el-dropdown-item command=">">Greater than</el-dropdown-item>
                                    <el-dropdown-item command=">=">Greater than or equals</el-dropdown-item>
                                    <el-dropdown-item command="In range">In range</el-dropdown-item>
                                    <el-dropdown-item command="Blank">Blank</el-dropdown-item>
                                    <el-dropdown-item command="Not blank">Not blank</el-dropdown-item>
                                    <el-dropdown-item command="REGEX">REGEX</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                                </el-dropdown>
                            </el-row> -->
                            <!-- <div class="spaceBetween" />
                                <el-row>
                                    <div v-for="i in form.directionalgos" :key="i.id">
                                            <el-row justify="space-between" :gutter="2">
                                                <el-col :span="10">
                                                    <el-input
                                                        v-model="i.value1"
                                                        size="small"
                                                        placeholder="value 1"
                                                        />
                                                </el-col>
                                                <el-col :span="4">
                                                    <div style="text-align: center;">
                                                    <el-tag class="ml-2" type="success">{{ i.operation }}</el-tag>
                                                    </div>
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-input
                                                        v-model="i.value2"
                                                        size="small"
                                                        placeholder="value 2"
                                                        />
                                                </el-col>
                                            </el-row>
                                            <div class="spaceBetween" />
                                    </div>
                                </el-row> -->
                        </div>
                    </el-form-item>
                    <hr class="spaceBetween" />
                    <el-form-item label="Graphic Box">
                        <!-- <el-card style="width: 100%;" class="box-card"> -->
                        <span style="width: 100%;">
                            <el-upload action="" :show-file-list="false" :drag="true" class="dragupload"
                                :http-request="(event) => { return addAttachment(event); }"
                                accept=".png,.jpg,.jpeg,.gif,.mp4,.mp3">
                                <el-icon :color="'#a8abb2'" :size="35"><upload-filled /></el-icon>
                                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                            </el-upload>
                            <!-- :on-change="() => { return editedchanged(form); }" -->
                            <span class="left">
                                <div v-if="form.file">
                                    <span class="fillnamelink">
                                        <div>
                                            <el-row>
                                                <el-col :span="22">
                                                    <span class="uploadedfilenames">
                                                        <span @click="beforeuploadpreview(form.file)">{{
                                                            form.file.originalname }}</span>
                                                    </span>
                                                </el-col>
                                                <el-col :span="2">
                                                    <span>
                                                        <el-icon class="deletefiles verticalmiddle"
                                                            @click="deleteAttachment()">
                                                            <close />
                                                        </el-icon>
                                                    </span>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </span>
                                </div>
                                <div class="guidancemediacontentstyle">
                                    <span v-if="form.mediacontent && form.mediacontent.name != ''"
                                        class="UR-Link marginleft5 marginright5px commonfontsize12 margintop5px"
                                        @click="showpreview(form.mediacontent)">{{ form.mediacontent.name }}</span>
                                </div>
                            </span>
                        </span>
                        <!-- </el-card> -->
                    </el-form-item>
                    <div class="spaceBetween" />
                    <el-form-item label="Message Box">
                        <div>
                            <div style="width: 285px;padding: 0px;height: 315px;overflow: auto;">
                                <editor :metadata="metadata"></editor>
                            </div>
                        </div>
                    </el-form-item>
                    <div class="spaceBetween" />
                    <el-form-item>

                    </el-form-item>
                </el-form>
            </div>
        </div>
        <show-media @deleteMedia="deleteMedia" ref="showmedia"></show-media>
        <!-- <div>
            <h2>WebSocket Chat</h2>
            <input type="text" v-model="message" @keyup.enter="sendMessage" placeholder="Type a message">
            <button @click="sendMessage">Send</button>
        </div>
        <div>
            <ul>
                <li v-for="(msg, index) in messages" :key="index">
                    <div v-if="msg != undefined">
                        <div v-for="(d, dindex) in msg" :key="dindex">
                            {{ d.Key }} : {{ d.Value }}
                        </div>
                    </div>
                </li>
            </ul>
        </div> -->
    </div>
</template>

<script>
import Editor from "./Editor.vue";
import showmedia from "./showmedia.vue";
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    data() {
        return {
            userrole:null,
            userroles:[],
            canpartshow: false,
            operations: [
                { label: "Greater than", value: ">" },
                { label: "Greater than or equals", value: ">=" },
                { label: "Less than", value: "<" },
                { label: "Less than or equals", value: "<=" },
            ],
            totalhours: {},
            selectprojectobj: [],
            projectparts: [],
            projectoptions: [],
            projects: [],
            metadata: {},
            beforemedia: {},
            extensions: [
                { name: 'jpg' },
                { name: 'jpeg' },
                { name: 'png' },
                { name: 'gif' },
                { name: 'mp4' },
                { name: 'mp3' }
            ],
            operationcount: 0,
            users: [],
            message: "",
            messages: [],
            socket: null,
            pages: [],
            actions: [],
            form: {
                id: 0
            },
            canedit: false,
            view: {
                pagesize: 20,
                filter: [
                    { table: 'd', column: 'directionname', value: '' },
                ],
            },
            tabledata: [],
            pagesizes: this.$store.state.pagesizes,
        }
    },

    components: {
        "Editor": Editor,
        'show-media': showmedia,
    },
    computed: {
        getPercentage() {
            var tt = ((this.totalhours.actualhours / this.totalhours.estimatedhours) * 100).toFixed(2);
            if (tt == "Infinity") {
                return "0 %";
            } else {
                return tt + " %";
            }
        },
        timeget() {
            return function (date) {
                if (date != null) {
                    var m = date % 60;
                    var h = (date - m) / 60;
                    return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
                }
                return "";
            };
        }
    },


    methods: {
        selectuserrole(event){
            
            if(event != ""){
               
            this.$confirm('Send to users will be changed are you sure to change the Users Role?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {
                    var obj = {id:event};
                    this.axios.post("account/getusersbyroleid",obj)
                        .then((response) => {
                            
                            var array = [];
                            response.data.forEach((d)=>{
                                array.push(d.id);
                            })
                            this.form.directionuserstemp = array;
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.apierror(error);
                        });
                }).catch(() => {
                      
                }); 


               
            }else{
                this.$confirm('Send to users will be removed are you sure to remove the Users Role?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {
                this.form.directionuserstemp = [];
                }).catch(() => {
                      
                }); 
            }
        },
        // showpopup(){
        //     // this.FormData.message = this.metadata.content;
        //       var option =  {
        //         uniqueid:1,
        //         isedit:true,
        //         visible: true,
        //         boxtype:"Small 2 Part Pop up",
        //         content:"sdfdsfsdf",
        //         mediacontent:{
        //             name: "",
        //             link: ""
        //           },
        //           offercloseoption:"error",
        //           link:"",
        //           heading:"Test dirction",
        //           directiondegree:"",
        //         }
        //     // if(this.FormData.mediacontent && this.FormData.mediacontent.id > 0){
        //     //     option.mediacontent = this.FormData.mediacontent;
        //     // }
        //     option.position = {};
        //     // var checkvalue = "";
        //     this.$store.state.directionsdialogue = [];
        //     // this.$store.state.directionsdialogue = [option];

        //     this.$store.state.directionsdialogue = [option];
        //     this.$store.state.currentdirectionindex = 0;
        //     this.$store.state.currentdirection = option;
        //         // this.pageactionchange('save',checkvalue,option);
        // },
        checknumber() {
            var pattern = /^[0-9]+$/;
            var test = pattern.test(this.form.directionalgorithm.value);

            if (test == false) {
                this.form.directionalgorithm.value = "";
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert("Please enter numeric values only!" + style, "Invalid Data", {
                    dangerouslyUseHTMLString: true,
                });
            }
        },
        getactivitys() {
            this.axios.post("action/getactions", this.selectprojectobj)
                .then((response) => {
                    if (this.selectprojectobj.typeid == 1) {
                        this.remoteMethodaction("");
                        this.projectparts = [];
                        if (response.data.pps.length > 0) {
                            this.projectparts = response.data.pps;
                        } else {
                            this.projectparts.push({ name: 'Single Bill', id: null });
                        }
                    } else {
                        this.actions = response.data.acs;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.apierror(error);
                });
        },
        selectproject(item) {
            if (item == "") {
                this.form.projectoverheadid = null;
                this.form.projectpartid = null;
                this.projectparts = [];
                this.canpartshow = false;
                this.form.directionactionstemp = [];
            } else {
                var isfindpro = this.projects.find(x => x.id == item);
                if (isfindpro) {
                    // this.form.directionactionstemp = [];
                    if (isfindpro.typeid == 1) {
                        this.canpartshow = true;
                    } else {
                        this.form.directionalgorithm.algorithmtype = "hours";
                        this.canpartshow = false;
                        this.form.projectpartid = null;
                    }
                    this.selectprojectobj = isfindpro;
                    this.getactivitys();
                }
            }
        },
        remoteMethodproject(query) {

            if (query == "") {
                this.projectoptions = this.projects
            } else {
                this.projectoptions = this.projects.filter(item => {
                    return item.name.toLowerCase()
                        .indexOf(query.toLowerCase()) > -1;
                });
            }
        },
        deleteMedia() {
            this.axios.post("direction/deletemedia", { id: this.form.id })
                .then(() => {
                    this.$message({
                        showClose: true,
                        message: "Media deleted successfully!",
                        type: "success",
                    });
                    this.$refs.showmedia.CloseMedia();
                    this.form.mediacontent = null;
                    // this.getmedia();
                })
                .catch((error) => {
                    this.apierror(error);
                });
        },
        // getmedia(){
        //     this.axios.post("direction/getmedia", { tableid: this.form.id, source: 'direction' })
        //       .then((response) => {
        //         this.form.mediacontent = response.data;
        //       })
        //       .catch((error) => {
        //           this.apierror(error);
        //       });
        // },
        showpreview(i) {
            // i.mousehover = false;
            // i.showdialog = true;
            this.$refs.showmedia.openmedia(i, 'selectedmedia');
        },
        beforeuploadpreview(i) {
            this.$refs.showmedia.openmedia(i, 'beforemedia');
        },
        deleteAttachment() {
            this.form.file = null;
            // this.$set(this.form, 'file', null);
        },
        addfile(file) {
            const name = file.file.name;
            const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
            const existextension = this.extensions.find(x => x.name == ext.toLowerCase());
            const validext = this.extensions.map(e => { return e.name; }).join(", ");
            var style = "<style> .el-message-box {width:30%;} </style>";
            if (existextension == undefined) {
                this.$alert(`<span style='color:red;'>${name}</span> has invalid extension, allowed extensions are  ${validext}. ${style}`,
                    "Validate extension", {
                    dangerouslyUseHTMLString: true,
                });
                return;
            }
            var maxsize = 104857600; // 100 MB size
            if (file.file.size > maxsize) {
                this.$alert(`<span style='color:red;'>${file.file.name}</span> File size must be less than 100 mb.<br/>. ${style}`,
                    "Validate extension", {
                    dangerouslyUseHTMLString: true,
                });
                return;
            }
            const rename = `${file.file.name}`;
            const renamefile = new File([file.file], rename);
            const f = {
                originalname: name,
                checkingname: rename,
                content: renamefile
            };
            this.form.file = f;
            // this.$set(this.form, 'file', f);
            // this.FormData.file = f;
        },
        addAttachment(file) {
            if (this.form.mediacontent && this.form.mediacontent.id > 0) {
                this.$confirm("Old media will be removed.Are you sure you want to Update New Media?", 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.form.mediacontent.name = "";
                    this.addfile(file);
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            } else {
                this.addfile(file);
            }
        },
        remoteMethodpage(query) {
            if (query == null) {
                query = "";
            }
            this.axios.post("direction/searchpage?q=" + query, {})
                .then((response) => {
                    // var overhead = JSON.parse(response.data);
                    this.pages = response.data;
                })
                .catch((error) => {
                    this.apierror(error);
                });
        },
        remoteMethodUsers(query) {
            if (query == null) {
                query = "";
            }
            this.axios.post("account/searchuser?q=" + query, {})
                .then((response) => {
                    // var overhead = JSON.parse(response.data);
                    this.users = JSON.parse(response.data);
                })
                .catch((error) => {
                    this.apierror(error);
                });
        },
        remoteMethodUsersRole(query) {
            if (query == null) {
                query = "";
            }
            this.axios.post("account/searchuserRole?q=" + query, {})
                .then((response) => {
                    // var overhead = JSON.parse(response.data);
                    this.userroles = response.data // JSON.parse(response.data);
                })
                .catch((error) => {
                    this.apierror(error);
                });
        },
        remoteMethodaction(query) {
            if (query == null) {
                query = "";
            }
            this.axios.post("Action/searchactions?q=" + query, {})
                .then((response) => {
                    // var overhead = JSON.parse(response.data);
                    this.actions = response.data;
                })
                .catch((error) => {
                    this.apierror(error);
                });
        },
        sendMessage() {
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                this.socket.send(this.message);
                // this.messages.push("You: " + this.message);
                this.message = "";
            } else {
                this.messages.push("WebSocket is not open");
            }
        },
        cancel() {
            this.canedit = false;
        },
        validsave() {
            var msg = [];
            if (this.form.directionname == "" || this.form.directionname == null) {
                msg.push("Direction Name must not be empty");
            }
            // if(this.FormData.sectionid == null)
            // {
            //     msg.push("NRM section must be required");
            // }
            if (this.form.directionalgorithm.algorithmtype == "" || this.form.directionalgorithm.algorithmtype == undefined) {
                msg.push("Please select a Algorithm Type");
            } else {
                if (this.form.directionalgorithm.operation == undefined || this.form.directionalgorithm.operation == "") {
                    msg.push("Algorithm Operation Box must not be empty");
                }
                if (this.form.directionalgorithm.value == null || this.form.directionalgorithm.value == "") {
                    if (this.form.directionalgorithm.algorithmtype == "percentage") {
                        msg.push("Percentage Box must not be empty");
                    } else {
                        msg.push("Hours Box must not be empty");
                    }
                }
            }

            if (this.form.message == "" || this.form.message == null) {
                msg.push("Message Box type must not be empty");
            }
            if (this.form.directionuserstemp == undefined || this.form.directionuserstemp.length == 0) {
                msg.push("Send to Users Box must not be empty");
            }
            if (msg.length > 0) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(msg.join("<br />") + style, "Invalid Data", {
                    dangerouslyUseHTMLString: true,
                });
                return false;
            } else {
                return true;
            }
        },
        setarraydirection() {
            if (this.form.directionactions)
                if (this.form.directionactionstemp.length > 0) {
                    var array = [];
                    this.form.directionactionstemp.forEach(element => {
                        var isfind = this.form.directionactions.find(x => x.actionid == element);
                        if (isfind == undefined) {
                            array.push({ actionid: element })
                        } else {
                            array.push(isfind);
                        }
                    });
                    this.form.directionactions = array;
                } else {
                    this.form.directionactions = [];
                }
            if (this.form.directionuserstemp.length > 0) {
                var arrayuser = [];
                this.form.directionuserstemp.forEach(element => {
                    var isfind = this.form.directionusers.find(x => x.actionid == element);
                    if (isfind == undefined) {
                        arrayuser.push({ userid: element })
                    } else {
                        arrayuser.push(isfind);
                    }
                });
                this.form.directionusers = arrayuser;
            } else {
                this.form.directionusers = [];
            }
        },
        save() {
            this.form.message = this.metadata.content;
            if (this.validsave()) {
                this.setarraydirection();
                this.showloading();
                const newsave = () => {
                    let formdata = this.jsonToFormData(this.form);
                    this.axios.post("direction/savedirection", formdata)
                        .then(() => {
                            this.metadata.content = "";
                            this.form = { directionactions: [], directionusers: [], directionalgorithm: {} }
                            this.canedit = false;
                            this.hideloading();
                            this.refresh();

                        })
                        .catch(error => {
                            this.hideloading();
                            this.apierror(error);
                        })
                };
                newsave();
            }

        },
        deleterow(data) {
            ElMessageBox.confirm(
                'Will permanently delete the Direction. Continue?',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                    center: true,
                }
            )
                .then(() => {
                    this.axios.post("direction/deletedirection", data.d)
                        .then(() => {
                            
                            ElMessage({
                                type: 'success',
                                message: 'Delete completed',
                            })
                            // this.metadata.content = "";
                            // this.form = { directionactions: [], directionusers: [], directionalgorithm: {} }
                            // this.canedit = false;
                            // this.hideloading();
                            this.refresh();
                        })
                        .catch(error => {
                            this.hideloading();
                            this.apierror(error);
                        })

                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: 'Delete canceled',
                    })
                })

        },
        editrow(row) {
            var obj = {
                id: row.d.id
            }
            this.axios.post("direction/getdirectionbyid", obj)
                .then(response => {
                    this.form = response.data;
                    this.metadata.content = this.form.message;
                    this.canedit = true;
                    this.form.directionactionstemp = this.form.directionactions.map(x => { return x.actionid });
                    this.form.directionuserstemp = this.form.directionusers.map(x => { return x.userid });
                    if (this.form.projectoverheadid != null || this.form.projectoverheadid != 0) {
                        // var isfind  = this.projects.find(x => x.id == this.form.projectoverheadid);
                        // if(isfind != undefined){
                        this.selectproject(this.form.projectoverheadid);
                        // }
                    }
                    this.hideloading();

                })
                .catch(error => {
                    this.hideloading();
                    this.apierror(error);
                })
        },

        copyrow(row) {
            var obj = {
                id: row.d.id,
                directionname: row.d.directionname + "-Copy"
            }
            
            this.$prompt('Would you like to copy this direction?', 'Confirm', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'confirm',
                inputValue: row.d.directionname + " - Copy"
            }).then((promptdata) => {
                this.showloading();
                if (promptdata.value && promptdata.value.trim() != "") {
                    obj.directionname = promptdata.value;
                }
                this.axios.post("direction/copyDirection", obj)
                    .then(response => {
                        this.hideloading();
                        this.$message({
                            showClose: true,
                            message: response.data,
                            type: "success"
                        });
                        this.refresh();
                    })
                    .catch(error => {
                        this.hideloading();
                        this.apierror(error);
                    });
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },

        addrow() {
            this.form = { directionactions: [], directionusers: [], directionalgorithm: {} }
            this.canedit = true;
            this.metadata.content = "";
            this.totalhours = {};
        },
        refresh: function () {
            // this.$store.state.actionview = this.view;
            this.showloading();
            this.axios.post("direction/getdirectionview", this.view)
                .then(response => {
                    this.hideloading();
                    this.tabledata = response.data.data;
                    this.view = response.data.view;
                })
                .catch(error => {
                    this.hideloading();
                    this.apierror(error);
                })
        },
        validtotal() {
            var msg = [];
            if (this.form.projectoverheadid == null || this.form.projectoverheadid == 0) {
                msg.push("Please select the Project/Overhead first");
            }
            if (this.selectprojectobj.typeid == 1) {
                if ((this.form.projectpartid == undefined || this.form.projectpartid == null) && this.projectparts[0] && this.projectparts[0].id != null) {
                    msg.push("Please select the Project part first");
                }
            }
            if (this.form.directionactionstemp == undefined || this.form.directionactionstemp.length == 0) {
                msg.push("Please select the Actions first");
            }
            if (msg.length > 0) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(msg.join("<br />") + style, "Invalid Data", {
                    dangerouslyUseHTMLString: true,
                });
                return false;
            } else {
                return true;
            }
        },
        gettotalhours() {
            var gettotal = () => {
                this.setarraydirection();
                this.axios.post("direction/gettotalhours", this.form)
                    .then(response => {
                        this.hideloading();
                        this.totalhours = response.data;
                    })
                    .catch(error => {
                        this.hideloading();
                        this.apierror(error);
                    })
            }
            if (this.validtotal()) {
                gettotal();
            }

        },

        sortchange: function (sort) {
            this.view.sortcol = sort.column.sortable;
            this.view.sortorder = sort.order;
            this.refresh();
        },

        rowperpages: async function (val) {
            try {
                this.usergridrow.ugv.rowperpage = val;
                this.usergridrow = await this.$commonutils.savepagelayout(this.usergridrow);
                this.view.pageno = 1;
                this.view.pagesize = val;
                this.refresh();
            } catch (error) {
                this.$bus.$emit('error', error);
            }
        },
    },
    created: function () {
        if (this.$store.state.projects.length == 0) {
            this.searchproject().then(() => {
                this.projectoptions = this.$store.state.projects;
                this.projects = this.$store.state.projects;
            });
        } else {
            this.projectoptions = this.$store.state.projects;
            this.projects = this.$store.state.projects;
        }
        this.remoteMethodUsers("");
        this.remoteMethodaction("");
        this.remoteMethodpage("");
        this.remoteMethodUsersRole("");
        const app = this;
        this.$direction.setcomponent(app);
        this.$commonutils.setcomponent(app);
        // this.$direction.showNotification();

        let gridrow = this.$commonutils.getusergridrow(this.$route);
        this.view.pagesize = gridrow.ugv.rowperpage;
        this.usergridrow = gridrow;

        if (this.canedit == false) {
            this.refresh();
        }
    }
}
</script>
<style>
.el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
}
</style>