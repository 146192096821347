<template>
    <div>
        <!-- <el-affix :offset="50"> -->
            <div class="colorwhite stickyheader">
                <el-row>
                    <el-col :span="12"> <div class="headerstyle">Fee Table</div> </el-col>
                    <el-col :span="12"> 
                        <div class="floatright margint8">
                            <el-button type="primary" @click="addcolumn" :disabled="!editpermission">Add Year</el-button>
                            <el-button type="success" @click="saveFeeData" icon="circle-check" :disabled="!editpermission">Save</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        <!-- </el-affix> -->

        <el-table :data="tableData.fv" style="width: 100%" border>
            <el-table-column width="160" label="name" prop="user.firstname">
                 <template #header>
                    <div>
                        <div>{{"Year"}}</div>
                        <div class="margintop5px">{{"Projected Inflation"}}</div>
                        <div class="margintop2px">{{"Name"}}</div>
                    </div>
                </template>

                <template #default="scope">
                    <div> {{ scope.row.user.firstname + " " + scope.row.user.lastname }} </div>
                </template>
            </el-table-column>
            
            <el-table-column v-for="(item, i) in tableData.feeyears" :key="i" width="80" label="rate" prop="id">
                <template #header>
                    <div>
                        <el-date-picker v-model="item.year" @change="editedchange(item)" type="year" placeholder="Pick a year" class="fullwidth" :clearable="false" :disabled-date="$commonutils.disableFutureDates"/>
                        <!-- <el-input v-model="item.year" @change="editedchange(item)"></el-input> -->
                        <el-input v-model="item.projectedinflationrate" @change="editedchange(item)" @blur="focusOut($event, item, 'projectedinflationrate')" @focus="focusIn($event, item, 'projectedinflationrate')" class="inputtextcenter spaceBetween2"></el-input>
                        <div class="center">{{"Rate"}}</div>
                    </div>
                </template>

                <template #default="scope">
                    <!-- <div v-if="scope.row.feerates[i].rate != undefined"> -->
                        <!-- <el-input v-model.number="scope.row.feerates[i].rate"></el-input> -->
                        <div v-if="getindex(item, scope.row, i) != -1">
                            <el-input-number v-model="scope.row.feerates[getindex(item, scope.row)].rate" :precision="2" :controls="false" @change="editedchange2(scope.row.feerates[getindex(item, scope.row)])" placeholder="Rate" class="fullwidth inputtypenumlr6px" />
                        </div>

                    <!-- </div> -->
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import moment from "moment";
export default {
    data(){
        return {
            tableData: [],
        }
    },
    computed: {
        getindex() {
            return function(data, item) {
                var index = 0;
                if (data && data.id && item && item.feerates.length > 0) {
                    index = item.feerates.findIndex(x => x.feeid == data.id);
                }
               return index;
            }
        }
    },
    
    methods: {
        addcolumn() {
            var currentdate = new Date();
            this.tableData.feeyears.push({ id: 0, year: currentdate, projectedinflationrate: "0%", edited: true });
            var iscount = -1;
            this.tableData.feeyears.forEach(y => {
                if (y.id <= 0 || y.id == null) {
                    y.id = iscount;
                    iscount--;
                }
            });

            var feeyearid = this.tableData.feeyears[this.tableData.feeyears.length - 1].id;
            this.tableData.fv.forEach(e => {
                e.feerates.push({ id: -1, feeid: feeyearid, userid: e.user.id, rate: 0 });
            });
        },

        focusOut(event,row,rowkey) {
                row[rowkey] = parseFloat(event.target.value).toFixed(1);
                if (isNaN(row[rowkey])) {
                    row[rowkey] = 0.0
                }
                if (row[rowkey] >= 100) {
                    row[rowkey] = (100).toFixed(1); 
                }
                if (row[rowkey] <= 0) {
                    row[rowkey] = (0).toFixed(1);
                }
                row[rowkey] = row[rowkey] + "%";
        },

        focusIn(event,row,rowkey) {
            row[rowkey] = parseFloat(event.target.value).toFixed(1);
            if (isNaN(row[rowkey])) { 
                row[rowkey] = 0.0;
            }
        },

        editedchange(row){
            row.edited = true;
        },

        editedchange2(raterow){
            if (raterow.rate == null && raterow.rate == undefined) {
                raterow.rate = 0;
            }
            raterow.edited = true;
        },

        getFeeData() {
            this.showloading();
            this.$http.get("sales/getFeeTableData")
                .then((response) => {
                    this.hideloading();
                    this.tableData = response.data;
                    this.tableData.feeyears.forEach(fy => {
                        var year = new Date(fy.year.toString());
                        fy.year = year;
                        fy.projectedinflationrate = fy.projectedinflationrate.toFixed(1) + "%";
                    });

                    var iscount = -1;
                    if (this.tableData.feeyears.length == 0) {
                        var currentdate = new Date();
                        this.tableData.feeyears.push({ id: -1, year: currentdate, projectedinflationrate: "0%", edited: true });
                        this.tableData.fv.forEach(e => {
                            if (e.feerates.length == 0) {
                                e.feerates.push({ id: iscount, feeid: -1, userid: e.user.id, rate: 0 });
                                iscount--;
                            }
                        });
                    } else {
                        this.tableData.fv.forEach(e => {
                            if (e.feerates.length < this.tableData.feeyears.length ) {
                                for (let i = 0; i < this.tableData.feeyears.length; i++) {
                                    var element = this.tableData.feeyears[i];
                                    e.feerates.push({ id: iscount, feeid: element.id, userid: e.user.id, rate: 0 });
                                    iscount--;
                                }
                            }
                        });
                    }
                })
                .catch((error) => {
                    this.hideloading();
                    this.apierror(error);
                });
        },
        
        saveFeeData() {
            var msg = [];
            var object = {};
            this.tableData.feeyears.forEach(function (item) {
            if(!object[moment(item.year).format("YYYY")])
                object[moment(item.year).format("YYYY")] = 0;
                object[moment(item.year).format("YYYY")] += 1;
            })

            for (var prop in object) {
                if(object[prop] >= 2) {
                    msg.push("Year '"+ prop +"' is duplicate so duplicate year is not allowed.");
                }
            }

            if (msg.length > 0) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(msg.join('<br/>') + style, "Duplicate Data", { dangerouslyUseHTMLString: true });
                return;
            }

            var feedata = { feeyears:[], fv: [] }
            this.tableData.feeyears.find(fy => {
                fy.year = fy.year.getFullYear(fy.year);
                fy.projectedinflationrate = parseFloat(fy.projectedinflationrate);
                feedata.feeyears.push(fy);
            });
            
            this.tableData.fv.forEach(v => {
               var feerate = v.feerates.filter(fr => {
                    if (fr.edited) {
                        return fr;
                    }
                });
                if (feerate.length > 0) {
                    var newobj = {feerates: [], user: v.user};
                    newobj.feerates.push(...feerate);
                    feedata.fv.push(newobj);
                }
            });
            if (feedata.feeyears.length > 0 || feedata.fv.length > 0) {
                this.$http.post("sales/saveFeeTableData", feedata)
                    .then((response) => {
                        this.$message({
                            type: "success",
                            showClose: true,
                            message: response.data.message
                        });
                        this.getFeeData();
                    })
                    .catch((error) => {
                        this.apierror(error);
                    });
            } else {
                this.$message({
                    type: "info",
                    showClose: true,
                    message: "Nothing to save"
                });
            }
        },
    },

    created() {
        this.getFeeData();
    },
}
</script>