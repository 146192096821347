<template>
    <div>
    <span class="section-to-print-head"> 
            <h4 style="margin: 0px; margin-left: 15px; margin-bottom: 5px;">
            {{ showTableTitle }}
            </h4>
          <!-- <div class="spaceBetween" /> -->
    </span>
    <table class="custom-table section-to-print-body">
      <thead>
        <!-- <tr v-for="(headerRow, rowIndex) in tableHeaders" :key="rowIndex">
          <th v-for="(header, colIndex) in headerRow" :key="colIndex"
              :colspan="header.colspan" :rowspan="header.rowspan"
              :style="{ width: header.width, minWidth: header.minWidth, textAlign: header.align }">
            {{ getHeaderLabel(header) }}
          </th>
        </tr>
        <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
          <td v-for="(column, colIndex) in getFlatColumns()" :key="colIndex">
            <slot>
              {{ resolveColumnData(row, column) }}
            </slot>
          </td>
        </tr> -->
  
        <tr v-for="(headerRow, rowIndex) in tableHeaders" :key="rowIndex">
                  <th v-for="(header, colIndex) in ignoreemptyheaders(headerRow)" :key="colIndex"
                      :colspan="header.colspan" :rowspan="header.rowspan"
                      :style="{ width: header.width, minWidth: header.minWidth, textAlign: header.align }">
                      {{ header.label }}
                  </th>
              </tr>
          </thead>
  
          <tbody>
              <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
                  <td :colspan="row.colspan" v-if="row.isHtmlRow">
                      <div v-html="row.htmlContent"></div>
                  </td>
                  <td v-else
                      v-for="(column, colIndex) in getTableHeaders" :key="colIndex">
                      <slot>
                          {{ getPropertyValue(row, column.prop) }}
                      </slot>
                  </td>
              </tr>
              
          </tbody>
    </table>
</div>
  </template>
  
  <script>
  export default {
    name: 'CustomTable',
    props: {
      tableHeaders: Array,
      tableData: Array,
      showTableTitle: String
    },
  
    computed: {
          getTableHeaders() {
              let index = -1;
              for (let i = 0; i < this.tableHeaders.length; i++) {
                  const innerArray = this.tableHeaders[i];
                  const foundItem = innerArray.find(item => item.prop && item.prop.trim() !== '');
  
                  if (foundItem) {
                      index = i;
                      break;
                  }
              }
  
              if (index != -1) {
                  return this.tableHeaders[index];
              }
  
              return [];
          }
      },
  
    methods: {
      ignoreemptyheaders(rows) {
              let theaders = [];
              for (let i = 0; i < rows.length; i++) {
                  const element = rows[i];
                  if (!element.isheaderignore) {
                      theaders.push(element);
                  }
              }
              return theaders;
          },
  
      getheaders(row) {
        return row.label;
      },
  
      getHeaderLabel(header) {
      if (header.label) {
        return header.label;
      } else if (header.text) {
        return header.text;
      } else {
        return '';
      }
    },
  
    getFlatColumns() {
        const flatColumns = [];
        for (const headerRow of this.tableHeaders) {
          for (const header of headerRow) {
            flatColumns.unshift(header);
          }
        }
        return flatColumns;
      },
  
    resolveColumnData(rowData, column) {
      if (column.prop) {
        const value = this.getPropertyValue(rowData, column.prop);
        return this.formatValue(value);
      } else {
        const columnIndex = this.tableHeaders.indexOf(column);
        return this.formatValue(rowData[columnIndex]);
      }
    },
  
      getColumnValue(rowData, prop) {
        const props = prop.split('.');
        let value = rowData;
        for (const prop of props) {
          if (value && value[prop] !== undefined) {
            value = value[prop];
          } else {
            value = '';
            break;
          }
        }
        return value;
      },
  
      formatValue(value) {
        if (Array.isArray(value)) {
          return value.join(', ');
        } else if (typeof value === 'object' && value !== null) {
          return JSON.stringify(value);
        } else {
          return value !== undefined && value !== null ? value.toString() : '';
        }
      },
      
      getPropertyValue(obj, keyPath) {
        if (!keyPath) {
          return '';
        }
  
        const keys = keyPath.split('.');
        let value = obj;
        for (const key of keys) {
          if (value && typeof value === 'object' && key in value) {
            value = value[key];
          } else {
            return '';
          }
        }
        return value;
      }
    }
  }
  </script>
  
  <style scoped>
  .custom-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #ccc;
    padding: 4px;
    text-align: left;
    line-height: 20px;
    font-size: 12px;
  }
  </style>