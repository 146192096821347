<template>
    <div>
        <div v-if="!details" class="salestablewidth">
            <!-- <el-affix :offset="50"> -->
                <div class="colorwhite stickyheader">
                    <el-row>
                        <el-col :span="10">
                            <div class="headerstyle">{{ page }}</div>
                        </el-col>
                        <el-col :span="9" class="margint8">
                            <el-pagination layout="prev, pager, next"
                                           :total="view.count"
                                           :page-size="view.pagesize"
                                           @current-change="refresh"
                                           v-model:currentPage="view.pageno">
                            </el-pagination>
                        </el-col>
                        <el-col :span="5">
                            <div class="floatright margint8">
                                <span>
                                    <el-button type="primary"
                                               @click="addrow()"
                                               icon="circle-plus-filled"
                                               :disabled="!editpermission"></el-button>
                                </span>
                            </div>
                        </el-col>
                    </el-row>
                    <hr class="spaceBetween" />
                </div>
            <!-- </el-affix> -->
            <div>
                <el-table :border="true"
                          :stripe="true"
                          :data="detailform"
                          @sort-change="sortchange"
                          :default-sort="{prop: 'p.name', order: 'descending'}">
                    <el-table-column prop="p.name"
                                     sortable="custom"
                                     label="Project Name"
                                     fixed="left"
                                     width="360">
                                     <template #default="scope">
                                       <div class="floatleft width40px">
                                        <el-button type="primary"
                                            class="padding7"
                                            icon="edit-pen"
                                            @click="editrow(scope.row)"
                                            :disabled="!editpermission"></el-button>
                                       </div>
                                        <div class="floatleft"> {{scope.row.p.name}}</div>
                                     </template>
                    </el-table-column>

                    <el-table-column prop="sq.mcehprojectname" label="Estimate Name" width="250"></el-table-column>

                    <el-table-column prop="sq.feequotation"
                                     label="Fee Quotation"
                                     width="90">
                    </el-table-column>

                    <el-table-column prop="sq.quotationdate"
                                     label="Quotation Date"
                                     :formatter="dateformatter"
                                     width="109">
                    </el-table-column>

                    <el-table-column prop="sq.hours"
                                     label="Hrs"
                                     width="40">
                    </el-table-column>

                    <el-table-column label="Base Cost" align="center">
                        <el-table-column prop="sq.basecost"
                                         label="Column 1"
                                         width="90">
                        </el-table-column>
                        <el-table-column prop="sq.averagebasecost"
                                         label="Column 2"
                                         width="90">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column label="Projected Margin" align="center">
                        <el-table-column label="Column 1"
                                         prop="sq.projectedmargin"
                                         width="90">
                        </el-table-column>
                        <el-table-column label="Column 2"
                                         prop="sq.averageprojectedmargin"
                                         width="90">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column label="Percentage %" align="center">
                        <el-table-column label="Column 1"
                                         prop="sq.basepr"
                                         width="80">
                        </el-table-column>
                        <el-table-column label="Column 2"
                                         prop="sq.averagepr"
                                         width="80">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column prop="sq.feeoptions"
                                     label="Fee Options"
                                     width="80">
                    </el-table-column>

                    <el-table-column prop="sq.comments"
                                     label="Comment"
                                     width="290">
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div v-if="details" class="salesforms">
            <!-- <el-affix :offset="50"> -->
                <div class="colorwhite stickyheader">
                    <el-row>
                        <el-col :span="15">
                            <div class="headerstyle">{{ page }}</div>
                        </el-col>
                        <el-col :span="9">
                            <div class="floatright margint8">
                                <span>
                                    <el-button type="success"
                                               @click="save"
                                               icon="circle-check"
                                               v-loading.fullscreen.lock="savingquotation"
                                               :disabled="!editpermission">Save</el-button>
                                </span>
                                <span>
                                    <el-button type="info"
                                               @click="resetform"
                                               class="marginleft5"
                                               icon="back">Back</el-button>
                                </span>
                            </div>
                        </el-col>
                    </el-row>
                    <hr class="spaceBetween" />
                </div>
            <!-- </el-affix> -->
            <div>
                <el-form :model="form" label-position="left">
                    <el-form-item label="Project Name" label-width="120px">
                        <el-select v-bind:style="{ width: '329px' }"
                                   v-model="form.projectid"
                                   placeholder="Project Name"
                                   :clearable="true"
                                   :filterable="true"
                                   popper-class="customselect"
                                   @change="selectproject(form.projectid, true)">
                            <el-option v-for="item in projectoptions"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                                <span>{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="spaceBetween" />

                    <el-form-item label="Estimate Name" label-width="120px">
                        <el-autocomplete
                            v-model="form.mcehprojectname"
                            :fetch-suggestions="getMCEHProjectList"
                            class="inline-input fullwidth"
                            placeholder="Estimate Name"
                            @select="handleSelect"
                            value-key="projectname">
                            <template #suffix>
                                <el-button @click="closeproject" type="text">&times;</el-button>
                            </template>
                            <template #default="{ item }">
                                <span style="float: left">{{ item.projectname }}</span>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                    <div class="spaceBetween" />

                    <el-row>
                        <div class="left">
                            <el-form-item label="Quotation Date" label-width="120px">
                                <el-date-picker v-model="form.quotationdate"
                                                type="date"
                                                :editable="false"
                                                placeholder="Date"
                                                :clearable="false"
                                                @change="datechange"
                                                format="DD-MMM-YYYY"
                                                :default-value="Date()"
                                                value-format="YYYY-MM-DD"
                                                class="customdate salesinputwidth85"
                                                :disabled-date="$commonutils.disableFutureDates">
                                </el-date-picker>
                            </el-form-item>                            
                            <div class="spaceBetween" />

                            <el-form-item label="Fee Quotation" label-width="120px">
                                <el-input v-model="form.feequotationstr"
                                          @blur="currencyblur(form, 'feequotation', $event)"
                                          @focus="currencyfocus(form, 'feequotation')"
                                          @change="editedchange(true)"
                                          :class="addClass(form.feequotation, 'salesinputwidth85 GBPcosttext', ' inputtextred')" />
                            </el-form-item>
                            <div class="margintop6px marginbottom12px" />

                            <span style="font-size: 12px; font-weight: bold; margin-left: 130px;">Cost Based</span>
                            <el-form-item label="Cost For Hours" label-width="120px" class="margintop6px">
                                <el-input v-model="form.basecoststr"
                                          @blur="currencyblur(form, 'basecost', $event)"
                                          @focus="currencyfocus(form, 'basecost')"
                                          @change="editedchange(true)"
                                          :class="addClass(form.basecost, 'salesinputwidth85 GBPcosttext', ' inputtextred')" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item label="Projected Margin" label-width="120px">
                                <el-input v-model="form.projectedmarginstr"
                                          :class="addClass(form.projectedmargin, 'salesinputwidth85 GBPcosttext readonlyinput', ' inputtextred')"
                                          :disabled="true" />
                            </el-form-item>
                        </div>

                        <div class="verticalline"></div>
                        <div class="left">
                            <el-form-item label="Hrs" label-width="30px">
                                <el-input-number :controls="false"
                                                 v-model="form.hours"
                                                 @change="editedchange(true)"
                                                 :class="addClass(form.hours, 'saleshours55 GBPcosttext inputtypenumlr6px', ' inputtextred')" />
                            </el-form-item>                            
                            <div class="spaceBetween" />

                            <el-form-item label-width="13px">
                                <!-- <span class="spancolor">Average</span> -->
                                <el-input v-model="form.feequotationstr" :class="addClass(form.feequotation, 'salesinputwidth85 GBPcosttext moveright13px readonlyinput', ' inputtextred')" disabled />
                            </el-form-item>
                            <div class="margintop6px marginbottom12px" />

                            <span style="font-size: 12px; font-weight: bold; margin-left: 8px;">Value Based</span>
                            <el-form-item>
                                <el-input v-model="form.averagebasecoststr"
                                          @blur="currencyblur(form, 'averagebasecost', $event)"
                                          @focus="currencyfocus(form, 'averagebasecost')"
                                          @change="editedchange(true)"
                                          :class="addClass(form.averagebasecost, 'salesinputwidth85 GBPcosttext margintop6px', ' inputtextred')" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.averageprojectedmarginstr"
                                          :class="addClass(form.averageprojectedmargin, 'salesinputwidth85 GBPcosttext readonlyinput', ' inputtextred')"
                                          :disabled="true" />
                            </el-form-item>
                        </div>
                    </el-row>
                    <div class="spaceBetween" />

                    <el-row>
                        <el-form-item>
                            <span :class="addClass(form.basepercentage, 'widthlabel190', ' red')">{{ form.basepercentage }} %</span>
                        </el-form-item>

                        <el-form-item>
                            <span :class="addClass(form.avaragepercentage, 'widthlabel130', ' red')">{{ form.avaragepercentage }} %</span>
                        </el-form-item>
                    </el-row>
                    <div class="spaceBetween" />

                    <el-form-item label="Fee Options" label-width="120px">
                        <el-input v-model="form.feeoptionsstr"
                                  @blur="currencyblur(form, 'feeoptions', $event)"
                                  @focus="currencyfocus(form, 'feeoptions')"
                                  @change="editedchange(true)"
                                  :class="addClass(form.feeoptions, 'salesinputwidth85 GBPcosttext', ' inputtextred')" />
                    </el-form-item>
                    <div class="spaceBetween" />

                    <el-form-item>
                        <span class="spancolor">Commercial Judgements (Comment)</span>
                    </el-form-item>
                    <div class="spaceBetween" />

                    <el-form-item>
                        <el-input type="textarea"
                                  v-model="form.comments"
                                  @change="editedchange(true)"
                                  :autosize="{ minRows: 3, maxRows: 4 }"
                                  placeholder="Commercial Judgements (Comment)" />
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    export default {
        data() {
            return {
                page: "Sales - Quotations",
                details: false,
                detailform: [],
                view: {
                    filter: [{ table: 'p', column: 'name', value: '' }]
                },
                form: {},
                projectoptions: [],
                projects: [],
                project: {},
                savingquotation: false,
                EHProjectlist: []
            }
        },

        methods: {
            refresh: function () {
                this.showloading();
                this.$http.post("sales/GetViewSalesQuotations", this.view)
                    .then((response) => {
                        this.hideloading();
                        this.view = response.data.view;
                        for (const item of response.data.data) {
                            if (item.sq.feequotation != 0 && item.sq.basecost != 0) {
                                let totalbasemargin = item.sq.feequotation - item.sq.basecost;
                                item.sq.projectedmargin = parseFloat(totalbasemargin.toFixed(2));
                                if (item.sq.projectedmargin != 0 && item.sq.feequotation != 0) {
                                    const pr = item.sq.projectedmargin / item.sq.feequotation;
                                    item.sq.basepr = pr.toFixed(2);
                                }
                            }
                            if (item.sq.feequotation != 0 && item.sq.averagebasecost != 0) {
                                let totalaveragemargin = item.sq.feequotation - item.sq.averagebasecost;
                                item.sq.averageprojectedmargin = parseFloat(totalaveragemargin.toFixed(2));
                                if (item.sq.averageprojectedmargin != 0 && item.sq.feequotation != 0) {
                                    const apr = item.sq.averageprojectedmargin / item.sq.feequotation;
                                    item.sq.averagepr = apr.toFixed(2);
                                }
                            }
                        }
                        this.detailform = response.data.data;
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },
            sortchange: function (sort) {
                this.view.sortcol = sort.prop;
                this.view.sortorder = sort.order;
                this.refresh();
            },
            // eslint-disable-next-line no-unused-vars
            dateformatter: function (_row, _column, cellValue) {
                if (cellValue) {
                    return moment(cellValue).format("ddd DD-MMM-YYYY");
                }
                return cellValue;
            },
            addrow: function () {
                this.page = "Add Sales - Quotation";
                this.form.quotationdate = moment(Date()).format("YYYY-MM-DD");
                this.details = true;
            },
            resetform: function () {
                this.page = "Sales - Quotations";
                var d = moment(Date()).format("YYYY-MM-DD");
                this.form = { quotationdate: d };
                this.details = false;
            },
            selectproject: function (item, event) {
                this.editedchange(event);
                if (item != "") {
                    this.project = this.projects.find(x => x.id == item);
                    this.form.projectid = this.project.id;
                }
            },
            editrow: function (row) {
                this.page = "Edit Sales - Quotation";
                this.details = true;
                this.form.id = row.sq.id;
                this.selectproject(row.p.id, false);
                this.form.quotationdate = moment(row.sq.quotationdate).format("YYYY-MM-DD");
                this.form.hours = row.sq.hours;
                this.form.feequotation = row.sq.feequotation;
                this.form.feequotationstr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.sq.feequotation));
                this.form.basecost = row.sq.basecost;
                this.form.basecoststr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.sq.basecost));
                this.form.averagebasecost = row.sq.averagebasecost;
                this.form.averagebasecoststr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.sq.averagebasecost));
                this.form.feeoptions = row.sq.feeoptions;
                this.form.feeoptionsstr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.sq.feeoptions));
                this.form.comments = row.sq.comments;
                this.form.mcehprojectid = row.sq.mcehprojectid;
                this.form.mcehprojectname = row.sq.mcehprojectname;
                this.calculateprojectmargin(this.form);
            },
            datechange: function (event) {
                this.editedchange(true);
                if (event == null) {
                    this.form.quotationdate = moment(Date()).format("YYYY-MM-DD");
                }
            },
            editedchange: function (event) {
                this.form.edited = event;
            },
            currencyfocus(row, name) {
                row[name + "str"] = row[name];
            },
            currencyblur(row, name, event) {
                let val = parseFloat(event.target.value);
                if (!isNaN(val) && isFinite(val)) {
                    let v = parseFloat(event.target.value);
                    row[name] = parseFloat(v.toFixed(2));
                    row[name + "str"] = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row[name]));
                } else {
                    row[name] = null;
                    row[name + "str"] = null;
                }
                if (name != "feeoptions") {
                    this.calculateprojectmargin(row);
                }
            },
            calculateprojectmargin(row) {
                if (row.feequotation > 0 && !isNaN(parseFloat(row.basecost))) {
                    row.projectedmargin = row.feequotation - parseFloat(row.basecost);
                    row.projectedmarginstr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.projectedmargin));
                    if (row.projectedmargin != 0 && row.feequotation != 0) {
                        var p = 0;
                        p = row.projectedmargin / row.feequotation;
                        row.basepercentage = p.toFixed(2);
                    }
                    else {
                        row.basepercentage = null;
                    }
                }
                else {
                    row.projectedmarginstr = null;
                    row.projectedmargin = null;
                    row.basepercentage = null;
                }
                if (row.feequotation > 0 && !isNaN(parseFloat(row.averagebasecost))) {
                    row.averageprojectedmargin = row.feequotation - parseFloat(row.averagebasecost);
                    row.averageprojectedmarginstr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.averageprojectedmargin));
                    if (row.averageprojectedmargin != 0 && row.feequotation != 0) {
                        var avg = 0;
                        avg = row.averageprojectedmargin / row.feequotation;
                        row.avaragepercentage = avg.toFixed(2);
                    }
                    else {
                        row.avaragepercentage = null;
                    }
                }
                else {
                    row.averageprojectedmarginstr = null;
                    row.averageprojectedmargin = null;
                    row.avaragepercentage = null;
                }
            },
            myvalidate(callback) {
                var msg = [];
                if (!this.form.projectid || this.form.projectid == null) {
                    msg.push("Project should not be empty.");
                }
                if (!this.form.feequotation || this.form.feequotation == null) {
                    msg.push("Fee Quotation should not be empty.");
                }
                if (!this.form.quotationdate || this.form.quotationdate == null) {
                    msg.push("Quotation date should not be empty.");
                }
                if (!this.form.hours || this.form.hours == null) {
                    msg.push("Hrs should not be empty.");
                }
                if (!this.form.basecost || this.form.basecost == null) {
                    msg.push("Base Cost should not be empty.");
                }
                if (!this.form.averagebasecost || this.form.averagebasecost == null) {
                    msg.push("Average Base Cost should not be empty.");
                }
                if (!this.form.feeoptions || this.form.feeoptions == null) {
                    msg.push("Fee options should not be empty.");
                }
                if (msg.length > 0) {
                    this.savingquotation = false;
                    var style = '<style> .el-message-box {width:50%;} </style>';
                    this.$alert(msg.join('<br />') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }
                else {
                    callback();
                }
            },
            save: function () {
                this.savingquotation = true;
                if (this.form.edited && this.savingquotation) {
                    this.myvalidate(() => {
                        this.showloading();
                        this.$http.post("sales/savequotation", this.form)
                            .then((response) => {
                                this.hideloading();
                                this.savingquotation = false;
                                this.$message({
                                    type: "success",
                                    showClose: true,
                                    message: response.data.message
                                });
                                this.resetform();
                                this.refresh();
                                this.details = false;
                            })
                            .catch((err) => {
                                this.hideloading();
                                this.savingquotation = false;
                                this.alerterror(err);
                            });
                    });
                }
                else {
                    this.savingquotation = false;
                    this.$message({
                        type: "info",
                        showClose: true,
                        message: "Nothing to save"
                    });
                }
            },

            getMCEHProjectList: function(querystring, done) {
                if (!querystring) {
                    querystring = "";
                }
                this.$http.post("sales/getMCEHProjects?q=" + querystring, {})
                    .then((response) => {
                        this.EHProjectlist = response.data;
                        done(this.EHProjectlist);
                    })
                    .catch((err) => {
                        this.apierror(err);
                    });
            },

            addClass: function(value, classstr, newclass) {
                value = parseFloat(value);
                if (value < 0) {
                    classstr += newclass;
                }
                return classstr;
            },

            handleSelect: function(item) {
                this.form.mcehprojectid = item.id;
                this.editedchange(true);
            },

            closeproject: function(){
                this.form.mcehprojectid = null;
                this.form.mcehprojectname = "";
            },

            searchprojects: function () {
                this.axios.post("project/search?typeid=" + 1, {})
                    .then((response) => {
                        this.projectoptions = response.data;
                        this.projects = response.data;
                    })
                    .catch((err) => {
                        this.apierror(err);
                    });
            },
        },

        created() {
            // if (this.$store.state.projects.length == 0) {
            //     this.searchproject().then(() => {
            //         this.projectoptions = this.$store.state.projects;
            //         this.projects = this.$store.state.projects;
            //     });
            // } else {
            //     this.projectoptions = this.$store.state.projects;
            //     this.projects = this.$store.state.projects;
            // }
            this.searchprojects();
            var sortobj = { prop: 'p.name', order: 'descending' };
            this.sortchange(sortobj);
        }
    }
</script>
<style>
    span.widthlabel190 {
        display: inline-block;
        width: 190px;
        text-align: right;
    }

    span.widthlabel130 {
        display: inline-block;
        width: 130px;
        text-align: right;
    }
</style>