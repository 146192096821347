import { createWebHistory, createRouter } from "vue-router";
import axios from "axios";
import store from "../store/store";
import { eventbus } from "../eventbus";

import HomePage from "@/components/Home.vue";
import login from "@/components/login.vue";
import logout from "@/components/logout.vue";
import projectedit from "@/components/projectedit.vue";
import project from "@/components/project.vue";
import overhead from "@/components/project.vue";
import action from "@/components/action.vue";
import actionedit from "@/components/actionedit.vue";
import subactionedit from "@/components/subactionedit.vue";
import timesheet from "@/components/timesheet.vue";
import reports from "@/components/reports.vue";
import rmanage from "@/components/rmanage.vue";
import roverhead from "@/components/roverhead.vue";
import projecthours from "@/components/projecthours.vue";
import userlastentry from "@/components/userlastentry.vue";
import role from "@/components/role.vue";
import userpermission from "@/components/userpermission.vue"
import changepassword from "@/components/changepassword.vue"
import operativecost from "@/components/operativecost.vue";
import projectsummary from "@/components/projectsummary.vue";
import overheadsummary from "@/components/overheadsummary.vue"
import personalsummary from "@/components/overheadsummary.vue"
import internationalpayments from "@/components/internationalpayments.vue"
import salesquotation from "@/components/salesquotation.vue";
import salesinvoice from "@/components/salesinvoice.vue";
// import { toDisplayString } from "@vue/shared";
import updatedatabasestructure from "@/components/updatedatabasestructure";
import errorlog from "@/components/errorlog";
import outsourceinvoice from "@/components/outsourceinvoice";
import feerates from "@/components/feerates.vue";
import newrole from "@/components/newrole.vue";
import pages from "@/components/pages.vue";
import accesssignoff from "@/components/accesssignoff.vue";
import trainingpopup from "@/components/trainingpopup.vue";
import updateversions from "@/components/updateversions.vue";
import directionlibrary from "@/components/directionlibrary.vue";

const routes = [
    { index:1,  path: "/", component: HomePage, name: "home", },
    { index: 2,  path: "/timesheet", component: timesheet,name:"TimeEntry"},

    { index: 3,  path: "/project", component: project,name:"Project"  },
    { index: 4, path: "/projectedit", component: projectedit,name:"Project Edit" },

    { index: 5,  path: "/projectaction", component: action, name:"Project Actions"},
    { index: 6, path: "/projectactionedit", component: actionedit,name:"Project Action Edit" },
    { index: 7, path: "/projectsubactionedit", component: subactionedit ,name:"ProjectSubActionEdit"},

    { index: 8,  path: "/overhead", component: overhead,name:"Overhead"},
    { index: 9, path: "/overheadedit", component: projectedit,name:"Overhead Edit"},

    { index: 10, path: "/overheadaction", component: action,name:"Overhead Actions"},
    { index: 11, path: "/overheadactionedit", component: actionedit,name:"Overhead Action Edit"},
    { index: 12, path: "/overheadsubactionedit", component: subactionedit,name:"Overhead SubAction Edit"},

    { index: 13, path: "/reports", component: reports, name:"Individual Search"},
    { index: 14, path: "/rmanage", component: rmanage,name:"Multiple Searches"},
    { index: 15, path: "/roverhead", component: roverhead,name:"Overheads - Full"},
    { index: 16, path: "/projecthours", component: projecthours,name:"Project Hours"},

    { index: 17,  path: "/userlastentry", component: userlastentry,name:"User Last Entry"},

   
    { index: 18, path: "/logout", component: logout,name:"logout" },
    { index: 19,  path: "/login", component: login  ,name:"login"},
    { index: 20,  path: "/role", component: role  ,name:"Role"},
    { index: 21, path: "/userpermission", component: userpermission  ,name:"Access Permissions"},
    { index: 22, path: "/changepassword", component: changepassword  ,name:"changepassword"},
    { index: 23,  path: "/updatedatabasestructure", component: updatedatabasestructure  ,name:"updatedatabasestructure"},
    { index: 24, path: "/errorlog", component: errorlog  ,name:"errorlog"},
    { index: 25, path: "/operativecost", component: operativecost  ,name:"Operative Cost"},
    { index: 26, path: "/projectsummary", component: projectsummary, name:"Project Summary"},
    { index: 27, path: "/overheadsummary", component: overheadsummary  ,name:"Overheads - TSA"},
    { index: 27, path: "/personalsummary", component: personalsummary  ,name:"Personal Summary"},
    { index: 28, path: "/internationalpayments", component: internationalpayments  ,name:"International Payments"},    
    { index: 29, path: "/salesquotation", component: salesquotation, name:"Sales - Quotation Form"},
    { index: 30, path: "/salesinvoice", component: salesinvoice, name:"Sales - Invoicing Form"},
    { index: 31, path: "/outsourceinvoice", component: outsourceinvoice, name:"Outsource Invoice"},
    { index: 32, path: "/feerates", component: feerates, name:"Fee Table"},
    { index: 33, path: "/newrole", component: newrole, name:"New Role"},
    { index: 34, path: "/pages", component: pages, name:"Pages"},
    { index: 35, path: "/accesssignoff", component: accesssignoff, name:"Access - Sign Off"},
    { index: 36, path: "/trainingpopup", component: trainingpopup, name:"Training Popup"},
    { index: 37, path: '/updateversions', component: updateversions, name: 'Time Tracking Versions' },
    { index: 38, path: '/directionlibrary', component: directionlibrary, name: 'Direction Library' },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});


// function requireAuth(to, from, next) {
//     const { $cookies } = router.app.config.globalProperties;
//     next();
//   }
// router.beforeEach = requireAuth;

router.beforeEach((to, from, next) =>{
    store.state.currentdirection = {};
      const accessrouter = (rout) => {
        if (rout.name == "home" || rout.name == "login" || rout.name == "logout" || rout.name == "changepassword" || rout.name == "TimeEntry") {
            return true;
        }
        return false;
      }
    if(to.name == undefined){
        to.name = to.fullPath
    }
    var token = localStorage.getItem("token");
    
    if (token != null) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        if (store.state.user.id == undefined) {
            axios.post("account/getuserdetails")
                .then(response => {
                    
                    store.state.user = response.data;
                    var user = store.state.user;
                    eventbus.$emit("setuser", user);
                    axios.defaults.headers.common["Authorization"] = response.headers.authorization;

                    var role = user.userrole.rolepermissionpages.find(x => x.p.name === to.name && x.rp.permission != 0);                    
                    let setusergotit = { currentpage: role, currentroute: to, pagecellname: null };
                    eventbus.$emit("userguidegotit", setusergotit);
                    eventbus.$emit("connectWebSocket");   
                    if (accessrouter(to)) {
                        store.state.role = { p: {}, rp: { permission:2 }};
                        return next();
                    }
                    store.state.role = null;
                    if (user.superadmin == true) {
                        store.state.role = { p: {}, rp: { permission:2 }};
                        return next();
                    }
                    if (role) {
                        store.state.role = role;
                        if (role.p.status > 0) {
                            eventbus.$emit("pagestatus", role);
                        }
                        return next();
                    }
                    else {
                        return router.push({name: 'home',path:'/'});
                    }
                })
                .catch(() => {
                    eventbus.$emit("logout", null);
                });
        }
        else {
            var user = store.state.user;
            var rout = user.userrole.rolepermissionpages.find(x => x.p.name === to.name && x.rp.permission != 0);
            let setusergotit = { currentpage: rout, currentroute: to, pagecellname: null };
            eventbus.$emit("userguidegotit", setusergotit);
            
            if (accessrouter(to)) {
                store.state.role = { p: {}, rp: { permission:2 }};
                return next();
            }
            store.state.role = null;
            if (user.superadmin == true) {
                store.state.role = { p: {}, rp: { permission:2 }};
                return next();
            }
            eventbus.$emit("connectWebSocket");
            if (rout) {
                store.state.role = rout;
                if (rout.p.status > 0) {
                    eventbus.$emit("pagestatus", rout);
                }
                return next();
            }
            else {
                return router.push({name: 'home',path:'/'});
            }
            
        }
    }
    else {
        if (to.path !== "/login") {
            next("/login");
        }
        else {
            next();
        }
    }
});

export default router;