<template>
    <div>
        <transition name="fade" mode="in-out">
            <div ref="whiteBackground_popup" id="whiteBackground_popup" :draggable="isdraggble" class="draggable"
                v-if="guidancedialogue.visible">
                <div class="row cursorstylemove" @mouseover="isdraggble = true">
                </div>

                <div v-for="(gditem, index) in guidancedatalist" :key="index">
                    <div class="row cursorstylemove marginb3px" v-if="index == currentindex" @mouseover="isdraggble = true">
                        <span class="fontsize24px" v-if="!isedited">{{ gditem.popupname }}</span>
                        <span class="fontsize24px" v-else>Set Position</span>
                        <el-button class="glyphicon glyphicon-circle-arrow-up arrowiconstyle cursorstylemove"
                            :style="'transform: rotate(' + gditem.directiondegree + 'deg);'" size="default"
                            circle></el-button>
                    </div>

                    <div class="row flex" v-if="index == currentindex">
                        <div class="column1" @mouseover="isdraggble = false">
                            <p class="breakword" v-html="gditem.content"></p>
                        </div>

                        <div class="column2" @mouseover="isdraggble = false"
                            :style="'background-color: ' + gditem.content2">
                            <div v-for="svi in gditem.mediacontents" :key="svi.id"
                                style="display: flex; align-items: center; justify-content: center; height: 100%;">
                                <span
                                    v-if="svi.name.substring(svi.name.lastIndexOf('.') + 1).toLowerCase() == 'jpg' || svi.name.substring(svi.name.lastIndexOf('.') + 1).toLowerCase() == 'jpeg' || svi.name.substring(svi.name.lastIndexOf('.') + 1).toLowerCase() == 'gif' || svi.name.substring(svi.name.lastIndexOf('.') + 1).toLowerCase() == 'png'">
                                    <div class="image-wrapper">
                                        <el-image :preview-src-list="[svi.link]" :src="svi.link" class="previewbox"
                                            style="border: 1px solid; width: 250px; height: auto;" />
                                        <span class="fullscreen-icon" @click="openImage(svi.link)">
                                            <span>
                                                <el-icon>
                                                    <FullScreen :size="10" />
                                                </el-icon>
                                            </span>
                                        </span>
                                    </div>
                                </span>

                                <span v-if="svi.name.substring(svi.name.lastIndexOf('.') + 1).toLowerCase() == 'mp4'">
                                    <video controls class="previewbox" style="margin-bottom: 8px;">
                                        <source :src="svi.link" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row margintop5px" v-if="index == currentindex">
                        <el-button @click="changepage('leftarrow', gditem, index)" type="info" icon="arrow-left" circle
                            :class="`leftslidearrow${!hasPreviousContent ? ' disabled' : ''}`" :disabled="index == 0">
                        </el-button>

                        <span v-if="isedited">
                            <el-select v-model="gditem.positiontype" @change="changePositionType(gditem)"
                                placeholder="Select Position" class="width100px marginleft40px">
                                <el-option v-for="item in boxpositions" :key="item.label" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>

                            &nbsp; X: <el-input v-model="gditem.positionX" class="positionbtnstyle disinput"
                                disabled></el-input>
                            Y: <el-input v-model="gditem.positionY" class="positionbtnstyle disinput" disabled></el-input>

                            <span> <el-button type="success" @click="saveGuidanceBoxPositionData"
                                    class="marginleft20px">Save</el-button></span>
                        </span>

                        <span v-else>
                            <el-button type="success" @click="dlgClose" class="marginleft130px">Remind Me Later</el-button>
                            <el-button type="success" @click="usergotitguidance">Got it</el-button>
                        </span>

                        <el-button @click="changepage('rightarrow', gditem, index)" type="info" icon="arrow-right" circle
                            :class="`rightslidearrow${!hasNextContent ? ' disabled' : ''}`"
                            :disabled="index == guidancedatalist.length - 1">
                        </el-button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { eventbus } from "../eventbus";
export default {
    props: {
        guidancedialogue: { visible: Boolean, pageid: Number }
    },
    data() {
        return {
            guidancepagelist: [],
            guidancesubpagelist: [],
            pagecellname: null,
            pagelist: [],
            pageid: null,
            guidancedatalist: [],
            currentindex: 0,
            ugrdatalist: [],
            isdraggble: true,
            boxpositions: [
                { label: 'Top left', value: 1 },
                { label: 'Top right', value: 2 },
                { label: 'Bottom left', value: 3 },
                { label: 'Bottom right', value: 4 }
            ],
            currentpositiontype: 1,
            // positionX: 630,
            // positionY: 400,
            boxdisplaytype: 1
        };
    },

    computed: {
        isedited() {
            return this.$store.state.newfeaturedialgoue.editedialgoue;
        },

        positionX: {
            get() {
                return this.guidancedatalist[this.currentindex].positionX;
            },
            set(value) {
                this.guidancedatalist[this.currentindex].positionX = value;
            },
        },
        positionY: {
            get() {
                return this.guidancedatalist[this.currentindex].positionY;
            },
            set(value) {
                this.guidancedatalist[this.currentindex].positionY = value;
            },
        },

        hasPreviousContent() {
            return this.currentindex > 0;
        },

        hasNextContent() {
            return this.currentindex < this.guidancedatalist.length - 1;
        }
    },

    methods: {
        setboxpositionbyxandy() {
            this.positionX = this.guidancedatalist[this.currentindex].positionX;
            this.positionY = this.guidancedatalist[this.currentindex].positionY;
            this.currentpositiontype = this.guidancedatalist[this.currentindex].positiontype;
            if (this.positionX == 0 && this.positionY == 0) {
                this.positionX = 630;
                this.positionY = 400;
            }
            if (!this.currentpositiontype) {
                this.guidancedatalist[this.currentindex].positiontype = 1;
                this.currentpositiontype = 1;
            }
            if (this.currentpositiontype) {
                this.setBoxPositionByType(this.currentpositiontype, this.positionX, this.positionY);
            } else {
                this.currentpositiontype = 1;
                this.$refs.whiteBackground_popup.style.left = this.positionX + "px";
                this.$refs.whiteBackground_popup.style.top = this.positionY + "px";
            }
        },
        getboxpositionbyid(id) {
            var chickvalues = document.getElementById(id);
            if (chickvalues != null) {
                var topPos = chickvalues.getBoundingClientRect();
                if (topPos != null) {
                    const self = this;
                    self.$nextTick(() => {
                        var dd = this.getboxposition(topPos);
                        self.$refs['whiteBackground_popup'].style.left = dd.left;
                        self.$refs['whiteBackground_popup'].style.right = dd.right;
                        self.$refs['whiteBackground_popup'].style.top = dd.top;
                        self.$refs['whiteBackground_popup'].style.bottom = dd.bottom;
                    })
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        getboxposition(pos) {
            const returnposition = {};
            returnposition.left = "";
            returnposition.right = "";
            returnposition.top = "";
            returnposition.bottom = "";
            returnposition.x = 0;
            returnposition.y = 0;
            const position = pos;
            // Calculate the element's right, bottom, left, and top positions
            const right = position.right + 264;
            const bottom = position.bottom + 180;
            // const left = position.left + 264;
            // const top = position.top + 180;

            // Get the dimensions of the window
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            if (right > windowWidth || bottom > windowHeight) {
                // Adjust the element's position to keep it within the screen boundaries
                if (right > windowWidth) {
                    returnposition.left = (position.left - 264) + 'px';
                    returnposition.top = bottom + 'px';
                }

                if (bottom > windowHeight) {
                    returnposition.top = (position.top - 180) + "px";
                    returnposition.left = right + "px";
                }
            } else {
                // Set the element's original position
                returnposition.top = bottom + 'px';
                returnposition.left = right + 'px';
            }

            if (windowWidth > position.x + 528) {
                returnposition.x = position.x + 264;
            } else {
                returnposition.x = position.x - 264;
            }

            if (windowHeight > position.y + 360) {
                returnposition.y = position.y + 180
            } else {
                returnposition.y = position.y - 180
            }
            return returnposition;
        },

        getUserGuidanceReminder: function () {
            this.showloading();
            this.ugrdatalist = [];
            this.axios.post("training/getUserGuideReminder?pageid=" + this.guidancedialogue.pageid + '&displaytype=' + this.boxdisplaytype, {})
                .then(response => {
                    const currentrouter = this.$route;
                    this.guidancepagelist = response.data.filter(x => x.p.name == currentrouter.name);
                    this.guidancesubpagelist = response.data.filter(x => x.p.name != currentrouter.name);
                    this.guidancedatalist = this.guidancepagelist;
                    if (this.guidancedatalist.length > 0) {
                        this.guidancedatalist = this.guidancedatalist.sort((a, b) => a.trainingnumber - b.trainingnumber);
                        this.guidancedialogue.visible = true;
                        this.$nextTick(() => {
                            this.guidancedatalist.forEach(gditem => {
                                gditem.metadata = { content: gditem.content };

                                let ugritem = { id: 0, guidanceid: gditem.id, userid: this.$store.state.user.id, status: true };
                                this.ugrdatalist.push(ugritem);
                            });
                            if (this.currentindex >= 0) {
                                if (this.pagecellname == null) {
                                    this.setboxpositionbyxandy();
                                } else {
                                    var isset = this.getboxpositionbyid(this.guidancedatalist[this.currentindex].pagecell.cellname);
                                    if (isset == false) {
                                        this.setboxpositionbyxandy();
                                    }
                                }
                            } else {
                                this.guidancedialogue.visible = false;
                            }
                        });
                    }
                    if (this.pagecellname != null) {
                        this.currentindex = this.guidancedatalist.findIndex(item => item.pagecell != null && item.pagecell.cellname == this.pagecellname);
                        if (this.currentindex < 0) {
                            this.guidancedatalist = this.guidancesubpagelist;
                            this.currentindex = this.guidancedatalist.findIndex(item => item.pagecell != null && item.pagecell.cellname == this.pagecellname);
                        }
                    } else {

                        this.currentindex = 0;
                    }

                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.apierror(err);
                });
        },

        changepage: function (slide, row, index) {
            if (slide == "leftarrow") {
                this.currentindex = index - 1;
            } else if (slide == "rightarrow") {
                this.currentindex = index + 1;
            }
            this.currentpositiontype = this.guidancedatalist[this.currentindex].positiontype;
            this.positionX = this.guidancedatalist[this.currentindex].positionX;
            this.positionY = this.guidancedatalist[this.currentindex].positionY;

            if (this.currentpositiontype == 0) {
                this.currentpositiontype = 1;
                this.guidancedatalist[this.currentindex].positiontype = 1;
            }

            if (this.positionX == 0 && this.positionY == 0) {
                this.positionX = 630;
                this.positionY = 400;
            }

            this.setBoxPositionByType(this.currentpositiontype, this.positionX, this.positionY);
        },

        dlgClose: function () {
            this.guidancedialogue.visible = false;
        },

        usergotitguidance: function () {
            this.guidancedialogue.visible = false;
            if (this.boxdisplaytype == 1) {
                this.showloading();
                this.axios.post("training/userGotItGuidance", this.ugrdatalist)
                    .then(() => {
                        this.guidancedialogue.visible = false;
                        this.hideloading();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.apierror(err);
                    });
            }
        },

        saveGuidanceBoxPositionData: function () {
            let guidancedata = { guidancedatalist: this.guidancedatalist };
            let formdata = this.jsonToFormData(guidancedata);
            this.showloading();

            this.axios.post("training/saveGuidanceData", formdata)
                .then(response => {
                    this.$message({
                        type: "success",
                        showClose: true,
                        message: response.data.message
                    });
                    this.getUserGuidanceReminder();
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.apierror(err);
                });
        },

        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        },

        jsonToFormData(data) {
            const formData = new FormData();
            this.buildFormData(formData, data);
            return formData;
        },

        changePositionType(row) {
            this.currentpositiontype = row.positiontype;
            this.setBoxPositionByType(row.positiontype, row.positionX, row.positionY);
        },

        setBoxPositionByType(positiontype, positionX, positionY) {
            let width = 528;
            let height = 360;
            if (document.getElementById("whiteBackground_popup")) {
                width = positionX + document.getElementById("whiteBackground_popup").offsetWidth;
                height = positionY + document.getElementById("whiteBackground_popup").offsetHeight;
            }

            this.$refs.whiteBackground_popup.style.left = "";
            this.$refs.whiteBackground_popup.style.top = "";
            this.$refs.whiteBackground_popup.style.right = "";
            this.$refs.whiteBackground_popup.style.bottom = "";

            if (positiontype == 1) {
                this.$refs.whiteBackground_popup.style.left = positionX + "px";
                this.$refs.whiteBackground_popup.style.top = positionY + "px";

            } else if (positiontype == 2) {
                this.$refs.whiteBackground_popup.style.right = (window.innerWidth - width) + "px";
                this.$refs.whiteBackground_popup.style.top = positionY + "px";

            } else if (positiontype == 3) {
                this.$refs.whiteBackground_popup.style.left = positionX + "px";
                this.$refs.whiteBackground_popup.style.bottom = (window.innerHeight - height) + "px";

            } else {
                this.$refs.whiteBackground_popup.style.right = (window.innerWidth - width) + "px";
                this.$refs.whiteBackground_popup.style.bottom = (window.innerHeight - height) + "px";
            }
        },

        popup_drag_start(event) {
            var style = window.getComputedStyle(event.target, null);
            var newstr = "popup_drag," +
                (parseInt(style.getPropertyValue("left"), 10) - event.clientX) +
                "," +
                (parseInt(style.getPropertyValue("top"), 10) - event.clientY)
            event.dataTransfer.setData("text/plain", newstr);
        },

        popup_drag_over(event) {
            event.preventDefault();
            return false;
        },

        popup_drop(event) {
            var offset = event.dataTransfer.getData("text/plain").split(",");
            if (offset[0] == "popup_drag") {
                this.positionX = event.clientX + parseInt(offset[1], 10);
                this.positionY = event.clientY + parseInt(offset[2], 10);
                this.setBoxPositionByType(this.currentpositiontype, this.positionX, this.positionY);
                event.preventDefault();
            }
            return false;
        },

        makeDraggable() {
            var dm = this.$refs.whiteBackground_popup;
            if (dm) {
                dm.addEventListener("dragstart", this.popup_drag_start, false);
                window.addEventListener("dragover", this.popup_drag_over, false);
                window.addEventListener("drop", this.popup_drop, false);
            }
        },

        heightCount() {
            if (this.height <= 318.5 && this.height != 0) {
                this.verticalcenter = true;
            } else {
                this.verticalcenter = false;
            }
        },

        openImage(imageUrl) {
            const imageElement = new Image();
            imageElement.src = imageUrl;

            imageElement.onload = () => {
                const containerElement = document.createElement('div');
                containerElement.style.display = 'flex';
                containerElement.style.justifyContent = 'center';
                containerElement.style.alignItems = 'center';
                containerElement.style.width = '100vw';
                containerElement.style.height = '100vh';
                containerElement.style.position = 'fixed';
                containerElement.style.top = '0';
                containerElement.style.left = '0';
                containerElement.style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
                containerElement.style.zIndex = '9999';

                const fullScreenImageElement = new Image();
                fullScreenImageElement.src = imageUrl;
                fullScreenImageElement.style.maxWidth = '90vw';
                fullScreenImageElement.style.maxHeight = '90vh';
                fullScreenImageElement.style.objectFit = 'contain';

                const closeIconElement = document.createElement('span');
                closeIconElement.classList.add('close-icon');
                closeIconElement.innerHTML = '&times;';

                containerElement.appendChild(closeIconElement);
                containerElement.appendChild(fullScreenImageElement);
                document.body.appendChild(containerElement);

                const closeFullscreen = () => {
                    document.body.removeChild(containerElement);
                };

                closeIconElement.addEventListener('click', closeFullscreen);
                fullScreenImageElement.addEventListener('click', closeFullscreen);
            };
        }
    },

    watch: {
        'guidancedialogue.visible'() {
            if (this.guidancedialogue.visible) {
                this.$nextTick(this.makeDraggable);
                this.$nextTick(this.heightCount);
            }
        }
    },

    created() {
        this.$nextTick(this.makeDraggable);
        this.$nextTick(this.heightCount);
    },

    mounted() {
        if (this.$store.state.pages.length == 0 && this.$store.state.user.id != undefined) {
            this.getPages();
        }
        eventbus.$on('userguidegotit', (para) => {
            let currentpage = para.currentpage;
            let currentrouter = para.currentroute;
            this.pagecellname = para.pagecellname;
            if (currentrouter.params && currentrouter.params.showguidancedialog) {
                this.boxdisplaytype = 2;
            } else {
                this.boxdisplaytype = 1;
            }

            if (this.$store.state.newfeaturedialgoue.params && this.$store.state.newfeaturedialgoue.params.openediteddialogue) {
                this.boxdisplaytype = 2;
            } else {
                this.$store.state.newfeaturedialgoue.editedialgoue = false;
            }
            this.$store.state.newfeaturedialgoue.params = {};

            if (!currentpage) {
                currentpage = this.$store.state.pages.find(pageitem => pageitem.p.name == currentrouter.name);
            }
            this.guidancedialogue.visible = false;
            if (currentpage && currentpage.p) {
                this.guidancedialogue.pageid = currentpage.p.id;
                this.getUserGuidanceReminder()
            }
        });
    },
};
</script>

<style scoped>
#whiteBackground_popup {
    padding: 4px 10px 4px 10px;
    background-color: #e1e1e1;
    border: 1px solid;
    width: 528px;
    height: 360px;
    position: fixed;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 1200;
    align-items: center;
    resize: none;
}

.column1 {
    float: left;
    width: 250px;
    padding: 10px;
    height: 275px;
    border: 1px solid;
    background-color: white;
    overflow: auto;
}

.column2 {
    float: left;
    width: 250px;
    height: 275px;
    padding: 10px;
    border: 1px solid;
    background-color: white;
    margin-left: 8px;
    overflow: auto;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

#dlgbox {
    position: fixed;
    width: 90%;
    height: 50%;
    margin: auto;
    background-color: lightgray;
    z-index: 1;
    border-radius: 25px;
}

.dlg-body {
    background-color: lightgray;
    color: black;
    font-size: 14px;
    padding-left: 58px;
    border: 4px solid black;
    min-height: 90%;
    border-radius: 25px;
}

.newimg {
    bottom: 0;
    position: absolute;
    left: 0;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 38%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.el-checkbox.is-bordered.el-checkbox--mini {
    padding: 4px 8px 7px 8px;
    border-radius: 3px;
    height: 26px;
    border: 1px solid black;
}

.warnfooter {
    font-size: 18px;
    color: darkred;
    font-weight: bold;
}

.close-button {
    float: right;
    position: sticky;
    top: 3px;
    right: 3px;
}

p {
    margin: 0 0 0px;
}

.heading {
    font-family: "Calibri", "Comic Sans MS", "Times New Roman", "Arial";
    font-size: 17px;
}

.fade-enter-active {
    animation: fadein 0.5s;
    animation-iteration-count: 1;
}

.fade-leave-active {
    animation: fadeout 0.7s;
    animation-iteration-count: 1;
}

.margin10px0px {
    margin: 10px 0px;
}

.leftslidearrow {
    float: left;
    height: 25px;
    width: 25px;
    cursor: pointer;
    border: 1px solid;
    font-size: 15px;
}

.rightslidearrow {
    float: right;
    height: 25px;
    width: 25px;
    cursor: pointer;
    border: 1px solid;
    font-size: 15px;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
</style>
  