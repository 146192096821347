<template>
    <div>
        <div v-if="!details" class="salestablewidth">
            <!-- <el-affix :offset="50"> -->
                <div class="colorwhite stickyheader">
                    <el-row>
                        <el-col :span="10">
                            <div class="headerstyle">{{ page }}</div>
                        </el-col>
                        <el-col :span="9" class="margint8">
                            <el-pagination layout="prev, pager, next"
                                           :total="view.count"
                                           @current-change="refresh"
                                           :page-size="view.pagesize"
                                           v-model:currentPage="view.pageno">
                            </el-pagination>
                        </el-col>
                        <el-col :span="5">
                            <div class="floatright margint8">
                                <span>
                                    <el-button type="primary"
                                               @click="addrow()"
                                               icon="circle-plus-filled"
                                               :disabled="!editpermission"></el-button>
                                </span>
                            </div>
                        </el-col>
                    </el-row>
                    <hr class="spaceBetween" />
                </div>
            <!-- </el-affix> -->
            <div>
                <el-table :border="true"
                          :stripe="true"
                          :data="detailform"
                          @sort-change="sortchange"
                          :default-sort="{prop: 'p.name', order: 'descending'}">
                    <el-table-column prop="p.name"
                                     sortable="custom"
                                     label="Project Name"
                                     fixed="left" width="350">
                                     <template #default="scope">
                                       <div class="floatleft width40px">
                                        <el-button type="primary"
                                              class="padding7"
                                              icon="edit-pen"
                                              @click="editrow(scope.row)"
                                              :disabled="!editpermission"></el-button>
                                       </div>
                                        <div class="floatleft"> {{scope.row.p.name}}</div>
                                     </template>
                    </el-table-column>

                    <el-table-column prop="si.invoicevalue"
                                     label="Invoice Value"
                                     width="90">
                    </el-table-column>

                    <el-table-column label="Project Cost" align="center">
                        <el-table-column prop="si.basecost"
                                         label="Base"
                                         width="70">
                        </el-table-column>
                        <el-table-column prop="si.averagecost"
                                         label="Average"
                                         width="70">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column label="Projected Margin" align="center">
                        <el-table-column prop="si.basemargin"
                                         label="Base"
                                         width="70">
                        </el-table-column>
                        <el-table-column prop="si.averagemargin"
                                         label="Average"
                                         width="70">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column label="Percentage %" align="center">
                        <el-table-column prop="si.basepercentage"
                                         label="Base"
                                         width="70">
                        </el-table-column>
                        <el-table-column prop="si.averagepercentage"
                                         label="Average"
                                         width="70">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column prop="si.totalhours" label="Hours" width="60"></el-table-column>

                    <el-table-column label="Quotation Submitted" prop="si.quotationsubmitted" width="70">
                    </el-table-column>

                    <el-table-column label="Over Allocated" align="center">
                        <el-table-column prop="si.winhours"
                                         label="Hours"
                                         width="60">
                        </el-table-column>
                        <el-table-column prop="si.basewin"
                                         label="Base"
                                         width="70">
                        </el-table-column>
                        <el-table-column prop="si.averagewin"
                                         label="Average"
                                         width="70">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column label="Under Allocated" align="center">
                        <el-table-column prop="si.losehours"
                                         label="Hours"
                                         width="60">
                        </el-table-column>
                        <el-table-column prop="si.baselose"
                                         label="Base"
                                         width="70">
                        </el-table-column>
                        <el-table-column prop="si.averagelose"
                                         label="Average"
                                         width="70">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column label="Gifted Hours" align="center">
                        <el-table-column prop="si.gifthours"
                                         label="Hours"
                                         width="60">
                        </el-table-column>
                        <el-table-column prop="si.basegift"
                                         label="Base"
                                         idth="70">
                        </el-table-column>
                        <el-table-column prop="si.averagegift"
                                         label="Average"
                                         width="70">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column prop="si.basecharge" label="Charged Hours" width="60"></el-table-column>

                    <!-- <el-table-column prop="si.basecharge" label="Charged Hours" width="100">
                        <el-table-column prop="si.basecharge"
                                         label="Base"
                                         width="70">
                        </el-table-column>
                        <el-table-column prop="si.averagecharge"
                                         label="Average"
                                         width="70">
                        </el-table-column>
                    </el-table-column> -->

                    <el-table-column label="Quotation Variation" align="center">
                        <el-table-column prop="si.basequotationvariation"
                                         label="Base"
                                         width="70">
                        </el-table-column>
                        <el-table-column prop="si.averagequotationvariation"
                                         label="Average"
                                         width="70">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column label="Gain / Loss" align="center">
                        <el-table-column prop="si.basegainloss"
                                         label="Base"
                                         width="70">
                        </el-table-column>
                        <el-table-column prop="si.averagegainloss"
                                         label="Average"
                                         width="70">
                        </el-table-column>
                    </el-table-column>

                    <el-table-column prop="si.comments"
                                     label="Comment"
                                     width="290">
                    </el-table-column>

                    <el-table-column prop="si.checklistcompleted"
                                     label="Checklist Completed"
                                     align="center"
                                     width="73">
                        <template #default="scope">
                            <el-checkbox disabled
                                         v-model="scope.row.si.checklistcompleted">
                            </el-checkbox>
                        </template>
                    </el-table-column>

                    <el-table-column prop="si.isinvoiced"
                                     label="Invoiced"
                                     align="center"
                                     width="70">
                        <template #default="scope">
                            <el-checkbox disabled
                                         v-model="scope.row.si.isinvoiced">
                            </el-checkbox>
                        </template>
                    </el-table-column>

                    <el-table-column prop="si.modifiedon"
                                     :formatter="dateformatter"
                                     label="Completed On"
                                     width="109">
                    </el-table-column>

                    <el-table-column prop="u.loginname"
                                     label="Author"
                                     width="100">
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div v-if="details" class="salesforms">
            <!-- <el-affix :offset="50"> -->
                <div class="colorwhite stickyheader">
                    <el-row>
                        <el-col :span="15">
                            <div class="headerstyle">{{ page }}</div>
                        </el-col>
                        <el-col :span="9">
                            <div class="floatright margint8">
                                <span>
                                    <el-button type="success"
                                               @click="save"
                                               icon="circle-check"
                                               v-loading.fullscreen.lock="savinginvoice"
                                               :disabled="!editpermission">Save</el-button>
                                </span>
                                <span>
                                    <el-button type="info"
                                               @click="resetform"
                                               class="marginleft5"
                                               icon="back">Back</el-button>
                                </span>
                            </div>
                        </el-col>
                    </el-row>
                    <hr class="spaceBetween" />
                </div>
            <!-- </el-affix> -->

            <div>
                <el-form :model="form" label-position="left">
                    <div class="spaceBetween" />
                    <el-form-item>
                        <span class="spancolor">Commercial Review</span>
                    </el-form-item>

                    <el-form-item label="Project Name" label-width="120px">
                        <el-select v-bind:style="{ width: '329px' }"
                                   v-model="form.si.projectid"
                                   placeholder="Project Name"
                                   :clearable="true"
                                   :filterable="true"
                                   popper-class="customselect"
                                   @change="selectproject(form.si.projectid, true)">
                            <el-option v-for="item in projectoptions"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                                <span>{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="spaceBetween" />

                    <!-- <el-form-item label="Invoice Value" label-width="196px">
                        <el-input v-model="form.si.invoicevaluestr"
                                  @blur="currencyblur(form, 'invoicevalue', $event)"
                                  @focus="currencyfocus(form, 'invoicevalue')"
                                  @change="editedchange(true)"
                                  class="salesinputwidth85 GBPcosttext" />

                        <el-input v-model="form.si.invoicevaluestr" class="salesinputwidth85 GBPcosttext readonlyinput marginleft20px" :disabled="true" />
                    </el-form-item>
                    <div class="spaceBetween" /> -->

                    <el-row>
                        <div class="left">
                            <el-form-item label-width="130px">
                                <!-- <span class="spancolor">Hours</span> -->
                                <br>
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item label="Invoice Value" label-width="120px">
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item label="Cost" label-width="176px">
                                <!-- <el-input v-model="form.si.totalhours"
                                          class="saleshours55 GBPcosttext readonlyinput"
                                          :disabled="true" /> -->
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item label="Projected Margin" label-width="120px">
                            </el-form-item>
                        </div>

                        <div class="left marginleft20px">
                            <el-form-item label-width="4px">
                                <span class="spancolor bold">Cost+ Based</span>
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.invoicevaluestr"
                                        @blur="currencyblur(form, 'invoicevalue', $event)"
                                        @focus="currencyfocus(form, 'invoicevalue')"
                                        @change="editedchange(true)"
                                        :class="form.si.invoicevalue < 0 ? 'salesinputwidth85 GBPcosttext inputtextred' : 'salesinputwidth85 GBPcosttext'" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.basecoststr"
                                          :class="form.si.basecost < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.basemarginstr"
                                          :class="form.si.basemargin < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <span :class="form.si.basepercentage < 0 ? 'widthlabel77 red' : 'widthlabel77'">{{ form.si.basepercentage }} %</span>
                            </el-form-item>
                        </div>

                        <div class="left marginleft20px">
                            <el-form-item label-width="4px">
                                <span class="spancolor bold">Fee Based</span>
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.invoicevaluestr" :class="form.si.invoicevalue < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'" :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.averagecoststr"
                                          :class="form.si.averagecost < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.averagemarginstr"
                                          :class="form.si.averagemargin < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <span :class="form.si.averagepercentage < 0 ? 'widthlabel77 red' : 'widthlabel77'">{{ form.si.averagepercentage }} %</span>
                            </el-form-item>
                        </div>
                    </el-row>

                    <div class="fontsize14px spancolor bold marginbottom12px">Quotation Analysis</div>
                    <el-row>
                        <div class="left">
                            <el-form-item label="Hours(Actual / Base)" label-width="175px">
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item label="Hourly Rate" label-width="175px">
                            </el-form-item>
                        </div>

                        <div class="left marginleft20px">
                            <el-form-item>
                                <el-input v-model="form.si.totalhours"
                                          class="salesinputwidth85 GBPcosttext readonlyinput"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.actualhourlyratestr"
                                          class="salesinputwidth85 GBPcosttext readonlyinput"
                                          :disabled="true" />
                            </el-form-item>
                        </div>

                        <div class="left marginleft20px">
                            <el-form-item>
                                <el-input v-model="form.si.totalhours2"
                                          class="salesinputwidth85 GBPcosttext readonlyinput"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.basehourlyratestr"
                                          class="salesinputwidth85 GBPcosttext readonlyinput"
                                          :disabled="true" />
                            </el-form-item>
                        </div>
                    </el-row>
                    <hr>

                    <el-row>
                        <div class="left">
                            <!-- <el-form-item label-width="130px">
                                <span class="spancolor">Hours</span>
                            </el-form-item>
                            <div class="spaceBetween" /> -->

                            <el-form-item label="Quotation Submitted" label-width="142px">
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item label="Over Allocated" label-width="120px">
                                <el-input-number v-model="form.si.winhours"
                                                 @change="calculateprojectmargin(form, true)"
                                                 :controls="false"
                                                 :class="form.si.winhours < 0 ? 'saleshours55 GBPcosttext inputtypenumlr6px inputtextred' : 'saleshours55 GBPcosttext inputtypenumlr6px'" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item label="Under Allocated" label-width="120px">
                                <el-input-number v-model="form.si.losehours"
                                                 @change="calculateprojectmargin(form, true)"
                                                 :controls="false"
                                                 class="saleshours55 GBPcosttext inputtypenumlr6px inputtextred" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item label="Charged Hours" label-width="120px">
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item label="Gifted Hours" label-width="120px">
                                <el-input-number v-model="form.si.gifthours"
                                                 @change="calculateprojectmargin(form, true)"
                                                 :controls="false"
                                                 class="saleshours55 GBPcosttext inputtypenumlr6px inputtextred" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item label="Quotation Variation" label-width="142px">
                            </el-form-item>
                            <div class="spaceBetween" />                           

                            <el-form-item class="fontsize14px">
                                <span class="fontsize14px spancolor bold">Gain / Loss</span>
                            </el-form-item>
                        </div>

                        <div class="left marginleft20px">
                            <!-- <el-form-item label-width="4px">
                                <span class="spancolor bold">Cost Based</span>
                            </el-form-item>
                            <div class="spaceBetween" /> -->

                            <el-form-item>
                                <el-input v-model="form.si.quotationsubmittedstr"
                                          :class="form.si.quotationsubmitted < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.basewinstr"
                                          :class="form.si.basewin < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.baselosestr" class="salesinputwidth85 GBPcosttext readonlyinput inputtextred" :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.basechargestr"
                                          @blur="currencyblur(form, 'basecharge', $event)"
                                          @focus="currencyfocus(form, 'basecharge')"
                                          @change="editedchange(true)"
                                          :class="form.si.basecharge < 0 ? 'salesinputwidth85 GBPcosttext inputtextred' : 'salesinputwidth85 GBPcosttext'" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.basegiftstr" class="salesinputwidth85 GBPcosttext readonlyinput inputtextred" :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.basequotationvariationstr"
                                          :class="form.si.basequotationvariation < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.basegainlossstr"
                                          :class="form.si.basegainloss < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                        </div>

                        <div class="left marginleft20px">
                            <!-- <el-form-item label-width="4px">
                                <span class="spancolor bold">Value Based</span>
                            </el-form-item>
                            <div class="spaceBetween" /> -->

                            <el-form-item>
                                <el-input v-model="form.si.quotationsubmittedstr"
                                          :class="form.si.quotationsubmitted < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />                            

                            <el-form-item>
                                <el-input v-model="form.si.averagewinstr"
                                          :class="form.si.averagewin < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.averagelosestr" class="salesinputwidth85 GBPcosttext readonlyinput inputtextred" :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.basechargestr" :class="form.si.basecharge < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'" :disabled="true" />
                                <!-- <el-input v-model="form.si.averagechargestr"
                                          @blur="currencyblur(form, 'averagecharge', $event)"
                                          @focus="currencyfocus(form, 'averagecharge')"
                                          @change="editedchange(true)"
                                          :class="form.si.averagecharge < 0 ? 'salesinputwidth85 GBPcosttext inputtextred' : 'salesinputwidth85 GBPcosttext'" /> -->
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.averagegiftstr" class="salesinputwidth85 GBPcosttext readonlyinput inputtextred" :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.averagequotationvariationstr"
                                          :class="form.si.averagequotationvariation < 0 ? 'salesinputwidth85 GBPcosttext readonlyinput inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                            <div class="spaceBetween" />

                            <el-form-item>
                                <el-input v-model="form.si.averagegainlossstr"
                                          :class="form.si.averagegainloss < 0 ? 'salesinputwidth85 GBPcosttext inputtypenumlr6px inputtextred' : 'salesinputwidth85 GBPcosttext readonlyinput'"
                                          :disabled="true" />
                            </el-form-item>
                        </div>
                    </el-row>

                    <div class="spaceBetween" />
                    <el-form-item label="Comment" label-width="120px">
                        <el-input type="textarea"
                                  v-model="form.si.comments"
                                  @change="editedchange(true)"
                                  :autosize="{ minRows: 3, maxRows: 4 }"
                                  placeholder="Comment" />
                    </el-form-item>

                    <div class="spaceBetween" />
                    <el-form-item label="Checklist Completed" label-width="160px">
                        <el-checkbox v-model="form.si.checklistcompleted"
                                     @change="editedchange(true)">
                        </el-checkbox>
                        <span class="paddingleft50">
                            <strong>Saved:</strong><span v-if="form.si.id > 0">{{form.u.loginname}} {{lastedited}}</span>
                        </span>
                    </el-form-item>

                    <!-- <div class="spaceBetween" /> -->
                    <el-form-item label="Invoiced" label-width="160px">
                        <el-checkbox v-model="form.si.isinvoiced"
                                     @change="editedchange(true)">
                        </el-checkbox>
                        <span class="paddingleft50">
                            <strong>Saved:</strong><span v-if="form.si.id > 0">{{form.u.loginname}} {{lastedited}}</span>
                        </span>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    export default {
        data() {
            return {
                page: "Sales - Invoices",
                details: false,
                detailform: [],
                view: {
                    filter: [{ table: 'p', column: 'name', value: '' }]
                },
                form: {
                    si: {
                        id: 0,
                        quotationsubmitted: 0,
                        invoicevalue: 0,
                        basecost: 0,
                        averagecost: 0
                    }
                },
                projectoptions: [],
                projects: [],
                project: {},
                savinginvoice: false,
                lastedited: ""
            }
        },

        methods: {
            refresh: function () {
                this.showloading();
                this.$http.post("sales/GetViewInvoice", this.view)
                    .then((response) => {
                        this.hideloading();
                        this.view = response.data.view;
                        for (const i of response.data.data) {
                            // i.si.averagecost = 10; // Temporary assigned value of average cost;
                            this.calculateprojectmargin(i, false);
                        }
                        this.detailform = response.data.data;
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },
            sortchange: function (sort) {
                this.view.sortcol = sort.prop;
                this.view.sortorder = sort.order;
                this.refresh();
            },
            dateformatter: function (_row, _column, cellValue) {
                if (cellValue) {
                    return moment(cellValue).format("ddd DD-MMM-YYYY");
                }
                return cellValue;
            },
            addrow: function () {
                this.page = "Sales - Invoicing Add";
                this.form.si.averagecoststr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(this.form.si.averagecost));
                this.details = true;
            },
            resetform: function () {
                this.page = "Sales - Invoices";
                this.form = {
                    si: {
                        averagecost: 0,
                        quotationsubmitted: 0,
                        invoicevalue: 0,
                        edited: false,
                        isinvoiced: false,
                        checklistcompleted: false
                    }
                };
                this.form.si.averagecoststr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(this.form.si.averagecost));
                this.details = false;
            },
            selectproject: function (item, event) {
                this.editedchange(event);
                if (item != "") {
                    this.project = this.projects.find(x => x.id == item);
                    
                    this.showloading();
                    this.axios.post("sales/getprojectcost", this.project)
                        .then((response) => {
                            this.hideloading();
                            this.form.si = response.data.si;
                            this.form.u = response.data.u;
                            this.form.si.projectid = this.project.id;
                            this.form.si.totalhours = response.data.totalhours;
                            this.form.si.totalhours2 = response.data.totalhours2;
                            this.form.si.basecost = response.data.basecost;
                            this.form.si.averagecost = response.data.averagecost;
                            this.calculateprojectmargin(this.form, event);
                            this.editrow(this.form);
                        })
                        .catch((error) => {
                            this.hideloading();
                            this.apierror(error);
                        });
                } else {
                    this.form.si.id = 0;
                    this.form.si.totalhours = 0;
                    this.form.si.totalhours2 = 0;
                    this.form.si.basecost = 0;
                    this.form.si.averagecost = 0;
                    this.form.si.invoicevalue = 0;
                    this.form.si.quotationsubmitted = 0;
                    this.form.si.winhours = 0;
                    this.form.si.losehours = 0;
                    this.form.si.gifthours = 0;
                    this.form.si.basecharge = 0;
                    this.form.si.message = "";
                    this.calculateprojectmargin(this.form, event);
                    this.editrow(this.form);
                }
            },
            editrow: function (row) {
                this.page = "Sales - Invoicing Edit";
                this.details = true;
                this.form = JSON.parse(JSON.stringify(row));
                this.form.si.invoicevaluestr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.si.invoicevalue));
                this.form.si.basecoststr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.si.basecost));
                this.form.si.averagecoststr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.si.averagecost));
                this.form.si.basechargestr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.si.basecharge));
                this.form.si.averagechargestr = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.si.averagecharge));
                this.lastedited = moment(this.form.si.modifiedon).format("DD-MMM-YYYY HH:mm");
                this.form.si.edited = false;

                if (this.form.si.message) {
                    var style = '<style> .el-message-box { width:650px; max-width: 650px; } .el-message-box__message {max-height:60vh; overflow:auto} .spancolor{color: #606266}</style>'
                    this.$alert(this.form.si.message + style, "Warning", {
                        dangerouslyUseHTMLString: true,
                    });
                }
            },
            editedchange: function (event) {
                this.form.si.edited = event;
            },
            currencygbp(row, name, event) {
                let v = parseFloat(event);
                if (!isNaN(v) && isFinite(v)) {
                    row.si[name] = parseFloat(event);
                    row.si[name + "str"] = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.si[name]));
                } else {
                    row.si[name] = null;
                    row.si[name + "str"] = null;
                }
            },
            calculateprojectmargin: function (row, event) {
                row.si.edited = event;

                if (!row.si.invoicevalue) {
                    row.si.invoicevalue = 0;
                }

                if (!row.si.basecharge) {
                    row.si.basecharge = 0;
                }

                var quotesubmitted = parseFloat(row.si.quotationsubmitted.toFixed(2));
                this.currencygbp(row, "quotationsubmitted", parseFloat(quotesubmitted.toFixed(2)));
                
                var basemargin = row.si.invoicevalue - row.si.basecost;
                var averagemargin = row.si.invoicevalue - row.si.averagecost;

                this.currencygbp(row, "basemargin", parseFloat(basemargin.toFixed(2)));
                this.currencygbp(row, "averagemargin", parseFloat(averagemargin.toFixed(2)));

                if (row.si.invoicevalue && row.si.basemargin && row.si.basemargin != 0) {
                    let basepercentage = ( row.si.basemargin / row.si.invoicevalue ) * 100;
                    row.si.basepercentage = parseFloat(basepercentage.toFixed(2));
                } else {
                    row.si.basepercentage = 0;
                }

                if (row.si.invoicevalue && row.si.averagemargin && row.si.averagemargin != 0) {
                    let averagepercentage = ( row.si.averagemargin / row.si.invoicevalue ) * 100;
                    row.si.averagepercentage = parseFloat(averagepercentage.toFixed(2));
                } else {
                    row.si.averagepercentage = 0;
                }

                if (row.si.totalhours && row.si.totalhours != 0 && row.si.basecost && row.si.basecost != 0) {
                    let ahrate = row.si.basecost / row.si.totalhours;
                    row.si.actualhourlyrate = parseFloat(ahrate.toFixed(2));
                    // this.currencygbp(row, "actualhourlyrate", row.si.actualhourlyrate);
                } else {
                    row.si.actualhourlyrate = 0;
                }
                this.currencygbp(row, "actualhourlyrate", row.si.actualhourlyrate);

                if (row.si.totalhours2 && row.si.totalhours2 != 0 && row.si.averagecost && row.si.averagecost != 0) {
                    let bhrate = row.si.averagecost / row.si.totalhours2;
                    row.si.basehourlyrate = parseFloat(bhrate.toFixed(2));
                    // this.currencygbp(row, "basehourlyrate", row.si.basehourlyrate);
                } else {
                    row.si.basehourlyrate = 0;
                }
                this.currencygbp(row, "basehourlyrate", row.si.basehourlyrate);

                row.si.winhours = row.si.winhours ? Math.abs(row.si.winhours) : 0;
                row.si.losehours = row.si.losehours ? -Math.abs(row.si.losehours) : 0;
                row.si.gifthours = row.si.gifthours ? -Math.abs(row.si.gifthours) : 0;

                if (row.si.actualhourlyrate && row.si.actualhourlyrate != 0) {
                    let actualoverallocated = row.si.winhours * row.si.actualhourlyrate;
                    quotesubmitted += actualoverallocated ? actualoverallocated : 0;
                    row.si.basewin = parseFloat(actualoverallocated.toFixed(2));

                    let actualunderallocated = row.si.losehours * row.si.actualhourlyrate
                    quotesubmitted += actualunderallocated ? actualunderallocated : 0;
                    row.si.baselose = parseFloat(actualunderallocated.toFixed(2));

                    let actualgiftedhours = row.si.gifthours * row.si.actualhourlyrate
                    quotesubmitted += actualgiftedhours ? actualgiftedhours : 0;
                    row.si.basegift = parseFloat(actualgiftedhours.toFixed(2));

                    // this.currencygbp(row, "basewin", row.si.basewin);
                    // this.currencygbp(row, "baselose", row.si.baselose);
                    // this.currencygbp(row, "basegift", row.si.basegift);
                } else {
                    row.si.basewin = 0;
                    row.si.baselose = 0;
                    row.si.basegift = 0;
                }
                this.currencygbp(row, "basewin", row.si.basewin);
                this.currencygbp(row, "baselose", row.si.baselose);
                this.currencygbp(row, "basegift", row.si.basegift);
                quotesubmitted += row.si.basecharge;

                let  bqvariation = quotesubmitted;
                let bqvvalue = bqvariation ? parseFloat(bqvariation.toFixed(2)) : 0;
                this.currencygbp(row, "basequotationvariation", bqvvalue);

                let basegainloss = row.si.quotationsubmitted - row.si.basequotationvariation;
                let bglvalue = basegainloss ? parseFloat(basegainloss.toFixed(2)) : 0;
                this.currencygbp(row, "basegainloss", bglvalue);


                quotesubmitted = parseFloat(row.si.quotationsubmitted.toFixed(2));
                if (row.si.basehourlyrate && row.si.baselhourlyrate != 0) {
                    let baseoverallocated = row.si.winhours * row.si.basehourlyrate;
                    quotesubmitted += baseoverallocated ? baseoverallocated : 0;
                    row.si.averagewin = parseFloat(baseoverallocated.toFixed(2));

                    let baseunderallocated = row.si.losehours * row.si.basehourlyrate
                    quotesubmitted += baseunderallocated ? baseunderallocated : 0;
                    row.si.averagelose = parseFloat(baseunderallocated.toFixed(2));

                    let basegiftedhours = row.si.gifthours * row.si.basehourlyrate
                    quotesubmitted += basegiftedhours ? basegiftedhours : 0;
                    row.si.averagegift = parseFloat(basegiftedhours.toFixed(2));

                    // this.currencygbp(row, "averagewin", row.si.averagewin);
                    // this.currencygbp(row, "averagelose", row.si.averagelose);
                    // this.currencygbp(row, "averagegift", row.si.averagegift);
                } else {
                    row.si.averagewin = 0;
                    row.si.averagelose = 0;
                    row.si.averagegift = 0;
                }
                this.currencygbp(row, "averagewin", row.si.averagewin);
                this.currencygbp(row, "averagelose", row.si.averagelose);
                this.currencygbp(row, "averagegift", row.si.averagegift);
                quotesubmitted += row.si.basecharge;

                let aqvariation = quotesubmitted;
                let aqvvalue = aqvariation ? parseFloat(aqvariation.toFixed(2)) : 0;
                this.currencygbp(row, "averagequotationvariation", aqvvalue);

                let averagegainloss = row.si.quotationsubmitted - row.si.averagequotationvariation;
                let aglvalue = averagegainloss ? parseFloat(averagegainloss.toFixed(2)) : 0;
                this.currencygbp(row, "averagegainloss", aglvalue);



                //old logic 
                // if (row.si.totalhours != 0 && row.si.basecost != 0 && row.si.winhours != undefined && row.si.winhours != 0) {
                //     let bch = row.si.basecost / row.si.totalhours;
                //     row.si.basecosthours = parseFloat(bch.toFixed(2));
                //     let bw = row.si.winhours * row.si.basecosthours;
                //     this.currencygbp(row, "basewin", parseFloat(bw.toFixed(2)));
                //     this.currencygbp(row, "baselose", row.si.basewin);
                //     this.currencygbp(row, "basegift", row.si.basewin);
                // }
                // if (row.si.totalhours != 0 && row.si.averagecost != 0 && row.si.winhours != undefined && row.si.winhours != 0) {
                //     let ach = row.si.averagecost / row.si.totalhours;
                //     row.si.averagecosthours = parseFloat(ach.toFixed(2));

                //     if (row.si.averagecosthours != 0 && row.si.winhours != undefined && row.si.winhours != 0) {
                //         let wins = row.si.winhours * row.si.averagecosthours;
                //         this.currencygbp(row, "averagewin", parseFloat(wins.toFixed(2)));
                //     }
                //     if (row.si.averagecosthours != 0 && row.si.losehours != undefined && row.si.losehours != 0) {
                //         let lose = row.si.losehours * row.si.averagecosthours;
                //         this.currencygbp(row, "averagelose", parseFloat(lose.toFixed(2)));
                //     }
                //     if (row.si.averagecosthours != 0 && row.si.gifthours != undefined && row.si.gifthours != 0) {
                //         let gift = row.si.gifthours * row.si.averagecosthours;
                //         this.currencygbp(row, "averagegift", parseFloat(gift.toFixed(2)));
                //     }
                // }
                // if (row.si.invoicevalue != undefined && row.si.basecost != undefined && row.si.basewin != undefined && row.si.baselose != undefined && row.si.basegift != undefined && row.si.basecharge != undefined) {
                //     let totalbasemargin = row.si.invoicevalue + row.si.basecost + row.si.basewin + row.si.baselose + row.si.basegift + row.si.basecharge;
                //     this.currencygbp(row, "basemargin", parseFloat(totalbasemargin.toFixed(2)));
                // }
                // if (row.si.invoicevalue != undefined && row.si.averagecost != undefined && row.si.averagewin != undefined && row.si.averagelose != undefined && row.si.averagegift != undefined && row.si.averagecharge != undefined) {
                //     let totalaveragemargin = row.si.invoicevalue + row.si.averagecost + row.si.averagewin + row.si.averagelose + row.si.averagegift + row.si.averagecharge;
                //     this.currencygbp(row, "averagemargin", parseFloat(totalaveragemargin.toFixed(2)));
                // }
                // if (row.si.basemargin != undefined && row.si.basemargin != 0 && row.si.invoicevalue != undefined && row.si.invoicevalue != 0) {
                //     let b = row.si.basemargin / row.si.invoicevalue;
                //     row.si.basepercentage = parseFloat(b.toFixed(2));
                // }
                // if (row.si.averagemargin != undefined && row.si.averagemargin != 0 && row.si.invoicevalue != undefined && row.si.invoicevalue != 0) {
                //     let avg = row.si.averagemargin / row.si.invoicevalue;
                //     row.si.averagepercentage = parseFloat(avg.toFixed(2));
                // }
            },
            currencyfocus(row, name) {
                row.si[name + "str"] = row.si[name];
            },
            currencyblur(row, name, event) {
                let v = parseFloat(event.target.value);
                if (!isNaN(v) && isFinite(v)) {
                    row.si[name] = parseFloat(event.target.value);
                    row.si[name + "str"] = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(row.si[name]));
                } else {
                    row.si[name] = null;
                    row.si[name + "str"] = null;
                }
                this.calculateprojectmargin(row, true);
            },
            myvalidate(callback) {
                var msg = [];
                if (!this.form.si.projectid || this.form.si.projectid == "") {
                    this.savinginvoice = false;
                    let style = '<style> .el-message-box {width:35%;} </style>';
                    this.$alert("Project should not be empty." + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                    return;
                }
                if (!this.form.si.invoicevalue || this.form.si.invoicevalue == "") {
                    msg.push('\xa0'.repeat(3) + " - Invoice Value");
                }
                if (!this.form.si.winhours || this.form.si.winhours == "") {
                    msg.push('\xa0'.repeat(3) + " - Over Allocated");
                }
                if (!this.form.si.losehours || this.form.si.losehours == "") {
                    msg.push('\xa0'.repeat(3) + " - Under Allocated");
                }
                if (!this.form.si.basecharge || this.form.si.basecharge == "") {
                    msg.push('\xa0'.repeat(3) + " - Charged Hours");
                }
                if (!this.form.si.gifthours || this.form.si.gifthours == "") {
                    msg.push('\xa0'.repeat(3) + " - Gifted Hours");
                }
                // if (!this.form.si.averagecharge || this.form.si.averagecharge == "") {
                //     msg.push("Average Extras - Charged should not be empty.");
                // }
                if (msg.length > 0) {
                    msg.unshift("Some of the following cells below have been left at zero. If this is correct please <b style='color: rgb(0, 162, 232);'>[Save]</b> if not, you should press <b>[Cancel]</b> and add the missing data.");
                    this.savinginvoice = false;
                    var style = '<style> .el-message-box {width:440px;} </style>';
                    this.$confirm(msg.join('<br />') + style, "Data Entry - Reminder", { 
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: 'Save',
                        cancelButtonText: 'Cancel' 
                    }).then(callback).catch(() => {/*Cancel clicked*/ });
                }
                else {
                    callback();
                }
            },
            save: function () {
                this.savinginvoice = true;
                if (this.form.si.edited && this.savinginvoice) {
                    this.myvalidate(() => {
                        this.showloading();
                        this.$http.post("sales/saveinvoice", this.form.si)
                            .then((response) => {
                                this.hideloading();
                                this.savinginvoice = false;
                                this.$message({
                                    type: "success",
                                    showClose: true,
                                    message: response.data.message
                                });
                                this.resetform();
                                this.refresh();
                                this.details = false;
                            })
                            .catch((err) => {
                                this.hideloading();
                                this.savinginvoice = false;
                                this.alerterror(err);
                            });
                    });
                }
                else {
                    this.savinginvoice = false;
                    this.$message({
                        type: "info",
                        showClose: true,
                        message: "Nothing to save"
                    });
                }
            },
            searchprojects: function () {
                this.axios.post("project/search?typeid=" + 1, {})
                    .then((response) => {
                        this.projectoptions = response.data;
                        this.projects = response.data;
                    })
                    .catch((err) => {
                        this.apierror(err);
                    });
            },
        },

        created() {
            // if (this.$store.state.projects.length == 0) {
            //     this.searchproject().then(() => {
            //         this.projectoptions = this.$store.state.projects;
            //         this.projects = this.$store.state.projects;
            //     });
            // } else {
            //     this.projectoptions = this.$store.state.projects;
            //     this.projects = this.$store.state.projects;
            // }
            this.searchprojects();
            var sortobj = { prop: 'p.name', order: 'descending' };
            this.sortchange(sortobj);
        }
    }
</script>
<style>
    span.widthlabel77 {
        display: inline-block;
        width: 77px;
        text-align: right;
    }
</style>