<template>
  <div>
    <div>
    <!-- <el-affix :offset="52" > -->
             <div class="colorwhite stickyheader">
    <div class="headerstyle">Overheads  Full</div>
    <el-row :gutter="5">
      <el-col :span="12">
          <el-date-picker
            v-model="view.filter[0].value"
            type="date"
            class="datewidth135 marginr4"
             format="ddd DD-MMM-YYYY"
          :editable="false"
          :clearable="false"
           @change="datechange($event, 0)"
            placeholder="To Date"
            :disabled-date="$commonutils.disableFutureDates">
            </el-date-picker>
          <el-date-picker
            v-model="view.filter[1].value"
            type="date"
            class="datewidth135 marginr4 left"
              format="ddd DD-MMM-YYYY"
          :editable="false"
          :clearable="false"
           @change="datechange($event,1)"
            placeholder="From Date"
            :disabled-date="$commonutils.disableFutureDates">
            </el-date-picker>
      </el-col>
      <el-col :span="12" >
        <div class="right">
        <el-button type="primary" 
        :disabled="!editpermission" 
        @click="getreportFun" 
        icon="document">
        View Report
        </el-button>
        </div>
      </el-col>
    </el-row>
    <hr class="spaceBetween" />
      </div>
    <!-- </el-affix> -->
  
    <el-table
     v-if="show"
      :show-header="true"
      :border="true"
      :row-class-name="tableRowClassName"
      :span-method="arraySpanMethod"
      :data="tabledata"
      :cell-style="tablecellStyles"
      @header-click="handleWidthChange"
      class="roverheadtable"
    >
      <el-table-column fixed="left" prop="pname" label="Overhead" width="300">
       <template #default="scope">
                           <div  v-if="scope.row.rowspan == true"  style="text-align: left; font-weight: 300;background-color: #C8C8C8; color:black">
                             {{scope.row.pname}}
                           </div>
                           <div v-else>
                             {{scope.row.aname}}
                           </div>
                         </template>
      </el-table-column>
      <el-table-column
        v-for="column in users"
        :key="column.id"
        prop="action"
      >
       <template #header>
         <div>
         {{column.firstname}}
         </div>
         <div class="spaceBetween"/>
         <div>
            {{column.lastname}}
         </div>
       <!-- {{column.firstname + '    ' + column.lastname}} -->
      </template>
        <el-table-column label="Time">
          <template #default="scope">
            {{ isuser(scope.row, column) }}
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      view: {
        filter: [
          {
            table: "t",
            column: "date",
            value: "",
            operation: ">=",
            paraname: "sdate",
          },
          {
            table: "t",
            column: "date",
            value: "",
            operation: "<=",
            paraname: "edate",
          },
           {
            table: "u",
            column: "active",
            value: "",
            operation: "<=",
            paraname: "edate",
          },
           {
            table: "u",
            column: "user",
            value: "",
            operation: "",
            paraname: "user",
          },
           {
            table: "a",
            column: "typeid",
            value: "",
            operation: "",
            paraname: "atypeid",
          },
        ],
      },
      tabledata: [],
      users: [],
      show:false
    };
  },
  methods: {
     tableRowClassName({row}) {
        if (row.rowspan == true) {
          return 'overhead-row';
        } 
        return '';
      },
    getreportFun(){
      if(this.show == false){
          this.show = true;
          this.refresh();
        }
    },
    datechange(event, index) {
       if (event == null) {
        this.view.filter[index].value = "";
      }else{
           this.view.filter[index].value = moment(event)
          .format("YYYY-MM-DD").toString()
      }
      this.show = false;
      // this.refresh();
    },
    arraySpanMethod({ row, columnIndex }) {
      if (columnIndex == 0) {
        if (row.rowspan == true) {
          return [1, this.users.length+2];
        }
      }
    },
    isuser(row, col) {
      var colname = "col" + col.id;
      if (row[colname] != undefined && row[colname] != 0) {
        return this.gettduration(row[colname]);
      }
      return "";
    },
    getmonth(date) {
      if (date != null) {
        var newdate = new Date();
        var y = date.getFullYear(),
          m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay =
          m == newdate.getMonth() ? new Date() : new Date(y, m + 1, 0);
        this.view.filter[0].value = moment(firstDay)
          .format("YYYY-MM-DD")
          .toString();
        this.view.filter[1].value = moment(lastDay)
          .format("YYYY-MM-DD")
          .toString();
        this.refresh();
      }
    },
    gettduration(duration) {
      var h = Math.floor(duration / 60),
        m = duration % 60;
      return (h<10?"0":"") + h.toString() + ":" + (m<10?"0":"") + m.toString();
    },
    refresh: function () {
      this.showloading();
      this.$http
        .post("reports/GetRoverheadView", this.view)
        .then((response) => {
          this.hideloading();
          var data = response.data.data;
          var pname = "";
         
          for (var i = 0; i < data.length; i++) {
            if (pname != data[i].pname) {
              pname = data[i].pname;
              data.splice(i, 0, { pname: pname });
              data[i].rowspan = true;
            }
          }
          this.tabledata = data;
         

          this.users = response.data.u;
        })
        .catch((error) => {
          this.hideloading();
          this.apierror(error);
        });
    },

    handleWidthChange(col, event) {
        const colindicies = this.$store.state.tablecellindicies;
        const index = event.currentTarget.cellIndex;

        this.toggleIndexInArray(colindicies.overhead3, col, index);
        this.tabledata.forEach(ti => {
          if (ti.iswidthchange) {
            ti.iswidthchange = false;
          } else {
            ti.iswidthchange = true;
          }
        });
      },

    toggleIndexInArray(columninfoarr, column, colindex) {
      const colInfoIndex  = columninfoarr.findIndex(item => item.columnindex === colindex);
      const cololdwidth = column.realWidth ? column.realWidth : column.width;

      if (colInfoIndex === -1) {
        column.width = 30;
        if (column.children) {
          column.children.forEach(childCol => {
            childCol.width = 30;
          });
        }

        columninfoarr.push({ columnindex: colindex, oldwidth: cololdwidth});
      } else {

        column.width = columninfoarr[colInfoIndex].oldwidth;
        if (column.children) {
          column.children.forEach(childCol => {
            childCol.width = columninfoarr[colInfoIndex].oldwidth;
          });
        }
        columninfoarr.splice(colInfoIndex, 1);
      }
    },

    tablecellStyles({  column,  columnIndex }) {
      const widths = this.$store.state.tablecellindicies;
      const index = columnIndex;

      if (widths.overhead3.some(item => item.columnindex === index)) {
          column.width = 30;
      }
    },
  },
  created() {
    if (this.tabledata.length <= 0) {
      var d = new Date();
      this.getmonth(d);
    }
  },
};
</script>
<style>
    .el-table .overhead-row {
    background: #C8C8C8;
  }

  .roverheadtable .cell {
    white-space: pre;
  }
</style>
