class commonutils {
    disableFutureDates(time) {
      return time.getTime() > Date.now();
    }
  

    /* Sorts an array of strings, an array of integers, or an array of objects (based on a specified property)
       in ascending or descending order. */
    sortArray(arr, prop, ascending = true) {
      const self = this;
      arr.sort(function (a, b) {
        const propValueA = prop ? self.getProperty(a, prop) : a;
        const propValueB = prop ? self.getProperty(b, prop) : b;
  
        let comparison = 0;
  
        if (propValueA > propValueB) {
          comparison = 1;
        } else if (propValueA < propValueB) {
          comparison = -1;
        }
  
        return ascending ? comparison : -comparison;
      });
      return arr;
    }

    async savepagelayout(row) {
      debugger
        try {
            const response = await this.component.axios.post("account/savepagelayout", row.ugv);
            this.component.$store.state.user.usergridviews = response.data;
            let ret = response.data.find(x => x.p.name === row.p.name);
            return ret;

        } catch (error) {
          this.component.eventbus.$emit("error", error);
        }
    }
  
    getProperty(obj, prop) {
      if (typeof prop === 'string') {
        const props = prop.split('.');
        let value = obj;
    
        for (const nestedProp of props) {
          value = value ? value[nestedProp] : undefined;
    
          if (value === undefined || value === null) {
            value = '';
            break;
          }
        }
    
        return value;
      } 
      else {
        return '';
      }
    }

    getusergridrow(currentpage) {
      debugger
        let ret = this.component.$store.state.user.usergridviews.find(x => x.p.name === currentpage.name);
        if (ret.ugv.rowperpage <= 0) {
            ret.ugv.rowperpage = 20; //Default pages 20
        }
        return ret;
    }

    setcomponent(prop) {
      debugger
      this.component = prop;
    }
  }
  
  export default commonutils;