<template>
    <div>
        <div class="width510px">
            <!-- <el-affix :offset="50"> -->
                <div class="colorwhite stickyheader">
                    <span class="headerstyle inlineblock">{{ modedisplay }}</span>
                    <span>
                        <div class="floatright margint8">
                            <el-button type="primary" icon="circle-plus-filled" @click="addversion"
                                v-if="!editversion"></el-button>
                            <el-button type="success" icon="circle-check" @click="save" v-if="editversion">Save</el-button>
                            <el-button type="info" class="marginleft5" icon="back"
                                @click="!editversion ? $router.go(-1) : refresh()">Back</el-button>
                        </div>
                    </span>
                    <hr class="margintop0px" />
                </div>
            <!-- </el-affix> -->

            <div class="paddingleftright">
                <el-table v-if="!editversion" :data="versions" stripe>
                    <el-table-column prop="date" label="Update Date" width="100">
                        <template #default="scope">
                            {{ dateformat(scope.row.date) }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="version" label="Version" width="150">
                    </el-table-column>

                    <el-table-column prop="version" label="Action" header-align="center" width="75">
                        <template #default="scope">
                            <el-button class="padding7" type="primary" title="Edit"
                                :icon="editpermission == true ? 'edit-pen' : 'view'" @click="editversionbyid(scope.row)">
                            </el-button>
                            <el-button class="padding7 marginleft5" type="danger" title="Delete" icon="delete"
                                @click="deleterow(scope.row)"> </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div v-if="editversion">
                <div class="spaceBetween">
                    <span class="bold width100px commonfontsize12 positionrelative inlineblock">Version:</span>
                    <span>
                        <el-input placeholder="Version" v-model="form.version" class="width50px"
                            @change="formatversion(form, 'version')"></el-input>
                    </span>
                </div>

                <div class="spaceBetween">
                    <span class="bold width100px commonfontsize12 positionrelative inlineblock">TT Update Date:</span>

                    <span>
                        <el-date-picker type="date" class="customdate salesinputwidth85" :default-value="new Date()"
                            v-model="form.date" format="DD-MMM-YYYY" value-format="YYYY-MM-DD" :editable="false"
                            :clearable="false" align="center" placeholder="Select Date">
                        </el-date-picker>
                    </span>
                </div>

                <div>
                    <span class="bold commonfontsize12">Notes:</span>
                </div>

                <div>
                    <editor :metadata="form"></editor>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Editor from "./Editor.vue";
import moment from "moment";
export default {
    data() {
        return {
            form: {},
            versions: [],
            editversion: false,
        }
    },

    components: {
        Editor
    },

    computed: {
        modedisplay: function () {
            return this.editversion == true ? "Add/Edit Time Tracking Versions" : "Time Tracking Versions";
        },

        dateformat: function () {
            return function (date) {
                if (date) {
                    return moment(new Date(date)).format("DD-MMM-YYYY");
                }
            }
        }
    },

    methods: {
        validate(callback) {
            var msg = [];
            if (this.form.content == "<p></p>") {
                this.form.content = "";
            }
            if (this.form.version == "") {
                msg.push("Version must not be empty");
            }
            if (this.form.date == null) {
                msg.push("Time Tracking Update Date must not be empty");
            }
            if (msg.length > 0) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(msg.join("<br />") + style, "Invalid Data", {
                    dangerouslyUseHTMLString: true,
                });
            } else {
                callback();
            }
        },

        formatversion(data, name) {
            const isValidFormat = /^\d{2}\.\d{2}$/.test(data[name]);
            data.isvalidversion = isValidFormat;
            if (!isValidFormat) {
                this.$alert("Please enter a version in the format 00.00", 'Invalid format', {
                    confirmButtonText: 'OK',
                    dangerouslyUseHTMLString: true,
                    type: 'warning'
                })
                data[name] = "";
            }
        },

        deleterow(row) {
            this.$confirm('This will permanently delete ' + row.version + ' version. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                var obj = { id: row.id };
                this.showloading();
                this.$http.post("account/deleteversionbyid", obj)
                    .then(response => {
                        this.refresh();
                        this.$message({
                            showClose: true,
                            message: response.data, type: "success"
                        });
                        this.hideloading();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.apierror(err);
                    });
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },

        save() {
            this.validate(() => {
                this.showloading();
                this.$http.post("account/saveversion", this.form)
                    .then(response => {
                        if (this.form.id != null) {
                            this.editversionbyid(this.form);
                        } else {
                            this.refresh();
                        }
                        this.$message({
                            showClose: true,
                            message: response.data, type: "success"
                        });
                        this.hideloading();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.apierror(err);
                    });
            });
        },
        
        editversionbyid(row) {
            var obj = { id: row.id };
            this.showloading();
            this.$http.post("account/getversionbyid", obj)
                .then(response => {
                    this.form = {};
                    this.form = response.data;
                    this.editversion = true;
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.apierror(err);
                });
        },

        addversion() {
            this.form = { version: "", date: "", content: "" };
            this.form.date = moment(new Date()).format("YYYY-MM-DD").toString();
            this.editversion = true;
        },

        refresh() {
            this.showloading();
            this.$http.get("account/getallversions")
                .then(response => {
                    this.editversion = false;
                    this.versions = response.data;
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.apierror(err);
                });
        }
    },

    created: function () {
        this.refresh();
    },
}
</script>
