export default {
    data() {
        return {
            loadingobj: null,
            loadingvisible: false
        };
    },
    computed: {
        editpermission() {
            if (this.$store.state.role.rp.permission == 2) {
                return true;
            }
            else {
                return false;
            }
        },
    },

    methods: {
        post(url,parm){
         
            return  this.axios.post(url,parm)
            .then(response => {
              return response
            })
            .catch(err => {
                this.apierror(err);
            });   
        },
        get(url){
            return  this.axios
            .get(url)
            .then(response => {

              return response
            })
            .catch(err => {
                this.apierror(err);
            });   
        },
        getPages: function () {
            this.$http.get('account/getpages')
                 .then(response => {
                     this.$store.state.pages = response.data;
                 }).catch(this.apierror);
         },
        searchuser: function(query, done) {
            this.axios
              .post("Account/searchuser?q=" + query, {})
              .then((response) => {
                var user = JSON.parse(response.data);
                done(user);
              })
              .catch(this.apierror);
        },
        getcurrency: function() {
            return this.axios
              .post("operativecost/getcurrency")
              .then((response) => {
                return this.$store.state.currencies = response.data;
              })
              .catch(this.apierror);
        },
        getproviderandcurrency: function() {
            return this.axios
              .post("InternationalPayment/getcurrencyandprovider")
              .then((response) => {
                 this.$store.state.providers = response.data.providers;
                 this.$store.state.currencies = response.data.currencys;
                 return response.data;
              })
              .catch(this.apierror);
        },

        searchproject: function () {
            return this.axios.post("project/search")
                .then((response) => {
                    this.$store.state.projects = response.data;
                })
                .catch((err) => {
                    this.apierror(err);
                });
        },

        getprojectbytypeid() {
            return this.axios.post("project/getprojectbytypeid")
                .then((response) => {
                    return response.data;
              })
              .catch(this.apierror);
        },

        getdirectionbyuserid: function (userid) {
            return this.axios.post("Direction/getdirectionbyuserid",{userid:userid})
                .then((response) => {
                    response.data.forEach(e => {
                        if(e.userids != undefined){
                            var isfind = e.userids.indexOf(this.$store.state.user.id);
                            if(isfind != -1){
                                if(this.$router != undefined &&  this.$router.options != undefined && this.$router.options.routes != undefined){
                                    var urentrouter = this.$router.options.routes.find(x => x.name == e.pagename);
                                    e.path = urentrouter.path;
                                }
                                if(this.$route.name == e.pagename){
                                    this.$direction.getdirectionspopupbyid(e);
                                }else{
                                    this.$direction.showNotification(e,"direction");
                                }
                            }
                        }
                    });
                })
                .catch((err) => {
                    this.apierror(err);
                });
        },
        apierror(error) {
            if (error.response != null) {
                if (typeof (error.response.data) == "object") {
                    var json = error.response.data;
                    this.$message({
                        showClose: true,
                        message: json.message,
                        type: 'error'
                    });
                }
                else {
                    var style = '<style> .el-message-box {width:60vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>'
                    this.$alert(error.response.data + style, error.message, {
                        dangerouslyUseHTMLString: true
                    });
                }
            }
            else {
                this.$alert(error.message, "Error");
            }
        },

        alerterror: function (error) {
            if (error.response.data.message) {
                this.$alert('warning', {
                    message: error.response.data.message,
                    type: 'warning'
                })
            } else {
                this.apierror(error);
            }
        },

        showloading: function () {
            this.loadingvisible = true;
            setTimeout(() => {
                if (this.loadingvisible == true) {
                    this.loadingobj = this.$loading({
                        lock: true,
                        text: 'Loading',
                        'element-loading-spinner': 'loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                }
            }, 300);
        },

        hideloading: function () {
            this.loadingvisible = false;
            if (this.loadingobj) {
                this.loadingobj.close();
            }
        },
        tsupdatereminder: function () {
            var time = new Date()
                    .toLocaleString("en-GB", { timeZone: "Europe/London" })
                    .split(" ")[1];
                var hms = time.split(":");
                var h = parseInt(hms[0]); var m = parseInt(hms[1]);
                if (((h == 9 && m > 30) || (h == 17 && m > 0)) && this.$store.state.isnotificationtime == false) {
                    this.$store.state.isnotificationtime = true;
                    var currentuser = this.$store.state.user;
                    var hour = (h == 9) ? "24 HOUR" : (h == 17) ? "8 HOUR" : "0 HOUR";
                    var view = {
                        filter: [
                            {value: hour},
                            {defaultfilter: currentuser.istimetrackeradmin},
                            {value: currentuser.id ? ""+currentuser.id : ""},
                        ]
                    };
                    
                    this.$http.post("timesheet/tsupdatereminder", view)
                    .then((response) => {
                        var e = {directionname:"Please update your Time Entry..."};
                        if (response.data.view.count == 0) {
                            if (Notification.permission === "granted") {
                                // this.showNotification();
                                this.$direction.showNotification(e);
                            } else if (Notification.permission !== "denied") {
                                Notification.requestPermission().then(permission => {
                                    if (permission === "granted") {
                                        // this.showNotification();
                                        this.$direction.showNotification(e);
                                    }
                                });
                            }
                            setTimeout(() => {
                                if (Object.keys(this.$store.state.user).length !== 0) {
                                    alert("Please update your Time Entry...");
                                    this.$router.push('/timesheet');    
                                }
                            }, 10000);   
                            
                        }
                        setTimeout(() => {
                            if (Object.keys(this.$store.state.user).length !== 0) {
                                if (response.data.users ) {
                                    if (response.data.users.length > 0) {
                                        this.griddata = response.data.users;
                                        this.dialogTableVisible = true;
                                    }
                                }
                            }
                        }, 30000);   
                        
                    });
                }
                if (h != 9 && h != 17) {
                    this.$store.state.isnotificationtime = false;
                }
                if (Object.keys(this.$store.state.user).length !== 0) {
                    setTimeout(() => {
                        this.tsupdatereminder();
                    }, 60000);   
                }
        },

        // showNotification: function() {
        //     const notification = new Notification("New message incoming", {
        //         body: "Please update your Time Entry..."
        //     });

        //     notification.onclick = () => {
        //         parent.focus();
        //         this.$router.push('/timesheet');
        //     };
        // },

        notifyme: function() {
            if (Notification.permission !== 'granted')
            Notification.requestPermission();
           else {
            var notification = new Notification('Notification title', {
             icon: '',
             body: 'Hey there! You\'ve been notified!',
            });
            notification.onclick = function() {
             window.open('https://www.google.com/');
            };
           }
        },

        measureTextWidth: function (str, fontSize = 12) {
            const widths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125, 0.5890625]
            const avg = 0.5279276315789471
            return str
                .split('')
                .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
                .reduce((cur, acc) => acc + cur) * fontSize
        },
        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        },

        jsonToFormData(data) {
            const formData = new FormData();
            this.buildFormData(formData, data);
            return formData;
        },
    }
}
