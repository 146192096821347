// import  globalMixin  from '../mixins';

class direction  {
    options=  {
        body: "this is a test Notifiaction",
        icon: "images/icons/icon-144x144.png",
        vibrate: [100, 50, 100],
        showNotification:true,
        data: {
            dateOfArrival: Date.now()
        },
        actions: [
            {
                action: "/timesheet", title: "Update Now",
                icon: "images/icons/schedule.png"
            },
            {
                action: "close", title: "Ignore",
                icon: "images/icons/circle.png"
            },
        ]
        }
    connectWebSocket() {
        if(this.socket == null){
            // Replace with your WebSocket server URL
            const socketURL = `wss://${location.hostname}/ws?userid=${this.component.$store.state.user.id}`;
            this.socket = new WebSocket(socketURL);

            this.socket.onopen = () => {
                // console.log(event);
                // this.messages.push("Connected to WebSocket server");
            };

            this.socket.onmessage = (event) => {
                // this.messages.push(JSON.parse(event.data));
                // console.log(this.component.$store.state.user);
                var dd = JSON.parse(event.data);
                dd.forEach(e => {
                    if(e.userids != undefined){
                        var isfind = e.userids.indexOf(this.component.$store.state.user.id);
                        if(isfind != -1){
                            this.showNotification(e,"direction");
                        }
                    }
                });
            };

            this.socket.onclose = (event) => {
                console.log(event);
                if(this.messages){
                    this.messages.push("Connection closed");
                }
            };
        }
    }
    getnotificationpermission(){
        
        if (Notification.permission === "granted") {
            // this.showNotification();
            return true;
        } else if (Notification.permission !== "granted") {
            Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                    // this.showNotification();
                    // callback();
                    return true;
                }
            });
        }
    }
    getdirectionspopupbyid(data){
        this.component.axios.post("Direction/getdirectionspopupbyid",{id:data.directionid})
                .then((response) => {
                    // console.log(response);
                  const form = response.data;
                     var option =  {
                        uniqueid:form.id,
                        isedit:true,
                        visible: true,
                        boxtype:"Small 2 Part Pop up",
                        content:form.message,
                        mediacontent:{
                            name: form.mediacontent == null || form.mediacontent.name == null ? "" : form.mediacontent.name,
                            link: form.mediacontent == null || form.mediacontent.link == null ? "" : form.mediacontent.link,
                        },
                        offercloseoption:"error",
                        link:form.link,
                        heading:form.directionname,
                        directiondegree:"",
                    }
                    option.position = {};
                    // this.component.$store.state.directionsdialogue = [];
                    // this.component.$store.state.directionsdialogue = [option];
                    // this.component.$store.state.currentdirectionindex = 0;
                    this.component.$store.state.currentdirection = option;
                    
                })
                .catch((err) => {
                    this.apierror(err);
                });
    }
    setBadge(count) {
        if (navigator.setAppBadge) {
          navigator.setAppBadge(count);
        } else if (navigator.setExperimentalAppBadge) {
          navigator.setExperimentalAppBadge(count);
        } else if (window.ExperimentalBadge) {
          window.ExperimentalBadge.set(count);
        }
      }
      clearBadge() {
        if (navigator.clearAppBadge) {
          navigator.clearAppBadge();
        } else if (navigator.clearExperimentalAppBadge) {
          navigator.clearExperimentalAppBadge();
        } else if (window.ExperimentalBadge) {
          window.ExperimentalBadge.clear();
        }
      }
    pushManager(data,newoption){
        newoption.body = data.directionname;
        // var newoption = {};
        // if(options == undefined){
        //     newoption = this.options;
        // }else{
        //     newoption = options;
        // }
        if(this.serviceWorker == undefined){
        navigator.serviceWorker.register("/sw.js")
                    .then((reg) => {
                        this.serviceWorker = reg;
                        //  this.onnotificationclick();
                        reg.showNotification("TSA Time Tracking", newoption).then(()=>{
                            // reg.onnotificationclose  = (event) => {
                            //     debugger
                            //     console.log("On notification close: ", event.notification.tag);
                            //   }; 
                            reg.getNotifications(newoption).then(notifications => {
                                // do something with your notifications
                                if(notifications){
                                    var newcount = notifications.length <= 0 ? 1 : notifications.length  
                                    navigator.setAppBadge(newcount);
                                }
                                // this.setBadge(notifications.length);
                              });
                        })
                        
                        // navigator.setAppBadge(this.component.$store.state.messagecount);
                        // this.component.$store.state.messagecount ++;
                        // this.setBadge(this.component.$store.state.messagecount);
                });
            }else{
                this.serviceWorker.showNotification("TSA Time Tracking", newoption).then(()=>{
                    this.serviceWorker.getNotifications(newoption).then(notifications => {
                        // do something with your notifications
                        if(notifications){
                            var newcount = notifications.length <= 0 ? 1 : notifications.length  
                            navigator.setAppBadge(newcount);
                        }
                        // this.setBadge(notifications.length);
                      });
                });
                // this.serviceWorker.onnotificationclose = (event) => {
                //     debugger
                //     console.log("On notification close: ", event.notification.tag);
                //   }; 
                
            }
      }
      showNotificationuinsgjs(data){
        if(this.getnotificationpermission() == true){
            const notification = new Notification("TSA Time Tracking", {
                body: data.directionname,
                dir:'rtl',
                icon:'logo.png',
            });
            notification.addEventListener("close", (event) => {
                
                console.log(event);
            });
            notification.onclose = ((data)=>{
                
                console.log(data);
                console.log("onclose");
            })
            notification.onshow = (data) => {
                
                console.log(data);
                console.log("onshow");
            };
            notification.onerror = (data) => {
                
                console.log(data);
                console.log("onerror");
            };
            notification.onclick = (ds) => {
                console.log(ds);
                console.log("onclick");
                // parent.focus();
                   // this.FormData.message = this.metadata.content;
                   if(data.directionid != undefined){
                    this.getdirectionspopupbyid(data);
                    this.component.$router.push({ name: data.pagename});
                   }
            //   var option =  {
            //     uniqueid:1,
            //     isedit:true,
            //     visible: true,
            //     boxtype:"Small 2 Part Pop up",
            //     content:"sdfdsfsdf",
            //     mediacontent:{
            //         name: "",
            //         link: ""
            //       },
            //       offercloseoption:"error",
            //       link:"",
            //       heading:"Test dirction",
            //       directiondegree:"",
            //     }
            // option.position = {};
            // this.component.$store.state.directionsdialogue = [];
            // this.component.$store.state.directionsdialogue = [option];
            // this.component.$store.state.currentdirectionindex = 0;
            // this.component.$store.state.currentdirection = option;
            // this.component.$router.push({ name: data.pagename});
            };
        }
      }  
    showNotification(data,type) {
        var newoption = {};
        // this.options.body = data.directionname;
        if(type == "direction"){
            newoption = JSON.parse(JSON.stringify(this.options));
            newoption.actions[0].title = "Show Direction"
            newoption.actions[0].action = data.path == undefined ? "/timesheet" : data.path 
        }else{
            newoption = this.options;
        }
        // this.showNotificationuinsgjs(data);
        this.pushManager(data,newoption);
    }
    setcomponent(prop) {
        this.component = prop;
        // console.log(this);
    }
    // constructor() {
    // }
}
export default direction;