<template>

    <div>
        <div class="headerstyle">Change Password</div>
           <hr class="spaceBetween"/>
        <el-form  label-width="130px" label-position="left">
            <el-form-item label="Password">
                <el-input class="width50" type="password" v-model="user.password" placeholder="Password"></el-input>
            </el-form-item>
             <div class="spaceBetween"/>
            <el-form-item label="Confirm Password">
                <el-input class="width50" type="password" v-model="user.confirmpassword" placeholder="Confirm Password"></el-input>
            </el-form-item>
            <div class="spaceBetween"/>
            <el-form-item>
                <el-button type="success" @click="save"  icon="circle-check">Save</el-button>
            </el-form-item>
            
        </el-form>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                user: {
                    password: "",
                    confirmpassword: ""
                },
            }
        },
        methods: {
            save: function () {
                if (this.isvalid()) {
                    this.$http.post("account/updatepassword", this.user)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                type: 'success',
                                message: response.data
                            });
                            this.$router.push("/");
                        })
                        .catch(err => {
                               this.apierror(err);
                            this.$bus.$emit('logout', null);
                        })
                }
            },
            isvalid: function () {
                var msg = [];
                if (!this.user.password){
                    msg.push("Password must not be empty");
                }
                if (!(this.user.password == this.user.confirmpassword)){
                    msg.push("Password doesn't match with confirm password");
                }
                if (this.user.password.length >= 50 ){
                    msg.push("Password should not exceed 50 characters.");
                }
                if (this.user.confirmpassword.length >= 50){
                    msg.push("Confirm Password should not exceed 50 characters.");
                }
                if (msg.length > 0) {
                    this.$alert(msg.join("</br>"), "Validation Error", { dangerouslyUseHTMLString: true });
                    return false;
                }
                return true;
            }

        }
    }
</script>
