<template>
   <div>
            <el-row>
                <el-col :span="12"><div class="headerstyle">{{modedisplay}}</div></el-col>
                <el-col :span="12" ><div class="topbottomspace floatright" v-if="id > 0">{{actionname}}: {{form.a.name}}</div></el-col>
            </el-row>
        <hr class="spaceBetween" />

        <el-form label-position="left" label-width="60px">

            <el-form-item label="Name ">
                <el-input class="width50" placeholder="Subaction Name" v-model="form.sa.name"></el-input>
            </el-form-item>
            <el-form-item label="Active ">
                <el-checkbox  v-model="form.sa.active"></el-checkbox>
            </el-form-item>
            <div class="spaceBetween" />
            <el-form-item>
                <el-button :disabled="!editpermission" type="success" icon="circle-check" @click="save">Save</el-button>
                <el-button icon="back" @click="$router.go(-1)">Back</el-button>
            </el-form-item>

        </el-form>
   </div>
</template>

<script>
    export default {
        data() {
            return {
                id: 0,
                form: {
                    a: {},
                    sa: {},
                },
            }
        },

        computed: {
            modedisplay: function () {
                if (this.$route.path.includes("/projectsubactionedit")) {
                    return this.id == 0 ? "Project Subaction Add" : "Project Subaction Edit";
                }
                else {
                    return this.id == 0 ? "Overhead Subaction Add" : "Overhead Subaction Edit";
                }
            },

            actionname: function () {
                if (this.$route.path.includes("/projectsubactionedit")) {
                    return "project Action Name";
                }
                else {
                    return "Overhead Action Name";
                }
            }
        },

        methods: {
            myvalidate: function (callback) {
                var msg = [];
                if (!this.form.sa.name) {
                    msg.push("Subaction Name is required");
                }
                 if (this.form.sa.name.length >= 100) {
                    msg.push("Subaction Name should not exceed 100 characters.");
                }    
                if (msg.length > 0) {
                    var style = '<style> .el-message-box {width:50%;} </style>'
                    this.$alert(msg.join('<br />') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }

                else {
                    callback();
                }
            },

            save: function () {
                this.myvalidate(() => {
                    this.showloading();
                    this.$http.post("action/savesubaction", this.form)
                        .then(response => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.$router.go(-1);
                        })
                        .catch(error => {
                            this.hideloading();
                            this.apierror(error);
                        });
                });
            },

            refresh: function () {
                this.showloading();
                this.$http.get("action/getsubaction", { params: this.form.sa })
                    .then(response => {
                        this.hideloading();

                        if (this.$route.path.includes("/projectsubactionedit")) {
                            response.data.sa.actionid = 0;
                        }
                        else {
                            response.data.sa.actionid = this.$route.query.actionid;
                        }
                        this.form = response.data;
                    })
                    .catch(error => {
                        this.hideloading();
                        this.apierror(error);
                    });
            }
        },

        created: function () {
            if (this.$route.query.id > 0) {
                this.id = this.$route.query.id;
                this.form.sa.id = this.id;
            }
            if (this.$route.query.actionid > 0) {
                this.actionid = this.$route.query.actionid;
                this.form.sa.actionid = this.actionid;
            }
            this.refresh();
        }
    }
</script>