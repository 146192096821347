<template>
    <div>
        <!-- <el-affix :offset="52"> -->
            <div class="colorwhite stickyheader">
                <el-row>
                    <el-col :span="12">
                        <div class="headerstyle">Time Entry</div>
                    </el-col>
                    <el-col :span="12" class="rightimp">
                        <div class="topbottomspace"> {{currenttime}}</div>
                    </el-col>
                </el-row>
                <el-row :gutter="5">
                    <el-col :span="24">
                        <el-icon class="verticalmiddle" @click="plusdate('subtract')">
                            <caret-left />
                        </el-icon>
                        <el-date-picker type="date"
                                        class="daytime"
                                        :default-value="Date()"
                                        v-model="view.filter[6].value"
                                        format="ddd DD-MMM-YYYY"
                                        value-format="YYYY-MM-DD"
                                        :editable="false"
                                        :clearable="false"
                                        @change="datechange"
                                        align="center"
                                        placeholder="Select Date"
                                        :disabled-date="$commonutils.disableFutureDates">
                        </el-date-picker>
                        <el-icon class="verticalmiddle" @click="plusdate('add')">
                            <caret-right />
                        </el-icon>

                    </el-col>
                </el-row>
                <hr class="spaceBetween" />
            </div>
        <!-- </el-affix> -->
        
        <div class="tabletexttop tablewordbreak">        
        <el-table :data="detailform"
                  :border="true"
                  class="paddingleftright fullwidth"
                  v-loading="loading"
                  :default-sort="{ prop: 'p.name', order: 'ascending', sortable:'p.name' }"
                  @sort-change="sortchange" :span-method="arraySpanMethod">
            <el-table-column label="Project/Overhead"
                             :sortable="cansort == false ? false : 'p.name'"
                             width="210">
                <template #default="scope">
                    <div v-if="!scope.row.edited">
                        <el-select class="fullwidth"
                                   v-bind:style="{ width: '280px' }"
                                   v-model="scope.row.t.projectid"
                                   @change="selectproject($event, scope.row)"
                                   placeholder="Project/Overhead"
                                   clearable
                                   filterable
                                   remote
                                   popper-class="customselect"
                                   reserve-keyword
                                   :remote-method="remoteMethodproject">
                            <el-option v-for="item in projectoptions"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                                <span>{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </div>
                    <div v-if="scope.row.edited">{{ scope.row.p.name }}</div>
                </template>
            </el-table-column>

            <el-table-column width="83px">
                <template #header>
                    <el-select class="innerinputfirst" v-model="showprojectsvalue" placeholder="Select" @change="getinvoiceprojectlist">
                        <el-option
                            v-for="item in showprojectslist"
                            :key="item.name"
                            :label="item.name"
                            :value="item.value"/>
                    </el-select>
                </template>
            </el-table-column>

            <el-table-column label="Activity Area "
                             :sortable="cansort == false ? false : 'a.name'"
                             width="290">
                <template #default="scope">
                    <div v-if="!scope.row.edited">
                        <el-select v-model="scope.row.t.actionid"
                                   v-if="scope.row.p != undefined && scope.row.p.typeid == 2"
                                   @change="getsubaction(scope.row)"
                                   placeholder="Activity Area"
                                   v-bind:style="{ width: '280px' }"
                                   class="fullwidth">
                            <el-option v-for="item in scope.row.projectactions"
                                       :key="item.id"
                                       popper-class="customselect"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-if="scope.row.p != undefined && scope.row.p.typeid == 1"
                                   v-model="scope.row.t.projectpartid"
                                   clearable
                                   placeholder="Project Part"
                                   class="fullwidth"
                                   popper-class="customselect"
                                   @change="projectpartchange(scope.row)"
                                   v-bind:style="{ width: '280px' }">
                            <el-option v-for="item in scope.row.projectparts"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div v-if="scope.row.edited">
                        <div v-if="scope.row.p.typeid == 1">
                            {{ scope.row.pp.name == null ? "Single Bill" : scope.row.pp.name}}
                        </div>
                        <div v-if="scope.row.p.typeid == 2">
                            {{ scope.row.a.name }}
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Actions"
                             :sortable="cansort == false ? false : 's.name'"
                             width="290">
                <template #default="scope">
                    <div v-if="!scope.row.edited && scope.row.p != undefined">
                        <el-select v-model="scope.row.t.actionid"
                                   v-if="scope.row.p != undefined && scope.row.p.typeid == 1"
                                   popper-class="customselect"
                                   @change="editedchanges(true, scope.row)"
                                   v-bind:style="{ width: '280px' }"
                                   placeholder="Action"
                                   class="fullwidth">
                            <el-option v-for="item in scope.row.projectactions"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="scope.row.t.subactionid" class="fullwidth"
                                   v-if="scope.row.p != undefined && scope.row.p.typeid == 2"
                                   v-bind:style="{ width: '280px' }"
                                   @change="subactionselect($event, scope.row)" placeholder="Sub-Action">
                            <el-option v-for="item in scope.row.projectsubactions"
                                       :key="item.id"
                                       popper-class="customselect"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div v-if="scope.row.edited">
                        <div v-if="scope.row.p.typeid == 2">
                            {{ scope.row.s.name }}
                        </div>
                        <div v-if="scope.row.p.typeid == 1">
                            {{ scope.row.a.name }}
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="Start"
                             :sortable="cansort == false ? false : 't.startminute'" width="70">
                <template #default="scope">
                    <div v-if="!scope.row.edited">
                        <el-time-select v-model="scope.row.t.startname"
                                        start="00:05"
                                        step="00:05"
                                        end="23:55"
                                        class="time innerinputfirst customdate"
                                        @change="starttimechange(scope.row)"
                                        :clearable="false"
                                        placeholder="Start">
                        </el-time-select>
                    </div>
                    <div v-if="scope.row.edited">{{ timeget(scope.row.t.startminute) }}</div>
                </template>
            </el-table-column>

            <el-table-column label="Work Description"
                             :sortable="cansort == false ? false : 't.comments'"
                             width="290">
                <template #default="scope">
                    <div v-if="!scope.row.edited">
                        <el-input v-model="scope.row.t.comments"
                                  placeholder="Work Description"
                                  class="fullwidth breakword padding5"
                                  type="textarea"
                                  @change="editedchanges(true, scope.row)"
                                  :autosize="{ minRows: 1, maxRows: 4 }"></el-input>
                    </div>
                    <div v-if="scope.row.edited">{{ scope.row.t.comments }}</div>
                </template>
            </el-table-column>

            <el-table-column label="Finish"
                             width="70">
                <template #default="scope">
                    <div v-if="!scope.row.edited">
                        <el-time-select v-model="scope.row.t.finishname"
                                        @change="endtimechange(scope.row)"
                                        :min-time="scope.row.t.startname"
                                        placeholder="Finish"
                                        start="00:05"
                                        step="00:05"
                                        end="23:55"
                                        class="time innerinputfirst customdate"
                                        :clearable="false">
                        </el-time-select>
                    </div>
                    <div v-if="scope.row.edited">{{ getendtime(scope.row) }}</div>
                </template>
            </el-table-column>

            <el-table-column label="Actions" width="70">
                <template #default="scope">
                    <div class="floatleft">
                        <div v-if="scope.row.edited">
                            <el-button class="padding7"
                                       :disabled="!editpermission"
                                       icon="edit-pen" type="primary"
                                       @click="editrow(scope.row, scope.$index)"></el-button>
                        </div>
                        <div v-if="!scope.row.edited && scope.row.t.id > 0">
                            <el-button type="success"
                                       class="padding7"
                                       :disabled="!editpermission"
                                       @click="savesheet(scope.row)"
                                       v-loading.fullscreen.lock="timesheetsaving"
                                       icon="circle-check"></el-button>
                        </div>
                    </div>
                    <div class="floatright" v-if="scope.row.t.id > 0">
                        <el-button type="danger"
                                   class="padding7"
                                   icon="delete"
                                   :disabled="!editpermission"
                                   @click="deleterow(scope.row)"></el-button>
                    </div>
                    <div v-if="scope.row.t.id <= 0">
                        <el-button class="fullwidth"
                                   :disabled="!editpermission"
                                   @click="savesheet(scope.row)"
                                   v-loading.fullscreen.lock="timesheetsaving"
                                   type="success">Post</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
    </div>
</template>
<script>
    import moment from "moment";
    import { ElMessageBox } from 'element-plus'
    export default {
        data() {
            return {
                defaultentry:null,
                cansort:true,
                loading: false,
                user: this.$store.state.user,
                currenttime: "",
                projects: [],
                projectoptions: [],
                view: {
                    ispage: true,
                    filter: [
                        { table: "p", column: "name", value: "" },
                        { table: "a", column: "name", value: "" },
                        { table: "s", column: "name", value: "" },
                        { table: "t", column: "startminute", value: "" },
                        { table: "t", column: "duration", value: "" },
                        { table: "t", column: "comments", value: "" },
                        { table: "t", column: "date", value: "", operation: "=" },
                        { table: "t", column: "userid", value: "", operation: "=" }
                    ],
                },
                detailform: [],
                addrow: true,
                clickedit: false,
                timesheetsaving: false,
                showprojectslist: [
                    {value: 0, name: "WIP"},
                    {value: 2, name: "All"},
                    {value: 1, name: "Invoiced"}
                ],
                showprojectsvalue: 0
            };
        },
        methods: {
            showtime: function () {
                var time = new Date().toLocaleString("en-GB", { timeZone: "Europe/London" }).split(" ")[1];
                this.currenttime = time;
                setTimeout(() => {
                    this.showtime();
                }, 1000);
            },
            plusdate(name) {
                const currentdate = moment();
                var cdate = "";
                if (name == "add") {
                    cdate = moment(this.view.filter[6].value, "YYYY-MM-DD").add(1, 'days');
                } else {
                    cdate = moment(this.view.filter[6].value, "YYYY-MM-DD").subtract(1, 'days');
                }

                const isFutureDate = cdate.isAfter(currentdate);
                if (!isFutureDate) {
                this.view.filter[6].value = cdate.format('YYYY') + "-" + cdate.format('MM') + "-" + cdate.format('DD');
                this.defaultentry = null;
                this.refresh();
                }
            },
            datechange(event) {
                if (event == null) {
                    this.view.filter[6].value = moment(Date()).format("YYYY-MM-DD");
                }
                this.defaultentry = null;
                this.refresh();
            },
            sortchange: function (sort) {
                this.view.sortcol = sort.column.sortable;
                this.view.sortorder = sort.order;
                this.refresh();
            },
            editedchanges(event, row) {
                row.t.canedit = event;
            },
            getendtime(row) {
                var durations = [];
                durations.push(this.timeget(row.t.startminute));
                var h = Math.floor(row.t.duration / 60), m = row.t.duration % 60;
                durations.push((h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString());
                const totalDurations = durations.slice(1).reduce((prev, cur) => moment.duration(cur).add(prev), moment.duration(durations[0]));
                return moment.utc(totalDurations.asMilliseconds()).format("HH:mm");
            },
            previousfinishtime(row) {
                var durations = [];
                durations.push(this.timeget(row.t.previousstartminute));
                var h = Math.floor(row.t.previousduration / 60), m = row.t.previousduration % 60;
                durations.push((h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString());
                const totalDurations = durations.slice(1).reduce((prev, cur) => moment.duration(cur).add(prev), moment.duration(durations[0]));
                return moment.utc(totalDurations.asMilliseconds()).format("HH:mm");
            },
            deleterow(row) {
                if (row.p == undefined) {
                    this.refresh();
                    this.$message({
                        type: "success",
                        message: "Delete completed"
                    });
                    return;
                }
                this.$confirm("This will permanently delete the " + row.p.name + ". Continue?", "Warning", {
                    confirmButtonText: "OK",
                    cancelButtonText: "Cancel",
                    type: "warning"
                }).then(() => {
                    var data = { id: row.t.id };
                    this.$http.post("timesheet/delete", data)
                        .then(() => {
                            this.$message({
                                type: "success",
                                message: "Delete completed"
                            });
                            this.cansort = true;
                            this.refresh();
                        })
                        .catch((error) => {
                            this.hideloading();
                            this.apierror(error);
                        });
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "Delete canceled"
                    });
                });
            },
            refresh: function () {
                if (!this.view.sortcol) {
                    this.view.sortcol = "t.startminute";
                    this.view.sortorder = "ascending";
                }

                this.showloading();
                this.axios.post("TimeSheet/GetView", this.view)
                    .then((response) => {
                        this.hideloading();
                        this.detailform = response.data.data;
                        this.detailform.forEach(e => {
                            Object.assign(e.t, { previousstartminute: e.t.startminute });
                            Object.assign(e.t, { previousduration: e.t.duration });
                            if (e.p.typeid == 1 && e.s.name != undefined) {
                                const scomment = e.t.comments;
                                e.t.comments = e.s.name + " - " + scomment;
                            }
                            e.edited = true;
                        });
                        this.view = response.data.view;
                        if (this.detailform.length <= 0 || this.addrow) {
                            this.addtimesheet();
                        }
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },
            addtimesheet() {
                var t = {};
                if(this.defaultentry == null){
                    this.defaultentry = {id: 0,
                    projectid: null,
                    finishname: "",
                    startname: "",
                    comments: "",
                    projectpartname: "",
                    actionid: "",
                    subactionid: null,
                    projectpartid: null,
                    startminute: null,
                    duration: null};
                  
                }
                t = this.defaultentry;
                // var t = {
                //     id: 0,
                //     projectid: null,
                //     finishname: '',
                //     startname: '',
                //     comments: '',
                //     projectpartname: '',
                //     actionid: null,
                //     subactionid: null,
                //     projectpartid: null,
                //     startminute: null,
                //     duration: null
                // };
                if (this.detailform.length == 0) {
                    var start = moment("01-01-2000 " + this.currenttime);
                    var istime = start.hour().toString() + ":" + start.minute().toString();
                    if (istime == "23:55") {
                        t.startminute = 5;
                        t.startname = "00:05";
                        t.duration = 10;
                        t.finishname = "00:10";
                    } else {
                        const stepfive = 5 - (start.minute() % 5);
                        var timestart = moment(start).add(stepfive, "minutes").format("HH:mm");
                        t.startminute = parseInt(timestart.replace(":", ""));
                        t.startname = timestart;

                        timestart = moment("01-01-2000 " + timestart);
                        const stepten = 5 - (timestart.minute() % 5);
                        const timefinish = moment(timestart).add(stepten, "minutes").format("HH:mm");
                        t.duration = parseInt(timefinish.replace(":", ""));
                        t.finishname = timefinish;
                    }
                } else {
                    t.startname = this.getendtime(this.detailform[this.detailform.length - 1]);
                    t.startminute = t.startname.replace(":", "");
                    t.startminute = parseInt(t.startminute);

                    var newtime = moment("01-01-2000 " + t.startname + ":00");
                    const s1 = 5 - (newtime.minute() % 5);
                    const newtimefinish = moment(newtime).add(s1, "minutes").format("HH:mm");
                    t.duration = parseInt(newtimefinish.replace(":", ""));
                    t.finishname = newtimefinish;
                }
                var newrow = { t, edited: false };
                if(newrow.t.projectid != null){
                    this.selectproject(newrow.t.projectid, newrow);
                }
                this.detailform.push(newrow);
            },
            remoteMethodproject(query) {
                if (query == "") {
                    this.projectoptions = this.projects;
                }
                else {
                    this.projectoptions = this.projects.filter(item => {
                        return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }
            },
            selectproject: function (item, row) {
                row.t.canedit = true;
                row.projectactions = [];
                row.projectparts = [];
                row.projectsubactions = [];
                // row.t.id <= 0 ||
                if (item == "" || (row.p && row.p.id != item)) {
                    row.t.subactionid = null;
                    row.t.actionid = '';
                    row.t.projectpartid = null;
                }
                if (item != "") {
                    row.p = this.projects.find(x => x.id == item);
                    row.t.projectid = row.p.id;
                    this.getaction(row);
                }
            },
            getaction: function (row) {
                row.projectsubactions = [];
                this.axios.post("action/getactions?useintimeentry="+true, row.p)
                    .then((response) => {
                        row.projectactions = response.data.acs;
                        if (response.data.pps.length == 0) {
                            row.t.projectpartid = null;
                            row.projectparts.push({ name: 'Single Bill', id: null });
                        } else {
                            row.projectparts = response.data.pps;
                            if (row.projectparts.length == 1) {
                                row.pp = row.projectparts[0];
                                row.t.projectpartid = row.pp.id;
                            }

                        }
                        // row.t.id > 0 &&
                        if ( row.t.actionid != "" && row.p.typeid == 2) {
                           this.getsubaction(row);
                        }
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.apierror(error);
                    });
            },
            getsubaction: function (row) {
                debugger
                row.t.canedit = false;
                if (row.p.typeid == 2) {
                    var data = { id: row.t.subactionid == null ? 0 : parseInt(row.t.subactionid), actionid: row.t.actionid };
                    this.axios.post("action/getsubactions", data)
                        .then((response) => {
                            row.projectsubactions = response.data;
                            if (row.projectsubactions.length == 1) {
                                row.projectsubactions.forEach(x => {
                                    row.t.subactionid = x.id;
                                });
                            }
                            var id = row.projectsubactions.find(x => x.id == row.t.subactionid);
                            if (!id) {
                                row.t.subactionid = null;
                            }
                            if (!this.clickedit) {
                                row.t.canedit = true;
                            }
                            else{
                                row.t.canedit = false;
                                this.clickedit = false;
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.hideloading();
                            this.loading = false;
                            this.apierror(error);
                        });
                }
            },
            projectpartchange(row) {
                row.t.canedit = true;
                if (row.t.projectpartid != null || row.t.projectpartid != "") {
                    row.t.actionid = null;
                }
            },
            subactionselect(event, row) {
                row.t.canedit = true;
                row.t.subactionid = event;
            },
            starttimechange: function (row) {
                row.t.canedit = true;
                var start = row.t.startname.replace(":", "");
                row.t.startminute = parseInt(start);
            },
            endtimechange: function (row) {
                row.t.canedit = true;
                var end = row.t.finishname.replace(":", "");
                row.t.duration = parseInt(end);
            },
            gettimetotal(row) {
                var startTime = moment(row.t.startname, "HH:mm");
                var endTime = moment(row.t.finishname, "HH:mm");
                var duration = moment.duration(endTime.diff(startTime));
                row.t.duration = parseInt(duration.asMinutes());
                var start = row.t.startname.split(":");
                row.t.startminute = Number(start[0]) * 60 + Number(start[1]);
                row.t.date = new Date(this.view.filter[6].value);
            },
            editrow(row, index) {
                var findproject = this.projects.find(x => x.id == row.p.id);
                if (!findproject) {
                    ElMessageBox.alert("Project displayed in current row is not available in dropdown list, so to edit row,\
                                        Please select 'All' option in dropdown of Project/Overhead column header.", 'Warning', {
                        confirmButtonText: 'OK',
                        type: 'warning',
                    })
                    return;
                }
                this.cansort = false;
                this.clickedit = true;
                this.detailform[index].edited = false;
                this.selectproject(row.p.id, row);
                row.t.startname = this.timeget(row.t.startminute);
                row.t.finishname = this.getendtime(row);
                row.t.canedit = false;
            },
            savesheet: function (row) {
                if (!this.timesheetsaving) {
                    this.timesheetsaving = true;
                    if (row.t.canedit == false) {
                        this.timesheetsaving = false;
                        this.refresh();
                        this.cansort = true;
                        this.$message({ type: "info", message: "Nothing to save" });
                        return;
                    }
                    this.myvalidate(row).then((err) => {
                        if (err) {
                            return;
                        }
                        this.showloading();
                        this.gettimetotal(row);
                        if (row.p.typeid == 2) {
                            row.t.projectpartid = null;
                        }
                        this.axios.post("timesheet/save", row.t)
                            .then(() => {
                                this.timesheetsaving = false;
                                this.$message({ showClose: true, message: "Save Successful!", type: "success" });
                                this.hideloading();
                                this.defaultentry = null;
                                this.cansort = true;
                                this.refresh();
                            })
                            .catch((error) => {
                                this.timesheetsaving = false;
                                this.hideloading();
                                this.apierror(error);
                            });
                    });
                }
            },
            myvalidate: function (row) {
                var warning = [];
                var err = [];
                if (row.t.projectid == null || row.t.projectid == "") {
                    err.push("Project should not be empty.");
                }
                if (row.t.actionid == null || row.t.actionid == "") {
                    err.push("Action should not be empty.");
                }
                if (row.p != undefined && row.p.typeid == 2) {
                    if (row.t.subactionid == null || row.t.subactionid == "") {
                        err.push("Sub-Action should not be empty.");
                    }
                }
                if (row.t.startname == "" || row.t.startname == undefined) {
                    err.push("Start Time should not be empty.");
                }
                if (row.t.finishname == "" || row.t.finishname == undefined) {
                    err.push("Finish Time should not be empty.");
                }
                if (row.t.startname == "00:00") {
                    err.push("Start should be greater than 00:00");
                }
                if (row.t.startname == row.t.finishname) {
                    err.push("Start and Finish should not be same.");
                }
                if (row.t.startname > row.t.finishname) {
                    err.push("Start should be less than Finish.");
                }
                if (row.t.startname != undefined) {
                    if (!this.isTime(row.t.startname)) {
                        err.push("Invalid Start time");
                    }
                }
                if (row.t.finishname != undefined) {
                    if (!this.isTime(row.t.finishname)) {
                        err.push("Invalid Finish time");
                    }
                }
                if (row.t.comments.length >= 250) {
                    warning.push(`Your Comments is ${row.t.comments.length} characters`);
                    warning.push("over the point at which reports start to distort");
                }
                if (row.t.comments.length >= 300) {
                    err.push("Comments should not exceed 300 characters.");
                }
                this.detailform.forEach(er => {
                    if (er.t != undefined) {
                        var start = this.timeget(er.t.previousstartminute);
                        var end = this.previousfinishtime(er);
                        if (er.t.id != row.t.id) {
                            if (row.t.startname < end && row.t.startname >= start) {
                                err.push("Start time overlap with " + start + " and " + end);
                                return
                            }
                            if (row.t.finishname <= end && row.t.finishname > start) {
                                err.push("Finish time overlap with " + start + " and " + end);
                                return
                            }
                            if (start < row.t.finishname && start >= row.t.startname) {
                                err.push("Start and Finish time overlap with " + start + " and " + end);
                                return;
                            }
                            if (end <= row.t.finishname && end > row.t.startname) {
                                err.push("Start and Finish time overlap with " + start + " and " + end);
                                return;
                            }
                        }
                    }
                });
                if (err.length > 0) {
                    this.timesheetsaving = false;
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(err.join("<br/>") + style, "Critical data entry errors", {
                        dangerouslyUseHTMLString: true,
                    });
                    return new Promise(function (resolve) {
                        resolve(true);
                    });
                }
                if (warning.length > 0) {
                    this.timesheetsaving = false;
                    return this.Warningbox(warning.join(" "), "", "").then((row) => {
                        return row;
                    })
                } else {
                    this.timesheetsaving = false;
                    return new Promise(function (resolve) {
                        resolve(false);
                    });
                }
            },
            Warningbox(msg, suc, err) {
                return this.$confirm(msg, 'Warning', {
                    confirmButtonText: 'OK', cancelButtonText: 'Cancel', type: 'warning'
                }).then(() => {
                    if (suc.length > 0) {
                        this.$message({ type: 'success', message: suc });
                    }
                    return true;
                }).catch(() => {
                    if (err.length > 0) {
                        this.$message({ type: 'info', message: err });
                    }
                    return false;
                });
            },
            isTime(val) {
                if (val.length != 5) {
                    return false;
                }
                if (val.indexOf(":") != 2) {
                    return false;
                }
                if (!isFinite(val.split(":")[0]) || !isFinite(val.split(":")[1])) {
                    return false;
                }
                return true;
            },
            arraySpanMethod: function({columnIndex}) {
                if (columnIndex === 0) {
                    return [1, 2];
                } else if (columnIndex === 1) {
                    return [0, 0];
                }
            },
            getinvoiceprojectlist: function() {
                this.$nextTick(() => {
                    this.projectoptions = [];
                });
                this.axios.post("project/getinvoiceprojects?v=" + this.showprojectsvalue, {})
                .then((response) => {
                    this.projectoptions = response.data;
                    this.projects = response.data;
                })
                .catch((err) => {
                    this.apierror(err);
                });
            }
        },

        computed: {
            timeget() {
                return function (date) {
                    if (date != null) {
                        var m = date % 60;
                        var h = (date - m) / 60;
                        return (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
                    }
                    return "";
                };
            }
        },

        created() {
            this.view.filter[6].value = moment(Date()).format("YYYY-MM-DD");
            // if (this.$store.state.projects.length == 0) {
            //     this.searchproject().then(() => {
            //         this.projectoptions = this.$store.state.projects;
            //         this.projects = this.$store.state.projects;
            //     });
            // } else {
            //     this.projectoptions = this.$store.state.projects;
            //     this.projects = this.$store.state.projects;
            // }
            this.getinvoiceprojectlist();
            this.refresh();
            this.showtime();
        }
    }
</script>
<style>
    .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>
