import { createApp } from 'vue';
import { eventbus } from './eventbus';
import ElementPlus from 'element-plus';
import ElementTiptapPlugin from 'element-tiptap-vue3-fixed';
import 'element-tiptap-vue3-fixed/lib/style.css';
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import locale from 'element-plus/lib/locale/lang/en'
import axios from 'axios';
import VueAxios from 'vue-axios';
import mixin from '../src/mixins';
import App from './App.vue';
import router from './router/routers';
import './css/site.css';
import store from "./store/store";
import Vuex from 'vuex';
import "./css/site.css";
import '@neos21/bootstrap3-glyphicons/dist/css/bootstrap3-glyphicons.min.css';
import direction from "./classes/direction"
import commonutils from "./classes/commonutils"

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.$direction =  new direction(this);
app.config.globalProperties.$commonutils = new commonutils();
// createApp(App)
app.use(router)
.use(ElementPlus ,{ size: 'small', zIndex: 3000 })
.use(ElementTiptapPlugin)
.use(VueAxios, axios)
.use(eventbus)
.use(store)
.use(Vuex)
.mixin(mixin)
.mount('#app')
