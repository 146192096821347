<template>
    <div class="width330px">
       <!-- <el-affix :offset="52" > -->
            <div class="colorwhite stickyheader">
                <el-row>
                    <el-col :span="12">
                        <div class="headerstyle">Pages</div>
                    </el-col>
                </el-row>
                <hr class="spaceBetween" />
            </div>
        <!-- </el-affix> -->

        <el-table :data="tableData" border>
            <el-table-column prop="p.name" label="Page Name" width="180"></el-table-column>
            <el-table-column prop="" label="Status" width="150">
                <template #default="scope">
                    <el-select v-model="scope.row.p.status"
                                placeholder="Select"
                                @change="savepagestatus(scope.row.p)">
                        <el-option :disabled="!editpermission"
                                    v-for="item in [{ value: 0, name: 'Complete' }, { value: 1, name: 'Under Development' }, { value: 2, name: 'To Review' }]"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: []
        }
    },

    methods: {
        getPages: function () {
            this.showloading();
            this.$http.get("account/getpages")
                .then(response => {
                    this.tableData = response.data;
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.apierror(err);
                });
        },

        savepagestatus: function(row) {
            this.showloading();
            this.$http.post("account/savePageStatus", row)
                .then(response => {
                    this.$message({
                        showClose: true,
                        message: response.data.message,
                        type: "success"
                    });
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.apierror(err);
                });
        }
    },

    created() {
        this.getPages();
    },
}
</script>