<template>
    <div>
        <!-- <el-affix :offset="52" > -->
            <div class="colorwhite stickyheader">
                <el-row>
                    <el-col :span="12">
                        <div class="headerstyle">Access - Sign Off</div>
                    </el-col>
                    <el-col :span="12">
                        <div class="headerstyle floatright">
                            <el-button type="success" @click="saveAccessSignoff" icon="circle-check" :disabled="!editpermission">Save</el-button>
                            <el-button type="primary" @click="addRow" class="marginleft5" icon="circle-plus-filled" :disabled="!editpermission"></el-button>
                            <el-button @click="$router.go(-1)" class="marginleft5" icon="back" :disabled="!editpermission">Back</el-button>
                        </div>
                    </el-col>
                </el-row>
                <hr class="spaceBetween" />
            </div>
        <!-- </el-affix> -->

        <el-table :data="tableData" style="width: 100%" border>
            <el-table-column prop="su.firstname" label="Signatory" :width="column1width + ''" sortable>
                <template #default="scope">
                    <el-select v-model="scope.row.aso.signatoryuserid" @visible-change="userchanges(scope.row, 1, $event)" placeholder="Select" class="fullwidth" clearable>
                        <el-option
                            v-for="item in users1"
                            :key="item.id"
                            :label="item.firstname + ' ' + item.lastname"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>

            <el-table-column prop="p.name" label="Project/Overhead" width="320" sortable>
                <template #default="scope">
                    <el-select  class="fullwidth"
                        v-model="scope.row.aso.projectid"
                        @change="selectproject($event, scope.row)"
                        clearable
                        placeholder="Project/Overhead"
                        filterable
                        remote
                        popper-class="customselect"
                        reserve-keyword
                        :remote-method="remoteMethodproject">
                            <el-option
                                v-for="item in projectoptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                    </el-select>  
                </template>
            </el-table-column>

            <el-table-column prop="a.name" label="Activity Area" width="320" sortable>
                <template #default="scope">
                    <el-select 
                        v-model="scope.row.aso.actionid" clearable
                        placeholder="Actions"
                        popper-class="customselect"
                        class="fullwidth"
                        @change="editedchanges(true, scope.row)">
                            <el-option 
                                v-for="item in scope.row.actions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                    </el-select>
                </template>
            </el-table-column>

            <el-table-column prop="tu.firstname" label="Time Made By" sortable>
                <template #default="scope">
                    <el-select v-model="scope.row.aso.timemadebyusersid" @visible-change="userchanges(scope.row, 2, $event)" @change="timemadeuserchanges(scope.row, $event)" placeholder="Select" class="fullwidth" multiple clearable>
                        <el-option
                            v-for="item in users2"
                            :key="item.id"
                            :label="item.firstname + ' ' + item.lastname"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            users1: [],
            users2: [],
            projectoptions:[],
            projects:[],
            actions: [],
            column1width: 100
        }
    },
    methods: {
        getUsers: function() {
            this.$http.get("account/getusers")
                .then((response) => {
                    this.users1 = JSON.parse(response.data);
                    this.users1 = this.users1.filter(u => u.active);

                    this.users2 = JSON.parse(JSON.stringify(this.users1));
                    this.users2.unshift({ id: -1, firstname: "All", lastname: "" });
                })
                .catch((err) => {
                    this.apierror(err);
                });
        },

        getoverheadandallproject: function() {
            this.$http.get("project/searchOverheadAndAllProject")
                .then((response) => {
                    this.projectoptions = response.data;
                    this.projects = response.data;
                    this.getallactions();
                })
                .catch((err) => {
                    this.apierror(err);
                });
        },

        selectproject: function (item, row) {
            row.aso.actionid = null;
            if(item != ""){
                row.p = this.projects.find(x => x.id == item);
                this.findprojectactions(row);
                this.editedchanges(true, row);
            }else{
                row.actions = [];
            }
        },

        remoteMethodproject(query){
            if(query == ""){
                this.projectoptions = this.projects
            }else{
            this.projectoptions = this.projects.filter(item => {
                return item.name.toLowerCase()
                    .indexOf(query.toLowerCase()) > -1;
                });
            }
        },

        getallactions: function () {
            this.$http.get("action/getAllActions")
            .then((response) => {
                this.actions = response.data;
                this.tableData.forEach(td => {
                    this.findprojectactions(td);
                });
            })
            .catch((err) => {
                this.apierror(err);
            });
        },

        findprojectactions: function (row) {
            row.actions = this.actions.filter(a => {
                if ((a.projectid && a.projectid == row.aso.projectid) || (row.p && row.p.typeid == 2 && a.typeid == 6)) {
                    return a;
                } else if ((row.p && row.p.typeid == 4) && (a.typeid == 1 || a.typeid == 4 || a.typeid == 5)) {
                    return a;
                }
            });
        },

        getAccessSignoff: function () {
            this.showloading();
            this.column1width = 100;
            this.$http.get("account/getaccesssignoffdata")
                .then((response) => {
                    this.hideloading();
                    this.tableData = response.data;
                    this.tableData.forEach(as => {
                        as.aso.timemadebyusersid = [];

                        let fullname = as.su.firstname + ' ' + as.su.lastname;
                        let col1width = this.measureTextWidth(fullname) + 50;
                        if (col1width > this.column1width) {
                            this.column1width = col1width;
                        }

                        for (let i = 0; i < as.aso.accesssignofftimemadeusers.length; i++) {
                            as.aso.timemadebyusersid.push(as.aso.accesssignofftimemadeusers[i].timemadebyuserid);
                        }
                    });

                    this.getUsers();
                    this.getoverheadandallproject();
                    this.addRow();
                })
                .catch((err) => {
                    this.hideloading();
                    this.apierror(err);
                });
        },

        saveAccessSignoff: function () {
            let asodata = []; let uniquedata = []; let errmsg = [];
            this.tableData.forEach((f, index) => { 
                index = index + 1;

                let isduplicate = uniquedata.find((obj) => {
                    if (obj.signatoryuserid == f.aso.signatoryuserid && obj.projectid == f.aso.projectid && obj.actionid == f.aso.actionid) {
                        let ischildduplicate = obj.timemadebyusersid.every(childobj => obj.timemadebyusersid.length == f.aso.timemadebyusersid.length && f.aso.timemadebyusersid.includes(childobj));
                        
                        if (ischildduplicate) {
                            return obj;
                        }
                    }
                });

                if (!isduplicate) {
                    uniquedata.push(f.aso);
                } else {
                    errmsg.push("Row: " + index + " is duplicated.");
                }

                if (f.edited) {
                    for (let i = 0; i < f.aso.timemadebyusersid.length; i++) {
                        let ismatchedtimemadeuserid = f.aso.timemadebyusersid.includes(f.aso.accesssignofftimemadeusers[i] && f.aso.accesssignofftimemadeusers[i].timemadebyuserid);
                        if (!ismatchedtimemadeuserid) {
                            f.aso.accesssignofftimemadeusers.push({ id: 0, accesssignoffid: f.aso.id, timemadebyuserid: f.aso.timemadebyusersid[i]});
                        }
                    }

                    if (!f.aso.signatoryuserid) {
                        errmsg.push("In Row " + index + ": Signatory user should not be empty." );
                    }

                    if (!f.aso.projectid) {
                        errmsg.push("In Row " + index + ": Project should not be empty." );
                    }

                    if (!f.aso.actionid) {
                        errmsg.push("In Row " + index + ": Activity should not be empty." );
                    }

                    if (f.aso.timemadebyusersid.length == 0) {
                        errmsg.push("In Row " + index + ": Time made by user should not be empty." );
                    }

                    asodata.push(f.aso);
                }
            });

            if (errmsg.length > 0) {
                var style = "<style> .el-message-box {width:400px;} </style>";
                this.$alert(errmsg.join("<br/>") + style, "Critical data entry errors", {
                    dangerouslyUseHTMLString: true,
                });
                return;
            }
            
            if (asodata.length > 0) {
                this.showloading();
                this.$http.post("account/saveaccesssignoffdata", asodata)
                .then((response) => {
                    this.hideloading();
                    this.$message({ showClose: true, message: response.data.message, type: "success" });
                    this.getAccessSignoff();
                })
                .catch((err) => {
                    this.hideloading();
                    this.apierror(err);
                });
            } else {
                this.$message({ type: "info", message: "Nothing to save" });
                return;
            }
        },

        userchanges: function (row, usertype, value) {
            if (value && usertype == 1 && row.aso.timemadebyusersid) {
                this.users1 = this.users1.filter(u1item => u1item.id !== row.aso.timemadebyusersid );
            } else if (value && usertype == 2 && row.aso.signatoryuserid) {
                this.users2 = this.users2.filter(u2item => u2item.id !== row.aso.signatoryuserid );
            }

            this.editedchanges(true, row);
        },

        timemadeuserchanges: function(row, event){
            let alloption = event.filter(f => f == -1);
            if (alloption.length > 0) {
                event = alloption;
                row.aso.timemadebyusersid = event;
            }

            let matchedasotu =  row.aso.accesssignofftimemadeusers.filter(f => event.includes(f.timemadebyuserid));
            row.aso.accesssignofftimemadeusers = matchedasotu;

            this.editedchanges(true, row);
        },

        editedchanges: function ( isedited, row) {
            row.edited = isedited;
        },

        addRow: function() {
            let foundemptyrow = this.tableData.find(td => !td.aso.signatoryuserid || !td.aso.timemadebyusersid || !td.aso.projectid || !td.aso.actionid);

            if (!foundemptyrow) {
                let item = { aso: { signatoryuserid: null, timemadebyusersid: [], projectid: null, actionid: null, accesssignofftimemadeusers: [] } }
                this.tableData.push(item);
            }
        }
    },

    created: function () {
        this.getAccessSignoff();
    }
}
</script>