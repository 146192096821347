<template>
    <div>
        <!-- <el-affix :offset="52"> -->
            <div class="colorwhite stickyheader projecthourswidth">
                <div>
                    <span class="headerstyle">Project Hours</span>
                </div>
                <div class="spaceBetween" />

                <el-row>
                    <el-col :span="18">
                        <span class="floatleft">
                            <el-autocomplete v-model="proj.name" :fetch-suggestions="searchproject" class="width50 left"
                                popper-class="customselect" clearable placeholder="All Project" @select="selectproject"
                                @change="isproject">
                                <template #default="{ item }">
                                    <div class="value">{{ item.name }}</div>
                                </template>
                            </el-autocomplete>
                        </span>

                        <span class="paddingl5 floatleft">
                            <el-date-picker v-model="filter.startdate" type="date" format="ddd DD-MMM-YYYY"
                                :editable="false" :clearable="false" class="datewidth135" placeholder="From Date"
                                :disabled-date="disablefuturedate" @change="datechange($event, 'startdate')">
                            </el-date-picker>
                        </span>

                        <span class="paddingl5 floatleft">
                            <el-date-picker v-model="filter.enddate" type="date" format="ddd DD-MMM-YYYY" :editable="false"
                                :clearable="false" class="datewidth135" placeholder="To Date"
                                :disabled-date="disablefuturedate" @change="datechange($event, 'enddate')">
                            </el-date-picker>
                        </span>
                    </el-col>

                    <el-col :span="6">
                        <span v-if="showPH">
                            <el-button class="marginleft4px right" icon="back" @click="$router.go(-1)">Back</el-button>
                            <el-button @click="saveprojecthours" class="right" plain type="success"
                                :disabled="isaddedfilter || !editpermission" icon="circle-check">Save</el-button>
                        </span>
                    </el-col>
                </el-row>
                <hr class="spaceBetween" />
            </div>
        <!-- </el-affix> -->

        <el-row v-if="showPH" class="projecthourswidth">
            <el-table :data="getprojectcomputed" class="fullwidth" :default-expand-all="true"
            :cell-style="cellStylewhiteborder" :row-class-name="tableRowClassprojectName" :cell-class-name="tableRowClassprojectName">
                <el-table-column type="expand" width="50">
                    <template #default="pr">
                        <el-table :show-header="false" :cell-style="cellStylewhiteborder" :default-expand-all="true" :cell-class-name="tableRowClassPartName"
                            :data="pr.row.proj.projectparts" :row-class-name="tableRowClassPartName" class="fullwidth">
                            <el-table-column type="expand" width="50">
                                <template #default="scope">
                                    <el-table :cell-style="cellStylewhiteborder" :cell-class-name="tableRowClassactionheaderName" :default-expand-all="true"
                                        :show-header="false" :row-class-name="tableRowClassactionheaderName"
                                        class="fullwidth" :data="scope.row.actionsview">
                                        <el-table-column type="expand" width="50">
                                            <template #default="ah">
                                                <el-table :cell-style="cellStyleblackborder" :default-expand-all="true"
                                                    :cell-class-name="tableRowClassactionName" :show-header="false"
                                                    :row-class-name="tableRowClassactionName" class="fullwidth"
                                                    :data="ah.row.pactions">
                                                    <el-table-column type="expand" width="50">
                                                        <template #default="a">
                                                            <el-table :cell-style="cellStyleblackborder" :default-expand-all="true" :show-header="false"
                                                                class="fullwidth" v-if="a.row.timesheets.length > 0" :data="a.row.timesheets" row-key="id">
                                                                <el-table-column width="50">
                                                                </el-table-column>
                                                                <el-table-column min-width="325" width="325" prop="user">
                                                                    <template #default="t1">
                                                                        <div>
                                                                            {{ t1.row.u.firstname + " " + t1.row.u.lastname
                                                                            }}
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Estimate" align="center"
                                                                    prop="quote" min-width="70" width="70">
                                                                    <template #default="qt">
                                                                        <div>
                                                                            {{ qt.row.quote == null ? "-" : qt.row.quote }}
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column align="center" label="Actual"
                                                                    prop="duration" min-width="70" width="70">
                                                                    <template #default="du">
                                                                        <div>
                                                                            <!-- {{ du.row.actualduration.toFixed(2) }} -->
                                                                            {{ displayhours(du.row.actualduration) }}
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Difference" prop="duration" align="center" min-width="70" width="70">
                                                                    <template #default="du">
                                                                        <div v-if="du">
                                                                            <!-- project -->
                                                                                {{ "" }}
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column align="left" label="Cleaning"
                                                                    prop="duration" min-width="70" width="70">
                                                                    <template #default="du">
                                                                        <div>
                                                                            <!-- timesheet -->
                                                                            <el-input :disabled="isaddedfilter"
                                                                                :precision="2"
                                                                                class="fullwidth inputtypenumlr6px rightimp"
                                                                                v-model="du.row.ph.cleaningminutedisplay"
                                                                                @change="cleaningtime($event, du.row)" />
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column align="center" label="Base" prop="duration"
                                                                    min-width="70" width="70">
                                                                    <template #default="du">
                                                                        <div>
                                                                            <!-- timesheet -->
                                                                            <!-- {{ du.row.baseduration.toFixed(2) }} -->
                                                                            {{ displayhours(du.row.baseduration) }}
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Comment" prop="comment"
                                                                    align="center" min-width="250" width="250">
                                                                    <template #default="com">
                                                                        <div>
                                                                            <!-- timesheet -->
                                                                            <el-row>
                                                                                <el-col :span="2">
                                                                                    <el-checkbox :disabled="isaddedfilter"
                                                                                        v-model="com.row.ph.canedit"
                                                                                        @change="stempchang(com.row)"></el-checkbox>
                                                                                </el-col>
                                                                                <el-col :span="22">
                                                                                    <el-input :disabled="isaddedfilter"
                                                                                        v-model="com.row.ph.comment" />
                                                                                </el-col>
                                                                            </el-row>
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Author" prop="authorname"
                                                                    align="center" min-width="250" width="250">
                                                                    <template #default="u">
                                                                        <div>
                                                                            <div>
                                                                                <!-- timesheet -->
                                                                                {{ getauthname(u.row) }}
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column label="Date Stamp" prop="sdate"
                                                                    align="center" min-width="120" width="120">
                                                                    <template #default="da">
                                                                        <div>
                                                                            <!-- timesheet -->
                                                                            {{ dateget(da.row.ph.editedon) }}
                                                                        </div>
                                                                    </template>
                                                                </el-table-column>
                                                                <el-table-column>
                                                                </el-table-column>
                                                            </el-table>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Name" min-width="325" width="325" prop="name">
                                                    </el-table-column>
                                                    <el-table-column align="center" label="Estimate" prop="quote"
                                                        min-width="70" width="70">
                                                        <template #default="qt">
                                                            <div>
                                                                <!-- action -->
                                                                {{ qt.row.estimatehour == null ? "-" : displayestimatehours(qt.row.estimatehour) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column align="center" label="Actual" prop="duration"
                                                        min-width="70" width="70">
                                                        <template #default="sdu">
                                                            <div>
                                                                <!-- action -->
                                                                    <!-- {{ sdu.row.actualduration.toFixed(2) }} -->
                                                                    {{ displayhours(sdu.row.actualduration) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Difference" prop="duration" align="center" min-width="70" width="70">
                                                        <template #default="du">
                                                            <div v-if="du">
                                                                <!-- project -->
                                                                    <div :class="du.row.rowcolor">
                                                                        {{ getDifferencehours(du.row.actualduration,du.row.estimatehour,du.row) }}
                                                                    </div>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column align="center" label="Cleaning" prop="duration"
                                                        min-width="70" width="70">
                                                        <template #default="sdu">
                                                            <div>
                                                                <!-- action -->
                                                                <!-- {{ sdu.row.cleaningdurationA.toFixed(2) }} -->
                                                                {{ displayhours(sdu.row.cleaningdurationA) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column align="center" label="Base" prop="duration"
                                                        min-width="70" width="70">
                                                        <template #default="sdu">
                                                            <div>
                                                                <!-- action -->
                                                                <!-- {{ sdu.row.basedurationA.toFixed(2) }} -->
                                                                {{ displayhours(sdu.row.basedurationA) }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Comment" prop="comment" align="center"
                                                        min-width="250" width="250">
                                                        <template #default="{ }">
                                                            <div>
                                                                <!-- action -->
                                                                {{ "-" }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Author" prop="author" align="center"
                                                        min-width="250" width="250">
                                                        <template #default="{ }">
                                                            <div>
                                                                <!-- action -->
                                                                <div>
                                                                    {{ "-" }}
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Date Stamp" prop="comment" align="center"
                                                        min-width="120" width="120">
                                                        <template #default="{ }">
                                                            <div>
                                                                <!-- action -->
                                                                {{ "-" }}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column>
                                                    </el-table-column>

                                                </el-table>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Section/Opperative(s)" prop="name" min-width="325"
                                            width="325">
                                            <template #default="n">
                                                <div>
                                                    <!-- actionheader -->
                                                    {{ n.row.a.header }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Estimate" prop="name" align="center" min-width="70"
                                            width="70">
                                            <template #default="n">
                                                <!-- actionheader -->
                                                <!-- <div v-if="n.row.a.name == 'Management and adminstration (general)'">
                                                    {{ displayhours(pr.row.estimatedurationtotalP / 60) }}
                                                </div>
                                                <div v-else>
                                                    {{ "- " }}
                                                </div> -->
                                                <div>{{ displayestimatehours(n.row.estimatedurationtotalAh ) }}</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Actual" prop="duration" align="center" min-width="70"
                                            width="70">
                                            <template #default="du">
                                                <div v-if="du && du.row">
                                                    <!-- paractionheadert -->
                                                    <!-- {{ du.row.actualduration.toFixed(2) }} -->
                                                    {{ displayhours(du.row.actualduration) }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Difference" prop="duration" align="center" min-width="70" width="70">
                                            <template #default="du">
                                                <div v-if="du && du.row ">
                                                    <!-- project -->
                                                        <div :class="du.row.rowcolor">
                                                            {{ getDifferencehours(du.row.actualduration,du.row.estimatedurationtotalAh,du.row) }}
                                                        </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Cleaning" prop="duration" align="center" min-width="70"
                                            width="70">
                                            <template #default="du">
                                                <div>
                                                    <!-- actionheader -->
                                                    <!-- {{ du.row.cleaningdurationAh.toFixed(2) }} -->
                                                    {{ displayhours(du.row.cleaningdurationAh) }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Base" prop="duration" align="center" min-width="70"
                                            width="70">
                                            <template #default="du">
                                                <div>
                                                    <!-- actionheader -->
                                                    <!-- {{ du.row.basedurationAh.toFixed(2) }} -->
                                                    {{ displayhours(du.row.basedurationAh) }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Comment" prop="comment" align="center" min-width="250"
                                            width="250">
                                            <template #default="{ }">
                                                <div>
                                                    <!-- actionheader -->
                                                    <div>
                                                        {{ "-" }}
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Author" prop="author" align="center" min-width="250"
                                            width="250">
                                            <template #default="{ }">
                                                <div>
                                                    <!-- actionheader -->
                                                    <div>
                                                        {{ "-" }}
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Date Stamp" prop="comment" align="center" min-width="120"
                                            width="120">
                                            <template #default="{ }">
                                                <div>
                                                    <!-- actionheader -->
                                                    {{ "-" }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column>
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column label="Section/Opperative(s)" prop="name" min-width="325" width="325">
                            </el-table-column>
                            <el-table-column label="Estimate" prop="quote" align="center" min-width="70" width="70">
                                <template #default="qt">
                                    <div v-if="qt && qt.row">
                                        <!-- part -->
                                        <!-- {{ qt.row.quote == null ? "-" : qt.row.quote }} -->
                                        {{ displayestimatehours(qt.row.estimatedurationtotalPr ) }}
                                </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Actual" prop="duration" align="center" min-width="70" width="70">
                                <template #default="du">
                                    <div v-if="du && du.row">
                                            <!-- part -->
                                            <!-- {{ du.row.actualduration.toFixed(2) }} -->
                                            {{ displayhours(du.row.actualduration) }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Difference" prop="duration" align="center" min-width="70" width="70">
                                <template #default="du">
                                    <div v-if="du && du.row && du.row.proj">
                                        <!-- project -->
                                            <div :class="du.row.rowcolor">
                                            {{ getDifferencehours(du.row.actualduration,du.row.estimatedurationtotalPr,du.row) }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Cleaning" prop="duration" align="center" min-width="70" width="70">
                                <template #default="du">
                                    <div>
                                        <!-- part -->
                                        <!-- {{ du.row.cleaningdurationPr.toFixed(2) }} -->
                                        {{ displayhours(du.row.cleaningdurationPr) }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Base" prop="duration" align="center" min-width="70" width="70">
                                <template #default="du">
                                    <div>
                                        <!-- part -->
                                        <!-- {{ du.row.basedurationPr.toFixed(2) }} -->
                                        {{ displayhours(du.row.basedurationPr) }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Comment" prop="comment" align="center" min-width="250" width="250">
                                <template #default="{ }">
                                    <div>
                                        <!-- part -->
                                        <div>
                                            {{ "-" }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Author" prop="author" align="center" min-width="250" width="250">
                                <template #default="{ }">
                                    <div>
                                        <!-- part -->
                                        <div>
                                            {{ "-" }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Date Stamp" prop="comment" align="center" min-width="120" width="120">
                                <template #default="{ }">
                                    <div>
                                        <!-- part -->
                                        <div>
                                            {{ "-" }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column label="Section/Opperative(s)" prop="proj" min-width="325" width="325">
                    <template #default="pro">
                        <div>
                            <!-- project -->
                            <div>
                                {{ pro.row.proj.name }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Estimate" prop="quote" align="center" min-width="70" width="70">
                    <template #default="pro">
                        <div v-if="pro && pro.row">
                            <!-- project -->
                                {{ displayestimatehours(pro.row.estimatedurationtotalP) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Actual" prop="duration" align="center" min-width="70" width="70">
                    <template #default="du">
                        <div >
                            <!-- project -->
                                <!-- {{ du.row.proj.actualduration.toFixed(2) }} -->
                                {{ displayhours(du.row.proj.actualduration) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Difference" prop="duration" align="center" min-width="70" width="70">
                    <template #default="du">
                        <div v-if="du && du.row && du.row.proj">
                            <!-- project -->
                            <div :class="du.row.rowcolor">
                                {{ getDifferencehours(du.row.proj.actualduration,du.row.estimatedurationtotalP,du.row) }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Cleaning" prop="duration" align="center" min-width="70" width="70">
                    <template #default="du">
                        <div>
                            <!-- project -->
                            <div>
                                <!-- {{ du.row.cleaningdurationP.toFixed(2) }} -->
                                {{ displayhours(du.row.cleaningdurationP) }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Base" prop="duration" align="center" min-width="70" width="70">
                    <template #default="du">
                        <div>
                            <!-- project -->
                            <div>
                                <!-- {{ du.row.basedurationP.toFixed(2) }} -->
                                {{ displayhours(du.row.basedurationP) }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Comment" prop="comment" align="center" min-width="250" width="250">
                    <template #default="{ }">
                        <div>
                            <!-- project -->
                            <div>
                                {{ "-" }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Author" prop="author" align="center" min-width="250" width="250">
                    <template #default="{ }">
                        <div>
                            <!-- project -->
                            <div>
                                {{ "-" }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Date Stamp" prop="comment" align="center" min-width="120" width="120">
                    <template #default="{ }">
                        <div>
                            <!-- project -->
                            {{ "-" }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column>
                </el-table-column>
            </el-table>
        </el-row>
    </div>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            author: this.$store.state.user,
            proj: { id: null },
            filter: {},
            showPH: false,
            projectoptions: [],
            project: [],
            isaddedfilter: false
        }
    },
    computed: {
        getDifferencehours(){
            return function(actual,estimate,row){
                var actualh = this.displayhours(actual);
                var  estimateh = this.displayestimatehours(estimate);
                if(actualh != undefined && estimateh != undefined){
                        // Parse the time strings into moments
                        var estimateMoment = moment.duration(estimateh);
                        var actualMoment = moment.duration(actualh);
                         // Compare the durations
                        var difference;
                        if (estimateMoment > actualMoment) {
                            difference = estimateMoment.subtract(actualMoment);
                            row.rowcolor = "green";
                        } else {
                            difference = actualMoment.subtract(estimateMoment);
                            row.rowcolor = "red";
                        }
                        // Calculate the difference in milliseconds
                        // var difference = estimateMoment.subtract(actualMoment);

                        // Extract hours and minutes from the difference
                        var hours = Math.floor(difference.asHours());
                        var minutes = difference.minutes();

                        // Format the difference
                        var formattedDifference = hours + ":" + (minutes < 10 ? "0" : "") + minutes;
                        return formattedDifference;
                }
                
                return "";  
            }
        },
        // getredgreencalss(){
        //     return function(row1,row2,estimate,actual,green,red){
                
        //         if(row1 != undefined && row2 != undefined){
        //             if(row1[estimate] && row2[actual]){
                        
        //                 if(row1[estimate] < (row2[actual] * 60)){
        //                     return red;
        //                 }
        //                 if(row1[estimate] > (row2[actual] * 60)){
        //                    return green;
        //                 }
        //             }
        //         }
        //         return "";
        //     }
        // },
        getprojectcomputed() {
            if (this.project.length == 0) {
                return [];
            } else {
                this.project.forEach(p => {
                    var prtotal = 0;
                    p.basedurationtotalP = 0;
                    p.cleaningdurationtotalP = 0;
                    p.estimatedurationtotalP = 0;
                    p.proj.projectparts.forEach(pr => {
                        var parttotal = 0;
                        pr.basedurationtotalPr = 0
                        pr.cleaningdurationtotalPr = 0
                        pr.estimatedurationtotalPr = 0
                        pr.actionsview.forEach((av) => {
                            var actiontotalH = 0;
                            av.basedurationtotalAh = 0
                            av.cleaningdurationtotalAh = 0
                            av.estimatedurationtotalAh = 0
                            av.pactions.forEach((a) => {
                                var actiontotal = 0;
                                a.basedurationtotalA = 0
                                a.cleaningdurationtotalA = 0
                                a.estimatedurationtotalA = 0
                                a.timesheets.forEach((t) => {
                                    var timsheettotal = 0;
                                    timsheettotal += this.convertinttominute(t.totaldurationint);
                                    parttotal += timsheettotal;
                                    actiontotal += timsheettotal;
                                    actiontotalH += timsheettotal;
                                    prtotal += timsheettotal
                                    t.actualduration = this.isnunornot(timsheettotal);
                                    let cleanhour = 0;
                                    if (t.ph.cleaningminute != 0) {
                                        cleanhour = t.ph.cleaningminute / 60;
                                        t.ph.cleaningminutedisplay = this.decimaltotimeconvert(cleanhour);
                                    }
                                    var tdu = this.convertinttominute(t.totaldurationint) + cleanhour;
                                    t.baseduration = this.isnunornot(tdu);
                                    t.basedurationtotal = tdu;
                                    a.basedurationtotalA += tdu;
                                    a.cleaningdurationtotalA += cleanhour;
                                    if (t.ph.u.id != 0) {
                                        t.ph.canedit = true;
                                    }
                                })
                                av.basedurationtotalAh += a.basedurationtotalA;
                                av.cleaningdurationtotalAh += a.cleaningdurationtotalA;
                                av.estimatedurationtotalAh += a.estimatehour;
                                a.basedurationA = this.isnunornot(a.basedurationtotalA);
                                a.cleaningdurationA = this.isnunornot(a.cleaningdurationtotalA);
                                a.actualduration = this.isnunornot(actiontotal);
                            })
                            pr.basedurationtotalPr += av.basedurationtotalAh
                            pr.cleaningdurationtotalPr += av.cleaningdurationtotalAh
                            pr.estimatedurationtotalPr += av.estimatedurationtotalAh;
                            av.basedurationAh = this.isnunornot(av.basedurationtotalAh);
                            av.cleaningdurationAh = this.isnunornot(av.cleaningdurationtotalAh);
                            av.actualduration = this.isnunornot(actiontotalH);
                        })
                        p.basedurationtotalP += pr.basedurationtotalPr;
                        p.cleaningdurationtotalP += pr.cleaningdurationtotalPr; 
                        p.estimatedurationtotalP += pr.estimatedurationtotalPr;
                        pr.basedurationPr = this.isnunornot(pr.basedurationtotalPr);
                        pr.cleaningdurationPr = this.isnunornot(pr.cleaningdurationtotalPr);
                        pr.actualduration = this.isnunornot(parttotal);
                    })
                    p.cleaningdurationP = this.isnunornot(p.cleaningdurationtotalP);
                    p.basedurationP = this.isnunornot(p.basedurationtotalP);
                    p.proj.actualduration = this.isnunornot(prtotal);
                })
                return this.project
            }
        },
        getauthname() {
            return function (data) {
                let f = "", l = "";
                if (data.ph.u.lastname != undefined) {
                    l = data.ph.u.lastname
                }
                if (data.ph.u.firstname != undefined) {
                    f = data.ph.u.firstname
                }
                return f + " " + l;
            }
        },
        isnunornot() {
            return function (data) {
                if (isNaN(parseInt(data))) {
                    return 0;
                } else {
                    // return data;
                    return parseFloat(data);
                }
            }
        },
        dateget() {
            return function (date) {
                if (date != undefined) {
                    var d = moment(date).format("DD-MMM-YYYY HH:mm");
                    if (d == "Invalid date") {
                        return "";
                    }
                    return d;
                } else {
                    return "";
                }
            };
        },
        cleaningH() {
            return function (data) {
                return data * 60;
            }
        },
        convertinttominute() {
            return function (data) {
                let h = data / 60;
                var min = parseFloat(h);
                return min;
            }
        },
        decimaltotimeconvert() {
            return function (data) {
                if (data != 0) {
                    const absoluteValue = Math.abs(data);
                    const hours = Math.floor(absoluteValue);
                    const m = Math.round((absoluteValue - hours) * 60);
                    const minutes = (m < 10) ? "0" + m : m;
                    let time = (data < 0 ? '-' : '') + `${hours}.${minutes}`;
                    return time;
                }
            }
        },
        displayestimatehours(){
            return function (data) {
                if (data) {
                    let [h, m] = [Math.floor(data / 60), data % 60];
                    if (m > 59) {
                        h = h + 1;
                        m = 0;
                    }
                    const hours = (h < 10) ? "0" + h : h;
                    const minutes = (m < 10) ? "0" + m : m;
                    return (data < 0 ? '-' : '') + `${hours}:${minutes}`;
                }
            }
        },
        displayhours() {
            return function (data) {
                if (data) {
                    let value = Math.abs(data) * 60;
                    let [h, m] = [Math.floor(value / 60), Math.round(value % 60)];
                    if (m > 59) {
                        h = h + 1;
                        m = 0;
                    }
                    const hours = (h < 10) ? "0" + h : h;
                    const minutes = (m < 10) ? "0" + m : m;
                    return (data < 0 ? '-' : '') + `${hours}:${minutes}`;
                }
            }
        }
    },
    methods: {
        // cellStyleblackborder(data){
        //     if(data.columnIndex == 4 || data.columnIndex == 5){
        //         return { "border-left":" 2px solid #D3D3D3"}
        //     }
        // },
        // cellStylewhiteborder(data){
        //     if(data.columnIndex == 4 || data.columnIndex == 5){
        //         return { "border-left":" 2px solid white"}
        //     }
        // },
        setdefaultmonth() {
            let today = new Date();
            let day = today.getDate();
            let month = today.getMonth() - 1;
            let year = today.getFullYear();
            let lastonemonth = new Date(year, month, day);

            this.filter.startdate = moment(lastonemonth).format("YYYY-MM-DD").toString();
            this.filter.enddate = moment(today).format("YYYY-MM-DD").toString();
        },

        datechange(event, columnname) {
            if (event == null) {
                this.filter[columnname] = "";
            }
            else {
                this.filter[columnname] = moment(event).format("YYYY-MM-DD").toString();
            }

            this.myvalidate(() => {
                this.refresh();
            });
        },

        disablefuturedate(time) {
            return time.getTime() > Date.now();
        },

        stempchang(data) {
            if (data.ph.canedit == true) {
                data.ph.canedit = true;
                data.ph.editedon = new Date();
                data.ph.u.firstname = this.author.firstname;
                data.ph.u.lastname = this.author.lastname;
                data.ph.authorid = this.author.id;
                data.ph.actionid = data.actionid;
                data.ph.projectid = data.projectid;
                data.ph.projectpartid = data.projectpartid;
                data.ph.userid = data.userid;
            } else {
                data.ph.canedit = false;
                data.ph.cleaningminute = 0;
                data.ph.comment = "";
                data.ph.editedon = null;
                data.ph.u.firstname = "";
                data.ph.u.lastname = "";
                data.ph.authorid = null;
                data.ph.actionid = null;
                data.ph.projectid = null;
                data.ph.projectpartid = null;
                data.ph.userid = null;
            }
        },
        cleaningtime(event, data) {
            let value = parseFloat(event);
            if (!isNaN(value)) {
                var splitvalue = event.split(".");
                let hour = splitvalue[0] * 60;
                let totalminute = 0;
                if (splitvalue[1] == undefined) {
                    totalminute = Math.abs(hour);
                }
                else {
                    let minute = splitvalue[1] > 59 ? 59 : splitvalue[1];
                    totalminute = Math.abs(hour) + parseInt(minute);
                }
                data.ph.cleaningminute = value < 0 ? -totalminute : totalminute;
            }
            else {
                data.ph.cleaningminute = 0;
            }
            data.ph.cleaningminutedisplay = event;
        },
        tableRowClassPartName() {
            return 'row-part';
        },
        tableRowClassactionName() {
            return 'row-action';
        },
        tableRowClassactionheaderName() {
            return 'row-actionheader';
        },
        tableRowClassprojectName() {
            return 'row-project';
        },
        saveprojecthours() {
            var phs = [];
            this.project[0].proj.projectparts.forEach(pr => {
                pr.actionsview.forEach((av) => {
                    av.pactions.forEach((a) => {
                        a.timesheets.forEach((t) => {
                            if (t.ph.canedit == true) {
                                phs.push(t.ph);
                            }
                        })
                    })
                })
            });
            if (phs.length > 0) {
                this.post("project/saveprojecthours", phs).then((data) => {
                    this.$message({
                        showClose: true,
                        message: data.data,
                        type: 'success'
                    });
                    this.refresh();
                });
            } else {
                this.$message({
                    type: "info",
                    message: "Nothing to save",
                });
            }

        },
        searchproject: function (query, done) {
            if (query == undefined) { query = ""; }
            this.axios
                .post("project/searchproject?q=" + query, {})
                .then((response) => {
                    done(response.data);
                })
                .catch(this.apierror);
        },
        myvalidate: function (callback) {
            var msg = [];
            if (this.proj.id == null || this.proj.name == undefined) {
                msg.push("Please select project name.");
            }
            if (msg.length > 0) {
                var style = '<style> .el-message-box {width:50%;} </style>'
                this.$alert(msg.join('<br />') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
            }
            else {
                callback();
            }
        },
        refresh() {
            this.proj.drv = this.filter;
            this.post("project/getprojecthours", this.proj).then((data) => {
                if (data.data.p.name == null) {
                    data.data.p.name = this.proj.name;
                    data.data.id = this.proj.id;
                }
                this.project = [{ proj: data.data.p }];
                this.showPH = true;
                this.$store.state.p = null;
            })
        },
        isproject(data) {
            if (data == undefined || data == "") {
                this.project = [];
                this.showPH = false;
                this.proj = { id: null };
            }
        },
        selectproject(item) {
            this.project = [];
            if (item != "") {
                this.showPH = true;
                this.proj = item;
                this.refresh();
            }
        },
    },
    created() {
        if (this.$store.state.p != null && this.$store.state.p.name != undefined) {
            this.proj = this.$store.state.p;
            this.filter = this.$store.state.p.drv;
            this.refresh();
        } else {
            this.setdefaultmonth();
        }
    }
}
</script>
<style >
.el-table .row-actionheader {
    color: white;
    background-color: #999999 !important;
}

.el-table:hover .row-actionheader {
    color: white;
    background-color: #999999 !important;
}

.row-actionheader .el-table__expand-icon>.el-icon {
    color: white;
}

.el-table .row-project {
    color: white;
    background-color: #424242 !important;
}

.el-table:hover .row-project {
    color: white;
    background-color: #424242 !important
}

.row-project .el-table__expand-icon>.el-icon {
    color: white;
}

.el-table .row-action {
    color: var(--el-text-color-regular);
    background-color: #efefef !important;
}

.el-table:hover .row-action {
    color: var(--el-text-color-regular);
    background-color: #efefef !important;
}

.row-action .el-table__expand-icon>.el-icon {
    color: var(--el-text-color-regular);
}

.el-table .row-part {
    color: white;
    background-color: #606060 !important;
}

.el-table:hover .row-part {

    color: white;
    background-color: #606060 !important;
}

.row-part .el-table__expand-icon>.el-icon {
    color: white;
}
</style>
