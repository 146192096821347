<template>
    <div :class="checkdisplaypage">
         <!-- <el-affix :offset="52" > -->
             <div class="colorwhite stickyheader">
                  <el-row>
                     <div class="headerstyle" >{{displayheader}}</div>
                  </el-row>
                <el-row>
                    <div class="fullwidth">
                       <el-input class="width50 floatleft" v-model="view.filter[0].value" :placeholder="isactivityarea" @change="refresh" prefix-icon="search">
                       </el-input>
                       <el-button class="floatright" :disabled="!editpermission" type="primary" icon="CirclePlusFilled" @click="addrow"></el-button>
                   </div>
                </el-row>
                <el-row class="justifycontentcenter">
                 <el-pagination layout="prev, pager, next"
                       :total="view.count"
                       :page-size="view.pagesize"
                       @current-change="refresh"
                       v-model:currentPage="view.pageno">
                 </el-pagination>
              </el-row>
              <hr class="spaceBetween" />
              </div>
         <!-- </el-affix> -->
        <el-table :data="tabledata"
                  border
                  :default-sort="{prop: 'a.name', order: 'ascending'}"
                  @sort-change="sortchange">

                  <el-table-column label="Overheads" 
                             v-if="$route.path.includes('/overheadaction')"
                             prop="p.name"
                             sortable="custom"
                             min-width="325"
                             width="325">
                </el-table-column>

                <el-table-column :label="isactivityarea"
                                prop="a.name"
                                sortable="custom"
                                min-width="325"
                                width="325">
                </el-table-column>
                <el-table-column align="center" label="Active"  
                                prop="a.active"
                                sortable="custom"
                                min-width="70"
                                width="70">
                                <template #default="scope">
                                    <el-checkbox disabled v-model="scope.row.a.active"></el-checkbox>
                                </template>
                </el-table-column>
                <el-table-column align="center" label="Actions" :width="checkdisplay ? 90 : 54">
                                <template #default="scope">
                                    <el-button type="primary" class="marginr4 marginl2" @click="editrow(scope.row)"
                                        :icon="editpermission == true ? 'editPen' : 'view'">
                                    </el-button>
                                    
                                    <el-button v-if="checkdisplay"
                                                icon="delete" 
                                                class="marginl2"
                                                type="danger" @click="deleterow(scope.row)">
                                    </el-button>
                                </template>
                </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                view: {
                    pagesize:20,
                    filter: [
                        { table: 'a', column: 'name', value: '' },
                        { table: 'a', column: 'typeid', value: '', operation: '=' },
                    ],
                },
                tabledata: [],
                user: this.$store.state.user
            }
        },

        computed: {
            checkdisplaypage(){
                if (this.user.istimetrackeradmin) {
                   return this.$route.path == '/overheadaction' ? 'width811' : 'width450';
                }else{
                    // return  "width777";
                    return this.$route.path == '/overheadaction' ? 'width777' : 'width450';
                }
            },
            checkdisplay() {
                if (this.$route.path == '/overheadaction') {
                    if (this.user.istimetrackeradmin) {
                        return true;        
                    }
                }
                return false;
            },
            displayheader: function () {
                if (this.$route.path.includes("/projectaction")) {
                    return "Project Actions";
                }
                else {
                    return "Overhead Actions";
                }
            },
            isactivityarea: function () {
                if (this.$route.path.includes("/projectaction")) {
                    return "Name";
                }
                else {
                    return "Activity Area";
                }
            }
        },

        methods: {
            addrow: function () {
                if (this.$route.path.includes("/projectaction")) {
                    this.$router.push('/projectactionedit');
                }
                else {
                    this.$router.push('/overheadactionedit');
                }
            },

            editrow: function (row) {
                if (this.$route.path.includes("/projectaction")) {
                    this.$router.push({ path: '/projectactionedit', query: { id: row.a.id } });
                }
                else {
                    this.$router.push({ path: '/overheadactionedit', query: { id: row.a.id } });
                }
            },

            deleterow: function (row) {
                if (row.a.typeid == 2 || row.a.typeid == 3) {              
                    this.$confirm('Are you sure you want to Delete?', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }).then(() => {
                        this.showloading();
                        let data = { id: row.a.id, name: row.a.name };
                        this.$http.post("/action/deleteoverheadaction", data)
                        .then(response => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.refresh();
                        })
                        .catch(error => {
                            this.hideloading();
                            if (error.response != null && typeof (error.response.data) != "object") 
                            {
                                var style = '<style> .el-message-box {width:60vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>'
                                this.$alert(error.response.data + style, "Invalid request", { dangerouslyUseHTMLString: true });
                            } else {
                                this.apierror(error);
                            }
                        });
                    }).catch(() => {
                        this.$message({
                            showClose: true,
                            type: 'info',
                            message: 'Delete canceled'
                        });
                    });
                }
            },

            sortchange: function (sort) {
                if (this.$route.path.includes("/projectaction")) {
                    this.view.filter[1].value = "1";
                    this.view.filter[1].operation = "=";
                }
                else {
                    this.view.filter[1].value = "2,3";
                    this.view.filter[1].operation = "In";
                }
                this.view.sortcol = sort.prop;
                this.view.sortorder = sort.order;
                this.refresh();
            },

            refresh: function () {
                this.$store.state.actionview = this.view;
                this.showloading();
                this.axios.post("action/getview", this.view)
                    .then(response => {
                        this.hideloading();
                        this.tabledata = response.data.data;
                        this.view = response.data.view;                        
                    })
                    .catch(error => {
                        this.hideloading();
                        this.apierror(error);
                    })
            },
            routewatch(){
                   var sortobj = { prop: 'a.name', order: 'ascending' };
                   this.sortchange(sortobj);
            },
        },
         watch: {
             '$route.path':function(){
                   this.routewatch();
            }
         },
        created: function () {
           this.routewatch();
        }
    }
</script>