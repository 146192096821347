<template>
    <div v-if="showdata">
        <!-- <el-affix :offset="52"> -->
            <div class="colorwhite stickyheader">
                <el-row>
                    <el-col :span="24">
                        <span class="headerstyle">Error Log</span>
                    </el-col>
                </el-row>
                <div class="spaceBetween"></div>

                <el-row :gutter="5">
                    <el-col :span="6">
                        <el-date-picker v-model="view.filter[0].value"
                                        placeholder="Select Date"
                                        value-format="YYYY-MM-DD"
                                        style="width:100%"
                                        :clearable="false"
                                        @change="refresh"
                                        type="date">
                        </el-date-picker>
                    </el-col>

                    <el-col :span="6">
                        <el-input v-model="view.filter[1].value"
                                  placeholder="Level"
                                  prefix-icon="search"
                                  @change="refresh">
                        </el-input>
                    </el-col>

                    <el-col :span="12">
                        <el-pagination layout="prev, pager, next"
                                       :total="view.count"
                                       :page-size="view.pagesize"
                                       @current-change="refresh"
                                       v-model:currentPage="view.pageno">
                        </el-pagination>
                    </el-col>
                </el-row>
                <hr class="spaceBetween" />
            </div>
        <!-- </el-affix> -->

        <div class="wrapetextheight">
            <el-table :data="form"
                      :default-sort="{prop: 'l.date', order: 'descending'}"
                      @sort-change="sortchange"
                      border stripe
                      style="width:100%;">                      
                <el-table-column label="Error Message" width="110" type="expand">
                    <template #default="scope">
                        <div style="white-space:pre;">
                            {{ scope.row.l.message }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="Thread ID"
                                 prop="l.threadid"
                                 width="100"
                                 sortable="custom"></el-table-column>

                <el-table-column label="Error On"
                                 prop="l.date"
                                 :formatter="dateformatter"
                                 sortable="custom"
                                 width="170"></el-table-column>

                <el-table-column label="Level"
                                 prop="l.level"
                                 sortable="custom"
                                 width="100"></el-table-column>

                <el-table-column label="Callsite"
                                 prop="l.callsite"
                                 sortable="custom"
                                 width="170">
                    <template #default="scope">
                        <el-tooltip effect="light" placement="bottom-start" :content="scope.row.l.callsite">
                            <span>{{ scope.row.l.callsite }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>

                <el-table-column label="Stacktrace"
                                 prop="l.stacktrace"
                                 sortable="custom"
                                 width="170">
                    <template #default="scope">
                        <el-tooltip effect="light" placement="bottom-start" :content="scope.row.l.stacktrace">
                            <span>{{ scope.row.l.stacktrace }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>

                <el-table-column label="Delete" align="center" width="60">
                    <template #default="scope">
                        <el-button type="danger"
                                   icon="delete"
                                   @click="deleteRow(scope.row)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                showdata: false,
                view: {
                    filter: [
                        { table: 'l', column: 'date', value: '' },
                        { table: 'l', column: 'level', value: '' }
                    ],
                },
                form: []
            }
        },
        methods: {
            dateformatter: function (row, column, cellValue) {
                if (cellValue) {
                    var d = new Date(cellValue);
                    return d.toLocaleString();
                }
                else {
                    return "";
                }
            },

            sortchange: function (sort) {
                this.view.sortcol = sort.prop;
                this.view.sortorder = sort.order;
                this.refresh();
            },

            refresh: function () {
                this.showloading();
                if (this.view.filter[0].value == null) {
                    this.view.filter[0].value = "";
                }
                this.$http.post('errorlog/getview', this.view)
                    .then(response => {
                        this.form = response.data.data;
                        this.view = response.data.view;
                        this.hideloading();
                    })
                    .catch(error => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },

            deleteRow: function (row) {
                this.$confirm('Are you sure you want to Delete?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.showloading();
                    this.$http.post("/errorlog/deletelogerror", { id: row.l.id })
                        .then(response => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.refresh();
                        })
                        .catch(error => {
                            this.hideloading();
                            this.apierror(error);
                        });
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            }
        },
        created: function () {
            var sort = { prop: 'l.date', order: 'descending' };
            if (this.$store.state.user.superadmin == true) {
                this.sortchange(sort);
                this.showdata = true;
            }
            else {
                this.$router.push('/');
                this.showdata = false;
            }
        }
    }
</script>
<style>
    .wrapetextheight .el-table .cell {
        white-space: pre;
        max-height: 40px;
    }
</style>