<template>
    <div :class="checkdisplaypage">
         <!-- <el-affix :offset="52" > -->
             <div class="colorwhite stickyheader">
               <el-row>  
                   <el-col :span="12">
                  <div class="headerstyle">{{routerdata}}</div>
                   </el-col>
               </el-row>
               <el-row>
               <div class="fullwidth">
                <el-input class="width50 floatleft" :clearable="true"
                          v-model="view.filter[0].value"
                          placeholder="Name"
                          @change="refresh"
                          prefix-icon="search">
                </el-input>
                
               <!-- <div class=" floatright" /> -->
               <el-button class="floatright" type="primary"
               icon="circle-plus-filled" 
               @click="this.$router.push({path: routername})"
               :disabled="!editpermission">
               </el-button>
              
               </div>
               </el-row>
               <div class="spaceBetween"></div>
               <el-row :justify=" this.$route.path == '/project' ? 'space-between' : 'space-around'">
                   <el-pagination layout="prev, pager, next"
                         :total="view.count"                         
                         :page-size="view.pagesize"
                         @current-change="refresh"
                         v-model:currentPage="view.pageno">
                   </el-pagination>
                   <el-button v-if="this.$route.path == '/project'" class="floatright"  type="primary"
                           :disabled="!editpermission">
                           <el-switch
                           @change="refresh"
                            v-model="view.Dfilter.isarchive">
                            </el-switch>
                            &nbsp;
                            Archive 
                </el-button>
               </el-row>
         <hr class="spaceBetween" />
             </div>
         <!-- </el-affix> -->
        <el-table :data="tabledata"
                  border
                  :default-sort="{ prop: 'p.createdon', order: 'descending' }"
                  @sort-change="sortchange"
                 >

            <el-table-column label="Name"
                             prop="p.name"
                             sortable="custom"
                             min-width="330"
                             width="330">
            </el-table-column>

            <el-table-column label="Active"
                             prop="p.active"
                             sortable="custom"
                             width="70"
                             min-width="70"
                             align="center">
                <template #default="scope">
                    <el-checkbox :disabled="true" v-model="scope.row.p.active"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column align="center" label="Actions" :width="checkdisplay ? 90 : 54">
                <template #default="scope">
                    <el-button class="marginr4 marginl2" :icon="editpermission == true ? 'edit-pen' : 'view'" type="primary" @click="editrow(scope.row)"></el-button>
                    <el-button v-if="checkdisplay"
                               class="marginl2"
                               icon="delete" 
                               type="danger" 
                               @click="deleterow(scope.row)"></el-button>
                </template>
            </el-table-column>

        </el-table>
    </div>
</template>
<script>
    export default {

        data() {
            return {
                isarchive:false,
                routerdata: "",
                routername: "",
                view: {
                    pagesize:20,
                    filter: [
                        { table: "p", column: "name", value: "" },
                        { table: "p", column: "typeid", value: "1" },
                    ],
                    Dfilter: {isarchive:false},
                },
                tabledata: [],
                user: this.$store.state.user
            }
        },
        
        computed : {
            checkdisplaypage(){
                if (this.user.istimetrackeradmin) {
                   return this.$route.path == '/overhead' ? 'width486' : 'width455';
                }else{
                    return "width452";
                }
            },
            checkdisplay() {
                if (this.$route.path == '/overhead') {
                    if (this.user.istimetrackeradmin) {
                        return true;        
                    }
                }
                return false;
            }
        },

        methods: {
            changearchive(event){
                console.log(typeof(event));
                if(typeof(event) == "boolean"){
                    this.refresh();
                }else{
                    // this.view.Dfilter.isarchive = !this.view.Dfilter.isarchive;
                    this.refresh();
                }
            },
            editrow: function (row) {
                this.$router.push({ path: this.routername, query: { id: row.p.id,isarchive:this.view.Dfilter.isarchive } });
            },

            deleterow: function (row) {
                if (row.p.typeid == 2) {              
                    this.$confirm('Are you sure you want to Delete?', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }).then(() => {
                        this.showloading();
                        let data = { id: row.p.id, name: row.p.name };
                        this.$http.post("/project/deleteoverhead", data)
                        .then(response => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.refresh();
                        })
                        .catch(error => {
                            this.hideloading();
                            if (error.response != null && typeof (error.response.data) != "object") 
                            {
                                var style = '<style> .el-message-box {width:60vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>'
                                this.$alert(error.response.data + style, "Invalid request", { dangerouslyUseHTMLString: true });
                            } else {
                                this.apierror(error);
                            }
                        });
                    }).catch(() => {
                        this.$message({
                            showClose: true,
                            type: 'info',
                            message: 'Delete canceled'
                        });
                    });
                }
            },

            sortchange: function (sort) {
                this.view.sortcol = sort.prop;
                this.view.sortorder = sort.order;
                this.refresh();
            },

            refresh: function () {
                
                 this.routerdata = this.$route.path == "/project" ? "Project" : "Overhead";
                 this.routername = this.$route.path == "/project" ? "/projectedit" : "/overheadedit";
                 if (this.routerdata == "Project") {
                     this.view.filter[1].value = "1";
                 } else {
                     this.view.filter[1].value = "2";
                 }
                this.showloading();
                this.axios.post("project/GetView", this.view)
                    .then((response) => {
                        this.hideloading();
                        this.tabledata = response.data.data;
                        this.view = response.data.view;
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            }
        },
         watch: {
             '$route.path':function(){
                
                if(this.$route.path != "/project"){
                    this.view.Dfilter.isarchive = false;
                }
                  this.sortchange({ prop: 'p.createdon', order: 'descending' });
            }
         }, 
        created: function () {
            this.sortchange({ prop: 'p.createdon', order: 'descending' });
        }
    }
</script>
<style >

</style>
