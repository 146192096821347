<template>
    <div>
        <div v-if="pagedetail == false" class="invoicefulltablewidth">
            <!-- <div> -->
                <!-- <el-affix :offset="52"> -->
                    <div class="colorwhite stickyheader">
                        <el-row>
                            <el-col :span="8">
                                <div class="headerstyle">Outsource Invoices</div>
                            </el-col>

                            <el-col :span="10" :offset="2">
                                <el-pagination layout="prev, pager, next"
                                               :total="view.count"
                                               class="margint8"
                                               :page-size="view.pagesize"
                                               @current-change="refresh"
                                               v-model:currentPage="view.pageno">
                                </el-pagination>
                            </el-col>

                            <el-col :span="4">
                                <div class="floatright headerstyle">
                                    <span>
                                        <el-button type="primary"
                                            @click="addinvoice"
                                            icon="circle-plus-filled"
                                            :disabled="!editpermission">
                                        </el-button>
                                    </span>
                                </div>
                            </el-col>
                        </el-row>
                        <hr class="spaceBetween" />
                    </div>
                <!-- </el-affix> -->
            <!-- </div> -->

            <div>
                <el-table :border="true"
                          :data="tabledata"
                          :header-cell-style="{'background-color': '#f3f3f3'}">

                    <el-table-column label="User Name" :fixed="true" width="120">
                        <template #default="scope">
                            <div>
                                {{ scope.row.o.firstname }} {{ scope.row.o.lastname }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Invoice Number"
                                     prop="osi.invoicenumber"
                                     :fixed="true" width="100"></el-table-column>

                    <el-table-column label="Month"
                                    prop="osi.startdate"
                                    :formatter="monthformatter"
                                    width="75"></el-table-column>

                    <el-table-column label="Invoice Date"
                                     prop="osi.invoicedate"
                                     :formatter="dateformatter"
                                     width="110"></el-table-column>

                    <el-table-column label="Start Date"
                                     prop="osi.startdate"
                                     :formatter="dateformatter"
                                     width="110"></el-table-column>

                    <el-table-column label="End Date"
                                     prop="osi.enddate"
                                     :formatter="dateformatter"
                                     width="110"></el-table-column>


                    <el-table-column label="Sub Total" width="80">
                        <template #default="scope">
                            <div v-if="scope.row.osi.subtotal > 0">
                                {{ scope.row.osi.subtotal.toFixed(2) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Expenses" width="80">
                        <template #default="scope">
                            <div v-if="scope.row.osi.expensecost > 0">
                                {{ scope.row.osi.expensecost.toFixed(2) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Gross Total" width="80">
                        <template #default="scope">
                            <div v-if="scope.row.osi.grosstotal > 0">
                                {{ scope.row.osi.grosstotal.toFixed(2) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Deductions" width="80">
                        <template #default="scope">
                            <div v-if="scope.row.osi.deduction > 0">
                                {{ scope.row.osi.deduction.toFixed(2) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Net Invoice" width="80">
                        <template #default="scope">
                            <div v-if="scope.row.osi.netinvoice > 0">
                                {{ scope.row.osi.netinvoice.toFixed(2) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Creator" width="120">
                        <template #default="c">
                            <div>
                                {{ c.row.creator.firstname }} {{ c.row.creator.lastname }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center"
                                         label="Actions" width="54">
                            <template #default="scope">
                                <el-button type="primary" icon="view"
                                            :disabled="!editpermission" 
                                            @click="viewinvoice(scope.row)">
                                </el-button>
                            </template>
                        </el-table-column>
                </el-table>
            </div>
        </div>

        <div v-if="pagedetail" class="outsourcewidth">
            <!-- <div> -->
                <!-- <el-affix :offset="52"> -->
                    <div class="colorwhite stickyheader">
                        <el-row>
                            <el-col :span="12">
                                <div class="headerstyle">Edit Outsource Invoice</div>
                            </el-col>

                            <el-col :span="12">
                                <div class="floatright headerstyle">
                                    <span v-if="detailform.u">
                                        <el-button type="primary"
                                                   @click="getuseraddress">
                                            Edit Template
                                        </el-button>
                                    </span>
                                    <span v-if="detailform.u">
                                        <el-button type="success" 
                                                    @click="saveinvoice"
                                                    icon="circle-check"
                                                    :disabled="!editpermission || !iseditable"
                                                    class="marginleft4px">Save</el-button>
                                    </span>
                                    <span>
                                        <el-button @click="cancelinvoice" icon="back" class="marginleft4px">Back</el-button>
                                    </span>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="12">
                                <el-autocomplete v-if="checkdisplay"
                                                 v-model="selecteduser.fullname"
                                                 popper-class="customselect"
                                                 :fetch-suggestions="searchuser"
                                                 placeholder="All Users"
                                                 @select="selectuser"
                                                 @change="userchange"
                                                 :clearable="true"
                                                 :disabled="!iseditable"
                                                 class="inline-input paddingLR6 left">
                                    <template #default="{ item }">
                                        <div class="value">{{ item.fullname }}</div>
                                    </template>
                                </el-autocomplete>
                            </el-col>

                            <el-col :span="12">
                                <span class="floatright">
                                    <el-date-picker v-model="search.startdate"
                                                    type="month"
                                                    format="MMM-YYYY"
                                                    value-format="YYYY-MM-DD"
                                                    :editable="false"
                                                    :clearable="false"
                                                    :disabled="!iseditable"
                                                    class="width90px"
                                                    @change="changemonth($event)"
                                                    placeholder="Month"
                                                    :disabled-date="$commonutils.disableFutureDates">
                                    </el-date-picker>
                                </span>
                            </el-col>
                        </el-row>
                        <hr class="spaceBetween" />
                    </div>
                <!-- </el-affix> -->
            <!-- </div> -->

            <div v-if="detailform.u" id="InvoicePDF" ref="InvoicePDFref">
                <div class="Invoiceheader">
                    <div class="Invoicefont">
                        <span v-if="selecteduser.fullname">
                            {{ selecteduser.fullname }} - Invoice
                        </span>
                    </div>
                </div>
                <br>

                <el-row>
                    <el-col :span="10">
                        <el-descriptions :column="1">
                            <el-descriptions-item align="left">
                                <b class="descriptionlabel">FAO.</b><b>{{detailform.cd.name }}</b>
                            </el-descriptions-item>
                        </el-descriptions>

                        <div class="marginleft45px">
                            <el-descriptions :column="1">
                                <el-descriptions-item align="left">{{ detailform.cd.addressline1 }},</el-descriptions-item>
                                <el-descriptions-item align="left">{{ detailform.cd.addressline2 }},</el-descriptions-item>
                                <el-descriptions-item align="left">{{ detailform.cd.addressline3 }},</el-descriptions-item>
                                <el-descriptions-item align="left">{{ detailform.cd.addressline4 }}</el-descriptions-item>
                            </el-descriptions>
                        </div>
                    </el-col>

                    <el-col :span="7" :offset="7">
                        <el-descriptions :column="1">
                            <el-descriptions-item align="left">
                                <b class="descriptionlabel">From:</b><b>{{ selecteduser.fullname }}</b>
                            </el-descriptions-item>
                            <el-descriptions-item align="left">{{ detailform.osi.addressline1 }}</el-descriptions-item>
                            <el-descriptions-item align="left">{{ detailform.osi.addressline2 }}</el-descriptions-item>
                            <el-descriptions-item align="left">{{ detailform.osi.addressline3 }}</el-descriptions-item>
                            <el-descriptions-item align="left">{{ detailform.osi.addressline4 }}</el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="7" :offset="17">
                        <el-descriptions :column="1">
                            <el-descriptions-item align="left">
                                <b class="padright5px">Invoice Number:</b>
                                <el-input class="salesinputwidth85"
                                          :disabled="!iseditable"
                                          v-model="detailform.osi.invoicenumber" />
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="17">
                        <el-descriptions :column="1">
                            <el-descriptions-item align="left">
                                <b class="padright5px">Period:</b>{{ display.start }} till {{ display.end }}
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>

                    <el-col :span="7">
                        <el-descriptions :column="1">
                            <el-descriptions-item align="left">
                                <b class="padright5px">Invoice Date:</b>{{ display.invoicedate }}
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                </el-row>
                <hr size="2" width="100%" color="#606060">

                <div>
                    <el-table :border="true"
                              style="width:690px;"
                              :data="detailform.osi.details"
                              :header-cell-style="{'background-color': '#f3f3f3'}">
                        <el-table-column label="Project / Overhead" width="369">
                            <template #default="scope">
                                <div v-if="scope.row.p.typeid == 1">
                                    <span>{{ scope.row.p.name }}</span>
                                    <span v-if="scope.row.pp.id > 0"> - {{ scope.row.pp.name }}</span>
                                    <span v-else> - Single Bill</span>
                                </div>
                                <div v-if="scope.row.p.typeid == 2">
                                    {{ scope.row.p.name }} - {{ scope.row.a.name }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Hours"
                                         prop="i.durationstr"
                                         align="right"
                                         header-align="center" width="80" />

                        <el-table-column label="Rate"
                                         header-align="center"
                                         align="right" width="80">
                            <template #default="scope">
                                <div v-if="scope.row.op.rate > 0">
                                    {{ scope.row.op.rate.toFixed(2) }}
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Currency"
                                         align="center"
                                         prop="cc.code" width="80" />

                        <el-table-column label="Total"
                                         prop="total"
                                         header-align="center"
                                         align="right" width="80">
                            <template #default="scope">
                                <div v-if="scope.row.i.total > 0">
                                    {{ scope.row.i.total.toFixed(2) }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <hr size="2" width="100%" color="#606060">

                <el-row>
                    <el-col :span="21">
                        <div class="margintop30px" />
                        <el-form label-position="left">
                            <el-form-item label="Expenses / Fixed Costs:" label-width="165px">
                                <el-input :disabled="!iseditable" v-model="detailform.osi.expensecomment" />
                            </el-form-item>
                            <div class="margintop30px" />

                            <el-form-item label="Deductions / Equipment:" label-width="165px">
                                <el-input :disabled="!iseditable" v-model="detailform.osi.deductioncomment" />
                            </el-form-item>
                        </el-form>
                    </el-col>

                    <el-col :span="3">
                        <div>
                            <span class="right totalwidth">
                                <div class="fontsize12 bold invoicetotal">{{ detailform.osi.subtotalstr }}</div>

                                <div class="myborder">
                                    <el-input-number :precision="2"
                                                     :controls="false"
                                                     :disabled="!iseditable"
                                                     v-model="detailform.osi.expensecost"
                                                     @change="changedexpense($event, detailform.osi)"
                                                     :class="detailform.osi.expensecost < 0 ? 'outsourceinput inputtypenumlr6px inputtextred' : 'inputtypenumlr6px outsourceinput'" />
                                </div>

                                <div class="fontsize12 bold invoicetotal">
                                    <span>{{ detailform.osi.grosstotalstr }}</span>
                                </div>

                                <div class="myborder">
                                    <el-input-number :precision="2"
                                                     :controls="false"
                                                     :disabled="!iseditable"
                                                     v-model="detailform.osi.deduction"
                                                     class="outsourceinput inputtextred inputtypenumlr6px"
                                                     @change="changededuction($event, detailform.osi)" />
                                </div>

                                <div class="fontsize12 bold invoicetotal">
                                    <span>
                                        {{ detailform.osi.netinvoicestr }}
                                    </span>
                                </div>
                            </span>
                        </div>
                    </el-col>
                </el-row>
                <hr size="2" width="100%" color="#606060">

                <el-row class="fullwidth" justify="space-between">
                    <el-col :span="7">
                        <el-descriptions :column="1">
                            <el-descriptions-item align="left">
                                <b class="padright5px">Payment Date:</b><span v-if="detailform.pd.id > 0">{{ detailform.pd.createdon }}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>

                    <el-col :span="10">
                        <el-descriptions :column="1">
                            <el-descriptions-item align="left">
                                <b class="padright5px">Transaction Reference:</b>{{ detailform.pd.transactionreference }}
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>

                    <el-col :span="7">
                        <el-descriptions :column="1">
                            <el-descriptions-item align="left">
                                <b class="padright5px">Payment:</b>{{ detailform.pd.GBPcost }}
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                </el-row>

                <el-row class="fullwidth" justify="space-between">
                    <el-col :span="12">
                        <div class="floatleft">
                            <span class="floatleft checkheight">
                                <el-checkbox :disabled="!iseditable" v-model="detailform.osi.issignoff"></el-checkbox>
                            </span>
                            <span class="floatleft">
                                <el-descriptions :column="1">
                                    <el-descriptions-item align="left">
                                        <b class="padright5px">Signoff by:</b>
                                        <span v-if="selecteduser.id > 0 && detailform.osi.issignoff == true && detailform.osi.signoff.id > 0">
                                            {{ detailform.osi.signoff.firstname }} {{ lastsignoff }}
                                        </span>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </span>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div v-if="detailform.u">                
                <div class="floatright">
                    <span v-if="detailform.osi.id > 0">
                        <el-button type="primary"
                                    icon="edit-pen"
                                   :disabled="!editpermission"
                                   @click="editrow">
                                </el-button>
                    </span>
                    <span v-if="detailform.osi.id > 0">
                        <el-button type="primary"
                                   class="marginleft5"
                                   icon="refresh"
                                   :disabled="!editpermission || !iseditable"
                                   @click="reloadinvoice">Reload</el-button>
                    </span>

                    <span>
                        <el-button type="primary" 
                                   icon="document"
                                   class="marginleft4px"
                                   @click="downloadpdf">
                                   Invoice PDF
                        </el-button>
                    </span>
                </div>
            </div>

            <div>
                <el-dialog title="Details"
                           v-model="addresstemplate"
                           custom-class="centerdialog"
                           width="400px" center>
                    <el-form>
                        <el-form-item label="Address line 1" label-width="115px">
                            <el-input placeholder="Address line 1"
                                      v-model="supplier.addressline1"
                                      @change="detailchanged(supplier, true)"></el-input>
                        </el-form-item>
                        <div class="spaceBetween" />

                        <el-form-item label="Address line 2" label-width="115px">
                            <el-input placeholder="Address line 2"
                                      v-model="supplier.addressline2"
                                      @change="detailchanged(supplier, true)"></el-input>
                        </el-form-item>
                        <div class="spaceBetween" />

                        <el-form-item label="Address line 3" label-width="115px">
                            <el-input placeholder="Address line 3"
                                      v-model="supplier.addressline3"
                                      @change="detailchanged(supplier, true)"></el-input>
                        </el-form-item>
                        <div class="spaceBetween" />

                        <el-form-item label="Address line 4" label-width="115px">
                            <el-input placeholder="Address line 4"
                                      v-model="supplier.addressline4"
                                      @change="detailchanged(supplier, true)"></el-input>
                        </el-form-item>
                    </el-form>

                    <template #footer>
                        <span>
                            <el-button type="success" 
                                icon="circle-plus-filled"
                                @click="savesupplierdetail()">
                                Save
                            </el-button>
                        </span>
                    </template>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
    import { jsPDF } from "jspdf";
    import moment from "moment";
    import { ElMessage } from "element-plus";
    import { eventbus } from "../eventbus";
    export default {
        data() {
            return {
                tabledata: [],
                view: {
                    filter: []
                },
                pagedetail: false,
                selecteduser: {},
                detailform: {},
                display: {},
                user: this.$store.state.user,
                addresstemplate: false,
                supplier: {},
                search: { id: 0, startdate: "", enddate: "", userid: "" },
                iseditable: false,
                lastsignoff: ""
            };
        },
        computed: {
            checkdisplay() {
                return !!(this.user.istimetrackeradmin || this.user.superadmin);
            }
        },

        methods: {
            refresh() {
                this.showloading();
                this.$http.post("InternationalPayment/getinvoiceview", this.view)
                    .then((response) => {
                        this.hideloading();
                        this.tabledata = response.data.data;
                        this.view = response.data.view;
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },

            currentChange: function (val) {
                this.view.pageno = val;
                this.refresh();
            },

            sortchange: function (sort) {
                this.view.sortcol = sort.prop;
                this.view.sortorder = sort.order;
                this.refresh();
            },

            dateformatter(_row, _column, cellValue) {
                if (cellValue) {
                    return moment(cellValue).format("ddd DD-MMM-YYYY");
                }
                return cellValue;
            },

            monthformatter(_row, _column, cellValue) {
                if (cellValue) {
                    return moment(cellValue).format("MMM-YYYY");
                }
                return cellValue;
            },

            deleterow: function (row) {
                this.$confirm('Are you sure you want to Delete?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.showloading();
                    let data = { id: row.osi.id };
                    this.$http.post("InternationalPayment/deleteinvoice", data)
                        .then(response => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.refresh();
                        })
                        .catch(error => {
                            this.hideloading();
                            if (error.response != null && typeof (error.response.data) != "object") {
                                let style = '<style> .el-message-box {width:60vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>';
                                this.$alert(error.response.data + style, "Invalid request", { dangerouslyUseHTMLString: true });
                            } else {
                                this.apierror(error);
                            }
                        });
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },

            addinvoice() {
                this.iseditable = true;
                eventbus.$on("setuser", (user2) => {
                    this.user = user2;
                });
                this.selecteduser = this.user;
                this.selecteduser.fullname = this.user.firstname + " " + this.user.lastname;
                this.search.userid = this.user.id;
                this.search.id = 0;
                this.changemonth(new Date());
            },

            userchange() {
                this.selecteduser = {};
                this.supplier = {};
                this.detailform = { osi: { details: [] } };
                this.lastsignoff = "";
            },

            selectuser(item) {
                if (item.loginname != null) {
                    this.selecteduser = item;
                    this.search.userid = item.id;
                    this.getinvoice();
                }
            },

            changemonth(event) {
                if (event == null) {
                    this.search.startdate = "";
                    this.search.enddate = "";
                }
                else {
                    let date = new Date(event);
                    let y = date.getFullYear(), m = date.getMonth();
                    let firstDay = new Date(y, m, 1);
                    let lastDay = new Date(y, m + 1, 0);
                    this.search.startdate = moment(firstDay).format("YYYY-MM-DD").toString();
                    this.search.enddate = moment(lastDay).format("YYYY-MM-DD").toString();
                    this.invoiceperiod(this.search);
                    this.getinvoice();
                }
            },

            viewinvoice(row) {
                let data = row.osi;
                this.showloading();
                this.$http.post("InternationalPayment/getinvoicebyid", data)
                    .then((response) => {
                        this.hideloading();
                        this.iseditable = false;
                        this.search = { id: response.data.osi.id, startdate: response.data.osi.startdate, enddate: response.data.osi.enddate, userid: response.data.osi.userid };
                        this.selecteduser = {
                            id: response.data.osi.userid,
                            fullname: `${response.data.u.firstname} ${response.data.u.lastname}`
                        };
                        this.lastsignoff = moment(response.data.osi.signoffon).format("DD-MMM-YYYY HH:mm");
                        response.data.pd.createdon = this.setdateformate(response.data.pd.createdon);
                        response.data.pd.GBPcost = response.data.pd.GBPcost <= 0 || response.data.pd.GBPcost == null ? null : new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(response.data.pd.GBPcost));
                        response.data.osi.details.forEach(row => {
                            if (row.i.duration) {
                                let duration = row.i.duration;
                                let h = Math.floor(duration / 60), m = duration % 60;
                                row.i.durationstr = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
                            }
                        });
                        this.convertstringtoint(response.data.osi, "subtotal", response.data.osi.subtotal);
                        this.convertstringtoint(response.data.osi, "grosstotal", response.data.osi.grosstotal);
                        this.convertstringtoint(response.data.osi, "netinvoice", response.data.osi.netinvoice);
                        this.display.start = this.setdateformate(response.data.osi.startdate);
                        this.display.end = this.setdateformate(response.data.osi.enddate);
                        this.display.invoicedate = this.setdateformate(response.data.osi.invoicedate);
                        this.pagedetail = true;
                        this.detailform = response.data;
                        this.supplier = this.detailform.u;
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },

            getinvoice() {
                this.showloading();
                this.$http.post("InternationalPayment/getinvoicedetail", this.search)
                    .then((response) => {
                        this.hideloading();
                        this.pagedetail = true;
                        this.calculateinvoice(response.data);
                        this.supplier = response.data.u;
                        this.detailform = response.data;
                        let address = JSON.parse(JSON.stringify(this.supplier));
                        this.setaddress(address);
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },

            invoiceperiod(v) {
                let period = {
                    start: this.setdateformate(v.startdate),
                    end: this.setdateformate(v.enddate),
                    invoicedate: this.setdateformate(new Date())
                };
                this.display = period;
            },
            setdateformate(value) {
                if (value) {
                    return moment(value).format("DD-MMM-YYYY").toString();
                }
                return null;
            },

            setaddress(row) {
                this.detailform.osi.addressline1 = row.addressline1;
                this.detailform.osi.addressline2 = row.addressline2;
                this.detailform.osi.addressline3 = row.addressline3;
                this.detailform.osi.addressline4 = row.addressline4;
            },

            convertstringtoint(row, name, event) {
                let v = parseFloat(event);
                if (!isNaN(v) && isFinite(v) && v != 0) {
                    row[name] = parseFloat(event);
                    row[name + "str"] = parseFloat(row[name]).toFixed(2);
                } else {
                    row[name] = null;
                    row[name + "str"] = null;
                }
            },

            calculateinvoice(item) {
                let summary = 0;
                item.pd.createdon = this.setdateformate(item.pd.createdon);
                item.pd.GBPcost = item.pd.GBPcost <= 0 || item.pd.GBPcost == null ? null : new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(item.pd.GBPcost));
                item.osi.details.forEach(row => {
                    if (row.i.duration) {
                        let duration = row.i.duration;
                        let h = Math.floor(duration / 60), m = duration % 60;
                        row.i.durationstr = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
                        if (duration > 0 && row.op.rate > 0) {
                            let d = duration / 60;
                            row.i.total = d * row.op.rate;
                            summary += row.i.total;
                        }
                    }
                });
                this.convertstringtoint(item.osi, "subtotal", summary);
                this.convertstringtoint(item.osi, "grosstotal", summary);
                this.convertstringtoint(item.osi, "netinvoice", summary);
            },

            changedexpense(event, row) {
                let value = parseFloat(event);
                if (!isNaN(value) && isFinite(value)) {
                    let summary = parseFloat(row.subtotal);
                    let calculateexpense = summary + value;
                    this.convertstringtoint(row, "grosstotal", calculateexpense);
                    let reduces = parseFloat(row.deduction);
                    if (!isNaN(reduces) && isFinite(reduces)) {
                        this.changededuction(reduces, row);
                    }
                    else {
                        // row.netinvoice = row.grosstotal;
                        this.convertstringtoint(row, "netinvoice", row.grosstotal);
                    }
                }
            },

            changededuction(event, row) {
                let value = parseFloat(event);
                if (!isNaN(value) && isFinite(value)) {
                    let allexpense = parseFloat(row.grosstotal);
                    let total = allexpense - value;
                    this.convertstringtoint(row, "netinvoice", total);
                }
            },

            cancelinvoice() {
                this.pagedetail = false;
                this.supplier = {};
                this.detailform = { osi: { details: [] } };
                this.lastsignoff = "";
            },

            editrow() {
                this.iseditable = true;
            },
            reloadinvoice() {
                this.$confirm('Are you sure you want to Reload this invoice?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    if (this.iseditable && this.search.userid > 0) {
                        this.getinvoice();
                    }
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'canceled'
                    });
                });
            },

            invoicevalidate: function (callback) {
                let msg = [];
                if (!this.detailform.osi.invoicenumber || this.detailform.osi.invoicenumber.trim() == "") {
                    msg.push("Please enter Invoice Number.");
                }
                if (msg.length > 0) {
                    let style = '<style> .el-message-box {width:50%;} </style>'
                    this.$alert(msg.join('<br />') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }
                else {
                    callback();
                }
            },

            saveinvoice() {
                this.detailform.osi.userid = this.search.userid;
                this.detailform.osi.startdate = this.search.startdate;
                this.detailform.osi.enddate = this.search.enddate;
                let data = this.detailform.osi;
                this.invoicevalidate(() => {
                    this.showloading();
                    this.$http.post("InternationalPayment/saveoutsourceinvoice", data)
                        .then((response) => {
                            this.hideloading();
                            this.cancelinvoice();
                            this.refresh();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.search.id = 0;
                            this.pagedetail = false;
                        })
                        .catch(error => {
                            this.hideloading();
                            if (error.response != null && typeof (error.response.data) != "object") {
                                let style = '<style> .el-message-box {width:60vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>'
                                this.$alert(error.response.data + style, "Invalid request", { dangerouslyUseHTMLString: true });
                            } else {
                                this.apierror(error);
                            }
                        });
                });
            },

            getuseraddress() {
                let data = { id: this.selecteduser.id };
                this.showloading();
                this.$http.post("account/getaddress", data)
                    .then((response) => {
                        this.addresstemplate = true;
                        this.hideloading();
                        this.supplier = response.data;
                    })
                    .catch((error) => {
                        this.hideloading();
                        this.apierror(error);
                    });
            },

            detailchanged: function (row, event) {
                row.edited = event;
            },

            validateuserdetail: function (callback) {
                let msg = [];
                if (!this.supplier.addressline1) {
                    msg.push("Address line 1 is required");
                }
                if (!this.supplier.addressline2) {
                    msg.push("Address line 2 is required");
                }
                if (msg.length > 0) {
                    let style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(msg.join('<br/>') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }
                else {
                    callback();
                }
            },

            savesupplierdetail() {
                if (this.supplier.edited) {
                    this.validateuserdetail(() => {
                        this.showloading();
                        this.$http.post("account/updateuseraddress", this.supplier)
                            .then((response) => {
                                this.hideloading();
                                this.$message({
                                    showClose: true,
                                    message: response.data.message,
                                    type: "success"
                                });
                                this.addresstemplate = false;
                                this.getinvoice();
                            })
                            .catch((err) => {
                                this.hideloading();
                                this.alerterror(err);
                            });
                    });
                }
                else {
                    this.$message({
                        type: "info",
                        showClose: true,
                        message: "Nothing to save"
                    });
                }
            },

            downloadpdf() {
                let dd = document.querySelector("#InvoicePDF");
                if (dd) {
                    if (!this.detailform.u) {
                        ElMessage({
                            showClose: true,
                            message: "Please Select User First!",
                            type: "error",
                        });
                        return;
                    }
                    const filename = `${this.detailform.u.firstname}-${this.detailform.u.lastname}-TSA-Invoice.pdf`;
                    let doc = new jsPDF('1', 'mm', [1200, 1210]);
                    doc.html(dd, {
                        callback: function (doc) {
                            doc.save(filename);
                        },
                        x: 10,
                        y: 10,
                    });
                }
            }
        },

        created() {
            let sortobj = { prop: 'osi.invoicedate', order: 'descending' };
            this.sortchange(sortobj);
        }
    };
</script>